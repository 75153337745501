import styled from 'styled-components';
import { SelectWrapper } from 'components/Form/Select/Select.ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 2.4rem;
  &,
  ${SelectWrapper} {
    ${({ theme }) => theme.mediaQueries.MD`width: 100%;`}
  }
  ${({ theme }) => (theme.isMobile ? 'padding: 0 2.4rem' : '')}
`;

export const LanguageLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  img {
    height: 2rem;
    display: block;
  }
  svg {
    margin-right: 0.266rem;
    path {
      stroke: currentColor;
    }
  }
`;
