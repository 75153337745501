import { put, takeLatest } from 'redux-saga/effects';

import {
  ADD_NOMINEE,
  DELETE_NOMINEE,
  ADD_BENEFICIARY,
  DELETE_BENEFICIARY,
  GET_USER_BENEFICIARIES,
  GET_USER_NOMINEES,
  UPDATE_PROFILE_DETAILS,
} from './userConstants';

import { getUserProfileRequest, getUserDataRequest } from './userActions';

function* watchUpdatePersonalDetails() {
  // yield put(change(CONTACT_DETAILS_FORM, 'showAlert', true));
  yield put(getUserProfileRequest());
}

function* watchUpdateBeneficiariesList() {
  yield put(getUserDataRequest(GET_USER_BENEFICIARIES));
}
function* watchUpdateNomineeList() {
  yield put(getUserDataRequest(GET_USER_NOMINEES));
}

function* userSaga() {
  yield takeLatest(UPDATE_PROFILE_DETAILS.SUCCESS, watchUpdatePersonalDetails);
  yield takeLatest(ADD_BENEFICIARY.SUCCESS, watchUpdateBeneficiariesList);
  yield takeLatest(DELETE_BENEFICIARY.SUCCESS, watchUpdateBeneficiariesList);
  yield takeLatest(ADD_NOMINEE.SUCCESS, watchUpdateNomineeList);
  yield takeLatest(DELETE_NOMINEE.SUCCESS, watchUpdateNomineeList);
}

export default userSaga;
