import { defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  toEarlyError: {
    id: 'DatePicker.error.toEarly',
    defaultMessage: 'The selected date is before the minimum allowed date',
  },
  toLateError: {
    id: 'DatePicker.error.toLate',
    defaultMessage: 'The selected date is after the maximum allowed date',
  },
  invalidDateError: {
    id: 'DatePicker.error.invalidDateError',
    defaultMessage: 'Invalid date entered',
  },
  isRequiredError: {
    id: 'DatePicker.error.isRequiredError',
    defaultMessage: 'The date is required',
  },
  backToYear: {
    id: 'DatePicker.backToYear',
    defaultMessage: 'Chose year',
  },
  backToMonth: {
    id: 'DatePicker.backToMonth',
    defaultMessage: 'Chose Month',
  },
});
