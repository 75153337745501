export interface TRoutingPaths {
  home: string;
  user: {
    dashboard: string;
    profile: string;
    myTransactions: string;
    myBeneficiaries: string;
    myNominees: string;
    claimMiles: string;
    milesCalculator: string;
    useMiles: {
      bookAwardTicket: string;
      useMilesForExtraBaggage: string;
      useMilesForUpgrading: string;
    };
  };
  login: string;
  registration: string;
  forgotPassword: string;
  newsletter: string;
}

export const ROUTING_PATHS: TRoutingPaths = {
  home: '/',
  user: {
    dashboard: '/dashboard',
    profile: '/profile',
    myTransactions: '/transactions',
    myBeneficiaries: '/beneficiaries',
    myNominees: '/nominees',
    claimMiles: '/claimMiles',
    milesCalculator: '/calculator',
    useMiles: {
      bookAwardTicket: '/awardTicket',
      useMilesForExtraBaggage: '/extraBaggage',
      useMilesForUpgrading: '/upgrade',
    },
  },
  login: '/login',
  registration: '/register',
  forgotPassword: '/forgotPassword/:ffNumber?',
  newsletter: '/newsletter',
};
