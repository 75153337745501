import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.colors.overlayColor || '#00000088'};
  display: flex;
  overscroll-behavior: contain;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  z-index: 8;
`;
export const DialogTitle = styled.div`
  ${props => props.theme.textStyles.header.h2};
`;
export const DialogActionButtons = styled.div<{ $justify: 'space-between' | 'start' | 'center' | 'end' }>`
  padding-top: 3.2rem;
  border-top: 0.1rem solid ${({ theme }) => theme.borderColor};
  display: flex;
  width: 100%;
  justify-content: ${({ $justify }) => $justify};
  align-items: center;
  gap: 8px;
`;

export const DialogContent = styled.div<{ $contentPadding?: string }>`
  ${({ theme }) => theme.textStyles.body.regular}
  padding: ${({ theme, $contentPadding }) => $contentPadding || theme.defaultPadding};
  flex-grow: 1;
`;

export const DialogWrapper = styled.div<{ $isMobile: boolean }>`
  min-width: ${({ $isMobile }) => `${$isMobile ? 100 : 40}vw`};
  max-width: ${({ $isMobile }) => `${$isMobile ? 100 : 80}vw`};
  min-height: ${({ $isMobile }) => ($isMobile ? 'calc(100% - 6.4rem)' : '20rem')};
  max-height: ${({ $isMobile }) => ($isMobile ? 'calc(100% - 4.8rem)' : '90vw')};
  padding: ${({ theme, $isMobile }) => ($isMobile ? '0' : theme.defaultPadding || '4rem')};
  background: ${({ theme }) => theme.colorPrimary.backgroundColor || '#fff'};
  border-radius: ${({ theme, $isMobile }) => ($isMobile ? `${theme.borderRadius} ${theme.borderRadius} 0 0` : theme.borderRadius || '1rem')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  ${({ $isMobile }) =>
    $isMobile &&
    `
    align-self: flex-end;
    ${DialogContent}, ${DialogActionButtons} { padding: 1.6rem; };
  `}
`;

export const DialogActionButton = styled.button<{ $type?: 'danger' | 'success' | 'info' }>`
  ${({ $type, theme }) =>
    $type === 'danger'
      ? `background: ${theme.colors.errorDark};
		color: ${theme.colors.white};`
      : $type === 'success'
      ? `background: ${theme.colors.successDark};
		color: ${theme.colors.white};`
      : $type === 'info'
      ? `background: ${theme.colors.infoDark};
		color: ${theme.colors.white};`
      : ''}
`;

export const DialogClose = styled.div`
  text-align: right;
  margin-left: auto;
  & button {
    cursor: pointer;
  }
`;

export const MobileDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & ${DialogTitle} {
    ${props => props.theme.textStyles.header.h3};
    color: ${({ theme }) => theme.colors.white};
  }
  background: ${({ theme }) => theme.primaryColor || 'gray'};
  border-radius: ${({ theme }) => `${theme.borderRadius || '1rem'} ${theme.borderRadius || '1rem'} 0 0`};
  padding: 1.6rem;
`;

export const DialogBody = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
`;
