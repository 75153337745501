import { all, put, takeLatest } from 'redux-saga/effects';
// import { GET_BOOKINGS } from './bookingsConstants';
// import { getCheckinDetailsRequest } from './bookingsActions';
import { TApiSuccessResponseAction } from '../request/requestActions';

// export function* fetchCheckinDetails(action: TApiSuccessResponseAction<{ bookings: TBooking[] }>) {
//   const bookings: TBooking[] = action.response.bookings;
//   yield all(
//     bookings.map(({ recordLocator, passengerLastName }) =>
//       put(
//         getCheckinDetailsRequest({
//           pnr: recordLocator,
//           passengerLastName: passengerLastName
//         })
//       )
//     )
//   );
// }
// function* test(){
// 	yield console.log('in test');
// }

export function* selfServiceSaga() {
  // yield takeLatest(GET_BOOKINGS.SUCCESS, fetchCheckinDetails);
  // yield takeLatest([CHECK_POINTS_FOR_AWARD_TICKET.SUCCESS], test);
}
