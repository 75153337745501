import styled from 'styled-components';
import { CommonErrorStyles } from '../Form.ui';

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const RadioWrapper = styled.div<{ $direction: 'row' | 'column'; $error: boolean }>`
  display: flex;
  gap: 2.4rem;
  flex-direction: ${({ $direction }) => $direction};
  ${({ $direction }) => ($direction === 'row' ? `flex-wrap: wrap;` : '')}
  padding-left: 0.1rem;
  ${({ $error, theme }) =>
    $error
      ? `
    border-radius: ${theme.borderRadius};
    outline: 0.1rem solid ${theme.colors.errorDark};
    outline-offset: 1rem;`
      : ''}
`;

export const FieldWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: flex-start;
  input,
  label {
    cursor: pointer;
  }
`;

export const RadioInput = styled.input.attrs(({ disabled }) => {
  const attributes = { type: 'radio' };
  if (disabled) attributes['aria-disabled'] = true;
  return attributes;
})`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -0.1rem;
    left: -0.1rem;
    width: 2.2rem;
    height: 2.2rem;
    background: ${({ theme }) => theme.backgroundColor || '#fff'};
    border-radius: 50%;
    border: solid 0.2rem ${({ theme, disabled }) => (disabled ? theme.disabledColor || '#073B5014' : theme.primaryColor || '#000')};
    pointer-events: none;
  }
  &:checked::before {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.primaryColor || '#000'};
    z-index: 1;
    pointer-events: none;
  }
`;

export const RadioLabel = styled.label`
  ${({ theme }) => theme.textStyles.body.regular}
`;

export const RadioErrorMessage = styled(CommonErrorStyles)`
  padding: 0 1.6rem;
`;
