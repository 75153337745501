import { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import * as ui from './ExpandableMenu.ui';
import Icon from 'components/Icon';

interface ExpandableMenuProps {
  label: ReactNode;
  children: ReactNode;
  openByDefault?: boolean;
}
const ExpandableMenu = ({ label, children, openByDefault = false }: ExpandableMenuProps) => {
  const [isOpen, setOpenState] = useState(openByDefault);
  const [openedHeight, setOpenedHeight] = useState(0);
  const [closedHeight, setClosedHeight] = useState(0);

  // Refs
  const contentRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
  const labelRef: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement | null>(null);

  // Callbacks
  const calcContentHeightCallback = useCallback((): void => {
    const contentDiv: HTMLDivElement | null = contentRef.current;
    const wrapperDiv: HTMLButtonElement | null = labelRef.current;
    if (contentDiv && wrapperDiv) {
      const contentHeight = contentDiv.getBoundingClientRect().height;
      const wrapperHeight = wrapperDiv.getBoundingClientRect().height;
      setOpenedHeight(wrapperHeight + contentHeight);
      setClosedHeight(wrapperHeight);
    }
  }, []);

  const toggleOpenCallback = useCallback(() => {
    setOpenState((state: boolean): boolean => !state);
  }, []);

  // Effects
  useEffect((): void => calcContentHeightCallback(), [calcContentHeightCallback]);

  useEffect((): void => {
    isOpen && calcContentHeightCallback();
  }, [isOpen, calcContentHeightCallback]);

  useEffect(() => {
    setOpenState(openByDefault);
  }, [openByDefault]);
  // end of hooks

  return (
    <ui.ExpandableMenu className={isOpen ? 'openState' : undefined} $closedHeight={closedHeight} $openedHeight={openedHeight}>
      <ui.Label onClick={toggleOpenCallback} ref={labelRef}>
        {label}
        <Icon name="chevronRightLargeDark" />
      </ui.Label>
      <ui.Content ref={contentRef}>{children}</ui.Content>
    </ui.ExpandableMenu>
  );
};

export default ExpandableMenu;
