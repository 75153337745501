import styled from 'styled-components';
import { rem } from 'utils/commonUtils';

export const HeaderDrawer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor || '#fff'};
  padding: 0.6rem 0; // .5rem
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const HeaderDrawerContent = styled.div`
  padding: 4rem; // 2.5rem 1.25rem
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const HeaderDrawerCloseButton = styled.button`
  position: absolute;
  top: 1.9rem; // 1.2rem;
  right: 2.3rem; // 1.5rem;
  cursor: pointer;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  color: #073b50;
  margin: 0 4rem;
  ${({ theme }) => theme.mediaQueries.XL`
    margin: 0 1.5rem;
  `};
  position: relative;
`;

export const ColumnLinkHeader = styled.a`
  display: flex;
  gap: 1.4rem;
  align-items: center;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.textColor};
`;

export const ColumnHeader = styled.div`
  display: flex;
  gap: 1.4rem;
  align-items: center;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: 2.4rem;
`;

export const LinksGroup = styled.div<{ $hasIndent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  ${({ $hasIndent }) => ($hasIndent ? 'margin-left: 4rem' : '')}
`;

export const ColumnLink = styled.a`
  font-size: 1em;
  line-height: 1.3em;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.textColor};
`;

export const ImageHolder = styled.div<{ $height?: number; $width?: number }>`
  position: relative;
  & img {
    display: block;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  &::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    border-radius: ${({ theme }) => theme.borderRadius};
    content: ' ';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }
  width: ${({ $width }) => ($width ? `${rem($width)}` : '100%')};
  height: ${({ $height }) => ($height ? `${rem($height)}` : '100%')};
`;

export const TileLinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  width: 100%;
  font-size: 1.8rem;
  line-height: 4.4rem;
  color: ${({ theme }) => theme.invertedTextColor || '#fff'};
  border: 0.2rem solid ${({ theme }) => theme.invertedTextColor || '#fff'};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const TileDescriptionDiv = styled.div`
  bottom: 1.6rem;
  left: 1.6rem;
  right: 1.6rem;
  position: absolute;
  z-index: 2;
`;

export const TileDescription = styled.h2`
  color: ${({ theme }) => theme.invertedTextColor || '#fff'};
  margin-bottom: 1.6rem;
`;

export const SimpleColumnTilesDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1.5rem;
  max-width: 83rem;
  ${({ theme }) => theme.mediaQueries.XL`
    max-width: 70rem;
  `};
`;

export const SimpleColumnTileElement = styled.a`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 25.5rem;
  height: 14rem;
  display: inline-block;
  ${({ theme }) => theme.mediaQueries.XL`
    width: 21.5rem;
    height: 11rem;
  `};
  overflow: clip;
`;

export const SimpleColumnTileLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.invertedTextColor};
  position: absolute;
  bottom: 1.6rem;
  left: 1.6rem;
  right: 1.6rem;
  z-index: 2;
`;
