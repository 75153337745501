import * as ui from './Alert.ui';
import { MutableRefObject, ReactNode, useMemo } from 'react';
import Icon from 'components/Icon';

interface AlertProps {
  type?: AlertType;
  children: ReactNode;
  title?: string;
  showIcon?: boolean;
  showCloseButton?: boolean;
  closeOnClick?: boolean;
  onClose?: () => void;
  forwardRef?: MutableRefObject<HTMLDivElement | null>;
  titleOnTop?: boolean;
}

export enum AlertType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

export const Alert = ({
  type,
  children,
  title,
  forwardRef,
  onClose,
  showIcon = true,
  showCloseButton = false,
  closeOnClick = false,
  titleOnTop = false,
}: AlertProps) => {
  const icon = useMemo(() => {
    if (!showIcon) return null;
    switch (type) {
      case AlertType.INFO:
        return 'infoCircleDark';
      case AlertType.WARNING:
        return 'warningTriangle';
      case AlertType.ERROR:
        return 'clearButton';
      case AlertType.SUCCESS:
        return 'success';
    }
    return null;
  }, [type, showIcon]);

  const showClose = useMemo(() => {
    if (showCloseButton) return true;
    return !closeOnClick && typeof onClose === 'function';
  }, [showCloseButton, onClose, closeOnClick]);

  return (
    <ui.Alert type={type} onClick={closeOnClick ? onClose : undefined} ref={forwardRef}>
      {icon && <Icon name={icon} />}
      <ui.AlertBody $column={titleOnTop}>
        <ui.AlertTitle>{title}</ui.AlertTitle>
        <ui.AlertContent>{children}</ui.AlertContent>
      </ui.AlertBody>
      {showClose && (
        <ui.AlertCloseButton onClick={onClose}>
          <Icon name="close" />
        </ui.AlertCloseButton>
      )}
    </ui.Alert>
  );
};
