export const importIntlPluralrulesPolyfill = async () => {
  const module = await import(/* webpackChunkName: "intl/[request]" */ `@formatjs/intl-pluralrules/polyfill`);
  return module.default ? module.default : module;
};

export const importIntlPluralrules = async (locale: string) => {
  const module = await import(/* webpackChunkName: "intl/[request]" */ `@formatjs/intl-pluralrules/locale-data/${locale}`);
  return module.default ? module.default : module;
};

export const importIntlRelativetimeformatPolyfill = async () => {
  const module = await import(/* webpackChunkName: "intl/[request]" */ `@formatjs/intl-relativetimeformat/polyfill`);
  return module.default ? module.default : module;
};

export const importIntlRelativetimeformat = async (locale: any) => {
  const module = await import(/* webpackChunkName: "intl/[request]" */ `@formatjs/intl-relativetimeformat/locale-data/${locale}`);
  return module.default ? module.default : module;
};

export const importMomentLocale = async (locale: string) => {
  if (locale === 'en' || locale === 'en-us') {
    return Promise.resolve();
  }
  const module = await import(/* webpackChunkName: "moment/[request]" */ `moment/locale/${locale}`);
  return module.default ? module.default : module;
};
