import { defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  myAccount: {
    id: 'Header.myAccount',
    defaultMessage: 'My account',
  },
  search: {
    id: 'Header.search',
    defaultMessage: 'Search',
  },
  needHelp: {
    id: 'Header.needHelp',
    defaultMessage: 'Need help?',
  },
  goBack: {
    id: 'Header.goBack',
    defaultMessage: 'Close',
  },
  logout: {
    id: 'UserMenu.logout',
    defaultMessage: 'Logout',
  },
  menu: {
    id: 'Header.menu',
    defaultMessage: 'Menu',
  },
});
