import styled from 'styled-components';

export const FormAreaRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 2.4rem;
  column-gap: 3rem;
  align-items: flex-start;
  ${({ theme }) => theme.mediaQueries.LG`column-gap: 2.4rem;`}
  ${({ theme }) => theme.mediaQueries.MD`
    column-gap: 1.6rem;
    row-gap: 1.6rem;
  `}
  ${({ theme }) => theme.mediaQueries.SM`
    flex-wrap: no-wrap;
    flex-direction: column;
  `}
`;

export const AligningWrapper = styled.div`
  min-height: 6.4rem;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.MD`min-height: 5.5rem;`}
  ${({ theme }) => theme.mediaQueries.SM`min-height: auto;`}
`;

export const FormArea = styled.div<{ $noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $noMargin, theme }) => ($noMargin ? '0' : theme.defaultPadding)};
  ${({ theme }) => theme.textStyles.body.regular}
  gap: 3rem;
  ${({ theme }) => theme.mediaQueries.LG`gap: 2.4rem;`}
  ${({ theme }) => theme.mediaQueries.MD`gap: 1.6rem;`}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.body.small.join(' ')}`}
`;

export const FormAreaRowLineBreak = styled.div`
  flex-basis: 100%;
  &:empty {
    height: 0;
  }
`;

export const AllFieldsRequiredMessage = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  ${({ theme }) => theme.textStyles.body.regular}
`;

export const CommonErrorStyles = styled.div`
  white-space: pre-line;
  padding: 0.4rem 1.6rem;
  box-sizing: border-box;
  ${({ theme }) => theme.textStyles.caption.regular}
  background: ${({ theme }) => theme.backgroundColor || '#fff'};
  color: ${({ theme }) => theme.colors.errorDark || '#A00'};
`;
