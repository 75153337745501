import { REQUEST_STATUS } from '@triplake/lib-types';
import { useDispatch, useSelector } from 'react-redux';
import { ReactElement, useEffect, useState } from 'react';
import { Alert, AlertType } from 'components/Alert';
import * as ui from './ApiErrors.ui';
import { requestStateSelector } from 'src/store/request/requestSelectors';
import { resetError } from 'src/store/request/requestActions';
import { useIntl } from '@triplake/lib-intl';
import { getTranslation } from './ApiErrors.translations';

enum RequestTypes {
  LANGUAGES = 'GET_LANGUAGES',
  BENEFICIARIES = 'GET_USER_BENEFICIARIES',
  NOMINEES = 'GET_USER_NOMINEES',
  TRANSACTIONS = 'GET_USER_TRANSACTIONS',
  USER_PROFILE = 'GET_USER_PROFILE',
  USER_CARD = 'GET_USER_CARD',
  SEND_REQUEST = 'SEND_REQUEST_WITH_DATA',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
}
const requestTypesErrorsWhitelist: Array<string> = Object.values(RequestTypes);
const alertTypes = {
  [RequestTypes.LANGUAGES]: AlertType.WARNING,
  [RequestTypes.BENEFICIARIES]: AlertType.WARNING,
  [RequestTypes.NOMINEES]: AlertType.WARNING,
  [RequestTypes.TRANSACTIONS]: AlertType.WARNING,
  [RequestTypes.USER_PROFILE]: AlertType.ERROR,
  [RequestTypes.USER_CARD]: AlertType.ERROR,
  [RequestTypes.SEND_REQUEST]: AlertType.INFO,
  [RequestTypes.CREATE_ACCOUNT]: AlertType.ERROR,
};

const onlyStatus = /^([A-Z_]+)(_REQUEST)-(status)$/;

export const ApiErrors = ({ customStyles }) => {
  const requestState = useSelector(requestStateSelector);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [alerts, setAlerts] = useState<Array<ReactElement>>([]);

  useEffect(() => {
    const requestErrorTypeList: Array<string> = Object.keys(requestState)
      .filter(requestType => requestTypesErrorsWhitelist.includes(requestType.replace(onlyStatus, '$1')))
      .reduce((acc: Array<string>, type: string) => {
        if (requestState[type] === REQUEST_STATUS.FAILURE) acc.push(type.replace(onlyStatus, '$1$2'));
        return acc;
      }, []);

    const errors = requestErrorTypeList.map((name: string, index: number): ReactElement => {
      const error = requestState[`${name}-error`];
      const title = error?.message ? formatMessage(getTranslation(name)) : undefined;
      const alertType = alertTypes[name.replace(/_REQUEST$/, '')] || AlertType.ERROR;
      return (
        <Alert
          type={alertType}
          key={name}
          title={title}
          showCloseButton
          onClose={() => {
            dispatch(resetError(name));
            setAlerts(alerts => [...alerts.slice(0, index), ...alerts.slice(index + 1)]);
          }}>
          {error?.message ?? formatMessage(getTranslation(name))}
        </Alert>
      );
    });

    setAlerts(errors);
  }, [requestState, locale]);

  if (!requestState || !alerts.length) return null;

  return <ui.ApiErrorsWrapper style={customStyles}>{alerts}</ui.ApiErrorsWrapper>;
};
