export const checkIfFileAllowed = (files: Array<File>, allowed: Array<string>) => {
  if (!allowed.length) return true;
  return files.every((file: File) => {
    return allowed.some((format: string) => {
      // File extension case
      if (/^(\.)?[a-z]{2,4}$/i.test(format)) return new RegExp(`${format}$`, 'i').test(file.name);
      // File type case
      return file.type === format;
    });
  });
};
