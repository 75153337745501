import { Dictionaries, DictionaryNames, TRedirectUrl } from 'types/store/Config';
import { TAppState } from '../constants';

export const getLanguages = (state: TAppState) => state.config.languages || [];
export const getRedirectUrls = (state: TAppState): TRedirectUrl[] => {
  const allRedirectUrls = state.config.redirectUrls || [];
  return allRedirectUrls
    .filter(redirectUrl => redirectUrl.domain === 'TRIPLAKE_MEMBER_PORTAL')
    .map(redirectUrl => ({
      name: redirectUrl.name,
      url: redirectUrl.url,
      language: redirectUrl.language,
    }));
};

export const getFooter = (state: TAppState) => state.config.footer;
export const getHeader = (state: TAppState) => state.config.menuOptions;
export const getBaseUrl = (state: TAppState) => state.config.baseUrl;
export const getHelpMenu = (state: TAppState) => state.config.helpMenu;
export const getRegions = (state: TAppState) => state.config.regions;

export const getDictionaries =
  (...dictionaries: Array<DictionaryNames>) =>
  (state: TAppState) =>
    dictionaries.reduce((accumulator: Dictionaries, key: string) => {
      if (state.config.dictionaries[key]) accumulator[key] = state.config.dictionaries[key];
      return accumulator;
    }, {});

export const getAirlines = (state: TAppState) => state.config.airlines;
export const getAirports = (state: TAppState) => state.config.airports;
