import _ from 'lodash';
import PropTypes from 'prop-types';
import { passengerShape, priceValuesShape, segmentShape } from '../main';
import { SEAT_MAP_SLOT_TYPES } from '../../constants';

export const selectedSeatsShape = PropTypes.arrayOf(
  PropTypes.shape({
    passenger: passengerShape,
    ssrCode: PropTypes.string,
    price: PropTypes.number
  })
);

export const seatMapShape = PropTypes.shape({
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      rowNumber: PropTypes.number,
      slots: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(_.values(SEAT_MAP_SLOT_TYPES)),
          column: PropTypes.string,
          available: PropTypes.bool,
          seatNumber: PropTypes.string,
          service: PropTypes.string,
          features: PropTypes.arrayOf(PropTypes.string),
          isPaid: PropTypes.bool
        })
      )
    })
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      lowestPrice: PropTypes.number
    })
  )
});

export const seatsPerSegmentShape = PropTypes.arrayOf(
  PropTypes.shape({
    segment: segmentShape,
    seatMap: seatMapShape,
    selectedSeats: selectedSeatsShape,
    lowestPrice: priceValuesShape,
    total: priceValuesShape
  })
);

export const confirmedPetInCabinShape = PropTypes.arrayOf(
  PropTypes.shape({
    passenger: passengerShape,
    price: PropTypes.number,
    segmentKey: PropTypes.string,
    ssrCode: PropTypes.string
  })
);

export const seatFeaturesConfigShape = PropTypes.shape({
  paxTypesAllowedByDefault: PropTypes.arrayOf(PropTypes.string),
  rules: PropTypes.objectOf(
    PropTypes.shape({
      paxRules: PropTypes.arrayOf(
        PropTypes.shape({
          denyPaxCodes: PropTypes.arrayOf(PropTypes.string),
          additionalAllowedPaxCodes: PropTypes.arrayOf(PropTypes.string),
          messageCode: PropTypes.string
        })
      ),
      ssrRules: PropTypes.arrayOf(
        PropTypes.shape({
          denySpecialAssistance: PropTypes.bool,
          seatMapMessageCode: PropTypes.string,
          extrasMessageCode: PropTypes.string
        })
      )
    })
  ),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      denyFeatureCodes: PropTypes.arrayOf(PropTypes.string),
      mandatoryFeatureCodes: PropTypes.arrayOf(PropTypes.string),
      code: PropTypes.string
    })
  )
});
