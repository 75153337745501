import { TConfigState } from 'types/store/Config';
import { TSelfServiceState } from 'src/store/selfService/selfServiceTypes';
import { TRequestState } from './request/requestReducer';
import { TSessionState } from './session/sessionReducer';
import { TUserState } from 'types/store/User';
import { TIntlState } from 'types/store/Intl';

export enum STATE_KEYS {
  Config = 'config',
  User = 'user',
  SelfService = 'selfService',
  Request = 'request',
  Session = 'session',
  Intl = 'intl',
}

export interface TAppState {
  config: TConfigState;
  request: TRequestState;
  session: TSessionState;
  user: TUserState;
  selfService: TSelfServiceState;
  intl: TIntlState;
}

export interface TAccessSecuredApiResource {
  accountId: string;
  headers: { [key: string]: string };
}
