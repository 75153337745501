import { lazy, LazyExoticComponent } from 'react';

import { ROUTING_PATHS } from './routingPaths';

interface TPages {
  [key: string]: TPageComponent;
}

export type TPageComponent = LazyExoticComponent<(props: any) => JSX.Element>; // TODO Fix props type

export const pages: TPages = {
  [ROUTING_PATHS.home]: lazy(() => import(/* webpackChunkName: "HomePage" */ 'pages/HomePage')),
  [ROUTING_PATHS.registration]: lazy(() => import(/* webpackChunkName: "Registration" */ 'pages/RegistrationPage')),
  [ROUTING_PATHS.forgotPassword]: lazy(() => import(/* webpackChunkName: "ForgotPassword" */ 'pages/ForgotPassword')),
  [ROUTING_PATHS.user.dashboard]: lazy(() => import(/* webpackChunkName: "DashboardPage" */ 'pages/DashboardPage')),
  [ROUTING_PATHS.user.profile]: lazy(() => import(/* webpackChunkName: "ProfilePage" */ 'pages/ProfilePage')),
  [ROUTING_PATHS.user.myTransactions]: lazy(() => import(/* webpackChunkName: "MyTransactions" */ 'pages/MyTransactions')),
  [ROUTING_PATHS.user.myBeneficiaries]: lazy(() => import(/* webpackChunkName: "MyBeneficiaries" */ 'pages/MyBeneficiaries')),
  [ROUTING_PATHS.user.myNominees]: lazy(() => import(/* webpackChunkName: "MyNominees" */ 'pages/MyNominees')),
  [ROUTING_PATHS.user.claimMiles]: lazy(() => import(/* webpackChunkName: "ClaimMiles" */ 'pages/ClaimMissingMiles')),
  [ROUTING_PATHS.newsletter]: lazy(() => import(/* webpackChunkName: "newsletter" */ 'src/pages/Newsletter')),
  [ROUTING_PATHS.user.useMiles.bookAwardTicket]: lazy(
    () => import(/* webpackChunkName: "bookAwardTicket" */ 'src/pages/UsePointsPages/BookAwardTicket'),
  ),
  [ROUTING_PATHS.user.useMiles.useMilesForExtraBaggage]: lazy(
    () => import(/* webpackChunkName: "extraBabbage" */ 'src/pages/UsePointsPages/ExtraBaggage'),
  ),
  [ROUTING_PATHS.user.useMiles.useMilesForUpgrading]: lazy(() => import(/* webpackChunkName: "upgrading" */ 'src/pages/UsePointsPages/Upgrading')),
  '/showroom': lazy(() => import(/* webpackChunkName: "justDevsStuff" */ 'pages/ComponentsShowroom')),
  '*': lazy(() => import(/* webpackChunkName: "HttpErrorPage" */ 'pages/HttpErrorPages/HttpErrorPage')),
};
