import { AnyAction } from 'redux';
import { REQUEST, SUCCESS, FAILURE, action, TRequestTypes, RESET_REQUEST } from '../utils/actionHelpers';
import { HTTPMethod } from '../utils/apiHelpers';

/**
 * @function
 * @description Creates a specialised action that represents an Alloy API request.
 *
 * @param {Object} actionBase - object created by {@link module:actions/helpers.createRequestTypes}
 * It's an object with action types (just plain strings) that are keyed by REQUEST, SUCCESS, and FAILURE.
 * The REQUEST action type should end match "*REQUEST" for the action to trigger.
 *
 * @param {Function} apiMethod - the function to be called by {@link module:requestSaga.performRequest}.
 * It should make an asynchronous call to API endpoint.
 *
 * @param apiMethodArg - the argument to be passed to apiMethod
 *
 * @param apiMethodArgSelector - instead of `apiMethodArg` we can pass a selector function,
 * which gets `apiMethodArg` from Redux store
 *
 * @param argValidator - optional selector which should return true
 * if apiMethodArg is valid or false otherwise. The selector will be invoked with
 * (Redux_state, apiMethodArg) arguments.
 *
 * @param data - optional additional data. It can be read in reducer. See paymentReducer for example.
 *
 * @param onSuccessAction - action that is going to be performed on request success.
 * @returns {Object} action object ready to take by {@link module:requestSaga.watchRequest}
 *
 * @see {@link http://redux.js.org/docs/basics/Actions.html#action-creators}
 */

export interface TRequest {
  actionBase: TRequestTypes;
  apiMethod: (data: any) => Promise<{ response: any } | { error: { httpStatusCode: any; message: any } }>;
  apiMethodArg?: any;
  apiMethodArgSelector?: any;
  argValidator?: any;
  data?: any;
  onSuccessAction?: (response?: any) => void;
  onError?: (error: { httpStatusCode: number; statusText: string; message: string; json?: any }) => void;
  always?: (error: boolean) => void;
}

export type TRequestActionProps = Omit<TRequest, 'actionBase' | 'apiMethod'>;
export const request = ({
  actionBase,
  apiMethod,
  apiMethodArg,
  apiMethodArgSelector,
  argValidator,
  data,
  onSuccessAction,
  onError,
  always,
}: TRequest) => {
  const actionType = actionBase[REQUEST];
  return action(actionType, {
    actionBase,
    apiMethod,
    apiMethodArg,
    apiMethodArgSelector,
    argValidator,
    data,
    onSuccessAction,
    onError,
    always,
  });
};

export interface TApiSuccessResponseAction<T = any> {
  type: string;
  callback?: () => void;
  actionBase: TRequestTypes;
  response: T;
  originalAction: TRequest;
}

export const success = (actionBase: TRequestTypes, response: unknown, originalAction?: AnyAction) =>
  action(actionBase[SUCCESS], { actionBase, response, originalAction });

export const failure = (actionBase: TRequestTypes, error: unknown, originalAction?: AnyAction) =>
  action(actionBase[FAILURE], { actionBase, error, originalAction });

export const pimcoreRequest = (
  actionBase: TRequestTypes,
  path: string,
  method: HTTPMethod = HTTPMethod.GET,
  requestParams: string | Array<string> | object = '',
) => action(actionBase[REQUEST], { actionBase, path, method, requestParams });

export const resetError = (requestName: string) => action(RESET_REQUEST, { requestName });
