import styled from 'styled-components';

export const ProgressFace = styled.div<{ $percent: number; $color?: string; $size?: string }>`
  height: 100%;
  width: ${({ $percent }) => $percent || '0'}%;
  background: ${({ $color, theme }) => $color || theme.primaryColor};
  border-radius: ${({ $size }) => $size || '.8rem'};
`;

export const ProgressTrack = styled.div<{ $size?: string; $color?: string }>`
  height: ${({ $size }) => $size || '.8rem'};
  position: relative;
  background: ${({ $color, theme }) => ($color ? $color : theme.borderColor)};
  border-radius: ${({ $size }) => $size || '.8rem'};
`;

export const ProgressWrapper = styled.div`
  margin: 1.6rem 0;
  position: static;
  width: 100%;
`;
