import * as ui from './LoadingMask.ui';
import Icon from 'components/Icon';

export const LoadingMask = () => {
  return (
    <ui.Mask>
      <Icon name="flowerSpinner" />
    </ui.Mask>
  );
};
