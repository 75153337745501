import { Theme, AirlineTheme, CssProperties } from 'types/themes';
import airMalta from './airmalta';
import airMauritius from './airmauritius';
import oman from './oman';
import * as memberPortalStyles from './memberPortalStyles';

export enum Themes {
  AIR_MALTA = 'airmalta',
  AIR_MAURITIUS = 'airmauritius',
  OMAN = 'oman',
}

export enum ThemeCode {
  KM = 'KM',
  MK = 'MK',
  WY = 'WY',
}

function constructTheme(theme: AirlineTheme, themeName: string, overrides: CssProperties): Theme {
  return {
    ...theme,
    ...memberPortalStyles,
    ...overrides,
    themeName,
  };
}

export const getTheme = (themeName: Themes, overrides: CssProperties = {}): object => {
  switch (themeName) {
    case Themes.AIR_MALTA:
      return constructTheme(airMalta, themeName, overrides);
    case Themes.AIR_MAURITIUS:
      return constructTheme(airMauritius, themeName, overrides);
    case Themes.OMAN:
      return constructTheme(oman, themeName, overrides);
    default:
      return { ...(themeName as object), ...overrides };
  }
};

// TODO: Do we need this remapping? It is used in App.tsx to get correct images by theme name
export const mapClientCodeToTheme = (clientCode: string | undefined): Themes => {
  switch (clientCode) {
    case ThemeCode.KM:
      return Themes.AIR_MALTA;
    case ThemeCode.WY:
      return Themes.OMAN;
    case ThemeCode.MK:
    default:
      return Themes.AIR_MAURITIUS;
  }
};
