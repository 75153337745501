import { TAppState } from '../constants';

export const getSupportedLocales = (state: TAppState) => state.intl.supportedLocales;
export const getPreferredLocale = (state: TAppState) => state.intl.preferredLocale;
export const getRegion = (state: TAppState) => state.intl.region;
export const getDefaultLocale = (state: TAppState) => state.intl.defaultLocale;
export const getRequestedLocale = (state: TAppState) => state.intl.requestedLocale;
export const getLocaleError = (state: TAppState) => state.intl.errorMessage;
export const getMessages = (state: TAppState, locale: string | undefined = getPreferredLocale(state)) => state.intl.messages[locale ?? ''] ?? {};
export const getDefaultMessages = (state: TAppState) => state.intl.messages[getDefaultLocale(state)];
export const isLoadingLocale = (state: TAppState) => state.intl.isLoadingLocale;
