import styled from 'styled-components';

export const ApiErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  &:empty {
    display: none;
  }
  ${({ theme }) => theme.mediaQueries.SM`gap: .8rem;`}
`;
