import { ReactElement, useEffect } from 'react';
import { IntlProvider as TriplakeIntlProvider } from '@triplake/lib-intl';
import { useLocale } from 'src/hooks/useLocale';
import { getLanguageRequest, getDictionariesRequest } from 'src/store/config/configActions';
import { useDispatch } from 'react-redux';

interface TIntlProvider {
  children: ReactElement;
}

export const IntlProvider = ({ children }: TIntlProvider) => {
  const dispatch = useDispatch();
  const { preferredLocale, defaultLocale, messages, defaultMessages, region } = useLocale();
  const locale = `${preferredLocale || defaultLocale}${region && region !== 'international' ? `-${region}` : ''}`;
  useEffect(() => {
    dispatch(getLanguageRequest());
    dispatch(getDictionariesRequest('country'));
  }, []);

  return (
    <TriplakeIntlProvider key={locale} locale={locale} messages={{ ...defaultMessages, ...messages }}>
      {children}
    </TriplakeIntlProvider>
  );
};
