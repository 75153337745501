import * as ui from 'components/Form/Select/Select.ui';
import { rem } from 'utils/commonUtils';
import { OptionsBoxSpacing } from 'components/Form/Select/Select.ui';

export enum AlignItem {
  BOTTOM,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  LEFT,
  RIGHT,
  ANY,
  NONE,
  TOP,
  TOP_LEFT,
  TOP_RIGHT,
}
/**
 * returns number value:
 * -2 needs some more space on the page to display it below (add some margin, or some spacer before footer, so it fits without scrolling the footer up);
 * -1 should display below element
 * 0 the space to display is identical above and below, decide yourself :D
 * 1 should display above the element
 * @param element
 * @param height
 *
 * @return number
 */
export const displayAboveOrBelow = (element: HTMLElement, height: number, notScrollableBody: boolean): number => {
  const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop;
  const boundingClientRect = element.getBoundingClientRect();
  const bottomOfTheWrapper: number = boundingClientRect.bottom;
  const topOfTheWrapper: number = boundingClientRect.top;
  const viewPortHeight: number = window.innerHeight || document.documentElement.clientHeight;
  const bodyHeight: number = notScrollableBody ? viewPortHeight : document.body.clientHeight;

  const spaceOnPageBelow: number = bodyHeight - (bottomOfTheWrapper + scrollTop);
  const spaceOnPageAbove: number = topOfTheWrapper + (notScrollableBody ? 0 : scrollTop);
  const visibleSpaceBelow: number = viewPortHeight - bottomOfTheWrapper;
  const visibleSpaceAbove: number = topOfTheWrapper;
  const canDisplayAbove: boolean = spaceOnPageAbove > height;
  const canDisplayBelow: boolean = spaceOnPageBelow > height;

  if (visibleSpaceBelow === visibleSpaceAbove && canDisplayBelow) return AlignItem.ANY;
  if (visibleSpaceBelow === visibleSpaceAbove) return AlignItem.BOTTOM;
  if (canDisplayAbove && (visibleSpaceBelow < height || spaceOnPageBelow < height) && visibleSpaceAbove > visibleSpaceBelow) return AlignItem.TOP;
  if (!canDisplayBelow) return AlignItem.NONE;
  return AlignItem.BOTTOM;
};

/**
 * returns number value:
 * -2 needs some more space on the page to display it on either side (it will overflow the body);
 * -1 it should be aligned to left side of the element
 * 0 the space to display is identical on the right and left, decide yourself :D
 * 1 should be aligned to the right side of the element
 * @param element
 * @param width
 *
 * @return number
 */
export const displayLeftOrRight = (element: HTMLElement, width: number, notScrollableBody: boolean): number => {
  const boundingClientRect = element.getBoundingClientRect();
  const extraSpaceNeeded: number = width - boundingClientRect.width;
  if (extraSpaceNeeded <= 0) return -1;
  const scrollLeft: number = window.pageXOffset || document.documentElement.scrollLeft;
  const leftSide: number = boundingClientRect.left;
  const rightSide: number = boundingClientRect.right;
  const viewPortWidth: number = window.innerWidth || document.documentElement.clientWidth;
  const bodyWidth: number = notScrollableBody ? viewPortWidth : document.body.clientWidth;

  const spaceOnPageToTheLeft: number = leftSide + scrollLeft;
  const spaceOnPageToTheRight: number = bodyWidth - (rightSide + scrollLeft);
  const visibleSpaceToTheLeft: number = leftSide;
  const visibleSpaceToTheRight: number = viewPortWidth - rightSide;
  const canAlignToLeft: boolean = spaceOnPageToTheRight > extraSpaceNeeded;
  const canAlignToRight: boolean = spaceOnPageToTheLeft > extraSpaceNeeded;

  if (visibleSpaceToTheRight === visibleSpaceToTheLeft && canAlignToLeft) return AlignItem.ANY;
  if (visibleSpaceToTheRight === visibleSpaceToTheLeft) return AlignItem.LEFT;
  if (
    canAlignToRight &&
    (visibleSpaceToTheRight < extraSpaceNeeded || spaceOnPageToTheRight < extraSpaceNeeded) &&
    visibleSpaceToTheLeft > visibleSpaceToTheRight
  )
    return AlignItem.RIGHT;
  if (!canAlignToLeft) return AlignItem.NONE;
  return AlignItem.LEFT;
};

/**
 * returns AlignItem enum which is a number value and tells you how to best align the item related to the wrapperElement.
 * The enum names are self Explanatory.
 * @param wrapperElement
 * @param width
 * @param height
 *
 * @return AlignItem
 */
const howToAlignTheSpaceAroundTheWrapper = (wrapperElement: HTMLElement, width: number, height: number): AlignItem => {
  const notScrollableBody = document.body.classList.contains('noScroll');
  const verticalAlign = displayAboveOrBelow(wrapperElement, height, notScrollableBody);
  const horizontalAlign = displayLeftOrRight(wrapperElement, width, notScrollableBody);
  if (notScrollableBody) {
    wrapperElement.dataset.verticalAlign = verticalAlign.toString();
    wrapperElement.dataset.horizontalAlign = horizontalAlign.toString();
  }
  switch (verticalAlign) {
    case AlignItem.BOTTOM:
      switch (horizontalAlign) {
        case AlignItem.LEFT:
          return AlignItem.BOTTOM_LEFT;
        case AlignItem.ANY:
          return AlignItem.BOTTOM;
        case AlignItem.RIGHT:
          return AlignItem.BOTTOM_RIGHT;
        default:
          return AlignItem.BOTTOM_LEFT;
      }
    case AlignItem.TOP:
      switch (horizontalAlign) {
        case AlignItem.LEFT:
          return AlignItem.TOP_LEFT;
        case AlignItem.ANY:
          return AlignItem.TOP;
        case AlignItem.RIGHT:
          return AlignItem.TOP_RIGHT;
        default:
          return AlignItem.TOP_LEFT;
      }
    case AlignItem.ANY:
      return horizontalAlign !== AlignItem.NONE ? horizontalAlign : AlignItem.LEFT;
    default:
      return AlignItem.NONE;
  }
};
const howToAlignTheElementAroundTheWrapper = (anchorElement: HTMLElement, element: HTMLElement, distance: number = 8): AlignItem => {
  const width = element.clientWidth || element.getBoundingClientRect().width;
  const height = element.clientHeight || element.getBoundingClientRect().height;

  return howToAlignTheSpaceAroundTheWrapper(anchorElement, width, height + distance);
};

export const alignTheElementAroundTheWrapper = (wrapperElement: HTMLElement, arg2: number | HTMLElement, arg3: number): AlignItem => {
  if (!(wrapperElement instanceof HTMLElement) || typeof arg3 !== 'number')
    throw new Error('Wrong arguments supplied to the alignTheElementAroundTheWrapper function');

  if (typeof arg2 === 'number') return howToAlignTheSpaceAroundTheWrapper(wrapperElement, arg2, arg3);
  if (arg2 instanceof HTMLElement) return howToAlignTheElementAroundTheWrapper(wrapperElement, arg2, arg3);

  throw new Error('Wrong arguments supplied to the alignTheElementAroundTheWrapper function');
};

export const setThePositionToTheDesiredElement = (
  element: HTMLElement,
  anchor: HTMLElement,
  alignment: AlignItem = AlignItem.BOTTOM_LEFT,
  distance: number = 8,
) => {
  element.style.top = '';
  element.style.bottom = '';
  element.style.left = '';
  element.style.right = '';
  element.style.position = 'fixed';
  requestAnimationFrame(() => {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const { top, right, bottom, left, width } = anchor.getBoundingClientRect();
    const vertical = parseInt(anchor.dataset.verticalAlign ?? AlignItem.ANY.toString());
    const horizontal = parseInt(anchor.dataset.horizontalAlign ?? AlignItem.ANY.toString());

    const setVertically = () => {
      if (alignment < AlignItem.NONE) element.style.top = `${bottom + distance}px`;
      else if (alignment > AlignItem.NONE) element.style.bottom = `${viewportHeight - (top - ui.OptionsBoxSpacing)}px`;
    };
    const setHorizontally = () => {
      if (alignment === AlignItem.BOTTOM_LEFT || alignment === AlignItem.LEFT || alignment === AlignItem.TOP_LEFT || alignment === AlignItem.ANY)
        element.style.left = `${left}px`;
      else if (alignment === AlignItem.BOTTOM_RIGHT || alignment === AlignItem.RIGHT || alignment === AlignItem.TOP_RIGHT)
        element.style.right = `calc(100% - ${right}px)`;
    };

    if ([alignment, horizontal, vertical].includes(AlignItem.NONE)) {
      if (vertical === AlignItem.NONE) {
        const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop;
        element.style[viewportHeight - (bottom + scrollTop) < top ? 'top' : 'bottom'] = `${distance}px`;
      } else setVertically();

      if (horizontal === AlignItem.NONE) {
        const { width: elementWidth } = element.getBoundingClientRect();
        const extraSpaceNeeded: number = elementWidth - width;
        if (extraSpaceNeeded > 0) {
          const viewPortWidth: number = window.innerWidth || document.documentElement.clientWidth;
          if (viewPortWidth - right > extraSpaceNeeded) return;
          if (left > extraSpaceNeeded) element.style.right = `calc(100% - ${right}px)`;
          else element.style[left > viewPortWidth - right ? 'left' : 'right'] = `${distance}px`;
        }
      } else setHorizontally();

      return;
    }

    setVertically();
    setHorizontally();
  });
};

export const getPositionStyling = (alignment: AlignItem): string => {
  let top = `calc(100% + ${rem(OptionsBoxSpacing)})`;
  let left = '0';
  let right = '';
  let bottom = '';

  if (alignment === AlignItem.BOTTOM_RIGHT || alignment === AlignItem.TOP_RIGHT || alignment === AlignItem.RIGHT) {
    right = '0';
    left = '';
  }
  if (alignment > AlignItem.NONE) {
    top = '';
    bottom = `calc(100% + ${rem(OptionsBoxSpacing)})`;
  }
  if (alignment === AlignItem.NONE)
    return `
      position: fixed;
      right: calc(${rem(OptionsBoxSpacing)});
      bottom: calc(${rem(OptionsBoxSpacing)});
      width: fit-content;
      max-width: calc(100vw - ${rem(OptionsBoxSpacing * 2)});
      max-height: calc(100vh - ${rem(OptionsBoxSpacing * 2)});
    `;

  return `
    ${!!top ? `top: ${top};` : ''}
    ${!!bottom ? `bottom: ${bottom};` : ''}
    ${!!left ? `left: ${left};` : ''}
    ${!!right ? `right: ${right};` : ''}
  `;
};

const MinVisiblePartSize = 20;
export const scrollIntoViewIfNotVisible = (el: HTMLElement, anyPartVisible: boolean = false) => {
  const { top, right, bottom, left } = el.getBoundingClientRect();
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const isVisible =
    ((top >= 0 && top < viewportHeight - MinVisiblePartSize) || (anyPartVisible && bottom >= MinVisiblePartSize && bottom < viewportHeight)) &&
    ((right >= 0 && right < viewportWidth - MinVisiblePartSize) || (anyPartVisible && left >= MinVisiblePartSize && left < viewportWidth));
  if (!isVisible) el.scrollIntoView({ behavior: 'smooth' });
};
