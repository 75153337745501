export enum GranteeType {
  BENEFICIARY = 'beneficiary',
  NOMINEE = 'nominee',
  MEMBER = 'member',
}
export enum GrantType {
  USE_MILES_FOR_EXTRA_BAGGAGE = 'excess-luggage',
  USE_MILES_FOR_UPGRADING = 'upgrading',
  CLAIM_MISSING_MILES = 'missing-miles',
  BOOK_AWARD_TICKET = 'free-ticket',
}

export enum ChargeMode {
  MILES_ONLY = 'miles-only',
  CASH_AND_MILES = 'miles-ext-cash',
}

/**
 * Easier to map some new values to something that exists,
 * than try to convince Tomik to use something that already exists :P
 */
const GrantTypeMappingHalf = {
  [GrantType.BOOK_AWARD_TICKET]: 'free-ticket',
  [GrantType.CLAIM_MISSING_MILES]: 'missing-miles',
  [GrantType.USE_MILES_FOR_EXTRA_BAGGAGE]: 'excess-luggage',
  [GrantType.USE_MILES_FOR_UPGRADING]: 'upgrading',
};

export const GrantTypeMapping = {
  ...GrantTypeMappingHalf,
  [GrantTypeMappingHalf[GrantType.BOOK_AWARD_TICKET]]: GrantType.BOOK_AWARD_TICKET,
  [GrantTypeMappingHalf[GrantType.CLAIM_MISSING_MILES]]: GrantType.CLAIM_MISSING_MILES,
  [GrantTypeMappingHalf[GrantType.USE_MILES_FOR_EXTRA_BAGGAGE]]: GrantType.USE_MILES_FOR_EXTRA_BAGGAGE,
  [GrantTypeMappingHalf[GrantType.USE_MILES_FOR_UPGRADING]]: GrantType.USE_MILES_FOR_UPGRADING,
};
