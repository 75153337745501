import { ThemeGradients, ThemeColors } from 'types/themes';
import { createGradient } from 'src/themes/utils';
import backgroundImage from 'src/assets/images/oman/background.png';

export const colors: ThemeColors = {
  primaryColor: '#00728E',
  textColor: '#003A4E',
  darkSiteBackgroundColor: '#F6F5F6',
  homeBackgroundColor: '#EEE6DE',
  innerPanelsBgColor: '#F8F5F2',
  backgroundColor: '#FFF',
  bodyColor: `#EFF2F5; background-image: url(${backgroundImage}); background-size: cover; background-attachment: fixed`,
  black: '#000000',
  white: '#ffffff',
  level1: '#00728E',
  level2: '#A6A8AB',
  level3: '#B39759',
  overlayColor: '#00000088',
  disabledBackgroundColor: '#dddddd33',
  borderColor: '#EFF2F514',
  actionButtonColor: '#00728E',
  dropDownCategoryTextColor: '#00728E',

  /* Notifications */
  infoDark: '#4175BE',
  infoLight: '#D9E3F2',
  successDark: '#3F844E',
  successLight: '#D9E6DC',
  warningDark: '#DA924F',
  warningLight: '#F8E9DC',
  errorDark: '#BE2239',
  errorLight: '#F2D3D7',

  primaryButtonBackground: '#00728E',
  primaryButtonForeground: '#ffffff',
};

export const gradients: ThemeGradients = {
  hero: createGradient(0, colors.level1, colors.level1),
  defaultImageOverlay: createGradient(0, '#00000033', '#00000033'),
  footer: createGradient(0, colors.primaryColor, colors.primaryColor),
  textOverImage: createGradient(0, colors.primaryColor, colors.primaryColor),
};
export const levelGradients = [
  {
    hero: createGradient(0, colors.level1, colors.level1),
    main: createGradient(0, colors.white, colors.white),
  },
  {
    hero: createGradient(0, colors.level2, colors.level2),
    main: createGradient(0, colors.white, colors.white),
  },
  {
    hero: createGradient(0, colors.level3, colors.level3),
    main: createGradient(0, colors.white, colors.white),
  },
];
