import { REQUEST_STATUS } from '@triplake/lib-types';
import { AnyAction } from 'redux';
import { REQUEST, SUCCESS, FAILURE, RESET_REQUEST } from '../utils/actionHelpers';

// todo - try to make it not 'any'
export type TRequestState = any;

const initialState: TRequestState = {};

export const isRequest = (action: AnyAction) => action.type.endsWith(REQUEST);
export const isSuccess = (action: AnyAction) => action.type.endsWith(SUCCESS);
export const isFailure = (action: AnyAction) => action.type.endsWith(FAILURE);

const requestReducer = (state = initialState, action: AnyAction) => {
  if (action.actionBase) {
    if (isRequest(action)) {
      return {
        ...state,
        [`${action.actionBase[REQUEST]}-status`]: REQUEST_STATUS.PENDING,
        [`${action.actionBase[REQUEST]}-response`]: null,
        [`${action.actionBase[REQUEST]}-error`]: null,
      };
    }
    if (isSuccess(action)) {
      return {
        ...state,
        [`${action.actionBase[REQUEST]}-status`]: REQUEST_STATUS.SUCCESS,
        [`${action.actionBase[REQUEST]}-response`]: action.response,
        [`${action.actionBase[REQUEST]}-error`]: null,
      };
    }
    if (isFailure(action)) {
      return {
        ...state,
        [`${action.actionBase[REQUEST]}-status`]: REQUEST_STATUS.FAILURE,
        [`${action.actionBase[REQUEST]}-response`]: action.response,
        [`${action.actionBase[REQUEST]}-error`]: action.error,
      };
    }
  } else if (action.type === RESET_REQUEST) {
    return {
      ...state,
      [`${action.requestName}-status`]: undefined,
      [`${action.requestName}-response`]: undefined,
      [`${action.requestName}-error`]: undefined,
    };
  }

  return state;
};

export default requestReducer;
