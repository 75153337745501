import { defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  welcomeMessage: {
    id: 'SplashScreen.welcomeMessage',
    defaultMessage: 'Welcome to Kestrelflyer loyalty programme!',
  },
  authenticating: {
    id: 'SplashScreen.authenticatingUser',
    defaultMessage: 'Authenticating user...',
  },
});
