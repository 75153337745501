import * as ui from './ProgressBar.ui';

interface ProgressBarInterface {
  max?: number;
  current: number;
  faceColor?: string;
  trackColor?: string;
  size?: number;
}

export const ProgressBar = ({ max = 100, current, trackColor, faceColor, size }: ProgressBarInterface) => {
  const height: string | undefined = size ? `${size / 10}rem` : undefined;
  const width: number = current > 0 ? (current / max) * 100 : 0;
  return (
    <ui.ProgressWrapper>
      <ui.ProgressTrack $size={height} $color={trackColor}>
        <ui.ProgressFace $color={faceColor} $size={height} $percent={width > 100 ? 100 : width} />
      </ui.ProgressTrack>
    </ui.ProgressWrapper>
  );
};
