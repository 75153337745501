import { request, TRequestActionProps } from '../request/requestActions';
import { GET_LANGUAGES, GET_AIRLINES, GET_ROUTES, GET_DICTIONARIES, GET_HEADER, GET_FOOTER, SUBSCRIBE } from './configConstants';
import { getLanguage, getRoutes, getAirlines, getHeader, getFooter, getDictionaries, subscribe } from './configApi';
import { requestStatusSelector } from '../request/requestSelectors';
import { Dictionaries } from 'types/store/Config';
import { getAcceptLanguage } from 'src/store/selectors';

export const getLanguageRequestStatus = requestStatusSelector(GET_LANGUAGES);

export const getLanguageRequest = () =>
  request({
    actionBase: GET_LANGUAGES,
    apiMethod: getLanguage,
    apiMethodArgSelector: getAcceptLanguage,
  });

export const getAirlinesRequest = (priceList: string) =>
  request({
    actionBase: GET_AIRLINES,
    apiMethod: getAirlines,
    apiMethodArgSelector: getAcceptLanguage,
    apiMethodArg: { priceList },
  });

export const getRoutesRequest = (priceList: string) =>
  request({
    actionBase: GET_ROUTES,
    apiMethod: getRoutes,
    apiMethodArgSelector: getAcceptLanguage,
    apiMethodArg: { priceList },
  });

export const getDictionariesRequest = (...dictionaries: Array<keyof Dictionaries>) =>
  request({
    actionBase: GET_DICTIONARIES,
    apiMethod: getDictionaries,
    apiMethodArgSelector: getAcceptLanguage,
    apiMethodArg: { dictionaries },
  });

export const getHeaderRequest = (locale: string) =>
  request({
    actionBase: GET_HEADER,
    apiMethod: getHeader,
    apiMethodArg: locale,
  });

export const getFooterRequest = (locale: string) =>
  request({
    actionBase: GET_FOOTER,
    apiMethod: getFooter,
    apiMethodArg: locale,
  });

export const subscribeAction = (data, actionProps: TRequestActionProps = {}) =>
  request({
    actionBase: SUBSCRIBE,
    apiMethod: subscribe,
    apiMethodArg: { data },
    ...actionProps,
  });
