import styled from 'styled-components';
import { ButtonSizes, ButtonTypes } from './Button';
import { AirlineTheme, Theme } from 'types/themes';
import { Link } from 'react-router-dom';
import { getTransitions } from 'utils/commonUtils';

const getTypeStyle = (type: ButtonTypes, theme: Theme, disabled: boolean = false, overwriteFocus: string = ''): string => {
  const parts = {
    color: theme.colors.textColor,
    hoverColor: theme.colors.primaryColor,
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    border: '',
    hoverBorder: '',
    padding: '1.2rem 8rem',
    opacity: '',
  };
  parts.color = theme.colors[`${type}ButtonForeground`] || parts.color;
  parts.hoverColor = theme.colors[`${type}ButtonHoverForeground`] || parts.hoverColor;
  parts.backgroundColor = theme.colors[`${type}ButtonBackground`] || parts.backgroundColor;
  parts.hoverBackgroundColor = theme.colors[`${type}ButtonHoverBackground`] || parts.hoverBackgroundColor;
  parts.border = theme.colors[`${type}ButtonBorder`] || '';
  parts.hoverBorder = theme.colors[`${type}ButtonBorder`] || '';
  if (disabled) {
    if (theme.colors.disabledButtonForeground) parts.color = theme.colors.disabledButtonForeground;
    if (theme.colors.disabledButtonBackground) parts.color = theme.colors.disabledButtonBackground;
    if (!theme.colors.disabledButtonForeground && !theme.colors.disabledButtonBackground) parts.opacity = 'opacity: .5;';
  }
  if (type === 'link' || type === 'link-2-colors') parts.padding = '0';
  if (type === 'link-2-colors') parts.hoverColor = theme.colors[`linkButtonHoverForeground`] || parts.hoverColor;

  const hoverStyle =
    overwriteFocus ||
    `${parts.hoverBorder && `border: .1rem solid ${parts.hoverBorder};`}
	  ${/^link/.test(type) && !parts.hoverBorder && `//text-decoration: underline;`}
	  color: ${parts.hoverColor};
	  background: ${parts.hoverBackgroundColor};`;

  return `background: ${parts.backgroundColor};
	color: ${parts.color};
	padding: ${parts.padding};
	${parts.border && `border: .1rem solid ${parts.border};`}
	${parts.opacity}
	${
    type === 'link-2-colors'
      ? ''
      : `
	& svg g {
		fill: ${parts.color};
	}`
  }
	${
    disabled
      ? ''
      : `
	&:hover {
	  background: ${parts.hoverBackgroundColor};
	  color: ${parts.hoverColor};
	  ${parts.hoverBorder && `border: .1rem solid ${parts.hoverBorder};`}
	  ${
      type === 'link-2-colors'
        ? ''
        : `svg g {
      fill: ${parts.hoverColor};
    }`
    }
	}
	&:focus { ${hoverStyle}	}
	${
    !/^link/.test(type) && theme.shadow
      ? `&:active {
	  box-shadow: inset ${theme.shadow};
	}`
      : ''
  }
	`
  }
`;
};

const getOtherStyles = (theme: AirlineTheme, size: ButtonSizes = 'regular', disabled: boolean, takeWholeContainer: boolean): string => `
  border-radius: ${theme.borderRadius};
  box-sizing: border-box;
  ${theme.textStyles.button[size].join('')};
  display: flex;
  gap: 0.8rem;
  align-items: center;
  cursor: ${disabled ? 'default' : 'pointer'};
  text-align: center;
  transition: ${getTransitions(theme.animationSpeed || '', 'color', 'background', 'border', 'box-shadow')};
  &.narrow {
    padding: 1.2rem 4rem;
  }
  &.fullWidth {
    width: 100%;
    justify-content: center;
  }
  ${
    takeWholeContainer
      ? `
    width: 100%;
    height: 100%;
    justify-content: center;
  `
      : ''
  }
`;

const LoadingIconOverlap = '.4rem';

export const Button = styled.button.attrs(({ disabled }) => disabled && { 'aria-disabled': true })<{
  $type: ButtonTypes;
  $size: ButtonSizes;
  $takeWholeContainer: boolean;
  $overwriteFocus: string;
}>`
  ${({ theme, $size, $takeWholeContainer, disabled }) => getOtherStyles(theme, $size, !!disabled, $takeWholeContainer)}
  ${({ $type, theme, disabled, $overwriteFocus }) => getTypeStyle($type, theme, !!disabled, $overwriteFocus)}
  ${({ theme, $type }) => ($type !== 'link' && $type !== 'link-2-colors' ? theme.mediaQueries.MD`padding: .8rem 4rem;` : '')}
  .loadingIcon {
    height: calc(1.3em + ${LoadingIconOverlap});
    margin: calc(${LoadingIconOverlap} / -2) 0;
  }
  ${({ onClick }) => (typeof onClick === 'undefined' ? 'cursor: default;' : '')}
  ${({ theme }) => theme.mediaQueries.MD`gap: .4rem;`}
`;

export const NavigationButton = styled(Link)<{
  $type: ButtonTypes;
  $size: ButtonSizes;
  $disabled: boolean;
  $takeWholeContainer: boolean;
  $overwriteFocus: string;
}>`
  ${({ theme, $size, $disabled, $takeWholeContainer }) => getOtherStyles(theme, $size, $disabled, $takeWholeContainer)}
  ${({ $type, theme, $disabled, $overwriteFocus }) => getTypeStyle($type, theme, !!$disabled, $overwriteFocus)}
  ${({ theme, $type }) => ($type !== 'link' && $type !== 'link-2-colors' ? theme.mediaQueries.MD`padding: .8rem 4rem;` : '')}
  ${({ theme }) => theme.mediaQueries.MD`gap: .4rem;`}
`;
