import { callSecuredApi, callApi, HTTPMethod } from '../utils/apiHelpers';
import { TDeleteNomineePayload } from 'types/store/User';
import { TAccessSecuredApiResource } from 'src/store/constants';

export const createProfile = ({ accountData }) => {
  const path = '/loyalty/accounts';
  return callApi(path, HTTPMethod.POST, accountData);
};

export const forgotPassword = ({ email, memberNumber }) => {
  const path = `/loyalty/accounts/${memberNumber}/pin-reset`;
  return callApi(path, HTTPMethod.POST, { email });
};

export const getProfileDetails = ({ accountId, headers }: TAccessSecuredApiResource) => {
  const path = `/loyalty/accounts/${accountId}`;
  return callSecuredApi(path, HTTPMethod.GET, headers);
};

export const updateProfileDetails = ({ accountId, headers, data }) => {
  const path = `/loyalty/accounts/${accountId}`;
  return callSecuredApi(path, HTTPMethod.PATCH, headers, data);
};

export const getUserData = ({ accountId, headers, dataName }) => {
  const path = `/loyalty/accounts/${accountId}/${dataName}`;
  return callSecuredApi(path, HTTPMethod.GET, headers);
};

export const addNominee = ({ accountId, headers, data }) => {
  const path = `loyalty/accounts/${accountId}/nominees`;
  // adds the certificate file to the request
  const requestBody = new FormData();
  if (data.certificate !== null) requestBody.append('certificate', data.certificate);
  const dob = data.nominee.birthDate instanceof Date ? data.nominee.birthDate.toJSON() : data.nominee.birthDate;
  requestBody.append(
    'nominee',
    new Blob(
      [
        JSON.stringify({
          birthDate: dob,
          titleKey: data.nominee.titleKey,
          firstName: data.nominee.firstName,
          lastName: data.nominee.lastName,
          kinship: data.nominee.kinship,
        }),
      ],
      { type: 'application/json' },
    ),
  );
  return callSecuredApi(path, HTTPMethod.POST, headers, requestBody);
};
export const addBeneficiary = ({ accountId, headers, data }) => {
  const path = `loyalty/accounts/${accountId}/beneficiaries`;
  return callSecuredApi(path, HTTPMethod.POST, headers, data);
};

export const deleteBeneficiary = ({ accountId, headers, id }) => {
  const path = `/loyalty/accounts/${accountId}/beneficiaries/${id}`;
  return callSecuredApi(path, HTTPMethod.DELETE, headers);
};

export const deleteNominee = ({ accountId, headers, id }: TDeleteNomineePayload) => {
  const path = `/loyalty/accounts/${accountId}/nominees/${id}`;
  return callSecuredApi(path, HTTPMethod.DELETE, headers);
};
