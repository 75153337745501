import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';
import { useMessage } from './useMessage';

export const FormattedMessage = ({ id, defaultMessage, description, values }) => {
  const { defaultText } = useMessage(id, defaultMessage);

  return <ReactIntlFormattedMessage id={id} defaultMessage={defaultText} description={description} values={values} />;
};

FormattedMessage.propTypes = {
  id: PropTypes.any,
  defaultMessage: PropTypes.any,
  description: PropTypes.any,
  values: PropTypes.any
};
