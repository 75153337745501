import { AirlineTheme, LevelTheme } from 'types/themes';
import { colors, gradients, levelGradients } from './colors';
import images from './images';
import fonts from './fonts';
import { fontSizes } from '../utils';

const cardLevels: Array<LevelTheme> = [
  {
    tierKey: 'blue',
    entryPoint: 0,
    color: colors.level1,
    heroGradient: levelGradients[0].hero,
    dashboardGradient: levelGradients[0].main,
  },
  {
    tierKey: 'silver',
    entryPoint: 500,
    color: colors.level2,
    heroGradient: levelGradients[1].hero,
    dashboardGradient: levelGradients[1].main,
  },
  {
    tierKey: 'gold',
    entryPoint: 1100,
    color: colors.level3,
    heroGradient: levelGradients[2].hero,
    dashboardGradient: levelGradients[2].main,
  },
];

const theme: AirlineTheme = {
  textColor: colors.textColor,
  primaryColor: colors.primaryColor,
  primaryTextColor: colors.textColor,
  secondaryTextColor: colors.white,
  fontFamilyPrimary: 'Arsenal',
  fontFamilySecondary: 'Arsenal',
  fontSizeBody: '1rem',
  fonts: {
    primary: 'Arsenal:100,200,300,400,500,600,700,800',
  },
  invertedTextColor: colors.white,
  colorAccent: colors.primaryColor,
  colorLinkHover: colors.primaryColor,
  borderRadius: '1.6rem 1.6rem 0 1.6rem',
  boxShadow: 'none',
  shadow: 'none',
  //
  inputFontSize: '1.125rem',
  //
  borderColor: colors.borderColor,
  disabledColor: colors.disabledBackgroundColor,
  buttonColorPrimary: colors.primaryColor,
  backgroundColor: colors.backgroundColor,
  // fontBaseSize: '16px',
  animationSpeed: '512ms',
  gradients,
  images,
  textStyles: fonts,
  colors,
  fontWeights: fontSizes,
  defaultPadding: '4rem',
  defaultMobilePadding: '2.4rem',

  layoutTheme: {
    order: {
      extras: 3,
      breadCrumbs: 1,
      apiErrors: 2,
      contentWrapper: 4,
    },
  },
  dashboardTheme: {
    order: {
      mainContainer: 2,
      actionButtons: 1,
    },
    dashboardButtonRows: 5,
    cardPartOrder: {
      cardName: 1,
      image: 2,
      link: 3,
    },
    pointsPartOrder: {
      current: 1,
      nextLevel: 2,
      progressBar: 3,
      miles: 4,
      expiringMiles: 5,
    },
    customStyles: {
      mainContainer: `& > div { color: ${colors.textColor}; } & a > div { color: #00728E; }`,
    },
  },
  tableHeaderTextColor: colors.white,
  tableHeaderBackgroundColor: colors.primaryColor,
  tableRowBackgroundColor: colors.white,
  tableAltRowBackgroundColor: '#EFF2F5',

  cardLevels,
};

export default theme;
