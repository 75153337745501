import { ChangeEvent } from 'react';
export const createChangeEvent = (value: string): ChangeEvent<HTMLInputElement> => {
	const nativeEvent = new Event('change');
	Object.defineProperty(nativeEvent, 'target', {
		value: { value },
		writable: false,
	});
	const syntheticEvent = new (Event as any)('change');
	Object.defineProperty(syntheticEvent, 'nativeEvent', {
		value: nativeEvent,
		writable: false,
	});

	return syntheticEvent;
};