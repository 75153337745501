import styled from 'styled-components';
import { HideType } from 'types/themes';

export const LayoutWrapper = styled.div`
  flex-grow: 1;
  background: ${props => props.theme.colors.bodyColor || '#fff'};
`;

export const LayoutErrorWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.bodyColor || '#fff'};
`;

export const PageWrapper = styled.div`
  width: 111rem;
  margin: 0 auto;
  padding-bottom: 4rem;
  padding-top: 2.4rem;
  color: ${({ theme }) => theme.primaryTextColor};
  display: flex;
  flex-direction: column;
  gap: 4rem;

  ${({ theme }) => theme.mediaQueries.LG`
    width: 80rem;
  `};
  ${({ theme }) => theme.mediaQueries.MD`
    width: calc(100vw - 1.6rem * 2);
    margin: 0 1.6rem;
    padding-top: 1.6rem;
  `};
`;

export const OrderedElement = styled.div<{ $order?: number; $hide?: HideType }>`
  ${props => (props.$order ? `order: ${props.$order};` : '')}
  ${props => (props.$hide === 'hide' ? 'visibility: hidden;' : props.$hide === 'remove' ? 'display: none;' : '')}
  &:empty {
    display: none;
  }
`;

export const ContentWrapper = styled.div<{ $customStyles?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQueries.MD`
    flex-direction: column;
  `};

  ${props => props.$customStyles || ''}
`;

export const FlexRowForPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HideOnDesktop = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueries.MD`
    display: block;
  `}
`;
