import { FC, HTMLAttributes } from 'react';
import { Theme } from 'types/themes';
import { useTheme } from 'styled-components';
import defaultIcons from '../../assets/iconFiles/defaultIcons';

interface IconProps {
  name: string;
  outline?: boolean;
  className?: string;
}

export type IconComponent = FC<IconProps>;

export const Icon: IconComponent = ({ name, outline, className }: IconProps) => {
  const theme: Theme = useTheme() as Theme;

  const IconComponent: FC<HTMLAttributes<HTMLOrSVGElement>> =
    (theme.icons || defaultIcons)[`${name}${outline ? '_inversed' : ''}`] || (theme.icons || defaultIcons)['default'];
  return IconComponent ? <IconComponent className={className} /> : null;
};
