import { useMemo, useContext, useState } from 'react';
import * as ui from './DashboardCard.ui';
import { useUser } from 'src/hooks/useUser';
import { Dialog } from 'components/Dialog';
import { ROUTING_PATHS } from 'src/router/routingPaths';
import { FormattedDate, FormattedMessage, FormattedNumber } from '@triplake/lib-intl';
import { messages } from '../DashboardPage.translations';
import Icon from 'components/Icon';
import { getLevel, getPointsBoxesAlign } from '../utils';
import { CardLevels, DeviceContextType } from 'types/types';
import { ProgressBar } from 'components/ProgressBar';
import { HideDashboardPart, PointsPartOrder, Theme } from 'types/themes';
import { useTheme } from 'styled-components';
import { OrderedElement } from 'components/Layout/Layout.ui';
import { mergeCustomStyles } from 'src/themes/utils';
import { PageCustomThemes, PageElementCustomThemes } from 'src/themes/constants';
import { DeviceTypeContext } from '@triplake/lib-hooks';
import CardDialog from './CardDialog';

const useCardData = (theme: Theme) => {
  const { card, profile } = useUser('card');
  const cData = useMemo(() => {
    const currentLevelIndex: number = getLevel(profile?.tier.typeKey ?? '', theme.cardLevels);
    const nextLevelIndex: number = currentLevelIndex < theme.cardLevels.length - 1 ? currentLevelIndex + 1 : 0;
    const currentLevel: CardLevels = `level${currentLevelIndex + 1}` as CardLevels;
    const nextLevel: CardLevels | null = nextLevelIndex ? (`level${nextLevelIndex + 1}` as CardLevels) : null;
    return { currentLevelIndex, nextLevelIndex, currentLevel, nextLevel };
  }, [profile?.tier.typeKey]);
  return { ...cData, card, tier: profile?.tier };
};

export const DashboardCard = () => {
  const theme: Theme = useTheme() as Theme;
  const device = useContext(DeviceTypeContext) as DeviceContextType;
  const [cardDialog, showCardDialog] = useState(false);
  const { currentLevelIndex, nextLevelIndex, currentLevel, nextLevel, card, tier } = useCardData(theme);

  const pointsPartOrder: PointsPartOrder | undefined = theme.dashboardTheme.pointsPartOrder;
  const alignRightMap = getPointsBoxesAlign(pointsPartOrder);
  const hidePart: HideDashboardPart | undefined = theme.dashboardTheme.hidePart;
  const styles = mergeCustomStyles(
    theme,
    PageCustomThemes.DASHBOARD,
    PageElementCustomThemes.DASHBOARD_CARD,
    typeof theme.layoutTheme?.order?.extras === 'number' && theme.layoutTheme?.order?.extras > 1
      ? 'margin: 0 auto 4rem; transform: none;'
      : undefined,
  );

  const cardImage = useMemo(
    () => (
      <OrderedElement $order={theme.dashboardTheme.cardPartOrder?.image} $hide={hidePart?.image}>
        <ui.CardImage $cardlevel={currentLevel} />
      </OrderedElement>
    ),
    [currentLevel],
  );
  const cardName = useMemo(
    () => (
      <>
        <OrderedElement $order={theme.dashboardTheme.cardPartOrder?.cardName} $hide={hidePart?.cardName}>
          <ui.CardName $color={theme.cardLevels[currentLevelIndex].color}>
            {/*<FormattedMessage {...messages.cardName} /> */}
            <FormattedMessage id={tier?.typeKey ?? messages[currentLevel as string]?.id ?? 'Dashboard.level1Name'} defaultMessage={tier?.name} />
          </ui.CardName>
        </OrderedElement>
        <OrderedElement $order={theme.dashboardTheme.cardPartOrder?.link} $hide={hidePart?.link}>
          <ui.CardButton onClick={() => showCardDialog(true)}>
            <FormattedMessage {...messages.viewCard} />
            <Icon name="chevronRightSmallDark" />
          </ui.CardButton>
        </OrderedElement>
      </>
    ),
    [currentLevelIndex, currentLevel, tier],
  );
  const cardImagePart = useMemo(
    () =>
      device.isMobile ? (
        <>
          <ui.CardPartContent $dir="row">
            {cardImage}
            <ui.CardPartContent $grow={3}>{cardName}</ui.CardPartContent>
          </ui.CardPartContent>
          <ui.MobileHorizontalSeparator />
        </>
      ) : (
        <ui.CardPartContent $grow={1}>
          {cardImage}
          {cardName}
        </ui.CardPartContent>
      ),
    [cardImage, cardName],
  );

  return (
    <ui.CardWrapper $styles={styles}>
      <ui.CardPart>
        <ui.CardPartContent>
          <h2>{`${card?.title ?? ''} ${card?.firstName ?? ''} ${card?.lastName || ''}`}</h2>
          <ui.CardLink to={ROUTING_PATHS.user.profile}>
            <FormattedMessage {...messages.myProfile} />
            <Icon name="chevronRightSmallDark" />
          </ui.CardLink>
        </ui.CardPartContent>
        <ui.CardPartContent $align="end">
          <div>
            <FormattedMessage {...messages.accountNo} />: {card?.accountId}
          </div>
          <div>
            <FormattedMessage {...messages.memberStartDate} />:{'\u00A0'}
            <FormattedDate
              value={(!!card?.accountRegistrationDate && new Date(card.accountRegistrationDate)) || undefined}
              day="2-digit"
              month="short"
              year="numeric"
            />
          </div>
        </ui.CardPartContent>
      </ui.CardPart>
      <ui.CardPart>
        {cardImagePart}
        <ui.CardPartContent $grow={3}>
          <ui.CardPoints>
            <ui.CardPointsBox $order={pointsPartOrder?.current} $hide={hidePart?.current} $alignRight={alignRightMap.current}>
              <label>
                <FormattedMessage {...messages.miles} />
              </label>
              <ui.CardTierPoints>
                <FormattedNumber value={card?.tierPoints ?? 0} />
              </ui.CardTierPoints>
            </ui.CardPointsBox>
            <ui.CardPointsBox $order={pointsPartOrder?.expiringMiles} $hide={hidePart?.expiringMiles} $alignRight={alignRightMap.expiringMiles}>
              <label>
                <FormattedMessage {...messages.expiringMiles} />
              </label>
              <ui.CardExpiringPoints>
                <FormattedNumber value={card?.expiringMile?.count || 0} />
                {card?.expiringMile && (
                  <>
                    {'\u00A0'}
                    <FormattedMessage {...messages.onDate} />
                    {'\u00A0'}
                    <FormattedDate value={card?.expiringMile?.expirationDate} day="2-digit" month="short" year="numeric" />
                  </>
                )}
              </ui.CardExpiringPoints>
            </ui.CardPointsBox>
            <ui.CardPointsBox $order={pointsPartOrder?.progressBar} $fullWidth $hide={hidePart?.progressBar}>
              <ProgressBar
                max={theme.cardLevels[nextLevelIndex].entryPoint}
                current={nextLevel ? card?.qualifyingMiles ?? 0 : 100}
                faceColor={theme.cardLevels[currentLevelIndex].color}
              />
            </ui.CardPointsBox>
            <ui.CardPointsBox $order={pointsPartOrder?.miles} $hide={hidePart?.miles} $alignRight={alignRightMap.miles}>
              <label>
                <FormattedMessage {...messages.qualifyingMiles} />
              </label>
              <ui.CardMilesAndNextLevel>
                <FormattedNumber value={card?.qualifyingMiles || 0} />
              </ui.CardMilesAndNextLevel>
            </ui.CardPointsBox>
            <ui.CardPointsBox $order={pointsPartOrder?.nextLevel} $hide={hidePart?.nextLevel} $alignRight={alignRightMap.nextLevel}>
              {nextLevel && (
                <div>
                  <FormattedMessage {...messages[nextLevel as string]} />
                </div>
              )}
              {nextLevel && (
                <ui.CardMilesAndNextLevel>
                  <FormattedNumber value={theme.cardLevels[nextLevelIndex].entryPoint} />
                </ui.CardMilesAndNextLevel>
              )}
            </ui.CardPointsBox>
          </ui.CardPoints>
        </ui.CardPartContent>
      </ui.CardPart>
      <Dialog
        isOpen={cardDialog}
        closeOnOverlayClick
        showCloseButton
        onClose={() => showCardDialog(false)}
        title={<FormattedMessage {...messages.yourCard} />}>
        <CardDialog closeDialog={() => showCardDialog(false)} tier={tier} card={card} currentLevel={currentLevel} />
      </Dialog>
    </ui.CardWrapper>
  );
};
