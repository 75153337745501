import { defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  welcome: {
    id: 'UserMenu.welcome',
    defaultMessage: 'Welcome, {userName}',
  },
  dashboard: {
    id: 'UserMenu.buttons.dashboard',
    defaultMessage: 'Dashboard',
  },
  profile: {
    id: 'MenuButton.myProfileLink',
    defaultMessage: 'My profile',
  },
  transactions: {
    id: 'MenuButton.myTransactions',
    defaultMessage: 'My Transactions',
  },
  nominees: {
    id: 'MenuButton.myNominees',
    defaultMessage: 'My Nominees',
  },
  beneficiaries: {
    id: 'MenuButton.myBeneficiaries',
    defaultMessage: 'My Beneficiaries',
  },
  claimMiles: {
    id: 'MenuButton.claimMiles',
    defaultMessage: 'Claim Miles',
  },
  useMiles: {
    id: 'UserMenu.buttons.useMiles',
    defaultMessage: 'Use Miles',
  },
  bookAwardTicket: {
    id: 'MenuButton.bookAwardTicket',
    defaultMessage: 'Book Award Ticket',
  },
  upgrade: {
    id: 'MenuButton.useMilesToUpgrade',
    defaultMessage: 'Use Miles for Upgrading',
  },
  extraBaggage: {
    id: 'MenuButton.useMilesForExtraBaggage',
    defaultMessage: 'Use Miles for Extra Baggage',
  },
  premiumLounge: {
    id: 'MenuButton.useYourMilesForLounge',
    defaultMessage: 'Use Miles for Premium Lounge',
  },
  logout: {
    id: 'UserMenu.logout',
    defaultMessage: 'Logout',
  },
  login: {
    id: 'UserMenu.login',
    defaultMessage: 'Login | Sign up',
  },
});
