import { ChangeEvent as ReactChangeEvent, MouseEvent, KeyboardEvent, useMemo, useCallback, useRef, useState } from 'react';
import * as ui from './File.ui';
import * as inputUi from '../Input/Input.ui';
import { FileProps, FileField, ChangeEvent } from 'types/Form';
import { useIntl } from '@triplake/lib-intl';
import Icon from 'components/Icon';
import { checkIfFileAllowed } from 'components/Form/File/File.utils';

export const File: FileField = ({
  name,
  placeholder,
  tabindex,
  id,
  inputClassName,
  width = 350,
  onChange = () => {},
  disabled = false,
  optional = true,
  error = false,
  clearButton = false,
  allowedFormats = [],
  multiple = false,
}: FileProps) => {
  const [files, setFile] = useState<File | FileList | null>(null);
  const intl = useIntl();

  // Refs
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Callbacks
  const onChangeCallback = useCallback(
    (event: ReactChangeEvent<HTMLInputElement>): void => {
      const target = event.target;
      if (target.files) {
        const value: File | FileList = multiple ? target.files : target.files[0];
        if (!checkIfFileAllowed(multiple ? Array.from(value as FileList) : [value as File], allowedFormats)) return;
        const shouldUpdate = onChange(
          {
            value,
            targetName: name,
            targetElement: wrapperRef.current,
          } as ChangeEvent<FileField>,
          multiple ? (value as FileList) : (value as File),
        );
        if (shouldUpdate === false) return;
        setFile(value);
      }
    },
    [onChange, name, files, multiple, allowedFormats],
  );

  const clearFieldCallback = useCallback((e: MouseEvent): void => {
    e.preventDefault();
    if (fileInputRef.current) fileInputRef.current.files = null;
    setFile(null);
  }, []);

  const openFileChooser = useCallback((event: MouseEvent | KeyboardEvent) => {
    // keyboard event
    event.nativeEvent.preventDefault();
    if (typeof (event as KeyboardEvent).key !== 'undefined') {
      const key = (event as KeyboardEvent).key;
      if (key !== 'Enter' && key !== ' ') return;
    }
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);

  // Memos
  const accept = useMemo(() => allowedFormats.map(format => (/^[^\.][a-z]{2,4}$/i.test(format) ? `.${format}` : format)).join(','), [allowedFormats]);
  const className: string | undefined = useMemo(() => {
    const out: Array<string> = [];
    if (inputClassName) out.push(inputClassName);
    if (error) out.push('error');
    if (clearButton) out.push('clearButton');
    return out.length ? out.join(' ') : undefined;
  }, [inputClassName, error, clearButton]);

  const fileNameValue = useMemo(() => {
    if (!files) return '';
    if (multiple)
      return Array.from(files as FileList)
        .map(file => file.name)
        .join(', ');
    return (files as File).name;
  }, [files, multiple]);
  // end of hooks

  return (
    <inputUi.InputWrapper $halfSize={width < 200} ref={wrapperRef}>
      <ui.InputFileField
        ref={inputRef}
        tabIndex={tabindex}
        disabled={disabled}
        className={className}
        $width={width}
        required={!optional}
        readOnly
        onClick={openFileChooser}
        onKeyUp={openFileChooser}
        value={fileNameValue}
      />
      <ui.FileField ref={fileInputRef} name={name} id={id} onChange={onChangeCallback} disabled={disabled} accept={accept} required={!optional} />
      <ui.FilePlaceholder $small={!!files}>
        {placeholder}
        {optional && (
          <inputUi.InputOptionalLabel>{intl.formatMessage({ id: 'common.optional', defaultMessage: ' (Optional)' })}</inputUi.InputOptionalLabel>
        )}
      </ui.FilePlaceholder>
      {clearButton && (
        <ui.FileClearButton onMouseDown={clearFieldCallback} className={!!files ? 'show' : undefined}>
          <Icon name="clearButton" />
        </ui.FileClearButton>
      )}
      <ui.UploadIcon onClick={openFileChooser}>
        <Icon name="upload" />
      </ui.UploadIcon>
      {typeof error === 'string' ? <inputUi.InputErrorMessage $width={width}>{error}</inputUi.InputErrorMessage> : null}
    </inputUi.InputWrapper>
  );
};
