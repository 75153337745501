import { join } from 'utils/urlUtils';
import { callApi, HTTPMethod } from '../utils/apiHelpers';

export const getMessages = (locale: string) => {
  const path = '/translations';
  return callApi(path, HTTPMethod.GET, undefined, null, { 'Accept-Language': locale });
};
//
// export const postLanguage = (language: string) => {
//   const path = `language`;
//   return callApi(path, HTTPMethod.POST, { language });
// };
