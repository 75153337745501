import { REFERENCE_DATA_TYPES } from '@triplake/lib-types';
import { defineDynamicMessages, defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  languageRegionLabel: {
    id: 'LocaleDropdown.regionLabel',
    defaultMessage: 'Choose your region',
  },
  languageLanguageLabel: {
    id: 'LocaleDropdown.languageLabel',
    defaultMessage: 'Choose your language',
  },
  internationalRegion: {
    id: 'LocaleDropdown.internationalRegion',
    defaultMessage: 'International',
  },
  infoMessage: {
    id: 'LocaleDropdown.infoMessage',
    defaultMessage: 'Chose your region and language to match your preferences.',
  },
});
