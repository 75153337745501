import styled from 'styled-components';
import { InputPlaceholder, Boundry, ClearButton as InputClearButton, InputOptionalLabel } from '../Input/Input.ui';
import { getTransitions, rem } from 'utils/commonUtils';
import { AlignItem, getPositionStyling } from 'utils/DOMmanupulationUtils';
import { CommonErrorStyles } from '../Form.ui';

export const OptionsBoxSpacing = 8;

export const SelectPlaceholder = styled(InputPlaceholder)`
  max-width: calc(100% - 4.8rem);
`;

export const SelectButton = styled.button<{ $width: number }>`
  ${({ theme }) => theme.textStyles.button.large};
  ${({ disabled, theme }) => (disabled ? `color: ${theme.textColor}cb` : '')};
  padding: 2.862rem 3.2rem 1rem 1.6rem;
  border-radius: ${({ theme }) => theme.borderRadius || '1rem'};
  border: 0.1rem solid ${({ theme }) => theme.borderColor || '#000'};
  ${({ $width }) => $width && `width: ${rem($width)};`}
  ${({ theme, $width }) => theme.mediaQueries.LG`
    width: calc(${rem($width)} * .7);
  `}
  box-sizing: border-box;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  ${({ disabled, theme }) =>
    disabled &&
    `border-color: transparent;
    background: ${theme.colors.disabledBackgroundColor};
    & + ${SelectPlaceholder} {
		color: ${theme.textColor || '#073B50'}cb;
	}
    cursor: default;
	`}
  &&&:focus {
    border-color: ${({ theme }) => theme.textColor};
    outline: 0.1rem solid ${({ theme }) => theme.colorAccent};
  }
  & + ${SelectPlaceholder}.small {
    top: 1.1rem;
    ${({ theme }) => theme.textStyles.caption.regular};
    ${({ theme }) => theme.mediaQueries.MD`top: 1rem`}
  }
  ${({ theme }) => theme.mediaQueries.MD`
    width: 100%;
    padding: 2.5rem 1.6rem 1rem;
    ${theme.textStyles.button.regular};
  `}
`;

export const SelectInputField = styled.input<{ $width: number }>`
  ${({ theme }) => theme.textStyles.button.large};
  ${({ disabled, theme }) => (disabled ? `color: ${theme.textColor}cb` : '')};
  padding: 2.862rem 3.2rem 1rem 1.6rem;
  border-radius: ${({ theme }) => theme.borderRadius || '1rem'};
  border: 0.1rem solid ${({ theme }) => theme.borderColor || '#000'};
  ${({ $width }) => `width: ${rem($width)};`}
  ${({ theme, $width }) => theme.mediaQueries.LG`
    width: calc(${rem($width)} * .7);
  `}
  box-sizing: border-box;
  cursor: pointer;
  ${({ disabled, theme }) =>
    disabled &&
    `border-color: transparent;
    background: ${theme.colors.disabledBackgroundColor};
    & + ${SelectPlaceholder} {
		color: ${theme.textColor || '#073B50'}cb;
	}
	cursor: default;
	`}
  & + ${SelectPlaceholder}.small, &:focus + ${SelectPlaceholder} {
    top: 1.1rem;
    ${({ theme }) => theme.textStyles.caption.regular};
    ${({ theme }) => theme.mediaQueries.MD`top: 1rem`}
  }
  ${({ theme }) => theme.mediaQueries.MD`
    width: 100%;
    padding: 2.5rem 3.2rem 1rem 1.6rem;
    ${theme.textStyles.button.regular};
  `}
`;

export const FieldButton = styled.div.attrs(() => ({
  role: 'button',
}))`
  position: relative;
`;

export const OptionsBox = styled.div<{ $maxHeight: number }>`
  position: absolute;
  z-index: 16;
  background: ${({ theme }) => theme.backgroundColor || '#fff'};
  border-radius: ${({ theme }) => theme.borderRadius || '1rem'};
  padding: calc(${({ theme }) => theme.defaultPadding || '4rem'} / 2);
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 0.1rem solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  width: max-content;
  max-height: ${({ $maxHeight }) => rem($maxHeight)};
  opacity: 1;
  transition: ${({ theme }) => getTransitions(theme.animationSpeed, 'max-height', 'opacity')};
  box-shadow: ${({ theme }) => theme.shadow};
  ${({ theme }) => theme.mediaQueries.SM`
    width: fit-content;
  `}
`;

export const OptionGroupTitle = styled.div`
  ${({ theme }) => theme.textStyles.body.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.dropDownCategoryTextColor};
  padding-bottom: 0.8rem;
  &:not(:first-child) {
    border-top: 0.1rem solid ${({ theme }) => theme.borderColor};
    margin-top: 0.8rem;
    padding-top: 0.8rem;
  }
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.body.small.join(' ')};
  `}
`;

export const OptionElement = styled.button`
  text-align: left;
  ${({ theme }) => theme.textStyles.body.regular};
  padding: calc(${({ theme }) => theme.defaultPadding || '4rem'} / 4) 0;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 0.8rem;
  }
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    color: ${({ theme }) => theme.colorAccent || theme.backgroundColor || '#fff'};
  }
  &:not([disabled]):focus::before,
  &:not([disabled]):hover::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(${({ theme }) => theme.defaultPadding || '4rem'} / -4);
    right: calc(${({ theme }) => theme.defaultPadding || '4rem'} / -4);
    border-radius: ${({ theme }) => theme.borderRadius || '1rem'};
    background: ${({ theme }) => theme.textColor || '#ddd'};
    z-index: -1;
  }
  &:first-child:focus::before,
  &:first-child:hover::before {
    top: calc(${({ theme }) => theme.defaultPadding || '4rem'} / -4);
  }
  &:last-child:focus::before,
  &:last-child:hover::before {
    bottom: calc(${({ theme }) => theme.defaultPadding || '4rem'} / -4);
  }

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &[disabled] {
    cursor: default;
    color: ${({ theme }) => theme.disabledColor || '#666'};
  }
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.body.small.join(' ')};
  `}
`;

export const OptionsCountLabel = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 1.1rem;
  ${({ theme }) => theme.textStyles.caption.small || `font-size: 1rem;`};
`;

export const OptionsWrapper = styled.div<{ $displayOptionsPosition: AlignItem; $overflowItsAncestors: boolean }>`
  &:not(.active) ${OptionsBox} {
    z-index: -1;
    opacity: 0;
    max-height: 0;
  }
  ${({ $overflowItsAncestors }) => ($overflowItsAncestors ? `& ${OptionsBox} { position: fixed; }` : '')}
  ${({ $displayOptionsPosition, $overflowItsAncestors }) =>
    $overflowItsAncestors
      ? ''
      : `& ${OptionsBox} {
    ${getPositionStyling($displayOptionsPosition)}
  }`}
`;

export const ClearButton = styled(InputClearButton)`
  right: calc(1.6rem * 1.5 + 2.4rem);
`;

export const SelectWrapper = styled.div<{ $disabled?: boolean; $halfSize: boolean }>`
  position: relative;
  box-sizing: border-box;
  font-size: 1.6rem;
  & ${Boundry}::after {
    content: '';
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    top: calc(50% - 1.2rem);
    right: 1.6rem;
    background: url(${({ theme }) => theme.images.selectOpenChevron}) no-repeat;
    pointer-events: none;
    transition: transform ${({ theme }) => theme.animationSpeed || '512ms'}, filter ${({ theme }) => theme.animationSpeed || '512ms'};
    transform: rotate(0deg);
    filter: brightness(1);
    ${({ $disabled }) => ($disabled ? `filter: grayscale(1) brightness(2.4);` : '')}
  }
  & ${SelectInputField}, & ${SelectButton} {
    transition: ${({ theme }) => getTransitions(theme.animationSpeed, 'border-color', 'background-color')};
  }
  &.active ${Boundry}::after {
    transform: rotate(-180deg);
    filter: brightness(0);
  }
  &.active ${SelectInputField}, &.active ${SelectButton} {
    background-color: ${({ theme }) => theme.colorAccent || '#fff'};
    border-color: ${({ theme }) => theme.colorAccent || '#fff'};
    outline: none;
  }
  &.error ${SelectButton}, &.error ${SelectInputField} {
    border-color: ${({ theme }) => theme.colors.errorDark || '#A00'};
  }
  ${({ theme, $halfSize }) => theme.mediaQueries.MD`
    width: ${$halfSize ? 'calc(50% - 15px)' : '100%'};
  `}

  &.clearButton ${SelectInputField}:focus ~ ${ClearButton}.show,
  &.clearButton ${SelectInputField}:hover ~ ${ClearButton}.show,
  &.clearButton ${SelectInputField} ~ ${ClearButton}.show:hover, 
  &.clearButton ${SelectButton}:focus ~ ${ClearButton}.show,
  &.clearButton ${SelectButton}:hover ~ ${ClearButton}.show,
  &.clearButton ${SelectButton} ~ ${ClearButton}.show:hover {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const SearchErrorMessage = styled(CommonErrorStyles)<{ $width: number }>`
  ${({ $width }) => `width: ${rem($width)};`}
  ${({ theme, $width }) => theme.mediaQueries.LG`
    width: calc(${rem($width)} * .7);
  `}
`;

export { Boundry, InputOptionalLabel as SelectOptionalLabel };
