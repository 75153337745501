import _ from 'lodash';
import PropTypes from 'prop-types';

import { REQUEST_STATUS, BOOKING_STATUS, PAYMENT_SUBTYPES, PAYMENT_TYPES } from '../../constants';

export const cssShape = PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]));
export const stylesShape = PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func, cssShape]);

export const passengerShape = PropTypes.shape({
  type: PropTypes.string,
  index: PropTypes.number
});

export const passengersShape = PropTypes.arrayOf(passengerShape);

export const segmentShape = PropTypes.shape({
  key: PropTypes.string,
  departureTime: PropTypes.string,
  arrivalTime: PropTypes.string,
  origin: PropTypes.string,
  originCity: PropTypes.string,
  originCountry: PropTypes.string,
  destination: PropTypes.string,
  destinationCity: PropTypes.string,
  destinationCountry: PropTypes.string,
  airline: PropTypes.string,
  flightNumber: PropTypes.number,
  durationInMinutes: PropTypes.number,
  connectionTimeInMinutes: PropTypes.number
});

export const segmentsShape = PropTypes.arrayOf(segmentShape);

export const boundShape = PropTypes.shape({
  name: PropTypes.string,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      destination: PropTypes.string,
      destinationCity: PropTypes.string,
      origin: PropTypes.string,
      originCity: PropTypes.string,
      key: PropTypes.string
    })
  )
});

export const boundsShape = PropTypes.arrayOf(boundShape);

export const fareShape = PropTypes.shape({
  basePrice: PropTypes.number,
  currency: PropTypes.string,
  fareBasis: PropTypes.string,
  passengerTypePrices: PropTypes.shape({
    loyaltyAmount: PropTypes.objectOf(PropTypes.number),
    potentialEarning: PropTypes.objectOf(PropTypes.number),
    taxAmount: PropTypes.objectOf(PropTypes.number),
    value: PropTypes.objectOf(PropTypes.number)
  }),
  taxBasePrice: PropTypes.number
});

export const flightShape = PropTypes.shape({
  key: PropTypes.string,
  origin: PropTypes.string,
  destination: PropTypes.string,
  departureTime: PropTypes.string,
  arrivalTime: PropTypes.string,
  segments: segmentsShape,
  fares: fareShape
});

export const flightsShape = PropTypes.arrayOf(flightShape);

export const cabinTypeShape = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  order: PropTypes.number
});

export const fareFamilyFeatureShape = PropTypes.shape({
  code: PropTypes.string,
  visibleInCollapsedView: PropTypes.bool,
  displayAsActive: PropTypes.bool
});

export const fareFamilyShape = PropTypes.shape({
  allowedChangeToFareFamilyCodes: PropTypes.array,
  code: PropTypes.string,
  commonFeatures: PropTypes.arrayOf(fareFamilyFeatureShape),
  ownFeatures: PropTypes.arrayOf(fareFamilyFeatureShape),
  isRestricted: PropTypes.bool,
  recommended: PropTypes.bool,
  cabinType: cabinTypeShape
});

export const fareFamiliesShape = PropTypes.arrayOf(fareFamilyShape);

export const calendarItemPriceShape = PropTypes.shape({
  currency: PropTypes.string,
  value: PropTypes.number
});

export const lowestPricePerDayShape = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string,
    price: calendarItemPriceShape,
    scheduleStatus: PropTypes.string
  })
);

export const itineraryPartShape = PropTypes.shape({
  index: PropTypes.number,
  key: PropTypes.string,
  origin: PropTypes.string,
  originCity: PropTypes.string,
  destination: PropTypes.string,
  destinationCity: PropTypes.string,
  date: PropTypes.string,
  durationInMinutes: PropTypes.number,
  cheapestFlightOption: PropTypes.shape({
    key: PropTypes.string,
    fareFamily: PropTypes.string
  }),
  flights: flightsShape,
  lowestPricePerDay: lowestPricePerDayShape
});

export const itineraryPartsShape = PropTypes.arrayOf(itineraryPartShape);

export const cabinTypeToCheapestFareShape = PropTypes.shape({
  fareBasis: PropTypes.string,
  seatsRemaining: PropTypes.number,
  soldOut: PropTypes.bool,
  unavailableReason: PropTypes.number || PropTypes.string,
  price: PropTypes.shape({
    currency: PropTypes.string,
    taxAmount: PropTypes.number,
    value: PropTypes.number
  }),
  cabinType: cabinTypeShape,
  sale: PropTypes.bool
});

const segmentWithDifference = PropTypes.shape({
  departureTime: PropTypes.string,
  arrivalTime: PropTypes.string,
  origin: PropTypes.string,
  originCity: PropTypes.string,
  originCountry: PropTypes.string,
  destination: PropTypes.string,
  destinationCity: PropTypes.string,
  destinationCountry: PropTypes.string,
  airline: PropTypes.string,
  flightNumber: PropTypes.number,
  durationInMinutes: PropTypes.number,
  connectionTimeInMinutes: PropTypes.number,
  difference: PropTypes.shape({
    airline: PropTypes.bool,
    flightNumber: PropTypes.bool,
    origin: PropTypes.shape({
      airport: PropTypes.bool,
      city: PropTypes.bool,
      time: PropTypes.bool
    }),
    destination: PropTypes.shape({
      airport: PropTypes.bool,
      city: PropTypes.bool,
      time: PropTypes.bool
    })
  })
});

export const itineraryPartWithDifference = PropTypes.shape({
  origin: PropTypes.string,
  originCity: PropTypes.string,
  destination: PropTypes.string,
  destinationCity: PropTypes.string,
  date: PropTypes.string,
  difference: PropTypes.shape({
    origin: PropTypes.bool,
    destination: PropTypes.bool,
    date: PropTypes.bool,
    fares: PropTypes.bool
  }),
  areSegmentCountsDifferent: PropTypes.bool,
  segments: PropTypes.arrayOf(segmentWithDifference)
});

export const contactDetailsShape = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  emailAddress: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    countryCode: PropTypes.string
  })
});

export const bookingDetailsShape = PropTypes.shape({
  priceBreakdown: PropTypes.shape({
    outboundTotal: PropTypes.number,
    inboundTotal: PropTypes.number,
    bagsTotal: PropTypes.number,
    seatsTotal: PropTypes.number,
    mealsTotal: PropTypes.number,
    otherAncillariesTotal: PropTypes.number,
    paymentFees: PropTypes.number,
    overallTotal: PropTypes.number,
    balanceDue: PropTypes.number,
    currency: PropTypes.string,
    outboundPassengerTypePrices: PropTypes.shape({
      ADT: PropTypes.number
    }),
    inboundPassengerTypePrices: PropTypes.shape({
      ADT: PropTypes.number
    })
  }),
  itineraryParts: PropTypes.arrayOf(itineraryPartShape),
  passengers: passengersShape,
  recordLocator: PropTypes.string,
  bookingDate: PropTypes.string,
  status: PropTypes.oneOf(_.values(BOOKING_STATUS)),
  payment: PropTypes.shape({
    paymentDate: PropTypes.string,
    instructions: PropTypes.string,
    balance: PropTypes.number
  }),
  contactDetails: contactDetailsShape,
  ancillaries: PropTypes.shape({
    bags: PropTypes.array,
    meals: PropTypes.array,
    seats: PropTypes.array,
    others: PropTypes.array
  })
});

export const dataPerSegmentShape = PropTypes.shape({
  segmentKey: PropTypes.string,
  departureDate: PropTypes.string,
  origin: PropTypes.string,
  destination: PropTypes.string,
  airline: PropTypes.string,
  flightNumber: PropTypes.number,
  originCity: PropTypes.string,
  destinationCity: PropTypes.string,
  lowestPrice: PropTypes.number,
  ssrs: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      price: PropTypes.number,
      minAge: PropTypes.number,
      maxAge: PropTypes.number
    })
  ),
  selectedUnaccompaniedMinorPerPassenger: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      price: PropTypes.number,
      segmentKey: PropTypes.string
    })
  ),
  uncommittedUnaccompaniedMinorPerPassenger: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      price: PropTypes.number,
      segmentKey: PropTypes.string
    })
  ),
  isInventoryLimitationExceeded: PropTypes.bool
});

export const paymentSubtypeShape = PropTypes.shape({
  subtype: PropTypes.oneOf(_.values(PAYMENT_SUBTYPES)),
  fee: PropTypes.number,
  imageUrl: PropTypes.string
});

export const subtypesProps = PropTypes.arrayOf(paymentSubtypeShape);

export const paymentOptionShape = PropTypes.shape({
  subtypes: subtypesProps,
  type: PropTypes.oneOf(_.values(PAYMENT_TYPES))
});

export const voucherProps = PropTypes.shape({
  availableAmount: PropTypes.number,
  redeemed: PropTypes.bool,
  valid: PropTypes.bool
});

export const currenciesShape = PropTypes.shape({
  fiatCurrency: PropTypes.string,
  loyaltyCurrency: PropTypes.string
});

export const priceValuesShape = PropTypes.shape({
  fiatValue: PropTypes.number,
  loyaltyValue: PropTypes.number,
  taxValue: PropTypes.number
});

export const loyaltyProgramPriceShape = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string
});

export const selectedFareBasePriceShape = PropTypes.shape({
  value: PropTypes.number,
  loyaltyProgramPrice: PropTypes.number,
  taxAmount: PropTypes.number
});

export const requestStatusShape = PropTypes.oneOf(_.keys(REQUEST_STATUS));

export const slotShape = PropTypes.shape({
  seatNumber: PropTypes.string,
  service: PropTypes.string,
  price: PropTypes.number,
  loyaltyProgramPrice: PropTypes.shape({ amount: PropTypes.number }),
  isPaid: PropTypes.bool,
  includedInBundle: PropTypes.bool
});

export const selectedFlightShape = PropTypes.shape({
  flight: flightShape,
  fareFamily: PropTypes.string,
  amount: PropTypes.number,
  passengerPrices: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      count: PropTypes.number,
      amount: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          amount: PropTypes.number
        })
      )
    })
  ),
  hasItineraryPartChanged: PropTypes.bool
});

export const regexShape = PropTypes.shape({
  expression: PropTypes.string.isRequired,
  messageCode: PropTypes.string
});

export const constraintsShape = PropTypes.shape({
  mandatory: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  regex: regexShape
});

export const validationFieldShape = PropTypes.shape({
  constraints: constraintsShape,
  field: PropTypes.string,
  type: PropTypes.oneOf(['DATE', 'OBJECT', 'STRING'])
});

export const validationRulesShape = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.objectOf(PropTypes.arrayOf(PropTypes.func))])
);

export const validationConfigShape = PropTypes.arrayOf(validationFieldShape);

// React Hook Forms
export const ValidationRuleShape = PropTypes.shape({
  message: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.instanceOf(RegExp)])
});

export const ValidationRulesShape = PropTypes.shape({
  maxLength: ValidationRuleShape,
  minLength: ValidationRuleShape,
  pattern: ValidationRuleShape,
  required: PropTypes.oneOfType([ValidationRuleShape, PropTypes.bool])
});

export const SelectOptionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
  })
);

export const childrenShape = PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]);

export const documentShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  bound: PropTypes.string,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: loyaltyProgramPriceShape.isRequired
});
