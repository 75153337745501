import { MouseEvent, ReactNode, useCallback } from 'react';
import * as ui from './Button.ui';
import Icon from '../Icon';
import { actOnEnterAndSpace } from 'utils/commonUtils';

export type ButtonTypes = 'primary' | 'secondary' | 'link' | 'link-2-colors';
export type ButtonSizes = 'small' | 'regular' | 'large';

interface ButtonProps {
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  type?: ButtonTypes;
  size?: ButtonSizes;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  takeWholeContainer?: boolean;
  overwriteFocus?: string;
  ariaLabel?: string;
}

export const Button = ({
  children,
  className,
  type = 'link',
  size = 'regular',
  loading = false,
  disabled = false,
  onClick = () => null,
  takeWholeContainer = false,
  overwriteFocus = '',
  ariaLabel,
}: ButtonProps) => {
  const onKeyUpCallback = useCallback(actOnEnterAndSpace(onClick), []);
  return (
    <ui.Button
      $type={type}
      $size={size}
      $takeWholeContainer={takeWholeContainer}
      className={className}
      onClick={loading ? undefined : onClick}
      disabled={loading || disabled}
      $overwriteFocus={overwriteFocus}
      onKeyUp={onKeyUpCallback}
      aria-label={ariaLabel}>
      {loading && <Icon name="loading" className="loadingIcon" />}
      {children}
    </ui.Button>
  );
};
