import { ImageType, SourceProps } from 'types/Image';
// Drawer stuff
export const getSourcesFromThumbImage = (
  thumb: string,
  sizes: Array<number> = [1, 2],
  types: Array<ImageType> = ['image/webp', 'image/jpeg'],
): Array<SourceProps> => {
  const thumbPath = thumb.replace(/(^.+)\.[a-z]{2,4}$/, '$1');
  const sources = types.map(
    (type): SourceProps => ({
      srcset: sizes.map(size => ({
        image: `${thumbPath}${size > 1 ? `@${size}x` : ''}.${type.replace(/image\/([a-z]+$)/, '$1')}`,
        condition: `${size}x`,
      })),
      type,
    }),
  );
  return sources;
};
