/* This code is a complete mess and needs major refactor. It is leftover from the beginning when we needed some
 * authentication to work, and it was mostly a C&P of an example auth context from our repo.
 * The code itself needs to be cleaned, all saved and fetched data needs to be reviewed if it is needed and where
 * it is saved. Also the update of redirectUri needs to be reviewed and fixed so when you refresh the page you do not
 * get redirected somewhere else. (I think that is a problem when keycloak is already initialized).
 * Also onTokenExpired needs to be re-thought and device Authorization has to be explored and finished (a separate
 * branch for this scenario should exist somewhere with a prototype).
 */
import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions, KeycloakProfile } from 'keycloak-js';
import { ReactNode, createContext, useState, useCallback, useRef, useEffect } from 'react';
import { sessionExpired, setSecuredApiAccessToken } from 'src/store/session/sessionActions';
import { getUserProfileRequest, setAccountId } from 'src/store/user/userActions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import config from 'src/config';
import { useIntl } from '@triplake/lib-intl';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const tokenName: string = '-h2O';

// Keycloak server connection config
const keycloakConfig: KeycloakConfig = {
  realm: config.KEYCLOAK_REALM || 'master',
  clientId: config.KEYCLOAK_CLIENT_ID || 'local-3000-client',
  url: config.KEYCLOAK_URL || 'http://localhost:8080/',
};

// Keycloak client instance
const keycloak = new Keycloak(keycloakConfig);

// Options used for keycloak client initialization
const keycloakInitOptions: KeycloakInitOptions = {
  // onLoad: 'login-required', // can be used to force user to log in before accessing the page
  onLoad: 'check-sso',
  // checkLoginIframe: true,
  enableLogging: true,
  // silentCheckSsoRedirectUri: '/silent-check-sso.html',
  // redirectUri: window.location.href,
};

export interface AuthContextValues {
  login: (loginOptions?: KeycloakLoginOptions) => void;
  logout: () => void;
  isAuthenticated: boolean;
  onTokenExpiredCallback?: (onTokenExpiredFunction: (keycloak: Keycloak) => void) => void;
  rememberDeviceCallback?: () => Promise<any>;
  isInitialized: boolean;
}

const defaultAuthContextValues: AuthContextValues = {
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  isInitialized: false,
};

export const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues);

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const [isAuthenticated, setAuthenticated] = useState(keycloak.authenticated ?? false);
  const [isInitialized, setInitialized] = useState(((keycloak as any).didInitialize || keycloak.authenticated) ?? false);
  const setToken = useCallback((accessToken: string | null): void => {
    if (accessToken === null) return localStorage.removeItem(tokenName);
    dispatch(setSecuredApiAccessToken({ accessToken }));
    localStorage.setItem(tokenName, accessToken);
  }, []);
  const onExpiredFoo = useRef<() => void | undefined>();
  const login = useCallback((options = {}) => keycloak.login({ locale, ...options }), [locale]);
  const logout = keycloak.logout;

  const onTokenExpiredCallback = useCallback((onTokenExpiredFunction: (keycloak: Keycloak) => void): void => {
    onExpiredFoo.current = () => {
      console.log('onTokenExpired called in provider');
      onTokenExpiredFunction(keycloak);
    };
  }, []);

  const rememberDeviceCallback = useCallback((): Promise<any> => {
    return fetch(`${keycloakConfig.url}realms/${keycloakConfig.realm}/protocol/openid-connect/auth/device`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `client_id=${keycloakConfig.clientId}`,
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('goTo: %o, and enter %s', (responseJson as any).verification_uri_complete, (responseJson as any).user_code);
        const fetchToken = () => {
          fetch(`${keycloakConfig.url}realms/${keycloakConfig.realm}/protocol/openid-connect/token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `grant_type=urn:ietf:params:oauth:grant-type:device_code&device_code=${responseJson.device_code || ''}&client_id=${
              keycloakConfig.clientId
            }`,
          }).then(response => {
            response.json().then(j => {
              if (j.access_token) setToken(j.access_token);
              console.log(j);
            });
            console.log(response);
          });
        };
        return { fetchToken, responseJson };
      });
  }, [setToken]);

  const onAuthSuccess = useCallback(async () => {
    try {
      const profile: KeycloakProfile = await keycloak.loadUserProfile();
      dispatch(setAccountId({ accountId: profile.id || '' }));
      if (keycloak.token) setToken(keycloak.token);
      dispatch(getUserProfileRequest());
    } catch (e: any) {
      const message = e?.message ? e.message : e.toString();
      console.log('Error loading profile from Keycloak: %c%s%c', 'font-weight:bold', message, 'font-weight:normal');
    }
    setAuthenticated(true);
  }, []);

  const onTokenExpired = useCallback(() => {
    if (typeof onExpiredFoo.current === 'function') onExpiredFoo.current();
    else {
      keycloak
        .updateToken(5)
        .then(
          refreshed => {
            if (refreshed) console.log('refreshed'); // Token refreshed,
            else console.log('session is still valid'); //Token is still valid now
          },
          error => {
            if (error) {
              console.log('Error refreshing');
              return Promise.reject(new Error('failed refreshing session, most probably '));
            }
            console.log('refresh failed: %o', error);
          },
        )
        .catch(function (message) {
          console.log('Failed to refresh the token, or the session has expired / %o', message);
          console.log(keycloak);
          console.log(keycloak.authenticated);
          console.log(keycloak.isTokenExpired());
          setAuthenticated(false);
          dispatch(sessionExpired());
        });
    }
  }, []);

  useEffect(() => {
    keycloakInitOptions.redirectUri = window.location.href;
    keycloakInitOptions.locale = locale;
    if ((keycloak as any)?.didInitialize) return;
    keycloak.onTokenExpired = onTokenExpired;
    keycloak.onAuthLogout = () => {
      setAuthenticated(false);
      dispatch(sessionExpired());
    };
    keycloak.onAuthSuccess = onAuthSuccess;
    keycloak.onAuthLogout = () => {
      setAuthenticated(false);
      dispatch(sessionExpired());
    };
    keycloak
      .init(keycloakInitOptions)
      .then(async authenticated => {
        if (authenticated) await onAuthSuccess();
        setInitialized(true);
      })
      .catch(error => {
        const message = error?.message || error?.error || error.toString();
        console.log('Error initializing Keycloak: %c%s%c', 'font-weight:bold', message, 'font-weight:normal');
        setAuthenticated(false);
      });
  }, [locale]);

  return (
    <AuthContext.Provider value={{ login, logout, isAuthenticated, onTokenExpiredCallback, rememberDeviceCallback, isInitialized }}>
      <PageWrapper>{children}</PageWrapper>
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
