import styled from 'styled-components';

export const OmanLogo = styled.a`
  height: 3.2rem;
`;
export const OmanHeaderWrapper = styled.div`
  width: 100vw;
  background: ${({ theme }) => theme.colors.white || '#fff'};
  padding: 2.4rem 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
`;
