import styled from 'styled-components';

/* linear-gradient(to top right, rgba(228,221,221, 0.50) 10%, rgba(0,0,0, 0.80 ) 60%), */
export const HeroWrapper = styled.div<{ $background?: string; $customStyles?: string }>`
  height: 20rem;
  ${({ $background }) => $background || `background-image: var(--Member-Portal-Gradients-Red, linear-gradient(139deg, ));`}
  width: 100vw;
  ${({ $customStyles }) => $customStyles || ''}
  ${({ theme }) => theme.mediaQueries.SM`height: 16rem;`}
`;

export const HeroBackground = styled.div<{ $image: string; $backgroundSize?: string; $backgroundPosition?: string }>`
  background-size: ${({ $backgroundSize }) => $backgroundSize || 'cover'};
  background-position: ${({ $backgroundPosition }) => $backgroundPosition || '100% 100%'};
  ${({ theme, $image }) => ($image ? `background-image: url(${theme.images[$image]});` : '')}
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  padding-top: 7.4rem;
  ${({ theme }) => theme.mediaQueries.SM`padding-top: 6rem;`}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Hero = styled.div`
  width: 111rem;
  margin: 0 auto;
  ${({ theme }) => theme.mediaQueries.LG`width: 80rem;`};
  ${({ theme }) => theme.mediaQueries.MD`
    width: calc(100vw - 1.6rem * 2);
    margin: 0 1.6rem;
  `};
`;

export const HeroTitle = styled.div<{ $color?: string }>`
  font: ${({ theme }) => theme.textStyles.header.h1};
  color: ${({ $color, theme }) => ($color ? theme.colors[$color] : theme.invertedTextColor)};
  ${({ theme }) => theme.mediaQueries.SM`font: ${theme.textStyles.header.h3};`};
`;

export const HeroSubtitle = styled.div<{ $color?: string }>`
  font: 3.2rem / 5.1rem ${props => props.theme.fontFamilyPrimary};
  color: ${props => props.theme.colors[props.$color || 'white']};
`;
