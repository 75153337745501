import { FormattedMessage } from '@triplake/lib-intl';
import { Select, SelectOption } from 'components/Form';
import { useLocale } from 'src/hooks/useLocale';
import Button from 'components/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLanguages, getRegions } from 'src/store/config/configSelectors';
import { useSelector } from 'react-redux';
import config from 'src/config';
import { International } from 'src/store/intl/intlConstants';
import Icon from 'components/Icon';
import { useDeviceType } from '@triplake/lib-hooks';
import * as mobileUi from 'components/Header/components/Drawer/mobile/Drawer.mobile.ui';
import { messages } from './LocaleDropdown.translations';
import * as ui from './LocaleDropdown.ui';

export const LocaleDropdown = ({ onDone }) => {
  const { isMobile } = useDeviceType();
  const { defaultLocale, preferredLocale, supportedLocales, changeLocale, region: defaultRegion } = useLocale();
  const languages = useSelector(getLanguages);
  const regions = useSelector(getRegions);
  const [language, setLanguage] = useState(preferredLocale || defaultLocale);
  const [region, setRegion] = useState(defaultRegion || International);

  const regionOptions = useMemo((): Array<SelectOption> => {
    const regionOptions = Object.keys(regions || {})
      .filter(v => v !== International)
      .map((value: string): SelectOption => {
        const pimcoreOrigin = (config.PIMCORE_URL || '').replace(/(^https?:\/\/[^/]+).*/, '$1');
        const url = `${pimcoreOrigin}/bundles/pimcoreadmin/img/flags/countries/${value.toLowerCase()}.svg`;
        const onError = e => {
          const img = e.target;
          img.dataset.empty = true;
        };
        return {
          label: value,
          richLabel: (
            <ui.LanguageLabel>
              <img src={url} onError={onError} alt={value} />
              <FormattedMessage id={`country.${value}`} />
            </ui.LanguageLabel>
          ),
          value,
        };
      })
      .filter(r => r.value);

    regionOptions.push({
      label: International,
      richLabel: (
        <ui.LanguageLabel>
          <Icon name="globe" />
          <FormattedMessage {...messages.internationalRegion} />
        </ui.LanguageLabel>
      ),
      value: International,
    });

    return regionOptions;
  }, [regions]);

  const locales = useMemo(
    () =>
      (regions?.[region ?? International] ?? [])
        .filter(locale => !!languages.find(lang => lang.languageCode === locale))
        .map((value: string) => {
          const language = languages.find(lang => lang.languageCode === value);
          return {
            value,
            label: language?.languageName ?? value,
          };
        }),
    [supportedLocales, regions, languages, language, region],
  );

  const onSubmit = useCallback(() => {
    changeLocale(language, region);
    if (typeof onDone === 'function') onDone();
  }, [changeLocale, language, region]);

  const onRegionChange = useCallback((event, value) => setRegion(value), [regions]);

  const onLocaleChange = useCallback((event, value) => setLanguage(value), []);

  useEffect(() => {
    if (!regions || !Array.isArray(regions[region ?? International])) setLanguage(defaultLocale);
    else setLanguage(language => (regions[region ?? International].includes(language) ? language : regions[region ?? International][0]));
  }, [region, regions, defaultLocale]);

  const content = (
    <ui.Wrapper>
      {isMobile ? <FormattedMessage {...messages.infoMessage} /> : null}
      <Select
        name="region"
        value={region}
        options={regionOptions}
        placeholder={<FormattedMessage {...messages.languageRegionLabel} />}
        onChange={onRegionChange}
      />
      <Select
        name="locale"
        value={language}
        options={locales}
        placeholder={<FormattedMessage {...messages.languageLanguageLabel} />}
        onChange={onLocaleChange}
      />
      <Button type="primary" onClick={onSubmit} takeWholeContainer={false} className={isMobile ? 'fullWidth' : 'narrow'}>
        Done
      </Button>
    </ui.Wrapper>
  );

  if (isMobile) {
    return (
      <mobileUi.MobileDrawer $menu={0}>
        <mobileUi.MainMenu>
          <mobileUi.MobileDrawerHeader />
          <mobileUi.MobileDrawerContent>{content}</mobileUi.MobileDrawerContent>
          <mobileUi.MobileDrawerCloseButton onClick={onDone}>
            <Icon name="closeDark" />
          </mobileUi.MobileDrawerCloseButton>
        </mobileUi.MainMenu>
      </mobileUi.MobileDrawer>
    );
  }

  return content;
};
