export const DAYS_OF_WEEK = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday'
};

export const WEEKEND_DAYS = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

export const DATE_FORMAT = 'YYYY-MM-DD';
