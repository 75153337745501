import { useState, useCallback, useEffect, useContext } from 'react';
import { DeviceTypeContext } from '@triplake/lib-hooks';
import { DeviceContextType } from 'types/types';
import { AccordionProps, ChordData } from 'types/Accordion';
import * as ui from './Accordion.ui';
import Chord from './Chord';

export const Accordion = ({
  chordsData,
  openButton,
  closeButton,
  activeAccordion = '',
  onAccordionOpen = () => {},
  onAccordionClose = () => {},
  clickableTitle = false,
}: AccordionProps) => {
  const [activeChord, setActiveChord] = useState('');
  const { isMobile } = useContext(DeviceTypeContext) as DeviceContextType;

  // Callbacks
  const isActiveCheck = useCallback((chordId: string): boolean => chordId === activeChord, [activeChord]);
  const onChordClick = useCallback(
    (chord: ChordData): void => {
      if (chord.isDisabled || chord.justATitleBar) return;
      setActiveChord((currentChordId: string): string => {
        const clickedOnActive = currentChordId === chord.id;
        if (clickedOnActive && typeof onAccordionClose === 'function' && onAccordionClose(chord.id) === false) return currentChordId;
        if (!clickedOnActive && onAccordionOpen) onAccordionOpen(chord.id);
        return clickedOnActive ? '' : chord.id;
      });
    },
    [onAccordionOpen, onAccordionClose],
  );

  // Effects
  useEffect(() => {
    setActiveChord(activeAccordion);
  }, [activeAccordion]);

  const chords = (Array.isArray(chordsData) ? chordsData : [chordsData]).map((chord: ChordData) => (
    <Chord
      {...chord}
      onChordClick={() => onChordClick(chord)}
      isActive={isActiveCheck(chord.id)}
      key={chord.id}
      openButton={openButton}
      closeButton={closeButton}
      clickableTitle={clickableTitle || isMobile}
    />
  ));

  return <ui.AccordionWrapper>{chords}</ui.AccordionWrapper>;
};
