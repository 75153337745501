export const Airlines = [
  {
    name: 'Air Mauritius',
    id: 'MK',
  },
  {
    name: 'Air France',
    id: 'AF',
  },
  {
    name: 'Emirates',
    id: 'EK',
  },
  {
    name: 'Air Canada',
    id: 'AC',
  },
];

export const Routes = {
  'free-ticket': {
    MK: [
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Mumbai',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: ['CDG', 'CPT', 'DEL', 'FCO', 'GVA', 'JNB', 'KUL', 'LGW', 'MAA', 'PER', 'RRG', 'RUN', 'TNR'],
        destination: ['BOM', 'CDG', 'CPT', 'DEL', 'FCO', 'GVA', 'JNB', 'KUL', 'LGW', 'MAA', 'PER', 'RRG', 'RUN', 'TNR'],
        city: 'Mauritius',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Paris',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Cape Town',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Delhi',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Rome',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Geneva',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Johannesburg',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'London',
        id: 'LGW',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'London',
      },
      {
        name: 'Chennai',
        id: 'MAA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Chennai',
      },
      {
        name: 'Perth',
        id: 'PER',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Perth',
      },
      {
        name: 'Rodrigues',
        id: 'RRG',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Rodrigues',
      },
      {
        name: 'Reunion',
        id: 'RUN',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Reunion',
      },
      {
        name: 'Antannarivo',
        id: 'TNR',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Antannarivo',
      },
    ],
    EK: [
      {
        name: 'Abidjan',
        id: 'ABJ',
        origin: ['ACC', 'DXB'],
        destination: ['ACC', 'DXB'],
        city: 'Abidjan',
      },
      {
        name: 'Accra',
        id: 'ACC',
        origin: ['DXB'],
        destination: ['ABJ', 'DXB'],
        city: 'Accra',
      },
      {
        name: 'Dubai',
        id: 'DXB',
        origin: [
          'ABV',
          'ACC',
          'ADD',
          'ADL',
          'AKL',
          'ALG',
          'AMD',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BAH',
          'BCN',
          'BEY',
          'BGW',
          'BHX',
          'BKK',
          'BLQ',
          'BLR',
          'BNE',
          'BOM',
          'BOS',
          'BRU',
          'BSR',
          'BUD',
          'CAI',
          'CAN',
          'CAS',
          'CCJ',
          'CCU',
          'CDG',
          'CEB',
          'CGK',
          'CGO',
          'CHC',
          'CKY',
          'CMB',
          'CMN',
          'COK',
          'CPH',
          'CPT',
          'CRK',
          'DAC',
          'DAM',
          'DAR',
          'DEL',
          'DFW',
          'DKR',
          'DME',
          'DMM',
          'DOH',
          'DPS',
          'DUB',
          'DUR',
          'DUS',
          'EBB',
          'EBL',
          'EZE',
          'FCO',
          'FRA',
          'GIG',
          'GLA',
          'GRU',
          'GVA',
          'HAM',
          'HAN',
          'HBE',
          'HKG',
          'HKT',
          'HND',
          'HRE',
          'HYD',
          'IAD',
          'IAH',
          'ICN',
          'IKA',
          'INC',
          'ISB',
          'IST',
          'JED',
          'JFK',
          'JNB',
          'KAN',
          'KBL',
          'KBP',
          'KHI',
          'KIX',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LAX',
          'LCA',
          'LED',
          'LGW',
          'LHE',
          'LHR',
          'LIS',
          'LOS',
          'LUN',
          'LYS',
          'MAA',
          'MAD',
          'MAN',
          'MCO',
          'MCT',
          'MED',
          'MEL',
          'MHD',
          'MLA',
          'MLE',
          'MNL',
          'MRU',
          'MUC',
          'MUX',
          'MXP',
          'NBO',
          'NCE',
          'NCL',
          'NGO',
          'NRT',
          'ORD',
          'OSL',
          'PEK',
          'PER',
          'PEW',
          'PRG',
          'PVG',
          'RGN',
          'RUH',
          'SAH',
          'SAW',
          'SEA',
          'SEZ',
          'SFO',
          'SGN',
          'SIN',
          'SKT',
          'SXF',
          'SYD',
          'THR',
          'TIP',
          'TPE',
          'TRV',
          'TUN',
          'VCE',
          'VIE',
          'WAW',
          'WLG',
          'YYZ',
          'ZRH',
        ],
        destination: [
          'ABJ',
          'ABV',
          'ACC',
          'ADD',
          'ADL',
          'AKL',
          'ALG',
          'AMD',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BAH',
          'BCN',
          'BEY',
          'BGW',
          'BHX',
          'BKK',
          'BLQ',
          'BLR',
          'BNE',
          'BOM',
          'BOS',
          'BRU',
          'BSR',
          'BUD',
          'CAI',
          'CAN',
          'CAS',
          'CCJ',
          'CCU',
          'CDG',
          'CEB',
          'CGK',
          'CGO',
          'CHC',
          'CKY',
          'CMB',
          'CMN',
          'COK',
          'CPH',
          'CPT',
          'CRK',
          'DAC',
          'DAM',
          'DAR',
          'DEL',
          'DFW',
          'DKR',
          'DME',
          'DMM',
          'DOH',
          'DPS',
          'DUB',
          'DUR',
          'DUS',
          'EBB',
          'EBL',
          'EZE',
          'FCO',
          'FRA',
          'GIG',
          'GLA',
          'GRU',
          'GVA',
          'HAM',
          'HAN',
          'HBE',
          'HKG',
          'HKT',
          'HND',
          'HRE',
          'HYD',
          'IAD',
          'IAH',
          'ICN',
          'IKA',
          'INC',
          'ISB',
          'IST',
          'JED',
          'JFK',
          'JNB',
          'KAN',
          'KBL',
          'KBP',
          'KHI',
          'KIX',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LAX',
          'LCA',
          'LED',
          'LGW',
          'LHE',
          'LHR',
          'LIS',
          'LOS',
          'LUN',
          'LYS',
          'MAA',
          'MAD',
          'MAN',
          'MCO',
          'MCT',
          'MED',
          'MEL',
          'MHD',
          'MLA',
          'MLE',
          'MNL',
          'MRU',
          'MUC',
          'MUX',
          'MXP',
          'NBO',
          'NCE',
          'NCL',
          'NGO',
          'NRT',
          'ORD',
          'OSL',
          'PEK',
          'PER',
          'PEW',
          'PRG',
          'PVG',
          'RGN',
          'RUH',
          'SAH',
          'SAW',
          'SEA',
          'SEZ',
          'SFO',
          'SGN',
          'SIN',
          'SKT',
          'SXF',
          'SYD',
          'THR',
          'TIP',
          'TPE',
          'TRV',
          'TUN',
          'VCE',
          'VIE',
          'WAW',
          'WLG',
          'YYZ',
          'ZRH',
        ],
        city: 'Dubai',
      },
      {
        name: 'Abuja',
        id: 'ABV',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Abuja',
      },
      {
        name: 'Addis Ababa',
        id: 'ADD',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Addis Ababa',
      },
      {
        name: 'Adelaide',
        id: 'ADL',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Adelaide',
      },
      {
        name: 'Auckland',
        id: 'AKL',
        origin: ['BNE', 'DXB', 'MEL', 'SYD'],
        destination: ['BNE', 'DXB', 'MEL', 'SYD'],
        city: 'Auckland',
      },
      {
        name: 'Brisbane',
        id: 'BNE',
        origin: ['DXB', 'SIN'],
        destination: ['AKL', 'DXB', 'SIN'],
        city: 'Brisbane',
      },
      {
        name: 'Melbourne',
        id: 'MEL',
        origin: ['DXB', 'KUL', 'SIN'],
        destination: ['AKL', 'DXB', 'KUL', 'SIN'],
        city: 'Melbourne',
      },
      {
        name: 'Sydney',
        id: 'SYD',
        origin: ['BKK', 'CHC', 'DXB'],
        destination: ['AKL', 'BKK', 'CHC', 'DXB'],
        city: 'Sydney',
      },
      {
        name: 'Algiers',
        id: 'ALG',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Algiers',
      },
      {
        name: 'Ahmedabad',
        id: 'AMD',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Ahmedabad',
      },
      {
        name: 'Amman',
        id: 'AMM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Amman',
      },
      {
        name: 'Amsterdam',
        id: 'AMS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Amsterdam',
      },
      {
        name: 'Stockholm',
        id: 'ARN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Stockholm',
      },
      {
        name: 'Athens',
        id: 'ATH',
        origin: ['DXB', 'LCA'],
        destination: ['DXB', 'LCA'],
        city: 'Athens',
      },
      {
        name: 'Larnaca',
        id: 'LCA',
        origin: ['DXB', 'MLA'],
        destination: ['ATH', 'DXB', 'MLA'],
        city: 'Larnaca',
      },
      {
        name: 'Bahrain',
        id: 'BAH',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bahrain',
      },
      {
        name: 'Barcelona',
        id: 'BCN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Barcelona',
      },
      {
        name: 'Beirut',
        id: 'BEY',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Beirut',
      },
      {
        name: 'Baghdad',
        id: 'BGW',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Baghdad',
      },
      {
        name: 'Birmingham',
        id: 'BHX',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Birmingham',
      },
      {
        name: 'Bangkok',
        id: 'BKK',
        origin: ['CHC', 'DXB', 'HKG', 'SYD'],
        destination: ['CHC', 'DXB', 'HKG', 'SYD'],
        city: 'Bangkok',
      },
      {
        name: 'Christchurch',
        id: 'CHC',
        origin: ['DXB', 'SYD'],
        destination: ['BKK', 'DXB', 'SYD'],
        city: 'Christchurch',
      },
      {
        name: 'Hong Kong',
        id: 'HKG',
        origin: ['DXB'],
        destination: ['BKK', 'DXB'],
        city: 'Hong Kong',
      },
      {
        name: 'Bologna',
        id: 'BLQ',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bologna',
      },
      {
        name: 'Bangalore',
        id: 'BLR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bangalore',
      },
      {
        name: 'Singapore',
        id: 'SIN',
        origin: ['CMB', 'DXB', 'MEL'],
        destination: ['BNE', 'CMB', 'DXB', 'MEL'],
        city: 'Singapore',
      },
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Mumbai',
      },
      {
        name: 'Boston',
        id: 'BOS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Boston',
      },
      {
        name: 'Brussels',
        id: 'BRU',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Brussels',
      },
      {
        name: 'Basrah',
        id: 'BSR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Basrah',
      },
      {
        name: 'Budapest',
        id: 'BUD',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Budapest',
      },
      {
        name: 'Cairo',
        id: 'CAI',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cairo',
      },
      {
        name: 'Guangzhou',
        id: 'CAN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Guangzhou',
      },
      {
        name: 'Casablanca–Anfa',
        id: 'CAS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Casablanca–Anfa',
      },
      {
        name: 'Kozhikode',
        id: 'CCJ',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Kozhikode',
      },
      {
        name: 'Kolkata',
        id: 'CCU',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Kolkata',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Paris',
      },
      {
        name: 'Cebu',
        id: 'CEB',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cebu',
      },
      {
        name: 'Jakarta',
        id: 'CGK',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Jakarta',
      },
      {
        name: 'Zhengzhou',
        id: 'CGO',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Zhengzhou',
      },
      {
        name: 'Conakry',
        id: 'CKY',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Conakry',
      },
      {
        name: 'Colombo',
        id: 'CMB',
        origin: ['DXB', 'MLE', 'SIN'],
        destination: ['DXB', 'MLE', 'SIN'],
        city: 'Colombo',
      },
      {
        name: 'Malé-Velana',
        id: 'MLE',
        origin: ['DXB'],
        destination: ['CMB', 'DXB'],
        city: 'Malé-Velana',
      },
      {
        name: 'Casablanca',
        id: 'CMN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Casablanca',
      },
      {
        name: 'Cochin',
        id: 'COK',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cochin',
      },
      {
        name: 'Copenhagen',
        id: 'CPH',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Copenhagen',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cape Town',
      },
      {
        name: 'Mabalacat, Philippines',
        id: 'CRK',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Mabalacat, Philippines',
      },
      {
        name: 'Dhaka',
        id: 'DAC',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dhaka',
      },
      {
        name: 'Damascus',
        id: 'DAM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Damascus',
      },
      {
        name: 'Dar Es Salaam',
        id: 'DAR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dar Es Salaam',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Delhi',
      },
      {
        name: 'Dallas/Ft. Worth',
        id: 'DFW',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dallas/Ft. Worth',
      },
      {
        name: 'Dakar',
        id: 'DKR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dakar',
      },
      {
        name: 'Moscow Domodedovo',
        id: 'DME',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Moscow Domodedovo',
      },
      {
        name: 'Dammam',
        id: 'DMM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dammam',
      },
      {
        name: 'Doha',
        id: 'DOH',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Doha',
      },
      {
        name: 'Bali Island, Denpasar',
        id: 'DPS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bali Island, Denpasar',
      },
      {
        name: 'Dublin',
        id: 'DUB',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dublin',
      },
      {
        name: 'Durban',
        id: 'DUR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Durban',
      },
      {
        name: 'Dusseldorf',
        id: 'DUS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dusseldorf',
      },
      {
        name: 'Entebbe',
        id: 'EBB',
        origin: [],
        destination: ['DXB'],
        city: 'Entebbe',
      },
      {
        name: 'Erbil',
        id: 'EBL',
        origin: [],
        destination: ['DXB'],
        city: 'Erbil',
      },
      {
        name: 'Buenos Aires',
        id: 'EZE',
        origin: ['GIG'],
        destination: ['DXB', 'GIG'],
        city: 'Buenos Aires',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: [],
        destination: ['DXB'],
        city: 'Rome',
      },
      {
        name: 'Frankfurt',
        id: 'FRA',
        origin: [],
        destination: ['DXB'],
        city: 'Frankfurt',
      },
      {
        name: 'Rio de Janeiro',
        id: 'GIG',
        origin: ['EZE'],
        destination: ['DXB', 'EZE'],
        city: 'Rio de Janeiro',
      },
      {
        name: 'Glasgow',
        id: 'GLA',
        origin: [],
        destination: ['DXB'],
        city: 'Glasgow',
      },
      {
        name: 'Sao Paulo',
        id: 'GRU',
        origin: [],
        destination: ['DXB'],
        city: 'Sao Paulo',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: [],
        destination: ['DXB'],
        city: 'Geneva',
      },
      {
        name: 'Hamburg',
        id: 'HAM',
        origin: [],
        destination: ['DXB'],
        city: 'Hamburg',
      },
      {
        name: 'Hanoi',
        id: 'HAN',
        origin: ['RGN'],
        destination: ['DXB', 'RGN'],
        city: 'Hanoi',
      },
      {
        name: 'Alexandria',
        id: 'HBE',
        origin: [],
        destination: ['DXB'],
        city: 'Alexandria',
      },
      {
        name: 'Phuket',
        id: 'HKT',
        origin: [],
        destination: ['DXB'],
        city: 'Phuket',
      },
      {
        name: 'Tokyo',
        id: 'HND',
        origin: [],
        destination: ['DXB'],
        city: 'Tokyo',
      },
      {
        name: 'Harare',
        id: 'HRE',
        origin: ['LUN'],
        destination: ['DXB', 'LUN'],
        city: 'Harare',
      },
      {
        name: 'Hyderabad',
        id: 'HYD',
        origin: [],
        destination: ['DXB'],
        city: 'Hyderabad',
      },
      {
        name: 'Washington DC (IAD)',
        id: 'IAD',
        origin: [],
        destination: ['DXB'],
        city: 'Washington DC (IAD)',
      },
      {
        name: 'Houston',
        id: 'IAH',
        origin: [],
        destination: ['DXB'],
        city: 'Houston',
      },
      {
        name: 'Seoul',
        id: 'ICN',
        origin: [],
        destination: ['DXB'],
        city: 'Seoul',
      },
      {
        name: 'Tehran',
        id: 'IKA',
        origin: [],
        destination: ['DXB'],
        city: 'Tehran',
      },
      {
        name: 'Yinchuan',
        id: 'INC',
        origin: [],
        destination: ['DXB'],
        city: 'Yinchuan',
      },
      {
        name: 'Islamabad',
        id: 'ISB',
        origin: [],
        destination: ['DXB'],
        city: 'Islamabad',
      },
      {
        name: 'Istanbul',
        id: 'IST',
        origin: [],
        destination: ['DXB'],
        city: 'Istanbul',
      },
      {
        name: 'Jeddah',
        id: 'JED',
        origin: [],
        destination: ['DXB'],
        city: 'Jeddah',
      },
      {
        name: 'New York (JFK)',
        id: 'JFK',
        origin: ['MXP'],
        destination: ['DXB', 'MXP'],
        city: 'New York (JFK)',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: [],
        destination: ['DXB'],
        city: 'Johannesburg',
      },
      {
        name: 'Kano',
        id: 'KAN',
        origin: [],
        destination: ['DXB'],
        city: 'Kano',
      },
      {
        name: 'Kabul',
        id: 'KBL',
        origin: [],
        destination: ['DXB'],
        city: 'Kabul',
      },
      {
        name: 'Boryspil',
        id: 'KBP',
        origin: [],
        destination: ['DXB'],
        city: 'Boryspil',
      },
      {
        name: 'Karachi',
        id: 'KHI',
        origin: [],
        destination: ['DXB'],
        city: 'Karachi',
      },
      {
        name: 'Osaka',
        id: 'KIX',
        origin: [],
        destination: ['DXB'],
        city: 'Osaka',
      },
      {
        name: 'Khartoum',
        id: 'KRT',
        origin: [],
        destination: ['DXB'],
        city: 'Khartoum',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: ['MEL'],
        destination: ['DXB', 'MEL'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'Kuwait',
        id: 'KWI',
        origin: [],
        destination: ['DXB'],
        city: 'Kuwait',
      },
      {
        name: 'Luanda',
        id: 'LAD',
        origin: [],
        destination: ['DXB'],
        city: 'Luanda',
      },
      {
        name: 'Los Angeles',
        id: 'LAX',
        origin: [],
        destination: ['DXB'],
        city: 'Los Angeles',
      },
      {
        name: 'St. Petersburg',
        id: 'LED',
        origin: [],
        destination: ['DXB'],
        city: 'St. Petersburg',
      },
      {
        name: 'London',
        id: 'LGW',
        origin: [],
        destination: ['DXB'],
        city: 'London',
      },
      {
        name: 'Lahore (Pakistan)',
        id: 'LHE',
        origin: [],
        destination: ['DXB'],
        city: 'Lahore (Pakistan)',
      },
      {
        name: 'London',
        id: 'LHR',
        origin: [],
        destination: ['DXB'],
        city: 'London',
      },
      {
        name: 'Lisbon',
        id: 'LIS',
        origin: [],
        destination: ['DXB'],
        city: 'Lisbon',
      },
      {
        name: 'Lagos',
        id: 'LOS',
        origin: [],
        destination: ['DXB'],
        city: 'Lagos',
      },
      {
        name: 'Lusaka',
        id: 'LUN',
        origin: ['HRE'],
        destination: ['DXB', 'HRE'],
        city: 'Lusaka',
      },
      {
        name: 'Lyon',
        id: 'LYS',
        origin: [],
        destination: ['DXB'],
        city: 'Lyon',
      },
      {
        name: 'Chennai',
        id: 'MAA',
        origin: [],
        destination: ['DXB'],
        city: 'Chennai',
      },
      {
        name: 'Madrid',
        id: 'MAD',
        origin: [],
        destination: ['DXB'],
        city: 'Madrid',
      },
      {
        name: 'Manchester',
        id: 'MAN',
        origin: [],
        destination: ['DXB'],
        city: 'Manchester',
      },
      {
        name: 'Orlando',
        id: 'MCO',
        origin: [],
        destination: ['DXB'],
        city: 'Orlando',
      },
      {
        name: 'Muscat',
        id: 'MCT',
        origin: [],
        destination: ['DXB'],
        city: 'Muscat',
      },
      {
        name: 'Medina',
        id: 'MED',
        origin: [],
        destination: ['DXB'],
        city: 'Medina',
      },
      {
        name: 'Mashhad',
        id: 'MHD',
        origin: [],
        destination: ['DXB'],
        city: 'Mashhad',
      },
      {
        name: 'Malta',
        id: 'MLA',
        origin: ['LCA'],
        destination: ['DXB', 'LCA'],
        city: 'Malta',
      },
      {
        name: 'Manila',
        id: 'MNL',
        origin: [],
        destination: ['DXB'],
        city: 'Manila',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: [],
        destination: ['DXB'],
        city: 'Mauritius',
      },
      {
        name: 'Munich',
        id: 'MUC',
        origin: [],
        destination: ['DXB'],
        city: 'Munich',
      },
      {
        name: 'Multan',
        id: 'MUX',
        origin: [],
        destination: ['DXB'],
        city: 'Multan',
      },
      {
        name: 'Milan',
        id: 'MXP',
        origin: ['JFK'],
        destination: ['DXB', 'JFK'],
        city: 'Milan',
      },
      {
        name: 'Nairobi',
        id: 'NBO',
        origin: [],
        destination: ['DXB'],
        city: 'Nairobi',
      },
      {
        name: 'Nice',
        id: 'NCE',
        origin: [],
        destination: ['DXB'],
        city: 'Nice',
      },
      {
        name: 'Newcastle',
        id: 'NCL',
        origin: [],
        destination: ['DXB'],
        city: 'Newcastle',
      },
      {
        name: 'Nagoya',
        id: 'NGO',
        origin: [],
        destination: ['DXB'],
        city: 'Nagoya',
      },
      {
        name: 'Tokyo',
        id: 'NRT',
        origin: [],
        destination: ['DXB'],
        city: 'Tokyo',
      },
      {
        name: 'Chicago',
        id: 'ORD',
        origin: [],
        destination: ['DXB'],
        city: 'Chicago',
      },
      {
        name: 'Oslo',
        id: 'OSL',
        origin: [],
        destination: ['DXB'],
        city: 'Oslo',
      },
      {
        name: 'Topeka',
        id: 'PEK',
        origin: [],
        destination: ['DXB'],
        city: 'Topeka',
      },
      {
        name: 'Perth',
        id: 'PER',
        origin: [],
        destination: ['DXB'],
        city: 'Perth',
      },
      {
        name: 'Peshawar',
        id: 'PEW',
        origin: [],
        destination: ['DXB'],
        city: 'Peshawar',
      },
      {
        name: 'Prague',
        id: 'PRG',
        origin: [],
        destination: ['DXB'],
        city: 'Prague',
      },
      {
        name: 'Shanghai',
        id: 'PVG',
        origin: [],
        destination: ['DXB'],
        city: 'Shanghai',
      },
      {
        name: 'Yangon',
        id: 'RGN',
        origin: ['HAN'],
        destination: ['DXB', 'HAN'],
        city: 'Yangon',
      },
      {
        name: 'Riyadh',
        id: 'RUH',
        origin: [],
        destination: ['DXB'],
        city: 'Riyadh',
      },
      {
        name: 'Sanaa',
        id: 'SAH',
        origin: [],
        destination: ['DXB'],
        city: 'Sanaa',
      },
      {
        name: 'Sabiha Gökçen (Istanbul)',
        id: 'SAW',
        origin: [],
        destination: ['DXB'],
        city: 'Sabiha Gökçen (Istanbul)',
      },
      {
        name: 'Seattle',
        id: 'SEA',
        origin: [],
        destination: ['DXB'],
        city: 'Seattle',
      },
      {
        name: 'Seychelles',
        id: 'SEZ',
        origin: [],
        destination: ['DXB'],
        city: 'Seychelles',
      },
      {
        name: 'San Francisco',
        id: 'SFO',
        origin: [],
        destination: ['DXB'],
        city: 'San Francisco',
      },
      {
        name: 'Ho Chi Minh City',
        id: 'SGN',
        origin: [],
        destination: ['DXB'],
        city: 'Ho Chi Minh City',
      },
      {
        name: 'Sialkot',
        id: 'SKT',
        origin: [],
        destination: ['DXB'],
        city: 'Sialkot',
      },
      {
        name: 'Berlin Schönefeld',
        id: 'SXF',
        origin: [],
        destination: ['DXB'],
        city: 'Berlin Schönefeld',
      },
      {
        name: 'Tehran',
        id: 'THR',
        origin: [],
        destination: ['DXB'],
        city: 'Tehran',
      },
      {
        name: 'Tripoli',
        id: 'TIP',
        origin: [],
        destination: ['DXB'],
        city: 'Tripoli',
      },
      {
        name: 'Taipei',
        id: 'TPE',
        origin: [],
        destination: ['DXB'],
        city: 'Taipei',
      },
      {
        name: 'Thiruvananthapuram',
        id: 'TRV',
        origin: [],
        destination: ['DXB'],
        city: 'Thiruvananthapuram',
      },
      {
        name: 'Tunis',
        id: 'TUN',
        origin: [],
        destination: ['DXB'],
        city: 'Tunis',
      },
      {
        name: 'Venice',
        id: 'VCE',
        origin: [],
        destination: ['DXB'],
        city: 'Venice',
      },
      {
        name: 'Vienna',
        id: 'VIE',
        origin: [],
        destination: ['DXB'],
        city: 'Vienna',
      },
      {
        name: 'Warsaw',
        id: 'WAW',
        origin: [],
        destination: ['DXB'],
        city: 'Warsaw',
      },
      {
        name: 'Wellington',
        id: 'WLG',
        origin: [],
        destination: ['DXB'],
        city: 'Wellington',
      },
      {
        name: 'Toronto',
        id: 'YYZ',
        origin: [],
        destination: ['DXB'],
        city: 'Toronto',
      },
      {
        name: 'Zurich',
        id: 'ZRH',
        origin: [],
        destination: ['DXB'],
        city: 'Zurich',
      },
    ],
    AF: [
      {
        name: 'Aalborg',
        id: 'AAL',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Aalborg',
      },
      {
        name: 'Amsterdam',
        id: 'AMS',
        origin: [
          'ABZ',
          'ACC',
          'ADD',
          'ALA',
          'ARN',
          'ATH',
          'ATL',
          'AUA',
          'AUH',
          'BAH',
          'BCN',
          'BGO',
          'BHX',
          'BJS',
          'BKK',
          'BLL',
          'BLQ',
          'BOD',
          'BON',
          'BRE',
          'BRS',
          'BUD',
          'BUE',
          'CAI',
          'CDG',
          'CFE',
          'CGN',
          'CPH',
          'CPT',
          'CTU',
          'CUR',
          'CWL',
          'DAR',
          'DEL',
          'DFW',
          'DMM',
          'DOH',
          'DPS',
          'DUS',
          'DXB',
          'EBB',
          'EDI',
          'FCO',
          'FRA',
          'GLA',
          'GOT',
          'GVA',
          'GYE',
          'HAJ',
          'HAM',
          'HAV',
          'HEM',
          'HGH',
          'HKG',
          'HUY',
          'IAH',
          'IST',
          'JKT',
          'JNB',
          'JRO',
          'KBP',
          'KGL',
          'KRS',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LBA',
          'LCY',
          'LED',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LPI',
          'LUN',
          'LUX',
          'LYS',
          'MAD',
          'MAN',
          'MCT',
          'MEX',
          'MLH',
          'MNL',
          'MRS',
          'MRU',
          'MUC',
          'NBO',
          'NCE',
          'NCL',
          'NTE',
          'NUE',
          'NWI',
          'NYC',
          'ORD',
          'OSA',
          'OSL',
          'OTP',
          'PBM',
          'PRG',
          'PTY',
          'RIO',
          'SAO',
          'SEL',
          'SFO',
          'SHA',
          'SIN',
          'STR',
          'SVG',
          'SVO',
          'SXB',
          'THR',
          'TIP',
          'TLS',
          'TLV',
          'TPE',
          'TRD',
          'TXL',
          'TYO',
          'UIO',
          'VCE',
          'VIE',
          'WAS',
          'WAW',
          'XMN',
          'YUL',
          'YVR',
          'YYC',
          'YYZ',
          'ZRH',
        ],
        destination: [
          'AAL',
          'ABZ',
          'ACC',
          'ADD',
          'ALA',
          'ARN',
          'ATH',
          'ATL',
          'AUA',
          'AUH',
          'BAH',
          'BCN',
          'BGO',
          'BHX',
          'BJS',
          'BKK',
          'BLL',
          'BLQ',
          'BOD',
          'BON',
          'BRE',
          'BRS',
          'BUD',
          'BUE',
          'CAI',
          'CDG',
          'CFE',
          'CGN',
          'CPH',
          'CPT',
          'CTU',
          'CUR',
          'CWL',
          'DAR',
          'DEL',
          'DFW',
          'DMM',
          'DOH',
          'DPS',
          'DUS',
          'DXB',
          'EBB',
          'EDI',
          'FCO',
          'FRA',
          'GLA',
          'GOT',
          'GVA',
          'GYE',
          'HAJ',
          'HAM',
          'HAV',
          'HEM',
          'HGH',
          'HKG',
          'HUY',
          'IAH',
          'IST',
          'JKT',
          'JNB',
          'JRO',
          'KBP',
          'KGL',
          'KRS',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LBA',
          'LCY',
          'LED',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LPI',
          'LUN',
          'LUX',
          'LYS',
          'MAD',
          'MAN',
          'MCT',
          'MEX',
          'MLH',
          'MNL',
          'MRS',
          'MRU',
          'MUC',
          'NBO',
          'NCE',
          'NCL',
          'NTE',
          'NUE',
          'NWI',
          'NYC',
          'ORD',
          'OSA',
          'OSL',
          'OTP',
          'PBM',
          'PRG',
          'PTY',
          'RIO',
          'SAO',
          'SEL',
          'SFO',
          'SHA',
          'SIN',
          'STR',
          'SVG',
          'SVO',
          'SXB',
          'THR',
          'TIP',
          'TLS',
          'TLV',
          'TPE',
          'TRD',
          'TXL',
          'TYO',
          'UIO',
          'VCE',
          'VIE',
          'WAS',
          'WAW',
          'XMN',
          'YUL',
          'YVR',
          'YYC',
          'YYZ',
          'ZRH',
        ],
        city: 'Amsterdam',
      },
      {
        name: 'Abidjan',
        id: 'ABJ',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Abidjan',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: [
          'ABZ',
          'AJA',
          'ALG',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BCN',
          'BES',
          'BEY',
          'BGF',
          'BHX',
          'BIA',
          'BIO',
          'BIQ',
          'BJS',
          'BKK',
          'BKO',
          'BLL',
          'BLQ',
          'BLR',
          'BOD',
          'BOG',
          'BOM',
          'BOS',
          'BRE',
          'BRN',
          'BRS',
          'BUD',
          'BUE',
          'BZV',
          'CAI',
          'CAN',
          'CAY',
          'CCS',
          'CFE',
          'CGN',
          'CKY',
          'CMN',
          'COO',
          'CPH',
          'DAM',
          'DEL',
          'DKR',
          'DLA',
          'DTT',
          'DUB',
          'DUS',
          'DXB',
          'EDI',
          'FCO',
          'FDF',
          'FIH',
          'FLR',
          'FNA',
          'FRA',
          'FSC',
          'GOA',
          'GVA',
          'HAJ',
          'HAM',
          'HAV',
          'HKG',
          'IAH',
          'IST',
          'JED',
          'JIB',
          'JNB',
          'KBP',
          'KWI',
          'LAD',
          'LAX',
          'LBV',
          'LCY',
          'LDE',
          'LED',
          'LFW',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LJU',
          'LOS',
          'LRT',
          'LYS',
          'MAD',
          'MAN',
          'MEX',
          'MIA',
          'MLH',
          'MLW',
          'MPL',
          'MRS',
          'MRU',
          'MUC',
          'NAP',
          'NCE',
          'NCL',
          'NDJ',
          'NIM',
          'NKC',
          'NOU',
          'NTE',
          'NUE',
          'NYC',
          'ORL',
          'OSA',
          'OSL',
          'OTP',
          'OUA',
          'PGF',
          'PHC',
          'PNH',
          'PNR',
          'PPT',
          'PRG',
          'PSA',
          'PTP',
          'PUF',
          'PUJ',
          'RBA',
          'RDZ',
          'RIO',
          'RNS',
          'RUH',
          'RUN',
          'SAO',
          'SCL',
          'SDQ',
          'SEL',
          'SFO',
          'SGN',
          'SHA',
          'SIN',
          'SOF',
          'SSG',
          'STR',
          'SVO',
          'SXB',
          'TLN',
          'TLS',
          'TLV',
          'TNR',
          'TRN',
          'TUN',
          'TXL',
          'TYO',
          'UIP',
          'VCE',
          'VIE',
          'VRN',
          'WAS',
          'WAW',
          'YAO',
          'YYZ',
          'ZAG',
          'ZRH',
        ],
        destination: [
          'ABJ',
          'ABZ',
          'AJA',
          'ALG',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BCN',
          'BES',
          'BEY',
          'BGF',
          'BHX',
          'BIA',
          'BIO',
          'BIQ',
          'BJS',
          'BKK',
          'BKO',
          'BLL',
          'BLQ',
          'BLR',
          'BOD',
          'BOG',
          'BOM',
          'BOS',
          'BRE',
          'BRN',
          'BRS',
          'BUD',
          'BUE',
          'BZV',
          'CAI',
          'CAN',
          'CAY',
          'CCS',
          'CFE',
          'CGN',
          'CKY',
          'CMN',
          'COO',
          'CPH',
          'DAM',
          'DEL',
          'DKR',
          'DLA',
          'DTT',
          'DUB',
          'DUS',
          'DXB',
          'EDI',
          'FCO',
          'FDF',
          'FIH',
          'FLR',
          'FNA',
          'FRA',
          'FSC',
          'GOA',
          'GVA',
          'HAJ',
          'HAM',
          'HAV',
          'HKG',
          'IAH',
          'IST',
          'JED',
          'JIB',
          'JNB',
          'KBP',
          'KWI',
          'LAD',
          'LAX',
          'LBV',
          'LCY',
          'LDE',
          'LED',
          'LFW',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LJU',
          'LOS',
          'LRT',
          'LYS',
          'MAD',
          'MAN',
          'MEX',
          'MIA',
          'MLH',
          'MLW',
          'MPL',
          'MRS',
          'MRU',
          'MUC',
          'NAP',
          'NCE',
          'NCL',
          'NDJ',
          'NIM',
          'NKC',
          'NOU',
          'NTE',
          'NUE',
          'NYC',
          'ORL',
          'OSA',
          'OSL',
          'OTP',
          'OUA',
          'PGF',
          'PHC',
          'PNH',
          'PNR',
          'PPT',
          'PRG',
          'PSA',
          'PTP',
          'PUF',
          'PUJ',
          'RBA',
          'RDZ',
          'RIO',
          'RNS',
          'RUH',
          'RUN',
          'SAO',
          'SCL',
          'SDQ',
          'SEL',
          'SFO',
          'SGN',
          'SHA',
          'SIN',
          'SOF',
          'SSG',
          'STR',
          'SVO',
          'SXB',
          'TLN',
          'TLS',
          'TLV',
          'TNR',
          'TRN',
          'TUN',
          'TXL',
          'TYO',
          'UIP',
          'VCE',
          'VIE',
          'VRN',
          'WAS',
          'WAW',
          'YAO',
          'YYZ',
          'ZAG',
          'ZRH',
        ],
        city: 'Paris',
      },
      {
        name: 'Aberdeen',
        id: 'ABZ',
        origin: ['AMS', 'CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Aberdeen',
      },
      {
        name: 'Accra',
        id: 'ACC',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Accra',
      },
      {
        name: 'Addis Ababa',
        id: 'ADD',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Addis Ababa',
      },
      {
        name: 'Malaga',
        id: 'AGP',
        origin: ['TLS'],
        destination: ['TLS'],
        city: 'Malaga',
      },
      {
        name: 'Toulouse',
        id: 'TLS',
        origin: [
          'AMS',
          'ATH',
          'CDG',
          'CMN',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'MLA',
          'NAP',
          'NCE',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVQ',
          'SXB',
          'TUN',
          'TXL',
          'VCE',
          'VIE',
        ],
        destination: [
          'AGP',
          'AMS',
          'ATH',
          'CDG',
          'CMN',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'MLA',
          'NAP',
          'NCE',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVQ',
          'SXB',
          'TUN',
          'TXL',
          'VCE',
          'VIE',
        ],
        city: 'Toulouse',
      },
      {
        name: 'Ajaccio',
        id: 'AJA',
        origin: ['CDG', 'ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Ajaccio',
      },
      {
        name: 'Paris Orly',
        id: 'ORY',
        origin: [
          'BES',
          'BIA',
          'BIQ',
          'BOD',
          'CFE',
          'CLY',
          'FDF',
          'FSC',
          'LCY',
          'LDE',
          'LRT',
          'LYS',
          'MLH',
          'MPL',
          'MRS',
          'NCE',
          'NTE',
          'PGF',
          'PTP',
          'PUF',
          'RDZ',
          'RUN',
          'SXB',
          'TLN',
          'TLS',
          'UIP',
        ],
        destination: [
          'AJA',
          'BES',
          'BIA',
          'BIQ',
          'BOD',
          'CFE',
          'CLY',
          'FDF',
          'FSC',
          'LCY',
          'LDE',
          'LRT',
          'LYS',
          'MLH',
          'MPL',
          'MRS',
          'NCE',
          'NTE',
          'PGF',
          'PTP',
          'PUF',
          'RDZ',
          'RUN',
          'SXB',
          'TLN',
          'TLS',
          'UIP',
        ],
        city: 'Paris Orly',
      },
      {
        name: 'Alma Ata',
        id: 'ALA',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Alma Ata',
      },
      {
        name: 'Algiers',
        id: 'ALG',
        origin: ['CDG', 'MRS'],
        destination: ['CDG', 'MRS'],
        city: 'Algiers',
      },
      {
        name: 'Marseille',
        id: 'MRS',
        origin: [
          'AMS',
          'ATH',
          'BCN',
          'BES',
          'BIQ',
          'BOD',
          'CDG',
          'CMN',
          'CPH',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'LYS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVO',
          'SXB',
          'TLV',
          'TUN',
          'TXL',
          'VCE',
        ],
        destination: [
          'ALG',
          'AMS',
          'ATH',
          'BCN',
          'BES',
          'BIQ',
          'BOD',
          'CDG',
          'CMN',
          'CPH',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'LYS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVO',
          'SXB',
          'TLV',
          'TUN',
          'TXL',
          'VCE',
        ],
        city: 'Marseille',
      },
      {
        name: 'Amman',
        id: 'AMM',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Amman',
      },
      {
        name: 'Stockholm',
        id: 'ARN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Stockholm',
      },
      {
        name: 'Athens',
        id: 'ATH',
        origin: ['CDG', 'MRS', 'NCE', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'NCE', 'TLS'],
        city: 'Athens',
      },
      {
        name: 'Atlanta',
        id: 'ATL',
        origin: [],
        destination: ['AMS'],
        city: 'Atlanta',
      },
      {
        name: 'Aruba',
        id: 'AUA',
        origin: [],
        destination: ['AMS'],
        city: 'Aruba',
      },
      {
        name: 'Abu Dhabi',
        id: 'AUH',
        origin: [],
        destination: ['AMS'],
        city: 'Abu Dhabi',
      },
      {
        name: 'Bahrain',
        id: 'BAH',
        origin: [],
        destination: ['AMS'],
        city: 'Bahrain',
      },
      {
        name: 'Barcelona',
        id: 'BCN',
        origin: ['BOD', 'CDG', 'LYS', 'MRS'],
        destination: ['AMS', 'BOD', 'CDG', 'LYS', 'MRS'],
        city: 'Barcelona',
      },
      {
        name: 'Bergen',
        id: 'BGO',
        origin: [],
        destination: ['AMS'],
        city: 'Bergen',
      },
      {
        name: 'Birmingham',
        id: 'BHX',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Birmingham',
      },
      {
        name: 'Beijing',
        id: 'BJS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Beijing',
      },
      {
        name: 'Bangkok',
        id: 'BKK',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bangkok',
      },
      {
        name: 'Billund',
        id: 'BLL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Billund',
      },
      {
        name: 'Bologna',
        id: 'BLQ',
        origin: ['CDG', 'LYS'],
        destination: ['AMS', 'CDG', 'LYS'],
        city: 'Bologna',
      },
      {
        name: 'Bordeaux',
        id: 'BOD',
        origin: ['BCN', 'BRU', 'CDG', 'FCO', 'LIL', 'MRS', 'NCE', 'NTE', 'ORY', 'SXB'],
        destination: ['AMS', 'BCN', 'BRU', 'CDG', 'FCO', 'LIL', 'MRS', 'NCE', 'NTE', 'ORY', 'SXB'],
        city: 'Bordeaux',
      },
      {
        name: 'Bonaire',
        id: 'BON',
        origin: [],
        destination: ['AMS'],
        city: 'Bonaire',
      },
      {
        name: 'Bremen',
        id: 'BRE',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bremen',
      },
      {
        name: 'Bristol',
        id: 'BRS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bristol',
      },
      {
        name: 'Budapest',
        id: 'BUD',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Budapest',
      },
      {
        name: 'Buenos Aires',
        id: 'BUE',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Buenos Aires',
      },
      {
        name: 'Cairo',
        id: 'CAI',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Cairo',
      },
      {
        name: 'Clermont-Ferrand',
        id: 'CFE',
        origin: ['CDG', 'LYS', 'NCE', 'ORY'],
        destination: ['AMS', 'CDG', 'LYS', 'NCE', 'ORY'],
        city: 'Clermont-Ferrand',
      },
      {
        name: 'Cologne',
        id: 'CGN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Cologne',
      },
      {
        name: 'Copenhagen',
        id: 'CPH',
        origin: ['CDG', 'MRS'],
        destination: ['AMS', 'CDG', 'MRS'],
        city: 'Copenhagen',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: [],
        destination: ['AMS'],
        city: 'Cape Town',
      },
      {
        name: 'Chengdu',
        id: 'CTU',
        origin: [],
        destination: ['AMS'],
        city: 'Chengdu',
      },
      {
        name: 'Curacao',
        id: 'CUR',
        origin: [],
        destination: ['AMS'],
        city: 'Curacao',
      },
      {
        name: 'Cardiff',
        id: 'CWL',
        origin: [],
        destination: ['AMS'],
        city: 'Cardiff',
      },
      {
        name: 'Dar Es Salaam',
        id: 'DAR',
        origin: [],
        destination: ['AMS'],
        city: 'Dar Es Salaam',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Delhi',
      },
      {
        name: 'Dallas/Ft. Worth',
        id: 'DFW',
        origin: [],
        destination: ['AMS'],
        city: 'Dallas/Ft. Worth',
      },
      {
        name: 'Dammam',
        id: 'DMM',
        origin: [],
        destination: ['AMS'],
        city: 'Dammam',
      },
      {
        name: 'Doha',
        id: 'DOH',
        origin: [],
        destination: ['AMS'],
        city: 'Doha',
      },
      {
        name: 'Bali Island, Denpasar',
        id: 'DPS',
        origin: [],
        destination: ['AMS'],
        city: 'Bali Island, Denpasar',
      },
      {
        name: 'Dusseldorf',
        id: 'DUS',
        origin: ['CDG', 'LYS', 'NTE'],
        destination: ['AMS', 'CDG', 'LYS', 'NTE'],
        city: 'Dusseldorf',
      },
      {
        name: 'Dubai',
        id: 'DXB',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Dubai',
      },
      {
        name: 'Entebbe',
        id: 'EBB',
        origin: [],
        destination: ['AMS'],
        city: 'Entebbe',
      },
      {
        name: 'Edinburgh',
        id: 'EDI',
        origin: ['CDG', 'LCY'],
        destination: ['AMS', 'CDG', 'LCY'],
        city: 'Edinburgh',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['BOD', 'CDG', 'LYS', 'MRS', 'TLS'],
        destination: ['AMS', 'BOD', 'CDG', 'LYS', 'MRS', 'TLS'],
        city: 'Rome',
      },
      {
        name: 'Frankfurt',
        id: 'FRA',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Frankfurt',
      },
      {
        name: 'Glasgow',
        id: 'GLA',
        origin: [],
        destination: ['AMS'],
        city: 'Glasgow',
      },
      {
        name: 'Gothenburg',
        id: 'GOT',
        origin: [],
        destination: ['AMS'],
        city: 'Gothenburg',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['BIQ', 'CDG'],
        destination: ['AMS', 'BIQ', 'CDG'],
        city: 'Geneva',
      },
      {
        name: 'Guayaquil',
        id: 'GYE',
        origin: [],
        destination: ['AMS'],
        city: 'Guayaquil',
      },
      {
        name: 'Hanover',
        id: 'HAJ',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Hanover',
      },
      {
        name: 'Hamburg',
        id: 'HAM',
        origin: ['CDG', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'TLS'],
        city: 'Hamburg',
      },
      {
        name: 'Havana',
        id: 'HAV',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Havana',
      },
      {
        name: 'Helsinki-Malmi',
        id: 'HEM',
        origin: [],
        destination: ['AMS'],
        city: 'Helsinki-Malmi',
      },
      {
        name: 'Hangzhou',
        id: 'HGH',
        origin: [],
        destination: ['AMS'],
        city: 'Hangzhou',
      },
      {
        name: 'Hong Kong',
        id: 'HKG',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Hong Kong',
      },
      {
        name: 'Humberside',
        id: 'HUY',
        origin: [],
        destination: ['AMS'],
        city: 'Humberside',
      },
      {
        name: 'Houston',
        id: 'IAH',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Houston',
      },
      {
        name: 'Istanbul',
        id: 'IST',
        origin: ['CDG', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'TLS'],
        city: 'Istanbul',
      },
      {
        name: 'Jakarta',
        id: 'JKT',
        origin: [],
        destination: ['AMS'],
        city: 'Jakarta',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Johannesburg',
      },
      {
        name: 'Kilimanjaro',
        id: 'JRO',
        origin: [],
        destination: ['AMS'],
        city: 'Kilimanjaro',
      },
      {
        name: 'Boryspil',
        id: 'KBP',
        origin: ['CDG', 'KBP'],
        destination: ['AMS', 'CDG', 'KBP'],
        city: 'Boryspil',
      },
      {
        name: 'Kigali',
        id: 'KGL',
        origin: [],
        destination: ['AMS'],
        city: 'Kigali',
      },
      {
        name: 'Kristiansand',
        id: 'KRS',
        origin: [],
        destination: ['AMS'],
        city: 'Kristiansand',
      },
      {
        name: 'Khartoum',
        id: 'KRT',
        origin: [],
        destination: ['AMS'],
        city: 'Khartoum',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: [],
        destination: ['AMS'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'Kuwait',
        id: 'KWI',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Kuwait',
      },
      {
        name: 'Luanda',
        id: 'LAD',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Luanda',
      },
      {
        name: 'Leeds',
        id: 'LBA',
        origin: [],
        destination: ['AMS'],
        city: 'Leeds',
      },
      {
        name: 'London City',
        id: 'LCY',
        origin: ['ANR', 'BES', 'BVE', 'CDG', 'DND', 'DOL', 'DUB', 'EDI', 'EIN', 'FLR', 'LUX', 'NTE', 'ORY', 'PUF', 'RTM', 'TLN'],
        destination: ['AMS', 'ANR', 'BES', 'BVE', 'CDG', 'DND', 'DOL', 'DUB', 'EDI', 'EIN', 'FLR', 'LUX', 'NTE', 'ORY', 'PUF', 'RTM', 'TLN'],
        city: 'London City',
      },
      {
        name: 'St. Petersburg',
        id: 'LED',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'St. Petersburg',
      },
      {
        name: 'London',
        id: 'LHR',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'London',
      },
      {
        name: 'Lima',
        id: 'LIM',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Lima',
      },
      {
        name: 'Milan Linate',
        id: 'LIN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Milan Linate',
      },
      {
        name: 'Lisbon',
        id: 'LIS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Lisbon',
      },
      {
        name: 'Linköping',
        id: 'LPI',
        origin: [],
        destination: ['AMS'],
        city: 'Linköping',
      },
      {
        name: 'Lusaka',
        id: 'LUN',
        origin: [],
        destination: ['AMS'],
        city: 'Lusaka',
      },
      {
        name: 'Luxembourg',
        id: 'LUX',
        origin: ['LCY'],
        destination: ['AMS', 'LCY'],
        city: 'Luxembourg',
      },
      {
        name: 'Lyon',
        id: 'LYS',
        origin: [
          'BCN',
          'BES',
          'BIQ',
          'BLQ',
          'BRU',
          'BSL',
          'CDG',
          'CFE',
          'CFR',
          'DUS',
          'ETZ',
          'FCO',
          'FLR',
          'LIL',
          'LRT',
          'MPL',
          'MRS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'PUF',
          'RNS',
          'STR',
          'SXB',
          'VCE',
        ],
        destination: [
          'AMS',
          'BCN',
          'BES',
          'BIQ',
          'BLQ',
          'BRU',
          'BSL',
          'CDG',
          'CFE',
          'CFR',
          'DUS',
          'ETZ',
          'FCO',
          'FLR',
          'LIL',
          'LRT',
          'MPL',
          'MRS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'PUF',
          'RNS',
          'STR',
          'SXB',
          'VCE',
        ],
        city: 'Lyon',
      },
      {
        name: 'Madrid',
        id: 'MAD',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Madrid',
      },
      {
        name: 'Manchester',
        id: 'MAN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Manchester',
      },
      {
        name: 'Muscat',
        id: 'MCT',
        origin: [],
        destination: ['AMS'],
        city: 'Muscat',
      },
      {
        name: 'Mexico City',
        id: 'MEX',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Mexico City',
      },
      {
        name: 'Mulhouse',
        id: 'MLH',
        origin: ['CDG', 'ORY'],
        destination: ['AMS', 'CDG', 'ORY'],
        city: 'Mulhouse',
      },
      {
        name: 'Manila',
        id: 'MNL',
        origin: [],
        destination: ['AMS'],
        city: 'Manila',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Mauritius',
      },
      {
        name: 'Munich',
        id: 'MUC',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Munich',
      },
      {
        name: 'Nairobi',
        id: 'NBO',
        origin: [],
        destination: ['AMS'],
        city: 'Nairobi',
      },
      {
        name: 'Nice',
        id: 'NCE',
        origin: ['ATH', 'BES', 'BIQ', 'BOD', 'CDG', 'CFE', 'ETZ', 'LIL', 'NAP', 'NTE', 'ORY', 'RNS', 'TLS', 'TLV', 'VCE'],
        destination: ['AMS', 'ATH', 'BES', 'BIQ', 'BOD', 'CDG', 'CFE', 'ETZ', 'LIL', 'NAP', 'NTE', 'ORY', 'RNS', 'TLS', 'TLV', 'VCE'],
        city: 'Nice',
      },
      {
        name: 'Newcastle',
        id: 'NCL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Newcastle',
      },
      {
        name: 'Nantes',
        id: 'NTE',
        origin: ['BOD', 'BRU', 'CDG', 'DUS', 'LCY', 'LIL', 'LYS', 'MPL', 'MRS', 'MXP', 'NCE', 'ORY', 'SXB', 'TLS'],
        destination: ['AMS', 'BOD', 'BRU', 'CDG', 'DUS', 'LCY', 'LIL', 'LYS', 'MPL', 'MRS', 'MXP', 'NCE', 'ORY', 'SXB', 'TLS'],
        city: 'Nantes',
      },
      {
        name: 'Nuremberg',
        id: 'NUE',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Nuremberg',
      },
      {
        name: 'Norwich',
        id: 'NWI',
        origin: [],
        destination: ['AMS'],
        city: 'Norwich',
      },
      {
        name: 'New York',
        id: 'NYC',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'New York',
      },
      {
        name: 'Chicago',
        id: 'ORD',
        origin: [],
        destination: ['AMS'],
        city: 'Chicago',
      },
      {
        name: 'Osaka',
        id: 'OSA',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Osaka',
      },
      {
        name: 'Oslo',
        id: 'OSL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Oslo',
      },
      {
        name: 'Bucharest',
        id: 'OTP',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bucharest',
      },
      {
        name: 'Paramaribo',
        id: 'PBM',
        origin: [],
        destination: ['AMS'],
        city: 'Paramaribo',
      },
      {
        name: 'Prague',
        id: 'PRG',
        origin: ['CDG', 'LYS', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'LYS', 'MRS', 'TLS'],
        city: 'Prague',
      },
      {
        name: 'Panama City',
        id: 'PTY',
        origin: [],
        destination: ['AMS'],
        city: 'Panama City',
      },
      {
        name: 'Rio de Janeiro',
        id: 'RIO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Rio de Janeiro',
      },
      {
        name: 'Sao Paulo',
        id: 'SAO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Sao Paulo',
      },
      {
        name: 'Seoul',
        id: 'SEL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Seoul',
      },
      {
        name: 'San Francisco',
        id: 'SFO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'San Francisco',
      },
      {
        name: 'Shanghai',
        id: 'SHA',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Shanghai',
      },
      {
        name: 'Singapore',
        id: 'SIN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Singapore',
      },
      {
        name: 'Stuttgart',
        id: 'STR',
        origin: ['CDG', 'LYS'],
        destination: ['AMS', 'CDG', 'LYS'],
        city: 'Stuttgart',
      },
      {
        name: 'Stavanger',
        id: 'SVG',
        origin: [],
        destination: ['AMS'],
        city: 'Stavanger',
      },
      {
        name: 'Moscow-Sheremetyevo',
        id: 'SVO',
        origin: ['CDG', 'MRS'],
        destination: ['AMS', 'CDG', 'MRS'],
        city: 'Moscow-Sheremetyevo',
      },
      {
        name: 'Strasbourg',
        id: 'SXB',
        origin: ['BOD', 'CDG', 'LIL', 'LYS', 'MRS', 'NTE', 'ORY', 'TLS'],
        destination: ['AMS', 'BOD', 'CDG', 'LIL', 'LYS', 'MRS', 'NTE', 'ORY', 'TLS'],
        city: 'Strasbourg',
      },
      {
        name: 'Tehran',
        id: 'THR',
        origin: [],
        destination: ['AMS'],
        city: 'Tehran',
      },
      {
        name: 'Tripoli',
        id: 'TIP',
        origin: [],
        destination: ['AMS'],
        city: 'Tripoli',
      },
      {
        name: 'Tel Aviv Yafo, Sde Dov',
        id: 'TLV',
        origin: ['CDG', 'MRS', 'NCE'],
        destination: ['AMS', 'CDG', 'MRS', 'NCE'],
        city: 'Tel Aviv Yafo, Sde Dov',
      },
      {
        name: 'Taipei',
        id: 'TPE',
        origin: [],
        destination: ['AMS'],
        city: 'Taipei',
      },
      {
        name: 'Trondheim',
        id: 'TRD',
        origin: [],
        destination: ['AMS'],
        city: 'Trondheim',
      },
      {
        name: 'Berlin',
        id: 'TXL',
        origin: ['CDG', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'TLS'],
        city: 'Berlin',
      },
      {
        name: 'Tokyo',
        id: 'TYO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Tokyo',
      },
      {
        name: 'Quito',
        id: 'UIO',
        origin: [],
        destination: ['AMS'],
        city: 'Quito',
      },
      {
        name: 'Venice',
        id: 'VCE',
        origin: ['CDG', 'LYS', 'MRS', 'NCE', 'TLS'],
        destination: ['AMS', 'CDG', 'LYS', 'MRS', 'NCE', 'TLS'],
        city: 'Venice',
      },
      {
        name: 'Vienna',
        id: 'VIE',
        origin: ['CDG', 'TLS'],
        destination: ['AMS', 'CDG', 'TLS'],
        city: 'Vienna',
      },
      {
        name: 'Washington',
        id: 'WAS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Washington',
      },
      {
        name: 'Warsaw',
        id: 'WAW',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Warsaw',
      },
      {
        name: 'Xiamen',
        id: 'XMN',
        origin: [],
        destination: ['AMS'],
        city: 'Xiamen',
      },
      {
        name: 'Montreal',
        id: 'YUL',
        origin: [],
        destination: ['AMS'],
        city: 'Montreal',
      },
      {
        name: 'Vancouver',
        id: 'YVR',
        origin: [],
        destination: ['AMS'],
        city: 'Vancouver',
      },
      {
        name: 'Calgary',
        id: 'YYC',
        origin: [],
        destination: ['AMS'],
        city: 'Calgary',
      },
      {
        name: 'Toronto',
        id: 'YYZ',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Toronto',
      },
      {
        name: 'Zurich',
        id: 'ZRH',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Zurich',
      },
      {
        name: 'Antwerp',
        id: 'ANR',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Antwerp',
      },
      {
        name: 'Brest',
        id: 'BES',
        origin: ['CDG', 'LCY', 'LYS', 'MRS', 'NCE', 'ORY'],
        destination: ['CDG', 'LCY', 'LYS', 'MRS', 'NCE', 'ORY'],
        city: 'Brest',
      },
      {
        name: 'Beirut',
        id: 'BEY',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Beirut',
      },
      {
        name: 'Bangui',
        id: 'BGF',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bangui',
      },
      {
        name: 'Bastia',
        id: 'BIA',
        origin: ['CDG', 'ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Bastia',
      },
      {
        name: 'Bilbao',
        id: 'BIO',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bilbao',
      },
      {
        name: 'Biarritz',
        id: 'BIQ',
        origin: ['CDG', 'GVA', 'LYS', 'MRS', 'NCE', 'ORY'],
        destination: ['CDG', 'GVA', 'LYS', 'MRS', 'NCE', 'ORY'],
        city: 'Biarritz',
      },
      {
        name: 'Bamako',
        id: 'BKO',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bamako',
      },
      {
        name: 'Bangalore',
        id: 'BLR',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bangalore',
      },
      {
        name: 'Brussels',
        id: 'BRU',
        origin: ['LYS', 'NTE'],
        destination: ['BOD', 'LYS', 'NTE'],
        city: 'Brussels',
      },
      {
        name: 'Lille',
        id: 'LIL',
        origin: ['LYS', 'MRS', 'NCE', 'NTE', 'SXB', 'TLS'],
        destination: ['BOD', 'LYS', 'MRS', 'NCE', 'NTE', 'SXB', 'TLS'],
        city: 'Lille',
      },
      {
        name: 'Bogota',
        id: 'BOG',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bogota',
      },
      {
        name: 'Bombay',
        id: 'BOM',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bombay',
      },
      {
        name: 'Boston',
        id: 'BOS',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Boston',
      },
      {
        name: 'Bern',
        id: 'BRN',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bern',
      },
      {
        name: 'Basle',
        id: 'BSL',
        origin: ['LYS'],
        destination: ['LYS'],
        city: 'Basle',
      },
      {
        name: 'Brive–Souillac',
        id: 'BVE',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Brive–Souillac',
      },
      {
        name: 'Brazzaville',
        id: 'BZV',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Brazzaville',
      },
      {
        name: 'Guangzhou',
        id: 'CAN',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Guangzhou',
      },
      {
        name: 'Cayenne',
        id: 'CAY',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Cayenne',
      },
      {
        name: 'Caracas',
        id: 'CCS',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Caracas',
      },
      {
        name: 'Conakry',
        id: 'CKY',
        origin: [],
        destination: ['CDG'],
        city: 'Conakry',
      },
      {
        name: 'Casablanca',
        id: 'CMN',
        origin: ['MRS', 'TLS'],
        destination: ['CDG', 'MRS', 'TLS'],
        city: 'Casablanca',
      },
      {
        name: 'Cotonou',
        id: 'COO',
        origin: [],
        destination: ['CDG'],
        city: 'Cotonou',
      },
      {
        name: 'Damascus',
        id: 'DAM',
        origin: [],
        destination: ['CDG'],
        city: 'Damascus',
      },
      {
        name: 'Dakar',
        id: 'DKR',
        origin: [],
        destination: ['CDG'],
        city: 'Dakar',
      },
      {
        name: 'Douala',
        id: 'DLA',
        origin: [],
        destination: ['CDG'],
        city: 'Douala',
      },
      {
        name: 'Detroit',
        id: 'DTT',
        origin: [],
        destination: ['CDG'],
        city: 'Detroit',
      },
      {
        name: 'Dublin',
        id: 'DUB',
        origin: ['LCY'],
        destination: ['CDG', 'LCY'],
        city: 'Dublin',
      },
      {
        name: 'Ft de France',
        id: 'FDF',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Ft de France',
      },
      {
        name: 'Kinshasa',
        id: 'FIH',
        origin: [],
        destination: ['CDG'],
        city: 'Kinshasa',
      },
      {
        name: 'Florence',
        id: 'FLR',
        origin: ['LCY', 'LYS'],
        destination: ['CDG', 'LCY', 'LYS'],
        city: 'Florence',
      },
      {
        name: 'Freetown',
        id: 'FNA',
        origin: [],
        destination: ['CDG'],
        city: 'Freetown',
      },
      {
        name: 'Figari–Sud Corse',
        id: 'FSC',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Figari–Sud Corse',
      },
      {
        name: 'Genoa',
        id: 'GOA',
        origin: [],
        destination: ['CDG'],
        city: 'Genoa',
      },
      {
        name: 'Jeddah',
        id: 'JED',
        origin: [],
        destination: ['CDG'],
        city: 'Jeddah',
      },
      {
        name: 'Djibouti',
        id: 'JIB',
        origin: [],
        destination: ['CDG'],
        city: 'Djibouti',
      },
      {
        name: 'Los Angeles',
        id: 'LAX',
        origin: [],
        destination: ['CDG'],
        city: 'Los Angeles',
      },
      {
        name: 'Libreville',
        id: 'LBV',
        origin: [],
        destination: ['CDG'],
        city: 'Libreville',
      },
      {
        name: 'Lourdes/Tarbes',
        id: 'LDE',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Lourdes/Tarbes',
      },
      {
        name: 'Lome',
        id: 'LFW',
        origin: [],
        destination: ['CDG'],
        city: 'Lome',
      },
      {
        name: 'Ljubljana',
        id: 'LJU',
        origin: [],
        destination: ['CDG'],
        city: 'Ljubljana',
      },
      {
        name: 'Lagos',
        id: 'LOS',
        origin: [],
        destination: ['CDG'],
        city: 'Lagos',
      },
      {
        name: 'Lorient South Brittany',
        id: 'LRT',
        origin: ['LYS', 'ORY'],
        destination: ['CDG', 'LYS', 'ORY'],
        city: 'Lorient South Brittany',
      },
      {
        name: 'Miami',
        id: 'MIA',
        origin: [],
        destination: ['CDG'],
        city: 'Miami',
      },
      {
        name: 'Monrovia',
        id: 'MLW',
        origin: [],
        destination: ['CDG'],
        city: 'Monrovia',
      },
      {
        name: 'Montpellier',
        id: 'MPL',
        origin: ['LYS', 'NTE', 'ORY'],
        destination: ['CDG', 'LYS', 'NTE', 'ORY'],
        city: 'Montpellier',
      },
      {
        name: 'Naples',
        id: 'NAP',
        origin: ['NCE', 'TLS'],
        destination: ['CDG', 'NCE', 'TLS'],
        city: 'Naples',
      },
      {
        name: 'N’djamena',
        id: 'NDJ',
        origin: [],
        destination: ['CDG'],
        city: 'N’djamena',
      },
      {
        name: 'Niamey',
        id: 'NIM',
        origin: [],
        destination: ['CDG'],
        city: 'Niamey',
      },
      {
        name: 'Nouakchott',
        id: 'NKC',
        origin: [],
        destination: ['CDG'],
        city: 'Nouakchott',
      },
      {
        name: 'Noumea',
        id: 'NOU',
        origin: [],
        destination: ['CDG'],
        city: 'Noumea',
      },
      {
        name: 'Orlando',
        id: 'ORL',
        origin: [],
        destination: ['CDG'],
        city: 'Orlando',
      },
      {
        name: 'Ouagadougou',
        id: 'OUA',
        origin: [],
        destination: ['CDG'],
        city: 'Ouagadougou',
      },
      {
        name: 'Perpignan',
        id: 'PGF',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Perpignan',
      },
      {
        name: 'Port Harcourt',
        id: 'PHC',
        origin: [],
        destination: ['CDG'],
        city: 'Port Harcourt',
      },
      {
        name: 'Phnom Penh',
        id: 'PNH',
        origin: [],
        destination: ['CDG'],
        city: 'Phnom Penh',
      },
      {
        name: 'Pointe Noire',
        id: 'PNR',
        origin: [],
        destination: ['CDG'],
        city: 'Pointe Noire',
      },
      {
        name: 'Papeete',
        id: 'PPT',
        origin: [],
        destination: ['CDG'],
        city: 'Papeete',
      },
      {
        name: 'Pisa',
        id: 'PSA',
        origin: [],
        destination: ['CDG'],
        city: 'Pisa',
      },
      {
        name: 'Point à Pitre',
        id: 'PTP',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Point à Pitre',
      },
      {
        name: 'Pau',
        id: 'PUF',
        origin: ['LCY', 'LYS', 'ORY'],
        destination: ['CDG', 'LCY', 'LYS', 'ORY'],
        city: 'Pau',
      },
      {
        name: 'Punta Cana',
        id: 'PUJ',
        origin: [],
        destination: ['CDG'],
        city: 'Punta Cana',
      },
      {
        name: 'Rabat',
        id: 'RBA',
        origin: [],
        destination: ['CDG'],
        city: 'Rabat',
      },
      {
        name: 'Rodez–Aveyron',
        id: 'RDZ',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Rodez–Aveyron',
      },
      {
        name: 'Rennes',
        id: 'RNS',
        origin: ['LYS', 'MRS', 'NCE', 'TLS'],
        destination: ['CDG', 'LYS', 'MRS', 'NCE', 'TLS'],
        city: 'Rennes',
      },
      {
        name: 'Riyadh',
        id: 'RUH',
        origin: [],
        destination: ['CDG'],
        city: 'Riyadh',
      },
      {
        name: 'St. Denis de La Reunion',
        id: 'RUN',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'St. Denis de La Reunion',
      },
      {
        name: 'Santiago',
        id: 'SCL',
        origin: [],
        destination: ['CDG'],
        city: 'Santiago',
      },
      {
        name: 'Santo Domingo',
        id: 'SDQ',
        origin: [],
        destination: ['CDG'],
        city: 'Santo Domingo',
      },
      {
        name: 'Ho Chi Minh City',
        id: 'SGN',
        origin: [],
        destination: ['CDG'],
        city: 'Ho Chi Minh City',
      },
      {
        name: 'Sofia',
        id: 'SOF',
        origin: [],
        destination: ['CDG'],
        city: 'Sofia',
      },
      {
        name: 'Malabo',
        id: 'SSG',
        origin: [],
        destination: ['CDG'],
        city: 'Malabo',
      },
      {
        name: 'Toulon/Hyeres',
        id: 'TLN',
        origin: ['LCY', 'ORY'],
        destination: ['CDG', 'LCY', 'ORY'],
        city: 'Toulon/Hyeres',
      },
      {
        name: 'Antananarivo',
        id: 'TNR',
        origin: [],
        destination: ['CDG'],
        city: 'Antananarivo',
      },
      {
        name: 'Turin',
        id: 'TRN',
        origin: [],
        destination: ['CDG'],
        city: 'Turin',
      },
      {
        name: 'Tunis',
        id: 'TUN',
        origin: ['MRS', 'TLS'],
        destination: ['CDG', 'MRS', 'TLS'],
        city: 'Tunis',
      },
      {
        name: 'Quimper',
        id: 'UIP',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Quimper',
      },
      {
        name: 'Verona',
        id: 'VRN',
        origin: [],
        destination: ['CDG'],
        city: 'Verona',
      },
      {
        name: 'Yaoundé',
        id: 'YAO',
        origin: [],
        destination: ['CDG'],
        city: 'Yaoundé',
      },
      {
        name: 'Zagreb',
        id: 'ZAG',
        origin: [],
        destination: ['CDG'],
        city: 'Zagreb',
      },
      {
        name: 'Caen',
        id: 'CFR',
        origin: ['LYS'],
        destination: ['LYS'],
        city: 'Caen',
      },
      {
        name: 'Calvi',
        id: 'CLY',
        origin: ['ORY'],
        destination: ['ORY'],
        city: 'Calvi',
      },
      {
        name: 'Dundee',
        id: 'DND',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Dundee',
      },
      {
        name: 'Deauville',
        id: 'DOL',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Deauville',
      },
      {
        name: 'Eindhoven',
        id: 'EIN',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Eindhoven',
      },
      {
        name: 'Metz–Nancy–Lorraine',
        id: 'ETZ',
        origin: ['LYS', 'NCE'],
        destination: ['LYS', 'NCE'],
        city: 'Metz–Nancy–Lorraine',
      },
      {
        name: 'Rotterdam',
        id: 'RTM',
        origin: [],
        destination: ['LCY'],
        city: 'Rotterdam',
      },
      {
        name: 'Milan Malpensa',
        id: 'MXP',
        origin: ['MRS', 'NTE'],
        destination: ['LYS', 'MRS', 'NTE'],
        city: 'Milan Malpensa',
      },
      {
        name: 'Malta',
        id: 'MLA',
        origin: ['TLS'],
        destination: ['TLS'],
        city: 'Malta',
      },
      {
        name: 'Marrakech',
        id: 'RAK',
        origin: ['TLS'],
        destination: ['MRS', 'TLS'],
        city: 'Marrakech',
      },
      {
        name: 'Seville',
        id: 'SVQ',
        origin: ['TLS'],
        destination: ['TLS'],
        city: 'Seville',
      },
    ],
    AC: [
      {
        name: 'Auckland',
        id: 'AKL',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Auckland',
      },
      {
        name: 'Vancouver',
        id: 'YVR',
        origin: [
          'ANC',
          'BNE',
          'BOS',
          'CDG',
          'CUN',
          'DEL',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FRA',
          'HKG',
          'HND',
          'HNL',
          'ICN',
          'KIX',
          'KOA',
          'LAS',
          'LAX',
          'LHR',
          'LIH',
          'MEL',
          'MEX',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PEK',
          'PHX',
          'PSP',
          'PVG',
          'PVR',
          'SAN',
          'SEA',
          'SFO',
          'SJC',
          'SJD',
          'SMF',
          'SYD',
          'TPE',
          'YCD',
          'YCG',
          'YEG',
          'YKA',
          'YLW',
          'YOW',
          'YPR',
          'YQQ',
          'YQR',
          'YUL',
          'YWG',
          'YXC',
          'YXE',
          'YXJ',
          'YXS',
          'YXT',
          'YXY',
          'YYC',
          'YYD',
          'YYF',
          'YYJ',
          'YYZ',
          'YZF',
          'YZP',
          'ZIH',
          'ZRH',
        ],
        destination: [
          'AKL',
          'ANC',
          'BNE',
          'BOS',
          'CDG',
          'CUN',
          'DEL',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FRA',
          'HKG',
          'HND',
          'HNL',
          'ICN',
          'KIX',
          'KOA',
          'LAS',
          'LAX',
          'LHR',
          'LIH',
          'MEL',
          'MEX',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PEK',
          'PHX',
          'PSP',
          'PVG',
          'PVR',
          'SAN',
          'SEA',
          'SFO',
          'SJC',
          'SJD',
          'SMF',
          'SYD',
          'TPE',
          'YCD',
          'YCG',
          'YEG',
          'YKA',
          'YLW',
          'YOW',
          'YPR',
          'YQQ',
          'YQR',
          'YUL',
          'YWG',
          'YXC',
          'YXE',
          'YXJ',
          'YXS',
          'YXT',
          'YXY',
          'YYC',
          'YYD',
          'YYF',
          'YYJ',
          'YYZ',
          'YZF',
          'YZP',
          'ZIH',
          'ZRH',
        ],
        city: 'Vancouver',
      },
      {
        name: 'Algiers',
        id: 'ALG',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Algiers',
      },
      {
        name: 'Montreal',
        id: 'YUL',
        origin: [
          'ATH',
          'AZS',
          'BCN',
          'BDL',
          'BGI',
          'BOD',
          'BOG',
          'BOS',
          'BRU',
          'BWI',
          'CCC',
          'CDG',
          'CMN',
          'CPH',
          'CUN',
          'CUR',
          'CZM',
          'DCA',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FCO',
          'FDF',
          'FLL',
          'FRA',
          'GRU',
          'GVA',
          'HAV',
          'HOG',
          'IAD',
          'IAH',
          'KEF',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'LYS',
          'MBJ',
          'MCO',
          'MEX',
          'MIA',
          'MRS',
          'NAS',
          'NCE',
          'NRT',
          'ORD',
          'OTP',
          'PAP',
          'PBI',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'PTP',
          'PUJ',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SFO',
          'SJO',
          'SJU',
          'SNU',
          'TLV',
          'TPA',
          'UVF',
          'VCE',
          'VRA',
          'YBC',
          'YBG',
          'YDF',
          'YEG',
          'YFC',
          'YGR',
          'YHM',
          'YHZ',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQY',
          'YSJ',
          'YTZ',
          'YUY',
          'YVO',
          'YVR',
          'YWG',
          'YWK',
          'YXU',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YYY',
          'YYZ',
          'YZV',
          'ZBF',
          'ZIH',
          'ZSA',
        ],
        destination: [
          'ALG',
          'ATH',
          'AZS',
          'BCN',
          'BDL',
          'BGI',
          'BOD',
          'BOG',
          'BOS',
          'BRU',
          'BWI',
          'CCC',
          'CDG',
          'CMN',
          'CPH',
          'CUN',
          'CUR',
          'CZM',
          'DCA',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FCO',
          'FDF',
          'FLL',
          'FRA',
          'GRU',
          'GVA',
          'HAV',
          'HOG',
          'IAD',
          'IAH',
          'KEF',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'LYS',
          'MBJ',
          'MCO',
          'MEX',
          'MIA',
          'MRS',
          'NAS',
          'NCE',
          'NRT',
          'ORD',
          'OTP',
          'PAP',
          'PBI',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'PTP',
          'PUJ',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SFO',
          'SJO',
          'SJU',
          'SNU',
          'TLV',
          'TPA',
          'UVF',
          'VCE',
          'VRA',
          'YBC',
          'YBG',
          'YDF',
          'YEG',
          'YFC',
          'YGR',
          'YHM',
          'YHZ',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQY',
          'YSJ',
          'YTZ',
          'YUY',
          'YVO',
          'YVR',
          'YWG',
          'YWK',
          'YXU',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YYY',
          'YYZ',
          'YZV',
          'ZBF',
          'ZIH',
          'ZSA',
        ],
        city: 'Montreal',
      },
      {
        name: 'Amsterdam',
        id: 'AMS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Amsterdam',
      },
      {
        name: 'Toronto',
        id: 'YYZ',
        origin: [
          'ANU',
          'ATH',
          'ATL',
          'AUA',
          'AUS',
          'AZS',
          'BCN',
          'BDA',
          'BDL',
          'BGI',
          'BNA',
          'BOG',
          'BOM',
          'BOS',
          'BUD',
          'BWI',
          'BZE',
          'CCC',
          'CDG',
          'CLE',
          'CLT',
          'CMH',
          'CPH',
          'CTG',
          'CUN',
          'CUR',
          'CVG',
          'CZM',
          'DCA',
          'DEL',
          'DEN',
          'DFW',
          'DOH',
          'DTW',
          'DUB',
          'DXB',
          'EDI',
          'EWR',
          'EZE',
          'FCO',
          'FLL',
          'FRA',
          'GCM',
          'GGT',
          'GLA',
          'GND',
          'GRU',
          'GVA',
          'HAV',
          'HKG',
          'HND',
          'HNL',
          'HOG',
          'HUX',
          'IAD',
          'IAH',
          'ICN',
          'IND',
          'JAX',
          'KEF',
          'KIN',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'MAD',
          'MAN',
          'MBJ',
          'MCI',
          'MCO',
          'MEM',
          'MEX',
          'MIA',
          'MKE',
          'MSP',
          'MSY',
          'MUC',
          'MXP',
          'NAS',
          'NRT',
          'OMA',
          'OPO',
          'ORD',
          'OTP',
          'PBI',
          'PDX',
          'PEK',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'POS',
          'PRG',
          'PSP',
          'PTY',
          'PUJ',
          'PVD',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SAN',
          'SAT',
          'SAV',
          'SCL',
          'SEA',
          'SFO',
          'SJD',
          'SJO',
          'SJU',
          'SKB',
          'SNU',
          'SRQ',
          'STL',
          'SVD',
          'SXM',
          'SYD',
          'TLV',
          'TPA',
          'TXL',
          'UIO',
          'UVF',
          'VCE',
          'VIE',
          'VRA',
          'WAW',
          'YAM',
          'YCD',
          'YDF',
          'YEG',
          'YFC',
          'YGK',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQR',
          'YQT',
          'YQX',
          'YQY',
          'YSB',
          'YSJ',
          'YTM',
          'YTS',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYB',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YZR',
          'ZAG',
          'ZIH',
          'ZRH',
        ],
        destination: [
          'AMS',
          'ANU',
          'ATH',
          'ATL',
          'AUA',
          'AUS',
          'AZS',
          'BCN',
          'BDA',
          'BDL',
          'BGI',
          'BNA',
          'BOG',
          'BOM',
          'BOS',
          'BUD',
          'BWI',
          'BZE',
          'CCC',
          'CDG',
          'CLE',
          'CLT',
          'CMH',
          'CPH',
          'CTG',
          'CUN',
          'CUR',
          'CVG',
          'CZM',
          'DCA',
          'DEL',
          'DEN',
          'DFW',
          'DOH',
          'DTW',
          'DUB',
          'DXB',
          'EDI',
          'EWR',
          'EZE',
          'FCO',
          'FLL',
          'FRA',
          'GCM',
          'GGT',
          'GLA',
          'GND',
          'GRU',
          'GVA',
          'HAV',
          'HKG',
          'HND',
          'HNL',
          'HOG',
          'HUX',
          'IAD',
          'IAH',
          'ICN',
          'IND',
          'JAX',
          'KEF',
          'KIN',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'MAD',
          'MAN',
          'MBJ',
          'MCI',
          'MCO',
          'MEM',
          'MEX',
          'MIA',
          'MKE',
          'MSP',
          'MSY',
          'MUC',
          'MXP',
          'NAS',
          'NRT',
          'OMA',
          'OPO',
          'ORD',
          'OTP',
          'PBI',
          'PDX',
          'PEK',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'POS',
          'PRG',
          'PSP',
          'PTY',
          'PUJ',
          'PVD',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SAN',
          'SAT',
          'SAV',
          'SCL',
          'SEA',
          'SFO',
          'SJD',
          'SJO',
          'SJU',
          'SKB',
          'SNU',
          'SRQ',
          'STL',
          'SVD',
          'SXM',
          'SYD',
          'TLV',
          'TPA',
          'TXL',
          'UIO',
          'UVF',
          'VCE',
          'VIE',
          'VRA',
          'WAW',
          'YAM',
          'YCD',
          'YDF',
          'YEG',
          'YFC',
          'YGK',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQR',
          'YQT',
          'YQX',
          'YQY',
          'YSB',
          'YSJ',
          'YTM',
          'YTS',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYB',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YZR',
          'ZAG',
          'ZIH',
          'ZRH',
        ],
        city: 'Toronto',
      },
      {
        name: 'Anchorage',
        id: 'ANC',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Anchorage',
      },
      {
        name: 'Antigua',
        id: 'ANU',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Antigua',
      },
      {
        name: 'Athens',
        id: 'ATH',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Athens',
      },
      {
        name: 'Atlanta',
        id: 'ATL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Atlanta',
      },
      {
        name: 'Aruba',
        id: 'AUA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Aruba',
      },
      {
        name: 'Austin',
        id: 'AUS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Austin',
      },
      {
        name: 'El Catey Intl',
        id: 'AZS',
        origin: ['YHZ', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YUL', 'YYZ'],
        city: 'El Catey Intl',
      },
      {
        name: 'Halifax',
        id: 'YHZ',
        origin: [
          'BOS',
          'CCC',
          'CUN',
          'FLL',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'YDF',
          'YFC',
          'YOW',
          'YQB',
          'YQM',
          'YQX',
          'YQY',
          'YSJ',
          'YUL',
          'YYC',
          'YYG',
          'YYR',
          'YYT',
          'YYZ',
        ],
        destination: [
          'AZS',
          'BOS',
          'CCC',
          'CUN',
          'FLL',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'YDF',
          'YFC',
          'YOW',
          'YQB',
          'YQM',
          'YQX',
          'YQY',
          'YSJ',
          'YUL',
          'YUY',
          'YYC',
          'YYG',
          'YYR',
          'YYT',
          'YYZ',
        ],
        city: 'Halifax',
      },
      {
        name: 'Barcelona',
        id: 'BCN',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Barcelona',
      },
      {
        name: 'Bermuda',
        id: 'BDA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Bermuda',
      },
      {
        name: 'Bradley',
        id: 'BDL',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Bradley',
      },
      {
        name: 'Barbados',
        id: 'BGI',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Barbados',
      },
      {
        name: 'Nashville',
        id: 'BNA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Nashville',
      },
      {
        name: 'Brisbane',
        id: 'BNE',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Brisbane',
      },
      {
        name: 'Bordeaux',
        id: 'BOD',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Bordeaux',
      },
      {
        name: 'Bogota',
        id: 'BOG',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Bogota',
      },
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Mumbai',
      },
      {
        name: 'Boston',
        id: 'BOS',
        origin: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYZ'],
        city: 'Boston',
      },
      {
        name: 'Ottawa',
        id: 'YOW',
        origin: [
          'CUN',
          'DCA',
          'EWR',
          'FLL',
          'FRA',
          'GGT',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'VRA',
          'YEG',
          'YFC',
          'YHZ',
          'YQB',
          'YQM',
          'YQR',
          'YSJ',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYC',
          'YYG',
          'YYT',
          'YYZ',
        ],
        destination: [
          'BOS',
          'CUN',
          'DCA',
          'EWR',
          'FLL',
          'FRA',
          'GGT',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'VRA',
          'YEG',
          'YFC',
          'YHZ',
          'YQB',
          'YQM',
          'YQR',
          'YSJ',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYC',
          'YYG',
          'YYT',
          'YYZ',
        ],
        city: 'Ottawa',
      },
      {
        name: 'Brussels',
        id: 'BRU',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Brussels',
      },
      {
        name: 'Budapest',
        id: 'BUD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Budapest',
      },
      {
        name: 'Baltimore',
        id: 'BWI',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Baltimore',
      },
      {
        name: 'Belize',
        id: 'BZE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Belize',
      },
      {
        name: 'Jardines Del Rey',
        id: 'CCC',
        origin: ['YHZ', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YUL', 'YYZ'],
        city: 'Jardines Del Rey',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Paris',
      },
      {
        name: 'Cleveland',
        id: 'CLE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Cleveland',
      },
      {
        name: 'Charlotte',
        id: 'CLT',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Charlotte',
      },
      {
        name: 'Columbus',
        id: 'CMH',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Columbus',
      },
      {
        name: 'Casablanca',
        id: 'CMN',
        origin: ['YUL', 'YYT'],
        destination: ['YUL', 'YYT'],
        city: 'Casablanca',
      },
      {
        name: 'St. John',
        id: 'YYT',
        origin: ['LHR', 'YDF', 'YHZ', 'YOW', 'YQT', 'YQX', 'YUL', 'YYC', 'YYR', 'YYZ'],
        destination: ['CMN', 'LHR', 'YDF', 'YHZ', 'YOW', 'YQT', 'YQX', 'YUL', 'YYC', 'YYR', 'YYZ'],
        city: 'St. John',
      },
      {
        name: 'Copenhagen',
        id: 'CPH',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Copenhagen',
      },
      {
        name: 'Cartagena',
        id: 'CTG',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Cartagena',
      },
      {
        name: 'Cancun',
        id: 'CUN',
        origin: ['YHZ', 'YOW', 'YQB', 'YUL', 'YVR', 'YWG', 'YYC', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YVR', 'YWG', 'YYC', 'YYZ'],
        city: 'Cancun',
      },
      {
        name: 'Quebec City',
        id: 'YQB',
        origin: ['PUJ', 'YFC', 'YGP', 'YGR', 'YHZ', 'YOW', 'YQM', 'YUL', 'YYG', 'YYY', 'YYZ', 'YZV'],
        destination: ['CUN', 'PUJ', 'YFC', 'YGP', 'YGR', 'YHZ', 'YOW', 'YQM', 'YUL', 'YYG', 'YYY', 'YYZ', 'YZV'],
        city: 'Quebec City',
      },
      {
        name: 'Winnipeg',
        id: 'YWG',
        origin: ['MBJ', 'YEG', 'YOW', 'YQR', 'YQT', 'YUL', 'YVR', 'YXE', 'YYC', 'YYZ'],
        destination: ['CUN', 'MBJ', 'YEG', 'YOW', 'YQR', 'YQT', 'YUL', 'YVR', 'YXE', 'YYC', 'YYZ'],
        city: 'Winnipeg',
      },
      {
        name: 'Calgary',
        id: 'YYC',
        origin: [
          'DEN',
          'EWR',
          'FRA',
          'IAH',
          'LAS',
          'LAX',
          'LHR',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PHX',
          'PSP',
          'PVR',
          'SFO',
          'SJD',
          'YCD',
          'YCG',
          'YEG',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQL',
          'YQR',
          'YQU',
          'YUL',
          'YVR',
          'YWG',
          'YXC',
          'YXE',
          'YXH',
          'YXT',
          'YYJ',
          'YYT',
          'YYZ',
          'YZF',
        ],
        destination: [
          'CUN',
          'DEN',
          'EWR',
          'FRA',
          'IAH',
          'LAS',
          'LAX',
          'LHR',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PHX',
          'PSP',
          'PVR',
          'SFO',
          'SJD',
          'YCD',
          'YCG',
          'YEG',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQL',
          'YQR',
          'YQU',
          'YUL',
          'YVR',
          'YWG',
          'YXC',
          'YXE',
          'YXH',
          'YXT',
          'YYJ',
          'YYT',
          'YYZ',
          'YZF',
        ],
        city: 'Calgary',
      },
      {
        name: 'Curacao',
        id: 'CUR',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Curacao',
      },
      {
        name: 'Cincinnati',
        id: 'CVG',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Cincinnati',
      },
      {
        name: 'Cozumel',
        id: 'CZM',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Cozumel',
      },
      {
        name: 'Washington DC (DCA)',
        id: 'DCA',
        origin: ['YOW', 'YUL', 'YYZ'],
        destination: ['YOW', 'YUL', 'YYZ'],
        city: 'Washington DC (DCA)',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Delhi',
      },
      {
        name: 'Denver',
        id: 'DEN',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Denver',
      },
      {
        name: 'Dallas/Ft. Worth',
        id: 'DFW',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Dallas/Ft. Worth',
      },
      {
        name: 'Doha',
        id: 'DOH',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Doha',
      },
      {
        name: 'Detroit',
        id: 'DTW',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Detroit',
      },
      {
        name: 'Dublin',
        id: 'DUB',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Dublin',
      },
      {
        name: 'Dubai',
        id: 'DXB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Dubai',
      },
      {
        name: 'Edinburgh',
        id: 'EDI',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Edinburgh',
      },
      {
        name: 'Newark',
        id: 'EWR',
        origin: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Newark',
      },
      {
        name: 'Buenos Aires',
        id: 'EZE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Buenos Aires',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Rome',
      },
      {
        name: 'Ft de France',
        id: 'FDF',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Ft de France',
      },
      {
        name: 'Fort Lauderdale',
        id: 'FLL',
        origin: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Fort Lauderdale',
      },
      {
        name: 'Frankfurt',
        id: 'FRA',
        origin: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Frankfurt',
      },
      {
        name: 'Grand Cayman',
        id: 'GCM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Grand Cayman',
      },
      {
        name: 'George Town, Great Exuma Island',
        id: 'GGT',
        origin: ['YOW', 'YYZ'],
        destination: ['YOW', 'YYZ'],
        city: 'George Town, Great Exuma Island',
      },
      {
        name: 'Glasgow',
        id: 'GLA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Glasgow',
      },
      {
        name: 'Grenada',
        id: 'GND',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Grenada',
      },
      {
        name: 'Sao Paulo',
        id: 'GRU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Sao Paulo',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Geneva',
      },
      {
        name: 'Havana',
        id: 'HAV',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Havana',
      },
      {
        name: 'Hong Kong',
        id: 'HKG',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Hong Kong',
      },
      {
        name: 'Tokyo',
        id: 'HND',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Tokyo',
      },
      {
        name: 'Honolulu',
        id: 'HNL',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Honolulu',
      },
      {
        name: 'Holguin',
        id: 'HOG',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Holguin',
      },
      {
        name: 'Sta Cruz Huat',
        id: 'HUX',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sta Cruz Huat',
      },
      {
        name: 'Washington DC (IAD)',
        id: 'IAD',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Washington DC (IAD)',
      },
      {
        name: 'Houston',
        id: 'IAH',
        origin: ['YQR', 'YUL', 'YYC', 'YYZ'],
        destination: ['YQR', 'YUL', 'YYC', 'YYZ'],
        city: 'Houston',
      },
      {
        name: 'Regina',
        id: 'YQR',
        origin: ['ORD', 'YMM', 'YOW', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ'],
        destination: ['IAH', 'ORD', 'YMM', 'YOW', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ'],
        city: 'Regina',
      },
      {
        name: 'Seoul',
        id: 'ICN',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Seoul',
      },
      {
        name: 'Indianapolis',
        id: 'IND',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Indianapolis',
      },
      {
        name: 'Jacksonville',
        id: 'JAX',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Jacksonville',
      },
      {
        name: 'Reykjavik',
        id: 'KEF',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Reykjavik',
      },
      {
        name: 'Kingston',
        id: 'KIN',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Kingston',
      },
      {
        name: 'Osaka',
        id: 'KIX',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Osaka',
      },
      {
        name: 'Kailua',
        id: 'KOA',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Kailua',
      },
      {
        name: 'Las Vegas',
        id: 'LAS',
        origin: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Las Vegas',
      },
      {
        name: 'Edmonton',
        id: 'YEG',
        origin: ['SFO', 'YLW', 'YMM', 'YOW', 'YQU', 'YUL', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ', 'YZF'],
        destination: ['LAS', 'SFO', 'YLW', 'YMM', 'YOW', 'YQU', 'YUL', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ', 'YZF'],
        city: 'Edmonton',
      },
      {
        name: 'Los Angeles',
        id: 'LAX',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Los Angeles',
      },
      {
        name: 'New York (LGA)',
        id: 'LGA',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'New York (LGA)',
      },
      {
        name: 'London',
        id: 'LHR',
        origin: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYC', 'YYT', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYC', 'YYT', 'YYZ'],
        city: 'London',
      },
      {
        name: 'Kauai Is',
        id: 'LIH',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Kauai Is',
      },
      {
        name: 'Lima',
        id: 'LIM',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Lima',
      },
      {
        name: 'Liberia',
        id: 'LIR',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Liberia',
      },
      {
        name: 'Lisbon',
        id: 'LIS',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Lisbon',
      },
      {
        name: 'Lyon',
        id: 'LYS',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Lyon',
      },
      {
        name: 'Madrid',
        id: 'MAD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Madrid',
      },
      {
        name: 'Manchester',
        id: 'MAN',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Manchester',
      },
      {
        name: 'Montego Bay',
        id: 'MBJ',
        origin: ['YUL', 'YWG', 'YYZ'],
        destination: ['YUL', 'YWG', 'YYZ'],
        city: 'Montego Bay',
      },
      {
        name: 'Kansas City',
        id: 'MCI',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Kansas City',
      },
      {
        name: 'Orlando',
        id: 'MCO',
        origin: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Orlando',
      },
      {
        name: 'Melbourne',
        id: 'MEL',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Melbourne',
      },
      {
        name: 'Memphis',
        id: 'MEM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Memphis',
      },
      {
        name: 'Mexico City',
        id: 'MEX',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Mexico City',
      },
      {
        name: 'Miami',
        id: 'MIA',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Miami',
      },
      {
        name: 'Milwaukee',
        id: 'MKE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Milwaukee',
      },
      {
        name: 'Marseille',
        id: 'MRS',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Marseille',
      },
      {
        name: 'Minneapolis',
        id: 'MSP',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Minneapolis',
      },
      {
        name: 'New Orleans',
        id: 'MSY',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'New Orleans',
      },
      {
        name: 'Munich',
        id: 'MUC',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Munich',
      },
      {
        name: 'Milan',
        id: 'MXP',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Milan',
      },
      {
        name: 'Nassau',
        id: 'NAS',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Nassau',
      },
      {
        name: 'Nice',
        id: 'NCE',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Nice',
      },
      {
        name: 'Tokyo',
        id: 'NRT',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Tokyo',
      },
      {
        name: 'Kahului',
        id: 'OGG',
        origin: ['YVR', 'YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Kahului',
      },
      {
        name: 'Omaha',
        id: 'OMA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Omaha',
      },
      {
        name: 'Porto',
        id: 'OPO',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Porto',
      },
      {
        name: 'Chicago',
        id: 'ORD',
        origin: ['YQR', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YQR', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Chicago',
      },
      {
        name: 'Bucharest',
        id: 'OTP',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Bucharest',
      },
      {
        name: 'Port au Prince',
        id: 'PAP',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Port au Prince',
      },
      {
        name: 'West Palm Beach',
        id: 'PBI',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'West Palm Beach',
      },
      {
        name: 'Portland',
        id: 'PDX',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Portland',
      },
      {
        name: 'Topeka',
        id: 'PEK',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Topeka',
      },
      {
        name: 'Philadelphia',
        id: 'PHL',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Philadelphia',
      },
      {
        name: 'Phoenix',
        id: 'PHX',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Phoenix',
      },
      {
        name: 'Pittsburgh',
        id: 'PIT',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Pittsburgh',
      },
      {
        name: 'Providenciales',
        id: 'PLS',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Providenciales',
      },
      {
        name: 'Puerto Plata',
        id: 'POP',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Puerto Plata',
      },
      {
        name: 'Port of Spain, Trinidad',
        id: 'POS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Port of Spain, Trinidad',
      },
      {
        name: 'Prague',
        id: 'PRG',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Prague',
      },
      {
        name: 'Palm Springs',
        id: 'PSP',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Palm Springs',
      },
      {
        name: 'Point à Pitre',
        id: 'PTP',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Point à Pitre',
      },
      {
        name: 'Panama City',
        id: 'PTY',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Panama City',
      },
      {
        name: 'Punta Cana',
        id: 'PUJ',
        origin: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Punta Cana',
      },
      {
        name: 'Providence',
        id: 'PVD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Providence',
      },
      {
        name: 'Shanghai',
        id: 'PVG',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Shanghai',
      },
      {
        name: 'Puerto Vallarta',
        id: 'PVR',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Puerto Vallarta',
      },
      {
        name: 'Raleigh/Durham',
        id: 'RDU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Raleigh/Durham',
      },
      {
        name: 'Fort Myers',
        id: 'RSW',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Fort Myers',
      },
      {
        name: 'San Diego',
        id: 'SAN',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'San Diego',
      },
      {
        name: 'San Antonio',
        id: 'SAT',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'San Antonio',
      },
      {
        name: 'Savannah',
        id: 'SAV',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Savannah',
      },
      {
        name: 'Santiago',
        id: 'SCL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Santiago',
      },
      {
        name: 'Seattle',
        id: 'SEA',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Seattle',
      },
      {
        name: 'San Francisco',
        id: 'SFO',
        origin: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'San Francisco',
      },
      {
        name: 'San Jose',
        id: 'SJC',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'San Jose',
      },
      {
        name: 'San Jose, Los Cabos',
        id: 'SJD',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'San Jose, Los Cabos',
      },
      {
        name: 'San Jose',
        id: 'SJO',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'San Jose',
      },
      {
        name: 'San Juan',
        id: 'SJU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'San Juan',
      },
      {
        name: 'St. Kitts',
        id: 'SKB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St. Kitts',
      },
      {
        name: 'Sacramento',
        id: 'SMF',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Sacramento',
      },
      {
        name: 'Santa Clara',
        id: 'SNU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Santa Clara',
      },
      {
        name: 'Sarasota/ Bradenton',
        id: 'SRQ',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sarasota/ Bradenton',
      },
      {
        name: 'St.  Louis',
        id: 'STL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St.  Louis',
      },
      {
        name: 'St. Vincent',
        id: 'SVD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St. Vincent',
      },
      {
        name: 'St. Maarten',
        id: 'SXM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St. Maarten',
      },
      {
        name: 'Sydney',
        id: 'SYD',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Sydney',
      },
      {
        name: 'Tel Aviv Yafo, Sde Dov',
        id: 'TLV',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Tel Aviv Yafo, Sde Dov',
      },
      {
        name: 'Tampa',
        id: 'TPA',
        origin: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Tampa',
      },
      {
        name: 'Taipei',
        id: 'TPE',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Taipei',
      },
      {
        name: 'Berlin',
        id: 'TXL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Berlin',
      },
      {
        name: 'Quito',
        id: 'UIO',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Quito',
      },
      {
        name: 'Vieux Fort',
        id: 'UVF',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Vieux Fort',
      },
      {
        name: 'Venice',
        id: 'VCE',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Venice',
      },
      {
        name: 'Vienna',
        id: 'VIE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Vienna',
      },
      {
        name: 'Varadero',
        id: 'VRA',
        origin: ['YOW', 'YUL', 'YYZ'],
        destination: ['YOW', 'YUL', 'YYZ'],
        city: 'Varadero',
      },
      {
        name: 'Warsaw',
        id: 'WAW',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Warsaw',
      },
      {
        name: 'Sault  Ste. Marie',
        id: 'YAM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sault  Ste. Marie',
      },
      {
        name: 'Baie-Comeau',
        id: 'YBC',
        origin: ['YUL', 'YYY'],
        destination: ['YUL', 'YYY'],
        city: 'Baie-Comeau',
      },
      {
        name: 'Mont-Joli',
        id: 'YYY',
        origin: ['YQB', 'YUL'],
        destination: ['YBC', 'YQB', 'YUL'],
        city: 'Mont-Joli',
      },
      {
        name: 'Bagotville',
        id: 'YBG',
        origin: ['YUL', 'YZV'],
        destination: ['YUL', 'YZV'],
        city: 'Bagotville',
      },
      {
        name: 'Sept-Iles',
        id: 'YZV',
        origin: ['YQB', 'YUL', 'YWK'],
        destination: ['YBG', 'YQB', 'YUL', 'YWK'],
        city: 'Sept-Iles',
      },
      {
        name: 'Nanaimo Airport',
        id: 'YCD',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Nanaimo Airport',
      },
      {
        name: 'Castlegar',
        id: 'YCG',
        origin: ['YVR', 'YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Castlegar',
      },
      {
        name: 'Deer Lake',
        id: 'YDF',
        origin: ['YHZ', 'YQT', 'YQX', 'YUL', 'YYR', 'YYT', 'YYZ'],
        destination: ['YHZ', 'YQT', 'YQX', 'YUL', 'YYR', 'YYT', 'YYZ'],
        city: 'Deer Lake',
      },
      {
        name: 'Thunder Bay',
        id: 'YQT',
        origin: ['YWG', 'YYT', 'YYZ'],
        destination: ['YDF', 'YWG', 'YYT', 'YYZ'],
        city: 'Thunder Bay',
      },
      {
        name: 'Gander',
        id: 'YQX',
        origin: ['YHZ', 'YWK', 'YYR', 'YYT', 'YYZ'],
        destination: ['YDF', 'YHZ', 'YWK', 'YYR', 'YYT', 'YYZ'],
        city: 'Gander',
      },
      {
        name: 'Goose Bay',
        id: 'YYR',
        origin: ['YHZ', 'YQX', 'YWK', 'YYT'],
        destination: ['YDF', 'YHZ', 'YQX', 'YWK', 'YYT'],
        city: 'Goose Bay',
      },
      {
        name: 'Kelowna',
        id: 'YLW',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YVR', 'YYC', 'YYZ'],
        city: 'Kelowna',
      },
      {
        name: 'Fort McMurray',
        id: 'YMM',
        origin: ['YQR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YQR', 'YYC', 'YYZ'],
        city: 'Fort McMurray',
      },
      {
        name: 'Grande Prairie',
        id: 'YQU',
        origin: ['YYC'],
        destination: ['YEG', 'YYC'],
        city: 'Grande Prairie',
      },
      {
        name: 'Saskatoon',
        id: 'YXE',
        origin: ['YOW', 'YQR', 'YVR', 'YWG', 'YYC', 'YYZ'],
        destination: ['YEG', 'YOW', 'YQR', 'YVR', 'YWG', 'YYC', 'YYZ'],
        city: 'Saskatoon',
      },
      {
        name: 'Yellowknife',
        id: 'YZF',
        origin: ['YVR', 'YYC'],
        destination: ['YEG', 'YVR', 'YYC'],
        city: 'Yellowknife',
      },
      {
        name: 'Fredericton',
        id: 'YFC',
        origin: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Fredericton',
      },
      {
        name: 'Kingston',
        id: 'YGK',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Kingston',
      },
      {
        name: 'Gaspe',
        id: 'YGP',
        origin: ['YGR', 'YQB'],
        destination: ['YGR', 'YQB'],
        city: 'Gaspe',
      },
      {
        name: 'Cap-aux-Meules,',
        id: 'YGR',
        origin: ['YQB', 'YUL'],
        destination: ['YGP', 'YQB', 'YUL'],
        city: 'Cap-aux-Meules,',
      },
      {
        name: 'Hamilton',
        id: 'YHM',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Hamilton',
      },
      {
        name: 'Moncton',
        id: 'YQM',
        origin: ['YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Moncton',
      },
      {
        name: 'Sydney, Cape Breton Island',
        id: 'YQY',
        origin: ['YUL', 'YYZ'],
        destination: ['YHZ', 'YUL', 'YYZ'],
        city: 'Sydney, Cape Breton Island',
      },
      {
        name: 'Saint John',
        id: 'YSJ',
        origin: ['YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Saint John',
      },
      {
        name: 'Rouyn-Noranda',
        id: 'YUY',
        origin: ['YUL'],
        destination: ['YUL', 'YVO'],
        city: 'Rouyn-Noranda',
      },
      {
        name: 'Charlottetown',
        id: 'YYG',
        origin: ['YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Charlottetown',
      },
      {
        name: 'Kamloops',
        id: 'YKA',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Kamloops',
      },
      {
        name: 'London',
        id: 'YXU',
        origin: ['YUL', 'YYZ'],
        destination: ['YOW', 'YUL', 'YYZ'],
        city: 'London',
      },
      {
        name: 'Prince Rupert',
        id: 'YPR',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Prince Rupert',
      },
      {
        name: 'Windsor',
        id: 'YQG',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Windsor',
      },
      {
        name: 'Lethbridge',
        id: 'YQL',
        origin: ['YYC'],
        destination: ['YYC'],
        city: 'Lethbridge',
      },
      {
        name: 'Vancouver Island',
        id: 'YQQ',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Vancouver Island',
      },
      {
        name: 'Wabush',
        id: 'YWK',
        origin: ['YUL', 'YYR', 'YZV'],
        destination: ['YQX', 'YUL', 'YYR', 'YZV'],
        city: 'Wabush',
      },
      {
        name: 'Sudbury',
        id: 'YSB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sudbury',
      },
      {
        name: 'Mont-Tremblant',
        id: 'YTM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Mont-Tremblant',
      },
      {
        name: 'Timmins',
        id: 'YTS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Timmins',
      },
      {
        name: 'Toronto, ON',
        id: 'YTZ',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Toronto, ON',
      },
      {
        name: 'Bourlamaq/Val D’or',
        id: 'YVO',
        origin: ['YUY'],
        destination: ['YUL'],
        city: 'Bourlamaq/Val D’or',
      },
      {
        name: 'Victoria International',
        id: 'YYJ',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Victoria International',
      },
      {
        name: 'Bathurst',
        id: 'ZBF',
        origin: [],
        destination: ['YUL'],
        city: 'Bathurst',
      },
      {
        name: 'Zihuatanejo',
        id: 'ZIH',
        origin: ['YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Zihuatanejo',
      },
      {
        name: 'San Salvador Island',
        id: 'ZSA',
        origin: [],
        destination: ['YUL'],
        city: 'San Salvador Island',
      },
      {
        name: 'Cranbrook',
        id: 'YXC',
        origin: ['YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Cranbrook',
      },
      {
        name: 'Fort St. John',
        id: 'YXJ',
        origin: [],
        destination: ['YVR'],
        city: 'Fort St. John',
      },
      {
        name: 'Prince George',
        id: 'YXS',
        origin: [],
        destination: ['YVR'],
        city: 'Prince George',
      },
      {
        name: 'Terrace',
        id: 'YXT',
        origin: ['YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Terrace',
      },
      {
        name: 'Whitehorse',
        id: 'YXY',
        origin: [],
        destination: ['YVR'],
        city: 'Whitehorse',
      },
      {
        name: 'Smithers',
        id: 'YYD',
        origin: [],
        destination: ['YVR'],
        city: 'Smithers',
      },
      {
        name: 'Penticton',
        id: 'YYF',
        origin: [],
        destination: ['YVR'],
        city: 'Penticton',
      },
      {
        name: 'Sandspit, Queen Charlotte Islands',
        id: 'YZP',
        origin: [],
        destination: ['YVR'],
        city: 'Sandspit, Queen Charlotte Islands',
      },
      {
        name: 'Zurich',
        id: 'ZRH',
        origin: ['YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Zurich',
      },
      {
        name: 'Medicine Hat',
        id: 'YXH',
        origin: ['YYC'],
        destination: ['YYC'],
        city: 'Medicine Hat',
      },
      {
        name: 'North Bay',
        id: 'YYB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'North Bay',
      },
      {
        name: 'Sarnia',
        id: 'YZR',
        origin: [],
        destination: ['YYZ'],
        city: 'Sarnia',
      },
      {
        name: 'Zagreb',
        id: 'ZAG',
        origin: [],
        destination: ['YYZ'],
        city: 'Zagreb',
      },
    ],
  },
  'missing-miles': {
    MK: [
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Mumbai',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: ['CDG', 'CPT', 'DEL', 'FCO', 'GVA', 'JNB', 'KUL', 'LGW', 'MAA', 'PER', 'RRG', 'RUN', 'TNR'],
        destination: ['BOM', 'CDG', 'CPT', 'DEL', 'FCO', 'GVA', 'JNB', 'KUL', 'LGW', 'MAA', 'PER', 'RRG', 'RUN', 'TNR'],
        city: 'Mauritius',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Paris',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Cape Town',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Delhi',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Rome',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Geneva',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Johannesburg',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'London',
        id: 'LGW',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'London',
      },
      {
        name: 'Chennai',
        id: 'MAA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Chennai',
      },
      {
        name: 'Perth',
        id: 'PER',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Perth',
      },
      {
        name: 'Rodrigues',
        id: 'RRG',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Rodrigues',
      },
      {
        name: 'Reunion',
        id: 'RUN',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Reunion',
      },
      {
        name: 'Antannarivo',
        id: 'TNR',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Antannarivo',
      },
    ],
    EK: [
      {
        name: 'Abidjan',
        id: 'ABJ',
        origin: ['ACC', 'DXB'],
        destination: ['ACC', 'DXB'],
        city: 'Abidjan',
      },
      {
        name: 'Accra',
        id: 'ACC',
        origin: ['DXB'],
        destination: ['ABJ', 'DXB'],
        city: 'Accra',
      },
      {
        name: 'Dubai',
        id: 'DXB',
        origin: [
          'ABV',
          'ACC',
          'ADD',
          'ADL',
          'AKL',
          'ALG',
          'AMD',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BAH',
          'BCN',
          'BEY',
          'BGW',
          'BHX',
          'BKK',
          'BLQ',
          'BLR',
          'BNE',
          'BOM',
          'BOS',
          'BRU',
          'BSR',
          'BUD',
          'CAI',
          'CAN',
          'CAS',
          'CCJ',
          'CCU',
          'CDG',
          'CEB',
          'CGK',
          'CGO',
          'CHC',
          'CKY',
          'CMB',
          'CMN',
          'COK',
          'CPH',
          'CPT',
          'CRK',
          'DAC',
          'DAM',
          'DAR',
          'DEL',
          'DFW',
          'DKR',
          'DME',
          'DMM',
          'DOH',
          'DPS',
          'DUB',
          'DUR',
          'DUS',
          'EBB',
          'EBL',
          'EZE',
          'FCO',
          'FRA',
          'GIG',
          'GLA',
          'GRU',
          'GVA',
          'HAM',
          'HAN',
          'HBE',
          'HKG',
          'HKT',
          'HND',
          'HRE',
          'HYD',
          'IAD',
          'IAH',
          'ICN',
          'IKA',
          'INC',
          'ISB',
          'IST',
          'JED',
          'JFK',
          'JNB',
          'KAN',
          'KBL',
          'KBP',
          'KHI',
          'KIX',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LAX',
          'LCA',
          'LED',
          'LGW',
          'LHE',
          'LHR',
          'LIS',
          'LOS',
          'LUN',
          'LYS',
          'MAA',
          'MAD',
          'MAN',
          'MCO',
          'MCT',
          'MED',
          'MEL',
          'MHD',
          'MLA',
          'MLE',
          'MNL',
          'MRU',
          'MUC',
          'MUX',
          'MXP',
          'NBO',
          'NCE',
          'NCL',
          'NGO',
          'NRT',
          'ORD',
          'OSL',
          'PEK',
          'PER',
          'PEW',
          'PRG',
          'PVG',
          'RGN',
          'RUH',
          'SAH',
          'SAW',
          'SEA',
          'SEZ',
          'SFO',
          'SGN',
          'SIN',
          'SKT',
          'SXF',
          'SYD',
          'THR',
          'TIP',
          'TPE',
          'TRV',
          'TUN',
          'VCE',
          'VIE',
          'WAW',
          'WLG',
          'YYZ',
          'ZRH',
        ],
        destination: [
          'ABJ',
          'ABV',
          'ACC',
          'ADD',
          'ADL',
          'AKL',
          'ALG',
          'AMD',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BAH',
          'BCN',
          'BEY',
          'BGW',
          'BHX',
          'BKK',
          'BLQ',
          'BLR',
          'BNE',
          'BOM',
          'BOS',
          'BRU',
          'BSR',
          'BUD',
          'CAI',
          'CAN',
          'CAS',
          'CCJ',
          'CCU',
          'CDG',
          'CEB',
          'CGK',
          'CGO',
          'CHC',
          'CKY',
          'CMB',
          'CMN',
          'COK',
          'CPH',
          'CPT',
          'CRK',
          'DAC',
          'DAM',
          'DAR',
          'DEL',
          'DFW',
          'DKR',
          'DME',
          'DMM',
          'DOH',
          'DPS',
          'DUB',
          'DUR',
          'DUS',
          'EBB',
          'EBL',
          'EZE',
          'FCO',
          'FRA',
          'GIG',
          'GLA',
          'GRU',
          'GVA',
          'HAM',
          'HAN',
          'HBE',
          'HKG',
          'HKT',
          'HND',
          'HRE',
          'HYD',
          'IAD',
          'IAH',
          'ICN',
          'IKA',
          'INC',
          'ISB',
          'IST',
          'JED',
          'JFK',
          'JNB',
          'KAN',
          'KBL',
          'KBP',
          'KHI',
          'KIX',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LAX',
          'LCA',
          'LED',
          'LGW',
          'LHE',
          'LHR',
          'LIS',
          'LOS',
          'LUN',
          'LYS',
          'MAA',
          'MAD',
          'MAN',
          'MCO',
          'MCT',
          'MED',
          'MEL',
          'MHD',
          'MLA',
          'MLE',
          'MNL',
          'MRU',
          'MUC',
          'MUX',
          'MXP',
          'NBO',
          'NCE',
          'NCL',
          'NGO',
          'NRT',
          'ORD',
          'OSL',
          'PEK',
          'PER',
          'PEW',
          'PRG',
          'PVG',
          'RGN',
          'RUH',
          'SAH',
          'SAW',
          'SEA',
          'SEZ',
          'SFO',
          'SGN',
          'SIN',
          'SKT',
          'SXF',
          'SYD',
          'THR',
          'TIP',
          'TPE',
          'TRV',
          'TUN',
          'VCE',
          'VIE',
          'WAW',
          'WLG',
          'YYZ',
          'ZRH',
        ],
        city: 'Dubai',
      },
      {
        name: 'Abuja',
        id: 'ABV',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Abuja',
      },
      {
        name: 'Addis Ababa',
        id: 'ADD',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Addis Ababa',
      },
      {
        name: 'Adelaide',
        id: 'ADL',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Adelaide',
      },
      {
        name: 'Auckland',
        id: 'AKL',
        origin: ['BNE', 'DXB', 'MEL', 'SYD'],
        destination: ['BNE', 'DXB', 'MEL', 'SYD'],
        city: 'Auckland',
      },
      {
        name: 'Brisbane',
        id: 'BNE',
        origin: ['DXB', 'SIN'],
        destination: ['AKL', 'DXB', 'SIN'],
        city: 'Brisbane',
      },
      {
        name: 'Melbourne',
        id: 'MEL',
        origin: ['DXB', 'KUL', 'SIN'],
        destination: ['AKL', 'DXB', 'KUL', 'SIN'],
        city: 'Melbourne',
      },
      {
        name: 'Sydney',
        id: 'SYD',
        origin: ['BKK', 'CHC', 'DXB'],
        destination: ['AKL', 'BKK', 'CHC', 'DXB'],
        city: 'Sydney',
      },
      {
        name: 'Algiers',
        id: 'ALG',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Algiers',
      },
      {
        name: 'Ahmedabad',
        id: 'AMD',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Ahmedabad',
      },
      {
        name: 'Amman',
        id: 'AMM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Amman',
      },
      {
        name: 'Amsterdam',
        id: 'AMS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Amsterdam',
      },
      {
        name: 'Stockholm',
        id: 'ARN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Stockholm',
      },
      {
        name: 'Athens',
        id: 'ATH',
        origin: ['DXB', 'LCA'],
        destination: ['DXB', 'LCA'],
        city: 'Athens',
      },
      {
        name: 'Larnaca',
        id: 'LCA',
        origin: ['DXB', 'MLA'],
        destination: ['ATH', 'DXB', 'MLA'],
        city: 'Larnaca',
      },
      {
        name: 'Bahrain',
        id: 'BAH',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bahrain',
      },
      {
        name: 'Barcelona',
        id: 'BCN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Barcelona',
      },
      {
        name: 'Beirut',
        id: 'BEY',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Beirut',
      },
      {
        name: 'Baghdad',
        id: 'BGW',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Baghdad',
      },
      {
        name: 'Birmingham',
        id: 'BHX',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Birmingham',
      },
      {
        name: 'Bangkok',
        id: 'BKK',
        origin: ['CHC', 'DXB', 'HKG', 'SYD'],
        destination: ['CHC', 'DXB', 'HKG', 'SYD'],
        city: 'Bangkok',
      },
      {
        name: 'Christchurch',
        id: 'CHC',
        origin: ['DXB', 'SYD'],
        destination: ['BKK', 'DXB', 'SYD'],
        city: 'Christchurch',
      },
      {
        name: 'Hong Kong',
        id: 'HKG',
        origin: ['DXB'],
        destination: ['BKK', 'DXB'],
        city: 'Hong Kong',
      },
      {
        name: 'Bologna',
        id: 'BLQ',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bologna',
      },
      {
        name: 'Bangalore',
        id: 'BLR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bangalore',
      },
      {
        name: 'Singapore',
        id: 'SIN',
        origin: ['CMB', 'DXB', 'MEL'],
        destination: ['BNE', 'CMB', 'DXB', 'MEL'],
        city: 'Singapore',
      },
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Mumbai',
      },
      {
        name: 'Boston',
        id: 'BOS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Boston',
      },
      {
        name: 'Brussels',
        id: 'BRU',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Brussels',
      },
      {
        name: 'Basrah',
        id: 'BSR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Basrah',
      },
      {
        name: 'Budapest',
        id: 'BUD',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Budapest',
      },
      {
        name: 'Cairo',
        id: 'CAI',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cairo',
      },
      {
        name: 'Guangzhou',
        id: 'CAN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Guangzhou',
      },
      {
        name: 'Casablanca–Anfa',
        id: 'CAS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Casablanca–Anfa',
      },
      {
        name: 'Kozhikode',
        id: 'CCJ',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Kozhikode',
      },
      {
        name: 'Kolkata',
        id: 'CCU',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Kolkata',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Paris',
      },
      {
        name: 'Cebu',
        id: 'CEB',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cebu',
      },
      {
        name: 'Jakarta',
        id: 'CGK',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Jakarta',
      },
      {
        name: 'Zhengzhou',
        id: 'CGO',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Zhengzhou',
      },
      {
        name: 'Conakry',
        id: 'CKY',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Conakry',
      },
      {
        name: 'Colombo',
        id: 'CMB',
        origin: ['DXB', 'MLE', 'SIN'],
        destination: ['DXB', 'MLE', 'SIN'],
        city: 'Colombo',
      },
      {
        name: 'Malé-Velana',
        id: 'MLE',
        origin: ['DXB'],
        destination: ['CMB', 'DXB'],
        city: 'Malé-Velana',
      },
      {
        name: 'Casablanca',
        id: 'CMN',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Casablanca',
      },
      {
        name: 'Cochin',
        id: 'COK',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cochin',
      },
      {
        name: 'Copenhagen',
        id: 'CPH',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Copenhagen',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Cape Town',
      },
      {
        name: 'Mabalacat, Philippines',
        id: 'CRK',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Mabalacat, Philippines',
      },
      {
        name: 'Dhaka',
        id: 'DAC',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dhaka',
      },
      {
        name: 'Damascus',
        id: 'DAM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Damascus',
      },
      {
        name: 'Dar Es Salaam',
        id: 'DAR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dar Es Salaam',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Delhi',
      },
      {
        name: 'Dallas/Ft. Worth',
        id: 'DFW',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dallas/Ft. Worth',
      },
      {
        name: 'Dakar',
        id: 'DKR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dakar',
      },
      {
        name: 'Moscow Domodedovo',
        id: 'DME',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Moscow Domodedovo',
      },
      {
        name: 'Dammam',
        id: 'DMM',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dammam',
      },
      {
        name: 'Doha',
        id: 'DOH',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Doha',
      },
      {
        name: 'Bali Island, Denpasar',
        id: 'DPS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Bali Island, Denpasar',
      },
      {
        name: 'Dublin',
        id: 'DUB',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dublin',
      },
      {
        name: 'Durban',
        id: 'DUR',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Durban',
      },
      {
        name: 'Dusseldorf',
        id: 'DUS',
        origin: ['DXB'],
        destination: ['DXB'],
        city: 'Dusseldorf',
      },
      {
        name: 'Entebbe',
        id: 'EBB',
        origin: [],
        destination: ['DXB'],
        city: 'Entebbe',
      },
      {
        name: 'Erbil',
        id: 'EBL',
        origin: [],
        destination: ['DXB'],
        city: 'Erbil',
      },
      {
        name: 'Buenos Aires',
        id: 'EZE',
        origin: ['GIG'],
        destination: ['DXB', 'GIG'],
        city: 'Buenos Aires',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: [],
        destination: ['DXB'],
        city: 'Rome',
      },
      {
        name: 'Frankfurt',
        id: 'FRA',
        origin: [],
        destination: ['DXB'],
        city: 'Frankfurt',
      },
      {
        name: 'Rio de Janeiro',
        id: 'GIG',
        origin: ['EZE'],
        destination: ['DXB', 'EZE'],
        city: 'Rio de Janeiro',
      },
      {
        name: 'Glasgow',
        id: 'GLA',
        origin: [],
        destination: ['DXB'],
        city: 'Glasgow',
      },
      {
        name: 'Sao Paulo',
        id: 'GRU',
        origin: [],
        destination: ['DXB'],
        city: 'Sao Paulo',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: [],
        destination: ['DXB'],
        city: 'Geneva',
      },
      {
        name: 'Hamburg',
        id: 'HAM',
        origin: [],
        destination: ['DXB'],
        city: 'Hamburg',
      },
      {
        name: 'Hanoi',
        id: 'HAN',
        origin: ['RGN'],
        destination: ['DXB', 'RGN'],
        city: 'Hanoi',
      },
      {
        name: 'Alexandria',
        id: 'HBE',
        origin: [],
        destination: ['DXB'],
        city: 'Alexandria',
      },
      {
        name: 'Phuket',
        id: 'HKT',
        origin: [],
        destination: ['DXB'],
        city: 'Phuket',
      },
      {
        name: 'Tokyo',
        id: 'HND',
        origin: [],
        destination: ['DXB'],
        city: 'Tokyo',
      },
      {
        name: 'Harare',
        id: 'HRE',
        origin: ['LUN'],
        destination: ['DXB', 'LUN'],
        city: 'Harare',
      },
      {
        name: 'Hyderabad',
        id: 'HYD',
        origin: [],
        destination: ['DXB'],
        city: 'Hyderabad',
      },
      {
        name: 'Washington DC (IAD)',
        id: 'IAD',
        origin: [],
        destination: ['DXB'],
        city: 'Washington DC (IAD)',
      },
      {
        name: 'Houston',
        id: 'IAH',
        origin: [],
        destination: ['DXB'],
        city: 'Houston',
      },
      {
        name: 'Seoul',
        id: 'ICN',
        origin: [],
        destination: ['DXB'],
        city: 'Seoul',
      },
      {
        name: 'Tehran',
        id: 'IKA',
        origin: [],
        destination: ['DXB'],
        city: 'Tehran',
      },
      {
        name: 'Yinchuan',
        id: 'INC',
        origin: [],
        destination: ['DXB'],
        city: 'Yinchuan',
      },
      {
        name: 'Islamabad',
        id: 'ISB',
        origin: [],
        destination: ['DXB'],
        city: 'Islamabad',
      },
      {
        name: 'Istanbul',
        id: 'IST',
        origin: [],
        destination: ['DXB'],
        city: 'Istanbul',
      },
      {
        name: 'Jeddah',
        id: 'JED',
        origin: [],
        destination: ['DXB'],
        city: 'Jeddah',
      },
      {
        name: 'New York (JFK)',
        id: 'JFK',
        origin: ['MXP'],
        destination: ['DXB', 'MXP'],
        city: 'New York (JFK)',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: [],
        destination: ['DXB'],
        city: 'Johannesburg',
      },
      {
        name: 'Kano',
        id: 'KAN',
        origin: [],
        destination: ['DXB'],
        city: 'Kano',
      },
      {
        name: 'Kabul',
        id: 'KBL',
        origin: [],
        destination: ['DXB'],
        city: 'Kabul',
      },
      {
        name: 'Boryspil',
        id: 'KBP',
        origin: [],
        destination: ['DXB'],
        city: 'Boryspil',
      },
      {
        name: 'Karachi',
        id: 'KHI',
        origin: [],
        destination: ['DXB'],
        city: 'Karachi',
      },
      {
        name: 'Osaka',
        id: 'KIX',
        origin: [],
        destination: ['DXB'],
        city: 'Osaka',
      },
      {
        name: 'Khartoum',
        id: 'KRT',
        origin: [],
        destination: ['DXB'],
        city: 'Khartoum',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: ['MEL'],
        destination: ['DXB', 'MEL'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'Kuwait',
        id: 'KWI',
        origin: [],
        destination: ['DXB'],
        city: 'Kuwait',
      },
      {
        name: 'Luanda',
        id: 'LAD',
        origin: [],
        destination: ['DXB'],
        city: 'Luanda',
      },
      {
        name: 'Los Angeles',
        id: 'LAX',
        origin: [],
        destination: ['DXB'],
        city: 'Los Angeles',
      },
      {
        name: 'St. Petersburg',
        id: 'LED',
        origin: [],
        destination: ['DXB'],
        city: 'St. Petersburg',
      },
      {
        name: 'London',
        id: 'LGW',
        origin: [],
        destination: ['DXB'],
        city: 'London',
      },
      {
        name: 'Lahore (Pakistan)',
        id: 'LHE',
        origin: [],
        destination: ['DXB'],
        city: 'Lahore (Pakistan)',
      },
      {
        name: 'London',
        id: 'LHR',
        origin: [],
        destination: ['DXB'],
        city: 'London',
      },
      {
        name: 'Lisbon',
        id: 'LIS',
        origin: [],
        destination: ['DXB'],
        city: 'Lisbon',
      },
      {
        name: 'Lagos',
        id: 'LOS',
        origin: [],
        destination: ['DXB'],
        city: 'Lagos',
      },
      {
        name: 'Lusaka',
        id: 'LUN',
        origin: ['HRE'],
        destination: ['DXB', 'HRE'],
        city: 'Lusaka',
      },
      {
        name: 'Lyon',
        id: 'LYS',
        origin: [],
        destination: ['DXB'],
        city: 'Lyon',
      },
      {
        name: 'Chennai',
        id: 'MAA',
        origin: [],
        destination: ['DXB'],
        city: 'Chennai',
      },
      {
        name: 'Madrid',
        id: 'MAD',
        origin: [],
        destination: ['DXB'],
        city: 'Madrid',
      },
      {
        name: 'Manchester',
        id: 'MAN',
        origin: [],
        destination: ['DXB'],
        city: 'Manchester',
      },
      {
        name: 'Orlando',
        id: 'MCO',
        origin: [],
        destination: ['DXB'],
        city: 'Orlando',
      },
      {
        name: 'Muscat',
        id: 'MCT',
        origin: [],
        destination: ['DXB'],
        city: 'Muscat',
      },
      {
        name: 'Medina',
        id: 'MED',
        origin: [],
        destination: ['DXB'],
        city: 'Medina',
      },
      {
        name: 'Mashhad',
        id: 'MHD',
        origin: [],
        destination: ['DXB'],
        city: 'Mashhad',
      },
      {
        name: 'Malta',
        id: 'MLA',
        origin: ['LCA'],
        destination: ['DXB', 'LCA'],
        city: 'Malta',
      },
      {
        name: 'Manila',
        id: 'MNL',
        origin: [],
        destination: ['DXB'],
        city: 'Manila',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: [],
        destination: ['DXB'],
        city: 'Mauritius',
      },
      {
        name: 'Munich',
        id: 'MUC',
        origin: [],
        destination: ['DXB'],
        city: 'Munich',
      },
      {
        name: 'Multan',
        id: 'MUX',
        origin: [],
        destination: ['DXB'],
        city: 'Multan',
      },
      {
        name: 'Milan',
        id: 'MXP',
        origin: ['JFK'],
        destination: ['DXB', 'JFK'],
        city: 'Milan',
      },
      {
        name: 'Nairobi',
        id: 'NBO',
        origin: [],
        destination: ['DXB'],
        city: 'Nairobi',
      },
      {
        name: 'Nice',
        id: 'NCE',
        origin: [],
        destination: ['DXB'],
        city: 'Nice',
      },
      {
        name: 'Newcastle',
        id: 'NCL',
        origin: [],
        destination: ['DXB'],
        city: 'Newcastle',
      },
      {
        name: 'Nagoya',
        id: 'NGO',
        origin: [],
        destination: ['DXB'],
        city: 'Nagoya',
      },
      {
        name: 'Tokyo',
        id: 'NRT',
        origin: [],
        destination: ['DXB'],
        city: 'Tokyo',
      },
      {
        name: 'Chicago',
        id: 'ORD',
        origin: [],
        destination: ['DXB'],
        city: 'Chicago',
      },
      {
        name: 'Oslo',
        id: 'OSL',
        origin: [],
        destination: ['DXB'],
        city: 'Oslo',
      },
      {
        name: 'Topeka',
        id: 'PEK',
        origin: [],
        destination: ['DXB'],
        city: 'Topeka',
      },
      {
        name: 'Perth',
        id: 'PER',
        origin: [],
        destination: ['DXB'],
        city: 'Perth',
      },
      {
        name: 'Peshawar',
        id: 'PEW',
        origin: [],
        destination: ['DXB'],
        city: 'Peshawar',
      },
      {
        name: 'Prague',
        id: 'PRG',
        origin: [],
        destination: ['DXB'],
        city: 'Prague',
      },
      {
        name: 'Shanghai',
        id: 'PVG',
        origin: [],
        destination: ['DXB'],
        city: 'Shanghai',
      },
      {
        name: 'Yangon',
        id: 'RGN',
        origin: ['HAN'],
        destination: ['DXB', 'HAN'],
        city: 'Yangon',
      },
      {
        name: 'Riyadh',
        id: 'RUH',
        origin: [],
        destination: ['DXB'],
        city: 'Riyadh',
      },
      {
        name: 'Sanaa',
        id: 'SAH',
        origin: [],
        destination: ['DXB'],
        city: 'Sanaa',
      },
      {
        name: 'Sabiha Gökçen (Istanbul)',
        id: 'SAW',
        origin: [],
        destination: ['DXB'],
        city: 'Sabiha Gökçen (Istanbul)',
      },
      {
        name: 'Seattle',
        id: 'SEA',
        origin: [],
        destination: ['DXB'],
        city: 'Seattle',
      },
      {
        name: 'Seychelles',
        id: 'SEZ',
        origin: [],
        destination: ['DXB'],
        city: 'Seychelles',
      },
      {
        name: 'San Francisco',
        id: 'SFO',
        origin: [],
        destination: ['DXB'],
        city: 'San Francisco',
      },
      {
        name: 'Ho Chi Minh City',
        id: 'SGN',
        origin: [],
        destination: ['DXB'],
        city: 'Ho Chi Minh City',
      },
      {
        name: 'Sialkot',
        id: 'SKT',
        origin: [],
        destination: ['DXB'],
        city: 'Sialkot',
      },
      {
        name: 'Berlin Schönefeld',
        id: 'SXF',
        origin: [],
        destination: ['DXB'],
        city: 'Berlin Schönefeld',
      },
      {
        name: 'Tehran',
        id: 'THR',
        origin: [],
        destination: ['DXB'],
        city: 'Tehran',
      },
      {
        name: 'Tripoli',
        id: 'TIP',
        origin: [],
        destination: ['DXB'],
        city: 'Tripoli',
      },
      {
        name: 'Taipei',
        id: 'TPE',
        origin: [],
        destination: ['DXB'],
        city: 'Taipei',
      },
      {
        name: 'Thiruvananthapuram',
        id: 'TRV',
        origin: [],
        destination: ['DXB'],
        city: 'Thiruvananthapuram',
      },
      {
        name: 'Tunis',
        id: 'TUN',
        origin: [],
        destination: ['DXB'],
        city: 'Tunis',
      },
      {
        name: 'Venice',
        id: 'VCE',
        origin: [],
        destination: ['DXB'],
        city: 'Venice',
      },
      {
        name: 'Vienna',
        id: 'VIE',
        origin: [],
        destination: ['DXB'],
        city: 'Vienna',
      },
      {
        name: 'Warsaw',
        id: 'WAW',
        origin: [],
        destination: ['DXB'],
        city: 'Warsaw',
      },
      {
        name: 'Wellington',
        id: 'WLG',
        origin: [],
        destination: ['DXB'],
        city: 'Wellington',
      },
      {
        name: 'Toronto',
        id: 'YYZ',
        origin: [],
        destination: ['DXB'],
        city: 'Toronto',
      },
      {
        name: 'Zurich',
        id: 'ZRH',
        origin: [],
        destination: ['DXB'],
        city: 'Zurich',
      },
    ],
    AF: [
      {
        name: 'Aalborg',
        id: 'AAL',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Aalborg',
      },
      {
        name: 'Amsterdam',
        id: 'AMS',
        origin: [
          'ABZ',
          'ACC',
          'ADD',
          'ALA',
          'ARN',
          'ATH',
          'ATL',
          'AUA',
          'AUH',
          'BAH',
          'BCN',
          'BGO',
          'BHX',
          'BJS',
          'BKK',
          'BLL',
          'BLQ',
          'BOD',
          'BON',
          'BRE',
          'BRS',
          'BUD',
          'BUE',
          'CAI',
          'CDG',
          'CFE',
          'CGN',
          'CPH',
          'CPT',
          'CTU',
          'CUR',
          'CWL',
          'DAR',
          'DEL',
          'DFW',
          'DMM',
          'DOH',
          'DPS',
          'DUS',
          'DXB',
          'EBB',
          'EDI',
          'FCO',
          'FRA',
          'GLA',
          'GOT',
          'GVA',
          'GYE',
          'HAJ',
          'HAM',
          'HAV',
          'HEM',
          'HGH',
          'HKG',
          'HUY',
          'IAH',
          'IST',
          'JKT',
          'JNB',
          'JRO',
          'KBP',
          'KGL',
          'KRS',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LBA',
          'LCY',
          'LED',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LPI',
          'LUN',
          'LUX',
          'LYS',
          'MAD',
          'MAN',
          'MCT',
          'MEX',
          'MLH',
          'MNL',
          'MRS',
          'MRU',
          'MUC',
          'NBO',
          'NCE',
          'NCL',
          'NTE',
          'NUE',
          'NWI',
          'NYC',
          'ORD',
          'OSA',
          'OSL',
          'OTP',
          'PBM',
          'PRG',
          'PTY',
          'RIO',
          'SAO',
          'SEL',
          'SFO',
          'SHA',
          'SIN',
          'STR',
          'SVG',
          'SVO',
          'SXB',
          'THR',
          'TIP',
          'TLS',
          'TLV',
          'TPE',
          'TRD',
          'TXL',
          'TYO',
          'UIO',
          'VCE',
          'VIE',
          'WAS',
          'WAW',
          'XMN',
          'YUL',
          'YVR',
          'YYC',
          'YYZ',
          'ZRH',
        ],
        destination: [
          'AAL',
          'ABZ',
          'ACC',
          'ADD',
          'ALA',
          'ARN',
          'ATH',
          'ATL',
          'AUA',
          'AUH',
          'BAH',
          'BCN',
          'BGO',
          'BHX',
          'BJS',
          'BKK',
          'BLL',
          'BLQ',
          'BOD',
          'BON',
          'BRE',
          'BRS',
          'BUD',
          'BUE',
          'CAI',
          'CDG',
          'CFE',
          'CGN',
          'CPH',
          'CPT',
          'CTU',
          'CUR',
          'CWL',
          'DAR',
          'DEL',
          'DFW',
          'DMM',
          'DOH',
          'DPS',
          'DUS',
          'DXB',
          'EBB',
          'EDI',
          'FCO',
          'FRA',
          'GLA',
          'GOT',
          'GVA',
          'GYE',
          'HAJ',
          'HAM',
          'HAV',
          'HEM',
          'HGH',
          'HKG',
          'HUY',
          'IAH',
          'IST',
          'JKT',
          'JNB',
          'JRO',
          'KBP',
          'KGL',
          'KRS',
          'KRT',
          'KUL',
          'KWI',
          'LAD',
          'LBA',
          'LCY',
          'LED',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LPI',
          'LUN',
          'LUX',
          'LYS',
          'MAD',
          'MAN',
          'MCT',
          'MEX',
          'MLH',
          'MNL',
          'MRS',
          'MRU',
          'MUC',
          'NBO',
          'NCE',
          'NCL',
          'NTE',
          'NUE',
          'NWI',
          'NYC',
          'ORD',
          'OSA',
          'OSL',
          'OTP',
          'PBM',
          'PRG',
          'PTY',
          'RIO',
          'SAO',
          'SEL',
          'SFO',
          'SHA',
          'SIN',
          'STR',
          'SVG',
          'SVO',
          'SXB',
          'THR',
          'TIP',
          'TLS',
          'TLV',
          'TPE',
          'TRD',
          'TXL',
          'TYO',
          'UIO',
          'VCE',
          'VIE',
          'WAS',
          'WAW',
          'XMN',
          'YUL',
          'YVR',
          'YYC',
          'YYZ',
          'ZRH',
        ],
        city: 'Amsterdam',
      },
      {
        name: 'Abidjan',
        id: 'ABJ',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Abidjan',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: [
          'ABZ',
          'AJA',
          'ALG',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BCN',
          'BES',
          'BEY',
          'BGF',
          'BHX',
          'BIA',
          'BIO',
          'BIQ',
          'BJS',
          'BKK',
          'BKO',
          'BLL',
          'BLQ',
          'BLR',
          'BOD',
          'BOG',
          'BOM',
          'BOS',
          'BRE',
          'BRN',
          'BRS',
          'BUD',
          'BUE',
          'BZV',
          'CAI',
          'CAN',
          'CAY',
          'CCS',
          'CFE',
          'CGN',
          'CKY',
          'CMN',
          'COO',
          'CPH',
          'DAM',
          'DEL',
          'DKR',
          'DLA',
          'DTT',
          'DUB',
          'DUS',
          'DXB',
          'EDI',
          'FCO',
          'FDF',
          'FIH',
          'FLR',
          'FNA',
          'FRA',
          'FSC',
          'GOA',
          'GVA',
          'HAJ',
          'HAM',
          'HAV',
          'HKG',
          'IAH',
          'IST',
          'JED',
          'JIB',
          'JNB',
          'KBP',
          'KWI',
          'LAD',
          'LAX',
          'LBV',
          'LCY',
          'LDE',
          'LED',
          'LFW',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LJU',
          'LOS',
          'LRT',
          'LYS',
          'MAD',
          'MAN',
          'MEX',
          'MIA',
          'MLH',
          'MLW',
          'MPL',
          'MRS',
          'MRU',
          'MUC',
          'NAP',
          'NCE',
          'NCL',
          'NDJ',
          'NIM',
          'NKC',
          'NOU',
          'NTE',
          'NUE',
          'NYC',
          'ORL',
          'OSA',
          'OSL',
          'OTP',
          'OUA',
          'PGF',
          'PHC',
          'PNH',
          'PNR',
          'PPT',
          'PRG',
          'PSA',
          'PTP',
          'PUF',
          'PUJ',
          'RBA',
          'RDZ',
          'RIO',
          'RNS',
          'RUH',
          'RUN',
          'SAO',
          'SCL',
          'SDQ',
          'SEL',
          'SFO',
          'SGN',
          'SHA',
          'SIN',
          'SOF',
          'SSG',
          'STR',
          'SVO',
          'SXB',
          'TLN',
          'TLS',
          'TLV',
          'TNR',
          'TRN',
          'TUN',
          'TXL',
          'TYO',
          'UIP',
          'VCE',
          'VIE',
          'VRN',
          'WAS',
          'WAW',
          'YAO',
          'YYZ',
          'ZAG',
          'ZRH',
        ],
        destination: [
          'ABJ',
          'ABZ',
          'AJA',
          'ALG',
          'AMM',
          'AMS',
          'ARN',
          'ATH',
          'BCN',
          'BES',
          'BEY',
          'BGF',
          'BHX',
          'BIA',
          'BIO',
          'BIQ',
          'BJS',
          'BKK',
          'BKO',
          'BLL',
          'BLQ',
          'BLR',
          'BOD',
          'BOG',
          'BOM',
          'BOS',
          'BRE',
          'BRN',
          'BRS',
          'BUD',
          'BUE',
          'BZV',
          'CAI',
          'CAN',
          'CAY',
          'CCS',
          'CFE',
          'CGN',
          'CKY',
          'CMN',
          'COO',
          'CPH',
          'DAM',
          'DEL',
          'DKR',
          'DLA',
          'DTT',
          'DUB',
          'DUS',
          'DXB',
          'EDI',
          'FCO',
          'FDF',
          'FIH',
          'FLR',
          'FNA',
          'FRA',
          'FSC',
          'GOA',
          'GVA',
          'HAJ',
          'HAM',
          'HAV',
          'HKG',
          'IAH',
          'IST',
          'JED',
          'JIB',
          'JNB',
          'KBP',
          'KWI',
          'LAD',
          'LAX',
          'LBV',
          'LCY',
          'LDE',
          'LED',
          'LFW',
          'LHR',
          'LIM',
          'LIN',
          'LIS',
          'LJU',
          'LOS',
          'LRT',
          'LYS',
          'MAD',
          'MAN',
          'MEX',
          'MIA',
          'MLH',
          'MLW',
          'MPL',
          'MRS',
          'MRU',
          'MUC',
          'NAP',
          'NCE',
          'NCL',
          'NDJ',
          'NIM',
          'NKC',
          'NOU',
          'NTE',
          'NUE',
          'NYC',
          'ORL',
          'OSA',
          'OSL',
          'OTP',
          'OUA',
          'PGF',
          'PHC',
          'PNH',
          'PNR',
          'PPT',
          'PRG',
          'PSA',
          'PTP',
          'PUF',
          'PUJ',
          'RBA',
          'RDZ',
          'RIO',
          'RNS',
          'RUH',
          'RUN',
          'SAO',
          'SCL',
          'SDQ',
          'SEL',
          'SFO',
          'SGN',
          'SHA',
          'SIN',
          'SOF',
          'SSG',
          'STR',
          'SVO',
          'SXB',
          'TLN',
          'TLS',
          'TLV',
          'TNR',
          'TRN',
          'TUN',
          'TXL',
          'TYO',
          'UIP',
          'VCE',
          'VIE',
          'VRN',
          'WAS',
          'WAW',
          'YAO',
          'YYZ',
          'ZAG',
          'ZRH',
        ],
        city: 'Paris',
      },
      {
        name: 'Aberdeen',
        id: 'ABZ',
        origin: ['AMS', 'CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Aberdeen',
      },
      {
        name: 'Accra',
        id: 'ACC',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Accra',
      },
      {
        name: 'Addis Ababa',
        id: 'ADD',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Addis Ababa',
      },
      {
        name: 'Malaga',
        id: 'AGP',
        origin: ['TLS'],
        destination: ['TLS'],
        city: 'Malaga',
      },
      {
        name: 'Toulouse',
        id: 'TLS',
        origin: [
          'AMS',
          'ATH',
          'CDG',
          'CMN',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'MLA',
          'NAP',
          'NCE',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVQ',
          'SXB',
          'TUN',
          'TXL',
          'VCE',
          'VIE',
        ],
        destination: [
          'AGP',
          'AMS',
          'ATH',
          'CDG',
          'CMN',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'MLA',
          'NAP',
          'NCE',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVQ',
          'SXB',
          'TUN',
          'TXL',
          'VCE',
          'VIE',
        ],
        city: 'Toulouse',
      },
      {
        name: 'Ajaccio',
        id: 'AJA',
        origin: ['CDG', 'ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Ajaccio',
      },
      {
        name: 'Paris Orly',
        id: 'ORY',
        origin: [
          'BES',
          'BIA',
          'BIQ',
          'BOD',
          'CFE',
          'CLY',
          'FDF',
          'FSC',
          'LCY',
          'LDE',
          'LRT',
          'LYS',
          'MLH',
          'MPL',
          'MRS',
          'NCE',
          'NTE',
          'PGF',
          'PTP',
          'PUF',
          'RDZ',
          'RUN',
          'SXB',
          'TLN',
          'TLS',
          'UIP',
        ],
        destination: [
          'AJA',
          'BES',
          'BIA',
          'BIQ',
          'BOD',
          'CFE',
          'CLY',
          'FDF',
          'FSC',
          'LCY',
          'LDE',
          'LRT',
          'LYS',
          'MLH',
          'MPL',
          'MRS',
          'NCE',
          'NTE',
          'PGF',
          'PTP',
          'PUF',
          'RDZ',
          'RUN',
          'SXB',
          'TLN',
          'TLS',
          'UIP',
        ],
        city: 'Paris Orly',
      },
      {
        name: 'Alma Ata',
        id: 'ALA',
        origin: ['AMS'],
        destination: ['AMS'],
        city: 'Alma Ata',
      },
      {
        name: 'Algiers',
        id: 'ALG',
        origin: ['CDG', 'MRS'],
        destination: ['CDG', 'MRS'],
        city: 'Algiers',
      },
      {
        name: 'Marseille',
        id: 'MRS',
        origin: [
          'AMS',
          'ATH',
          'BCN',
          'BES',
          'BIQ',
          'BOD',
          'CDG',
          'CMN',
          'CPH',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'LYS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVO',
          'SXB',
          'TLV',
          'TUN',
          'TXL',
          'VCE',
        ],
        destination: [
          'ALG',
          'AMS',
          'ATH',
          'BCN',
          'BES',
          'BIQ',
          'BOD',
          'CDG',
          'CMN',
          'CPH',
          'FCO',
          'HAM',
          'IST',
          'LIL',
          'LYS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'RAK',
          'RNS',
          'SVO',
          'SXB',
          'TLV',
          'TUN',
          'TXL',
          'VCE',
        ],
        city: 'Marseille',
      },
      {
        name: 'Amman',
        id: 'AMM',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Amman',
      },
      {
        name: 'Stockholm',
        id: 'ARN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Stockholm',
      },
      {
        name: 'Athens',
        id: 'ATH',
        origin: ['CDG', 'MRS', 'NCE', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'NCE', 'TLS'],
        city: 'Athens',
      },
      {
        name: 'Atlanta',
        id: 'ATL',
        origin: [],
        destination: ['AMS'],
        city: 'Atlanta',
      },
      {
        name: 'Aruba',
        id: 'AUA',
        origin: [],
        destination: ['AMS'],
        city: 'Aruba',
      },
      {
        name: 'Abu Dhabi',
        id: 'AUH',
        origin: [],
        destination: ['AMS'],
        city: 'Abu Dhabi',
      },
      {
        name: 'Bahrain',
        id: 'BAH',
        origin: [],
        destination: ['AMS'],
        city: 'Bahrain',
      },
      {
        name: 'Barcelona',
        id: 'BCN',
        origin: ['BOD', 'CDG', 'LYS', 'MRS'],
        destination: ['AMS', 'BOD', 'CDG', 'LYS', 'MRS'],
        city: 'Barcelona',
      },
      {
        name: 'Bergen',
        id: 'BGO',
        origin: [],
        destination: ['AMS'],
        city: 'Bergen',
      },
      {
        name: 'Birmingham',
        id: 'BHX',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Birmingham',
      },
      {
        name: 'Beijing',
        id: 'BJS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Beijing',
      },
      {
        name: 'Bangkok',
        id: 'BKK',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bangkok',
      },
      {
        name: 'Billund',
        id: 'BLL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Billund',
      },
      {
        name: 'Bologna',
        id: 'BLQ',
        origin: ['CDG', 'LYS'],
        destination: ['AMS', 'CDG', 'LYS'],
        city: 'Bologna',
      },
      {
        name: 'Bordeaux',
        id: 'BOD',
        origin: ['BCN', 'BRU', 'CDG', 'FCO', 'LIL', 'MRS', 'NCE', 'NTE', 'ORY', 'SXB'],
        destination: ['AMS', 'BCN', 'BRU', 'CDG', 'FCO', 'LIL', 'MRS', 'NCE', 'NTE', 'ORY', 'SXB'],
        city: 'Bordeaux',
      },
      {
        name: 'Bonaire',
        id: 'BON',
        origin: [],
        destination: ['AMS'],
        city: 'Bonaire',
      },
      {
        name: 'Bremen',
        id: 'BRE',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bremen',
      },
      {
        name: 'Bristol',
        id: 'BRS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bristol',
      },
      {
        name: 'Budapest',
        id: 'BUD',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Budapest',
      },
      {
        name: 'Buenos Aires',
        id: 'BUE',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Buenos Aires',
      },
      {
        name: 'Cairo',
        id: 'CAI',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Cairo',
      },
      {
        name: 'Clermont-Ferrand',
        id: 'CFE',
        origin: ['CDG', 'LYS', 'NCE', 'ORY'],
        destination: ['AMS', 'CDG', 'LYS', 'NCE', 'ORY'],
        city: 'Clermont-Ferrand',
      },
      {
        name: 'Cologne',
        id: 'CGN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Cologne',
      },
      {
        name: 'Copenhagen',
        id: 'CPH',
        origin: ['CDG', 'MRS'],
        destination: ['AMS', 'CDG', 'MRS'],
        city: 'Copenhagen',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: [],
        destination: ['AMS'],
        city: 'Cape Town',
      },
      {
        name: 'Chengdu',
        id: 'CTU',
        origin: [],
        destination: ['AMS'],
        city: 'Chengdu',
      },
      {
        name: 'Curacao',
        id: 'CUR',
        origin: [],
        destination: ['AMS'],
        city: 'Curacao',
      },
      {
        name: 'Cardiff',
        id: 'CWL',
        origin: [],
        destination: ['AMS'],
        city: 'Cardiff',
      },
      {
        name: 'Dar Es Salaam',
        id: 'DAR',
        origin: [],
        destination: ['AMS'],
        city: 'Dar Es Salaam',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Delhi',
      },
      {
        name: 'Dallas/Ft. Worth',
        id: 'DFW',
        origin: [],
        destination: ['AMS'],
        city: 'Dallas/Ft. Worth',
      },
      {
        name: 'Dammam',
        id: 'DMM',
        origin: [],
        destination: ['AMS'],
        city: 'Dammam',
      },
      {
        name: 'Doha',
        id: 'DOH',
        origin: [],
        destination: ['AMS'],
        city: 'Doha',
      },
      {
        name: 'Bali Island, Denpasar',
        id: 'DPS',
        origin: [],
        destination: ['AMS'],
        city: 'Bali Island, Denpasar',
      },
      {
        name: 'Dusseldorf',
        id: 'DUS',
        origin: ['CDG', 'LYS', 'NTE'],
        destination: ['AMS', 'CDG', 'LYS', 'NTE'],
        city: 'Dusseldorf',
      },
      {
        name: 'Dubai',
        id: 'DXB',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Dubai',
      },
      {
        name: 'Entebbe',
        id: 'EBB',
        origin: [],
        destination: ['AMS'],
        city: 'Entebbe',
      },
      {
        name: 'Edinburgh',
        id: 'EDI',
        origin: ['CDG', 'LCY'],
        destination: ['AMS', 'CDG', 'LCY'],
        city: 'Edinburgh',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['BOD', 'CDG', 'LYS', 'MRS', 'TLS'],
        destination: ['AMS', 'BOD', 'CDG', 'LYS', 'MRS', 'TLS'],
        city: 'Rome',
      },
      {
        name: 'Frankfurt',
        id: 'FRA',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Frankfurt',
      },
      {
        name: 'Glasgow',
        id: 'GLA',
        origin: [],
        destination: ['AMS'],
        city: 'Glasgow',
      },
      {
        name: 'Gothenburg',
        id: 'GOT',
        origin: [],
        destination: ['AMS'],
        city: 'Gothenburg',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['BIQ', 'CDG'],
        destination: ['AMS', 'BIQ', 'CDG'],
        city: 'Geneva',
      },
      {
        name: 'Guayaquil',
        id: 'GYE',
        origin: [],
        destination: ['AMS'],
        city: 'Guayaquil',
      },
      {
        name: 'Hanover',
        id: 'HAJ',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Hanover',
      },
      {
        name: 'Hamburg',
        id: 'HAM',
        origin: ['CDG', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'TLS'],
        city: 'Hamburg',
      },
      {
        name: 'Havana',
        id: 'HAV',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Havana',
      },
      {
        name: 'Helsinki-Malmi',
        id: 'HEM',
        origin: [],
        destination: ['AMS'],
        city: 'Helsinki-Malmi',
      },
      {
        name: 'Hangzhou',
        id: 'HGH',
        origin: [],
        destination: ['AMS'],
        city: 'Hangzhou',
      },
      {
        name: 'Hong Kong',
        id: 'HKG',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Hong Kong',
      },
      {
        name: 'Humberside',
        id: 'HUY',
        origin: [],
        destination: ['AMS'],
        city: 'Humberside',
      },
      {
        name: 'Houston',
        id: 'IAH',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Houston',
      },
      {
        name: 'Istanbul',
        id: 'IST',
        origin: ['CDG', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'TLS'],
        city: 'Istanbul',
      },
      {
        name: 'Jakarta',
        id: 'JKT',
        origin: [],
        destination: ['AMS'],
        city: 'Jakarta',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Johannesburg',
      },
      {
        name: 'Kilimanjaro',
        id: 'JRO',
        origin: [],
        destination: ['AMS'],
        city: 'Kilimanjaro',
      },
      {
        name: 'Boryspil',
        id: 'KBP',
        origin: ['CDG', 'KBP'],
        destination: ['AMS', 'CDG', 'KBP'],
        city: 'Boryspil',
      },
      {
        name: 'Kigali',
        id: 'KGL',
        origin: [],
        destination: ['AMS'],
        city: 'Kigali',
      },
      {
        name: 'Kristiansand',
        id: 'KRS',
        origin: [],
        destination: ['AMS'],
        city: 'Kristiansand',
      },
      {
        name: 'Khartoum',
        id: 'KRT',
        origin: [],
        destination: ['AMS'],
        city: 'Khartoum',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: [],
        destination: ['AMS'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'Kuwait',
        id: 'KWI',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Kuwait',
      },
      {
        name: 'Luanda',
        id: 'LAD',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Luanda',
      },
      {
        name: 'Leeds',
        id: 'LBA',
        origin: [],
        destination: ['AMS'],
        city: 'Leeds',
      },
      {
        name: 'London City',
        id: 'LCY',
        origin: ['ANR', 'BES', 'BVE', 'CDG', 'DND', 'DOL', 'DUB', 'EDI', 'EIN', 'FLR', 'LUX', 'NTE', 'ORY', 'PUF', 'RTM', 'TLN'],
        destination: ['AMS', 'ANR', 'BES', 'BVE', 'CDG', 'DND', 'DOL', 'DUB', 'EDI', 'EIN', 'FLR', 'LUX', 'NTE', 'ORY', 'PUF', 'RTM', 'TLN'],
        city: 'London City',
      },
      {
        name: 'St. Petersburg',
        id: 'LED',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'St. Petersburg',
      },
      {
        name: 'London',
        id: 'LHR',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'London',
      },
      {
        name: 'Lima',
        id: 'LIM',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Lima',
      },
      {
        name: 'Milan Linate',
        id: 'LIN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Milan Linate',
      },
      {
        name: 'Lisbon',
        id: 'LIS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Lisbon',
      },
      {
        name: 'Linköping',
        id: 'LPI',
        origin: [],
        destination: ['AMS'],
        city: 'Linköping',
      },
      {
        name: 'Lusaka',
        id: 'LUN',
        origin: [],
        destination: ['AMS'],
        city: 'Lusaka',
      },
      {
        name: 'Luxembourg',
        id: 'LUX',
        origin: ['LCY'],
        destination: ['AMS', 'LCY'],
        city: 'Luxembourg',
      },
      {
        name: 'Lyon',
        id: 'LYS',
        origin: [
          'BCN',
          'BES',
          'BIQ',
          'BLQ',
          'BRU',
          'BSL',
          'CDG',
          'CFE',
          'CFR',
          'DUS',
          'ETZ',
          'FCO',
          'FLR',
          'LIL',
          'LRT',
          'MPL',
          'MRS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'PUF',
          'RNS',
          'STR',
          'SXB',
          'VCE',
        ],
        destination: [
          'AMS',
          'BCN',
          'BES',
          'BIQ',
          'BLQ',
          'BRU',
          'BSL',
          'CDG',
          'CFE',
          'CFR',
          'DUS',
          'ETZ',
          'FCO',
          'FLR',
          'LIL',
          'LRT',
          'MPL',
          'MRS',
          'MXP',
          'NTE',
          'ORY',
          'PRG',
          'PUF',
          'RNS',
          'STR',
          'SXB',
          'VCE',
        ],
        city: 'Lyon',
      },
      {
        name: 'Madrid',
        id: 'MAD',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Madrid',
      },
      {
        name: 'Manchester',
        id: 'MAN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Manchester',
      },
      {
        name: 'Muscat',
        id: 'MCT',
        origin: [],
        destination: ['AMS'],
        city: 'Muscat',
      },
      {
        name: 'Mexico City',
        id: 'MEX',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Mexico City',
      },
      {
        name: 'Mulhouse',
        id: 'MLH',
        origin: ['CDG', 'ORY'],
        destination: ['AMS', 'CDG', 'ORY'],
        city: 'Mulhouse',
      },
      {
        name: 'Manila',
        id: 'MNL',
        origin: [],
        destination: ['AMS'],
        city: 'Manila',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Mauritius',
      },
      {
        name: 'Munich',
        id: 'MUC',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Munich',
      },
      {
        name: 'Nairobi',
        id: 'NBO',
        origin: [],
        destination: ['AMS'],
        city: 'Nairobi',
      },
      {
        name: 'Nice',
        id: 'NCE',
        origin: ['ATH', 'BES', 'BIQ', 'BOD', 'CDG', 'CFE', 'ETZ', 'LIL', 'NAP', 'NTE', 'ORY', 'RNS', 'TLS', 'TLV', 'VCE'],
        destination: ['AMS', 'ATH', 'BES', 'BIQ', 'BOD', 'CDG', 'CFE', 'ETZ', 'LIL', 'NAP', 'NTE', 'ORY', 'RNS', 'TLS', 'TLV', 'VCE'],
        city: 'Nice',
      },
      {
        name: 'Newcastle',
        id: 'NCL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Newcastle',
      },
      {
        name: 'Nantes',
        id: 'NTE',
        origin: ['BOD', 'BRU', 'CDG', 'DUS', 'LCY', 'LIL', 'LYS', 'MPL', 'MRS', 'MXP', 'NCE', 'ORY', 'SXB', 'TLS'],
        destination: ['AMS', 'BOD', 'BRU', 'CDG', 'DUS', 'LCY', 'LIL', 'LYS', 'MPL', 'MRS', 'MXP', 'NCE', 'ORY', 'SXB', 'TLS'],
        city: 'Nantes',
      },
      {
        name: 'Nuremberg',
        id: 'NUE',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Nuremberg',
      },
      {
        name: 'Norwich',
        id: 'NWI',
        origin: [],
        destination: ['AMS'],
        city: 'Norwich',
      },
      {
        name: 'New York',
        id: 'NYC',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'New York',
      },
      {
        name: 'Chicago',
        id: 'ORD',
        origin: [],
        destination: ['AMS'],
        city: 'Chicago',
      },
      {
        name: 'Osaka',
        id: 'OSA',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Osaka',
      },
      {
        name: 'Oslo',
        id: 'OSL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Oslo',
      },
      {
        name: 'Bucharest',
        id: 'OTP',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Bucharest',
      },
      {
        name: 'Paramaribo',
        id: 'PBM',
        origin: [],
        destination: ['AMS'],
        city: 'Paramaribo',
      },
      {
        name: 'Prague',
        id: 'PRG',
        origin: ['CDG', 'LYS', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'LYS', 'MRS', 'TLS'],
        city: 'Prague',
      },
      {
        name: 'Panama City',
        id: 'PTY',
        origin: [],
        destination: ['AMS'],
        city: 'Panama City',
      },
      {
        name: 'Rio de Janeiro',
        id: 'RIO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Rio de Janeiro',
      },
      {
        name: 'Sao Paulo',
        id: 'SAO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Sao Paulo',
      },
      {
        name: 'Seoul',
        id: 'SEL',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Seoul',
      },
      {
        name: 'San Francisco',
        id: 'SFO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'San Francisco',
      },
      {
        name: 'Shanghai',
        id: 'SHA',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Shanghai',
      },
      {
        name: 'Singapore',
        id: 'SIN',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Singapore',
      },
      {
        name: 'Stuttgart',
        id: 'STR',
        origin: ['CDG', 'LYS'],
        destination: ['AMS', 'CDG', 'LYS'],
        city: 'Stuttgart',
      },
      {
        name: 'Stavanger',
        id: 'SVG',
        origin: [],
        destination: ['AMS'],
        city: 'Stavanger',
      },
      {
        name: 'Moscow-Sheremetyevo',
        id: 'SVO',
        origin: ['CDG', 'MRS'],
        destination: ['AMS', 'CDG', 'MRS'],
        city: 'Moscow-Sheremetyevo',
      },
      {
        name: 'Strasbourg',
        id: 'SXB',
        origin: ['BOD', 'CDG', 'LIL', 'LYS', 'MRS', 'NTE', 'ORY', 'TLS'],
        destination: ['AMS', 'BOD', 'CDG', 'LIL', 'LYS', 'MRS', 'NTE', 'ORY', 'TLS'],
        city: 'Strasbourg',
      },
      {
        name: 'Tehran',
        id: 'THR',
        origin: [],
        destination: ['AMS'],
        city: 'Tehran',
      },
      {
        name: 'Tripoli',
        id: 'TIP',
        origin: [],
        destination: ['AMS'],
        city: 'Tripoli',
      },
      {
        name: 'Tel Aviv Yafo, Sde Dov',
        id: 'TLV',
        origin: ['CDG', 'MRS', 'NCE'],
        destination: ['AMS', 'CDG', 'MRS', 'NCE'],
        city: 'Tel Aviv Yafo, Sde Dov',
      },
      {
        name: 'Taipei',
        id: 'TPE',
        origin: [],
        destination: ['AMS'],
        city: 'Taipei',
      },
      {
        name: 'Trondheim',
        id: 'TRD',
        origin: [],
        destination: ['AMS'],
        city: 'Trondheim',
      },
      {
        name: 'Berlin',
        id: 'TXL',
        origin: ['CDG', 'MRS', 'TLS'],
        destination: ['AMS', 'CDG', 'MRS', 'TLS'],
        city: 'Berlin',
      },
      {
        name: 'Tokyo',
        id: 'TYO',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Tokyo',
      },
      {
        name: 'Quito',
        id: 'UIO',
        origin: [],
        destination: ['AMS'],
        city: 'Quito',
      },
      {
        name: 'Venice',
        id: 'VCE',
        origin: ['CDG', 'LYS', 'MRS', 'NCE', 'TLS'],
        destination: ['AMS', 'CDG', 'LYS', 'MRS', 'NCE', 'TLS'],
        city: 'Venice',
      },
      {
        name: 'Vienna',
        id: 'VIE',
        origin: ['CDG', 'TLS'],
        destination: ['AMS', 'CDG', 'TLS'],
        city: 'Vienna',
      },
      {
        name: 'Washington',
        id: 'WAS',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Washington',
      },
      {
        name: 'Warsaw',
        id: 'WAW',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Warsaw',
      },
      {
        name: 'Xiamen',
        id: 'XMN',
        origin: [],
        destination: ['AMS'],
        city: 'Xiamen',
      },
      {
        name: 'Montreal',
        id: 'YUL',
        origin: [],
        destination: ['AMS'],
        city: 'Montreal',
      },
      {
        name: 'Vancouver',
        id: 'YVR',
        origin: [],
        destination: ['AMS'],
        city: 'Vancouver',
      },
      {
        name: 'Calgary',
        id: 'YYC',
        origin: [],
        destination: ['AMS'],
        city: 'Calgary',
      },
      {
        name: 'Toronto',
        id: 'YYZ',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Toronto',
      },
      {
        name: 'Zurich',
        id: 'ZRH',
        origin: ['CDG'],
        destination: ['AMS', 'CDG'],
        city: 'Zurich',
      },
      {
        name: 'Antwerp',
        id: 'ANR',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Antwerp',
      },
      {
        name: 'Brest',
        id: 'BES',
        origin: ['CDG', 'LCY', 'LYS', 'MRS', 'NCE', 'ORY'],
        destination: ['CDG', 'LCY', 'LYS', 'MRS', 'NCE', 'ORY'],
        city: 'Brest',
      },
      {
        name: 'Beirut',
        id: 'BEY',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Beirut',
      },
      {
        name: 'Bangui',
        id: 'BGF',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bangui',
      },
      {
        name: 'Bastia',
        id: 'BIA',
        origin: ['CDG', 'ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Bastia',
      },
      {
        name: 'Bilbao',
        id: 'BIO',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bilbao',
      },
      {
        name: 'Biarritz',
        id: 'BIQ',
        origin: ['CDG', 'GVA', 'LYS', 'MRS', 'NCE', 'ORY'],
        destination: ['CDG', 'GVA', 'LYS', 'MRS', 'NCE', 'ORY'],
        city: 'Biarritz',
      },
      {
        name: 'Bamako',
        id: 'BKO',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bamako',
      },
      {
        name: 'Bangalore',
        id: 'BLR',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bangalore',
      },
      {
        name: 'Brussels',
        id: 'BRU',
        origin: ['LYS', 'NTE'],
        destination: ['BOD', 'LYS', 'NTE'],
        city: 'Brussels',
      },
      {
        name: 'Lille',
        id: 'LIL',
        origin: ['LYS', 'MRS', 'NCE', 'NTE', 'SXB', 'TLS'],
        destination: ['BOD', 'LYS', 'MRS', 'NCE', 'NTE', 'SXB', 'TLS'],
        city: 'Lille',
      },
      {
        name: 'Bogota',
        id: 'BOG',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bogota',
      },
      {
        name: 'Bombay',
        id: 'BOM',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bombay',
      },
      {
        name: 'Boston',
        id: 'BOS',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Boston',
      },
      {
        name: 'Bern',
        id: 'BRN',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Bern',
      },
      {
        name: 'Basle',
        id: 'BSL',
        origin: ['LYS'],
        destination: ['LYS'],
        city: 'Basle',
      },
      {
        name: 'Brive–Souillac',
        id: 'BVE',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Brive–Souillac',
      },
      {
        name: 'Brazzaville',
        id: 'BZV',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Brazzaville',
      },
      {
        name: 'Guangzhou',
        id: 'CAN',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Guangzhou',
      },
      {
        name: 'Cayenne',
        id: 'CAY',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Cayenne',
      },
      {
        name: 'Caracas',
        id: 'CCS',
        origin: ['CDG'],
        destination: ['CDG'],
        city: 'Caracas',
      },
      {
        name: 'Conakry',
        id: 'CKY',
        origin: [],
        destination: ['CDG'],
        city: 'Conakry',
      },
      {
        name: 'Casablanca',
        id: 'CMN',
        origin: ['MRS', 'TLS'],
        destination: ['CDG', 'MRS', 'TLS'],
        city: 'Casablanca',
      },
      {
        name: 'Cotonou',
        id: 'COO',
        origin: [],
        destination: ['CDG'],
        city: 'Cotonou',
      },
      {
        name: 'Damascus',
        id: 'DAM',
        origin: [],
        destination: ['CDG'],
        city: 'Damascus',
      },
      {
        name: 'Dakar',
        id: 'DKR',
        origin: [],
        destination: ['CDG'],
        city: 'Dakar',
      },
      {
        name: 'Douala',
        id: 'DLA',
        origin: [],
        destination: ['CDG'],
        city: 'Douala',
      },
      {
        name: 'Detroit',
        id: 'DTT',
        origin: [],
        destination: ['CDG'],
        city: 'Detroit',
      },
      {
        name: 'Dublin',
        id: 'DUB',
        origin: ['LCY'],
        destination: ['CDG', 'LCY'],
        city: 'Dublin',
      },
      {
        name: 'Ft de France',
        id: 'FDF',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Ft de France',
      },
      {
        name: 'Kinshasa',
        id: 'FIH',
        origin: [],
        destination: ['CDG'],
        city: 'Kinshasa',
      },
      {
        name: 'Florence',
        id: 'FLR',
        origin: ['LCY', 'LYS'],
        destination: ['CDG', 'LCY', 'LYS'],
        city: 'Florence',
      },
      {
        name: 'Freetown',
        id: 'FNA',
        origin: [],
        destination: ['CDG'],
        city: 'Freetown',
      },
      {
        name: 'Figari–Sud Corse',
        id: 'FSC',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Figari–Sud Corse',
      },
      {
        name: 'Genoa',
        id: 'GOA',
        origin: [],
        destination: ['CDG'],
        city: 'Genoa',
      },
      {
        name: 'Jeddah',
        id: 'JED',
        origin: [],
        destination: ['CDG'],
        city: 'Jeddah',
      },
      {
        name: 'Djibouti',
        id: 'JIB',
        origin: [],
        destination: ['CDG'],
        city: 'Djibouti',
      },
      {
        name: 'Los Angeles',
        id: 'LAX',
        origin: [],
        destination: ['CDG'],
        city: 'Los Angeles',
      },
      {
        name: 'Libreville',
        id: 'LBV',
        origin: [],
        destination: ['CDG'],
        city: 'Libreville',
      },
      {
        name: 'Lourdes/Tarbes',
        id: 'LDE',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Lourdes/Tarbes',
      },
      {
        name: 'Lome',
        id: 'LFW',
        origin: [],
        destination: ['CDG'],
        city: 'Lome',
      },
      {
        name: 'Ljubljana',
        id: 'LJU',
        origin: [],
        destination: ['CDG'],
        city: 'Ljubljana',
      },
      {
        name: 'Lagos',
        id: 'LOS',
        origin: [],
        destination: ['CDG'],
        city: 'Lagos',
      },
      {
        name: 'Lorient South Brittany',
        id: 'LRT',
        origin: ['LYS', 'ORY'],
        destination: ['CDG', 'LYS', 'ORY'],
        city: 'Lorient South Brittany',
      },
      {
        name: 'Miami',
        id: 'MIA',
        origin: [],
        destination: ['CDG'],
        city: 'Miami',
      },
      {
        name: 'Monrovia',
        id: 'MLW',
        origin: [],
        destination: ['CDG'],
        city: 'Monrovia',
      },
      {
        name: 'Montpellier',
        id: 'MPL',
        origin: ['LYS', 'NTE', 'ORY'],
        destination: ['CDG', 'LYS', 'NTE', 'ORY'],
        city: 'Montpellier',
      },
      {
        name: 'Naples',
        id: 'NAP',
        origin: ['NCE', 'TLS'],
        destination: ['CDG', 'NCE', 'TLS'],
        city: 'Naples',
      },
      {
        name: 'N’djamena',
        id: 'NDJ',
        origin: [],
        destination: ['CDG'],
        city: 'N’djamena',
      },
      {
        name: 'Niamey',
        id: 'NIM',
        origin: [],
        destination: ['CDG'],
        city: 'Niamey',
      },
      {
        name: 'Nouakchott',
        id: 'NKC',
        origin: [],
        destination: ['CDG'],
        city: 'Nouakchott',
      },
      {
        name: 'Noumea',
        id: 'NOU',
        origin: [],
        destination: ['CDG'],
        city: 'Noumea',
      },
      {
        name: 'Orlando',
        id: 'ORL',
        origin: [],
        destination: ['CDG'],
        city: 'Orlando',
      },
      {
        name: 'Ouagadougou',
        id: 'OUA',
        origin: [],
        destination: ['CDG'],
        city: 'Ouagadougou',
      },
      {
        name: 'Perpignan',
        id: 'PGF',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Perpignan',
      },
      {
        name: 'Port Harcourt',
        id: 'PHC',
        origin: [],
        destination: ['CDG'],
        city: 'Port Harcourt',
      },
      {
        name: 'Phnom Penh',
        id: 'PNH',
        origin: [],
        destination: ['CDG'],
        city: 'Phnom Penh',
      },
      {
        name: 'Pointe Noire',
        id: 'PNR',
        origin: [],
        destination: ['CDG'],
        city: 'Pointe Noire',
      },
      {
        name: 'Papeete',
        id: 'PPT',
        origin: [],
        destination: ['CDG'],
        city: 'Papeete',
      },
      {
        name: 'Pisa',
        id: 'PSA',
        origin: [],
        destination: ['CDG'],
        city: 'Pisa',
      },
      {
        name: 'Point à Pitre',
        id: 'PTP',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Point à Pitre',
      },
      {
        name: 'Pau',
        id: 'PUF',
        origin: ['LCY', 'LYS', 'ORY'],
        destination: ['CDG', 'LCY', 'LYS', 'ORY'],
        city: 'Pau',
      },
      {
        name: 'Punta Cana',
        id: 'PUJ',
        origin: [],
        destination: ['CDG'],
        city: 'Punta Cana',
      },
      {
        name: 'Rabat',
        id: 'RBA',
        origin: [],
        destination: ['CDG'],
        city: 'Rabat',
      },
      {
        name: 'Rodez–Aveyron',
        id: 'RDZ',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Rodez–Aveyron',
      },
      {
        name: 'Rennes',
        id: 'RNS',
        origin: ['LYS', 'MRS', 'NCE', 'TLS'],
        destination: ['CDG', 'LYS', 'MRS', 'NCE', 'TLS'],
        city: 'Rennes',
      },
      {
        name: 'Riyadh',
        id: 'RUH',
        origin: [],
        destination: ['CDG'],
        city: 'Riyadh',
      },
      {
        name: 'St. Denis de La Reunion',
        id: 'RUN',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'St. Denis de La Reunion',
      },
      {
        name: 'Santiago',
        id: 'SCL',
        origin: [],
        destination: ['CDG'],
        city: 'Santiago',
      },
      {
        name: 'Santo Domingo',
        id: 'SDQ',
        origin: [],
        destination: ['CDG'],
        city: 'Santo Domingo',
      },
      {
        name: 'Ho Chi Minh City',
        id: 'SGN',
        origin: [],
        destination: ['CDG'],
        city: 'Ho Chi Minh City',
      },
      {
        name: 'Sofia',
        id: 'SOF',
        origin: [],
        destination: ['CDG'],
        city: 'Sofia',
      },
      {
        name: 'Malabo',
        id: 'SSG',
        origin: [],
        destination: ['CDG'],
        city: 'Malabo',
      },
      {
        name: 'Toulon/Hyeres',
        id: 'TLN',
        origin: ['LCY', 'ORY'],
        destination: ['CDG', 'LCY', 'ORY'],
        city: 'Toulon/Hyeres',
      },
      {
        name: 'Antananarivo',
        id: 'TNR',
        origin: [],
        destination: ['CDG'],
        city: 'Antananarivo',
      },
      {
        name: 'Turin',
        id: 'TRN',
        origin: [],
        destination: ['CDG'],
        city: 'Turin',
      },
      {
        name: 'Tunis',
        id: 'TUN',
        origin: ['MRS', 'TLS'],
        destination: ['CDG', 'MRS', 'TLS'],
        city: 'Tunis',
      },
      {
        name: 'Quimper',
        id: 'UIP',
        origin: ['ORY'],
        destination: ['CDG', 'ORY'],
        city: 'Quimper',
      },
      {
        name: 'Verona',
        id: 'VRN',
        origin: [],
        destination: ['CDG'],
        city: 'Verona',
      },
      {
        name: 'Yaoundé',
        id: 'YAO',
        origin: [],
        destination: ['CDG'],
        city: 'Yaoundé',
      },
      {
        name: 'Zagreb',
        id: 'ZAG',
        origin: [],
        destination: ['CDG'],
        city: 'Zagreb',
      },
      {
        name: 'Caen',
        id: 'CFR',
        origin: ['LYS'],
        destination: ['LYS'],
        city: 'Caen',
      },
      {
        name: 'Calvi',
        id: 'CLY',
        origin: ['ORY'],
        destination: ['ORY'],
        city: 'Calvi',
      },
      {
        name: 'Dundee',
        id: 'DND',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Dundee',
      },
      {
        name: 'Deauville',
        id: 'DOL',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Deauville',
      },
      {
        name: 'Eindhoven',
        id: 'EIN',
        origin: ['LCY'],
        destination: ['LCY'],
        city: 'Eindhoven',
      },
      {
        name: 'Metz–Nancy–Lorraine',
        id: 'ETZ',
        origin: ['LYS', 'NCE'],
        destination: ['LYS', 'NCE'],
        city: 'Metz–Nancy–Lorraine',
      },
      {
        name: 'Rotterdam',
        id: 'RTM',
        origin: [],
        destination: ['LCY'],
        city: 'Rotterdam',
      },
      {
        name: 'Milan Malpensa',
        id: 'MXP',
        origin: ['MRS', 'NTE'],
        destination: ['LYS', 'MRS', 'NTE'],
        city: 'Milan Malpensa',
      },
      {
        name: 'Malta',
        id: 'MLA',
        origin: ['TLS'],
        destination: ['TLS'],
        city: 'Malta',
      },
      {
        name: 'Marrakech',
        id: 'RAK',
        origin: ['TLS'],
        destination: ['MRS', 'TLS'],
        city: 'Marrakech',
      },
      {
        name: 'Seville',
        id: 'SVQ',
        origin: ['TLS'],
        destination: ['TLS'],
        city: 'Seville',
      },
    ],
    AC: [
      {
        name: 'Auckland',
        id: 'AKL',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Auckland',
      },
      {
        name: 'Vancouver',
        id: 'YVR',
        origin: [
          'ANC',
          'BNE',
          'BOS',
          'CDG',
          'CUN',
          'DEL',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FRA',
          'HKG',
          'HND',
          'HNL',
          'ICN',
          'KIX',
          'KOA',
          'LAS',
          'LAX',
          'LHR',
          'LIH',
          'MEL',
          'MEX',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PEK',
          'PHX',
          'PSP',
          'PVG',
          'PVR',
          'SAN',
          'SEA',
          'SFO',
          'SJC',
          'SJD',
          'SMF',
          'SYD',
          'TPE',
          'YCD',
          'YCG',
          'YEG',
          'YKA',
          'YLW',
          'YOW',
          'YPR',
          'YQQ',
          'YQR',
          'YUL',
          'YWG',
          'YXC',
          'YXE',
          'YXJ',
          'YXS',
          'YXT',
          'YXY',
          'YYC',
          'YYD',
          'YYF',
          'YYJ',
          'YYZ',
          'YZF',
          'YZP',
          'ZIH',
          'ZRH',
        ],
        destination: [
          'AKL',
          'ANC',
          'BNE',
          'BOS',
          'CDG',
          'CUN',
          'DEL',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FRA',
          'HKG',
          'HND',
          'HNL',
          'ICN',
          'KIX',
          'KOA',
          'LAS',
          'LAX',
          'LHR',
          'LIH',
          'MEL',
          'MEX',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PEK',
          'PHX',
          'PSP',
          'PVG',
          'PVR',
          'SAN',
          'SEA',
          'SFO',
          'SJC',
          'SJD',
          'SMF',
          'SYD',
          'TPE',
          'YCD',
          'YCG',
          'YEG',
          'YKA',
          'YLW',
          'YOW',
          'YPR',
          'YQQ',
          'YQR',
          'YUL',
          'YWG',
          'YXC',
          'YXE',
          'YXJ',
          'YXS',
          'YXT',
          'YXY',
          'YYC',
          'YYD',
          'YYF',
          'YYJ',
          'YYZ',
          'YZF',
          'YZP',
          'ZIH',
          'ZRH',
        ],
        city: 'Vancouver',
      },
      {
        name: 'Algiers',
        id: 'ALG',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Algiers',
      },
      {
        name: 'Montreal',
        id: 'YUL',
        origin: [
          'ATH',
          'AZS',
          'BCN',
          'BDL',
          'BGI',
          'BOD',
          'BOG',
          'BOS',
          'BRU',
          'BWI',
          'CCC',
          'CDG',
          'CMN',
          'CPH',
          'CUN',
          'CUR',
          'CZM',
          'DCA',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FCO',
          'FDF',
          'FLL',
          'FRA',
          'GRU',
          'GVA',
          'HAV',
          'HOG',
          'IAD',
          'IAH',
          'KEF',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'LYS',
          'MBJ',
          'MCO',
          'MEX',
          'MIA',
          'MRS',
          'NAS',
          'NCE',
          'NRT',
          'ORD',
          'OTP',
          'PAP',
          'PBI',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'PTP',
          'PUJ',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SFO',
          'SJO',
          'SJU',
          'SNU',
          'TLV',
          'TPA',
          'UVF',
          'VCE',
          'VRA',
          'YBC',
          'YBG',
          'YDF',
          'YEG',
          'YFC',
          'YGR',
          'YHM',
          'YHZ',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQY',
          'YSJ',
          'YTZ',
          'YUY',
          'YVO',
          'YVR',
          'YWG',
          'YWK',
          'YXU',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YYY',
          'YYZ',
          'YZV',
          'ZBF',
          'ZIH',
          'ZSA',
        ],
        destination: [
          'ALG',
          'ATH',
          'AZS',
          'BCN',
          'BDL',
          'BGI',
          'BOD',
          'BOG',
          'BOS',
          'BRU',
          'BWI',
          'CCC',
          'CDG',
          'CMN',
          'CPH',
          'CUN',
          'CUR',
          'CZM',
          'DCA',
          'DEN',
          'DFW',
          'DUB',
          'EWR',
          'FCO',
          'FDF',
          'FLL',
          'FRA',
          'GRU',
          'GVA',
          'HAV',
          'HOG',
          'IAD',
          'IAH',
          'KEF',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'LYS',
          'MBJ',
          'MCO',
          'MEX',
          'MIA',
          'MRS',
          'NAS',
          'NCE',
          'NRT',
          'ORD',
          'OTP',
          'PAP',
          'PBI',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'PTP',
          'PUJ',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SFO',
          'SJO',
          'SJU',
          'SNU',
          'TLV',
          'TPA',
          'UVF',
          'VCE',
          'VRA',
          'YBC',
          'YBG',
          'YDF',
          'YEG',
          'YFC',
          'YGR',
          'YHM',
          'YHZ',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQY',
          'YSJ',
          'YTZ',
          'YUY',
          'YVO',
          'YVR',
          'YWG',
          'YWK',
          'YXU',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YYY',
          'YYZ',
          'YZV',
          'ZBF',
          'ZIH',
          'ZSA',
        ],
        city: 'Montreal',
      },
      {
        name: 'Amsterdam',
        id: 'AMS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Amsterdam',
      },
      {
        name: 'Toronto',
        id: 'YYZ',
        origin: [
          'ANU',
          'ATH',
          'ATL',
          'AUA',
          'AUS',
          'AZS',
          'BCN',
          'BDA',
          'BDL',
          'BGI',
          'BNA',
          'BOG',
          'BOM',
          'BOS',
          'BUD',
          'BWI',
          'BZE',
          'CCC',
          'CDG',
          'CLE',
          'CLT',
          'CMH',
          'CPH',
          'CTG',
          'CUN',
          'CUR',
          'CVG',
          'CZM',
          'DCA',
          'DEL',
          'DEN',
          'DFW',
          'DOH',
          'DTW',
          'DUB',
          'DXB',
          'EDI',
          'EWR',
          'EZE',
          'FCO',
          'FLL',
          'FRA',
          'GCM',
          'GGT',
          'GLA',
          'GND',
          'GRU',
          'GVA',
          'HAV',
          'HKG',
          'HND',
          'HNL',
          'HOG',
          'HUX',
          'IAD',
          'IAH',
          'ICN',
          'IND',
          'JAX',
          'KEF',
          'KIN',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'MAD',
          'MAN',
          'MBJ',
          'MCI',
          'MCO',
          'MEM',
          'MEX',
          'MIA',
          'MKE',
          'MSP',
          'MSY',
          'MUC',
          'MXP',
          'NAS',
          'NRT',
          'OMA',
          'OPO',
          'ORD',
          'OTP',
          'PBI',
          'PDX',
          'PEK',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'POS',
          'PRG',
          'PSP',
          'PTY',
          'PUJ',
          'PVD',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SAN',
          'SAT',
          'SAV',
          'SCL',
          'SEA',
          'SFO',
          'SJD',
          'SJO',
          'SJU',
          'SKB',
          'SNU',
          'SRQ',
          'STL',
          'SVD',
          'SXM',
          'SYD',
          'TLV',
          'TPA',
          'TXL',
          'UIO',
          'UVF',
          'VCE',
          'VIE',
          'VRA',
          'WAW',
          'YAM',
          'YCD',
          'YDF',
          'YEG',
          'YFC',
          'YGK',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQR',
          'YQT',
          'YQX',
          'YQY',
          'YSB',
          'YSJ',
          'YTM',
          'YTS',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYB',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YZR',
          'ZAG',
          'ZIH',
          'ZRH',
        ],
        destination: [
          'AMS',
          'ANU',
          'ATH',
          'ATL',
          'AUA',
          'AUS',
          'AZS',
          'BCN',
          'BDA',
          'BDL',
          'BGI',
          'BNA',
          'BOG',
          'BOM',
          'BOS',
          'BUD',
          'BWI',
          'BZE',
          'CCC',
          'CDG',
          'CLE',
          'CLT',
          'CMH',
          'CPH',
          'CTG',
          'CUN',
          'CUR',
          'CVG',
          'CZM',
          'DCA',
          'DEL',
          'DEN',
          'DFW',
          'DOH',
          'DTW',
          'DUB',
          'DXB',
          'EDI',
          'EWR',
          'EZE',
          'FCO',
          'FLL',
          'FRA',
          'GCM',
          'GGT',
          'GLA',
          'GND',
          'GRU',
          'GVA',
          'HAV',
          'HKG',
          'HND',
          'HNL',
          'HOG',
          'HUX',
          'IAD',
          'IAH',
          'ICN',
          'IND',
          'JAX',
          'KEF',
          'KIN',
          'LAS',
          'LAX',
          'LGA',
          'LHR',
          'LIM',
          'LIR',
          'LIS',
          'MAD',
          'MAN',
          'MBJ',
          'MCI',
          'MCO',
          'MEM',
          'MEX',
          'MIA',
          'MKE',
          'MSP',
          'MSY',
          'MUC',
          'MXP',
          'NAS',
          'NRT',
          'OMA',
          'OPO',
          'ORD',
          'OTP',
          'PBI',
          'PDX',
          'PEK',
          'PHL',
          'PHX',
          'PIT',
          'PLS',
          'POP',
          'POS',
          'PRG',
          'PSP',
          'PTY',
          'PUJ',
          'PVD',
          'PVG',
          'PVR',
          'RDU',
          'RSW',
          'SAN',
          'SAT',
          'SAV',
          'SCL',
          'SEA',
          'SFO',
          'SJD',
          'SJO',
          'SJU',
          'SKB',
          'SNU',
          'SRQ',
          'STL',
          'SVD',
          'SXM',
          'SYD',
          'TLV',
          'TPA',
          'TXL',
          'UIO',
          'UVF',
          'VCE',
          'VIE',
          'VRA',
          'WAW',
          'YAM',
          'YCD',
          'YDF',
          'YEG',
          'YFC',
          'YGK',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQB',
          'YQG',
          'YQM',
          'YQR',
          'YQT',
          'YQX',
          'YQY',
          'YSB',
          'YSJ',
          'YTM',
          'YTS',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYB',
          'YYC',
          'YYG',
          'YYJ',
          'YYT',
          'YZR',
          'ZAG',
          'ZIH',
          'ZRH',
        ],
        city: 'Toronto',
      },
      {
        name: 'Anchorage',
        id: 'ANC',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Anchorage',
      },
      {
        name: 'Antigua',
        id: 'ANU',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Antigua',
      },
      {
        name: 'Athens',
        id: 'ATH',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Athens',
      },
      {
        name: 'Atlanta',
        id: 'ATL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Atlanta',
      },
      {
        name: 'Aruba',
        id: 'AUA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Aruba',
      },
      {
        name: 'Austin',
        id: 'AUS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Austin',
      },
      {
        name: 'El Catey Intl',
        id: 'AZS',
        origin: ['YHZ', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YUL', 'YYZ'],
        city: 'El Catey Intl',
      },
      {
        name: 'Halifax',
        id: 'YHZ',
        origin: [
          'BOS',
          'CCC',
          'CUN',
          'FLL',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'YDF',
          'YFC',
          'YOW',
          'YQB',
          'YQM',
          'YQX',
          'YQY',
          'YSJ',
          'YUL',
          'YYC',
          'YYG',
          'YYR',
          'YYT',
          'YYZ',
        ],
        destination: [
          'AZS',
          'BOS',
          'CCC',
          'CUN',
          'FLL',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'YDF',
          'YFC',
          'YOW',
          'YQB',
          'YQM',
          'YQX',
          'YQY',
          'YSJ',
          'YUL',
          'YUY',
          'YYC',
          'YYG',
          'YYR',
          'YYT',
          'YYZ',
        ],
        city: 'Halifax',
      },
      {
        name: 'Barcelona',
        id: 'BCN',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Barcelona',
      },
      {
        name: 'Bermuda',
        id: 'BDA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Bermuda',
      },
      {
        name: 'Bradley',
        id: 'BDL',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Bradley',
      },
      {
        name: 'Barbados',
        id: 'BGI',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Barbados',
      },
      {
        name: 'Nashville',
        id: 'BNA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Nashville',
      },
      {
        name: 'Brisbane',
        id: 'BNE',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Brisbane',
      },
      {
        name: 'Bordeaux',
        id: 'BOD',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Bordeaux',
      },
      {
        name: 'Bogota',
        id: 'BOG',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Bogota',
      },
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Mumbai',
      },
      {
        name: 'Boston',
        id: 'BOS',
        origin: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYZ'],
        city: 'Boston',
      },
      {
        name: 'Ottawa',
        id: 'YOW',
        origin: [
          'CUN',
          'DCA',
          'EWR',
          'FLL',
          'FRA',
          'GGT',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'VRA',
          'YEG',
          'YFC',
          'YHZ',
          'YQB',
          'YQM',
          'YQR',
          'YSJ',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYC',
          'YYG',
          'YYT',
          'YYZ',
        ],
        destination: [
          'BOS',
          'CUN',
          'DCA',
          'EWR',
          'FLL',
          'FRA',
          'GGT',
          'LHR',
          'MCO',
          'PUJ',
          'TPA',
          'VRA',
          'YEG',
          'YFC',
          'YHZ',
          'YQB',
          'YQM',
          'YQR',
          'YSJ',
          'YUL',
          'YVR',
          'YWG',
          'YXE',
          'YXU',
          'YYC',
          'YYG',
          'YYT',
          'YYZ',
        ],
        city: 'Ottawa',
      },
      {
        name: 'Brussels',
        id: 'BRU',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Brussels',
      },
      {
        name: 'Budapest',
        id: 'BUD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Budapest',
      },
      {
        name: 'Baltimore',
        id: 'BWI',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Baltimore',
      },
      {
        name: 'Belize',
        id: 'BZE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Belize',
      },
      {
        name: 'Jardines Del Rey',
        id: 'CCC',
        origin: ['YHZ', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YUL', 'YYZ'],
        city: 'Jardines Del Rey',
      },
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Paris',
      },
      {
        name: 'Cleveland',
        id: 'CLE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Cleveland',
      },
      {
        name: 'Charlotte',
        id: 'CLT',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Charlotte',
      },
      {
        name: 'Columbus',
        id: 'CMH',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Columbus',
      },
      {
        name: 'Casablanca',
        id: 'CMN',
        origin: ['YUL', 'YYT'],
        destination: ['YUL', 'YYT'],
        city: 'Casablanca',
      },
      {
        name: 'St. John',
        id: 'YYT',
        origin: ['LHR', 'YDF', 'YHZ', 'YOW', 'YQT', 'YQX', 'YUL', 'YYC', 'YYR', 'YYZ'],
        destination: ['CMN', 'LHR', 'YDF', 'YHZ', 'YOW', 'YQT', 'YQX', 'YUL', 'YYC', 'YYR', 'YYZ'],
        city: 'St. John',
      },
      {
        name: 'Copenhagen',
        id: 'CPH',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Copenhagen',
      },
      {
        name: 'Cartagena',
        id: 'CTG',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Cartagena',
      },
      {
        name: 'Cancun',
        id: 'CUN',
        origin: ['YHZ', 'YOW', 'YQB', 'YUL', 'YVR', 'YWG', 'YYC', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YVR', 'YWG', 'YYC', 'YYZ'],
        city: 'Cancun',
      },
      {
        name: 'Quebec City',
        id: 'YQB',
        origin: ['PUJ', 'YFC', 'YGP', 'YGR', 'YHZ', 'YOW', 'YQM', 'YUL', 'YYG', 'YYY', 'YYZ', 'YZV'],
        destination: ['CUN', 'PUJ', 'YFC', 'YGP', 'YGR', 'YHZ', 'YOW', 'YQM', 'YUL', 'YYG', 'YYY', 'YYZ', 'YZV'],
        city: 'Quebec City',
      },
      {
        name: 'Winnipeg',
        id: 'YWG',
        origin: ['MBJ', 'YEG', 'YOW', 'YQR', 'YQT', 'YUL', 'YVR', 'YXE', 'YYC', 'YYZ'],
        destination: ['CUN', 'MBJ', 'YEG', 'YOW', 'YQR', 'YQT', 'YUL', 'YVR', 'YXE', 'YYC', 'YYZ'],
        city: 'Winnipeg',
      },
      {
        name: 'Calgary',
        id: 'YYC',
        origin: [
          'DEN',
          'EWR',
          'FRA',
          'IAH',
          'LAS',
          'LAX',
          'LHR',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PHX',
          'PSP',
          'PVR',
          'SFO',
          'SJD',
          'YCD',
          'YCG',
          'YEG',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQL',
          'YQR',
          'YQU',
          'YUL',
          'YVR',
          'YWG',
          'YXC',
          'YXE',
          'YXH',
          'YXT',
          'YYJ',
          'YYT',
          'YYZ',
          'YZF',
        ],
        destination: [
          'CUN',
          'DEN',
          'EWR',
          'FRA',
          'IAH',
          'LAS',
          'LAX',
          'LHR',
          'NRT',
          'OGG',
          'ORD',
          'PDX',
          'PHX',
          'PSP',
          'PVR',
          'SFO',
          'SJD',
          'YCD',
          'YCG',
          'YEG',
          'YHZ',
          'YKA',
          'YLW',
          'YMM',
          'YOW',
          'YQL',
          'YQR',
          'YQU',
          'YUL',
          'YVR',
          'YWG',
          'YXC',
          'YXE',
          'YXH',
          'YXT',
          'YYJ',
          'YYT',
          'YYZ',
          'YZF',
        ],
        city: 'Calgary',
      },
      {
        name: 'Curacao',
        id: 'CUR',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Curacao',
      },
      {
        name: 'Cincinnati',
        id: 'CVG',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Cincinnati',
      },
      {
        name: 'Cozumel',
        id: 'CZM',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Cozumel',
      },
      {
        name: 'Washington DC (DCA)',
        id: 'DCA',
        origin: ['YOW', 'YUL', 'YYZ'],
        destination: ['YOW', 'YUL', 'YYZ'],
        city: 'Washington DC (DCA)',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Delhi',
      },
      {
        name: 'Denver',
        id: 'DEN',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Denver',
      },
      {
        name: 'Dallas/Ft. Worth',
        id: 'DFW',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Dallas/Ft. Worth',
      },
      {
        name: 'Doha',
        id: 'DOH',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Doha',
      },
      {
        name: 'Detroit',
        id: 'DTW',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Detroit',
      },
      {
        name: 'Dublin',
        id: 'DUB',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Dublin',
      },
      {
        name: 'Dubai',
        id: 'DXB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Dubai',
      },
      {
        name: 'Edinburgh',
        id: 'EDI',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Edinburgh',
      },
      {
        name: 'Newark',
        id: 'EWR',
        origin: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Newark',
      },
      {
        name: 'Buenos Aires',
        id: 'EZE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Buenos Aires',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Rome',
      },
      {
        name: 'Ft de France',
        id: 'FDF',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Ft de France',
      },
      {
        name: 'Fort Lauderdale',
        id: 'FLL',
        origin: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Fort Lauderdale',
      },
      {
        name: 'Frankfurt',
        id: 'FRA',
        origin: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YOW', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Frankfurt',
      },
      {
        name: 'Grand Cayman',
        id: 'GCM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Grand Cayman',
      },
      {
        name: 'George Town, Great Exuma Island',
        id: 'GGT',
        origin: ['YOW', 'YYZ'],
        destination: ['YOW', 'YYZ'],
        city: 'George Town, Great Exuma Island',
      },
      {
        name: 'Glasgow',
        id: 'GLA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Glasgow',
      },
      {
        name: 'Grenada',
        id: 'GND',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Grenada',
      },
      {
        name: 'Sao Paulo',
        id: 'GRU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Sao Paulo',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Geneva',
      },
      {
        name: 'Havana',
        id: 'HAV',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Havana',
      },
      {
        name: 'Hong Kong',
        id: 'HKG',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Hong Kong',
      },
      {
        name: 'Tokyo',
        id: 'HND',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Tokyo',
      },
      {
        name: 'Honolulu',
        id: 'HNL',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Honolulu',
      },
      {
        name: 'Holguin',
        id: 'HOG',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Holguin',
      },
      {
        name: 'Sta Cruz Huat',
        id: 'HUX',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sta Cruz Huat',
      },
      {
        name: 'Washington DC (IAD)',
        id: 'IAD',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Washington DC (IAD)',
      },
      {
        name: 'Houston',
        id: 'IAH',
        origin: ['YQR', 'YUL', 'YYC', 'YYZ'],
        destination: ['YQR', 'YUL', 'YYC', 'YYZ'],
        city: 'Houston',
      },
      {
        name: 'Regina',
        id: 'YQR',
        origin: ['ORD', 'YMM', 'YOW', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ'],
        destination: ['IAH', 'ORD', 'YMM', 'YOW', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ'],
        city: 'Regina',
      },
      {
        name: 'Seoul',
        id: 'ICN',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Seoul',
      },
      {
        name: 'Indianapolis',
        id: 'IND',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Indianapolis',
      },
      {
        name: 'Jacksonville',
        id: 'JAX',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Jacksonville',
      },
      {
        name: 'Reykjavik',
        id: 'KEF',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Reykjavik',
      },
      {
        name: 'Kingston',
        id: 'KIN',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Kingston',
      },
      {
        name: 'Osaka',
        id: 'KIX',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Osaka',
      },
      {
        name: 'Kailua',
        id: 'KOA',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Kailua',
      },
      {
        name: 'Las Vegas',
        id: 'LAS',
        origin: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Las Vegas',
      },
      {
        name: 'Edmonton',
        id: 'YEG',
        origin: ['SFO', 'YLW', 'YMM', 'YOW', 'YQU', 'YUL', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ', 'YZF'],
        destination: ['LAS', 'SFO', 'YLW', 'YMM', 'YOW', 'YQU', 'YUL', 'YVR', 'YWG', 'YXE', 'YYC', 'YYZ', 'YZF'],
        city: 'Edmonton',
      },
      {
        name: 'Los Angeles',
        id: 'LAX',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Los Angeles',
      },
      {
        name: 'New York (LGA)',
        id: 'LGA',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'New York (LGA)',
      },
      {
        name: 'London',
        id: 'LHR',
        origin: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYC', 'YYT', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YVR', 'YYC', 'YYT', 'YYZ'],
        city: 'London',
      },
      {
        name: 'Kauai Is',
        id: 'LIH',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Kauai Is',
      },
      {
        name: 'Lima',
        id: 'LIM',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Lima',
      },
      {
        name: 'Liberia',
        id: 'LIR',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Liberia',
      },
      {
        name: 'Lisbon',
        id: 'LIS',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Lisbon',
      },
      {
        name: 'Lyon',
        id: 'LYS',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Lyon',
      },
      {
        name: 'Madrid',
        id: 'MAD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Madrid',
      },
      {
        name: 'Manchester',
        id: 'MAN',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Manchester',
      },
      {
        name: 'Montego Bay',
        id: 'MBJ',
        origin: ['YUL', 'YWG', 'YYZ'],
        destination: ['YUL', 'YWG', 'YYZ'],
        city: 'Montego Bay',
      },
      {
        name: 'Kansas City',
        id: 'MCI',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Kansas City',
      },
      {
        name: 'Orlando',
        id: 'MCO',
        origin: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Orlando',
      },
      {
        name: 'Melbourne',
        id: 'MEL',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Melbourne',
      },
      {
        name: 'Memphis',
        id: 'MEM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Memphis',
      },
      {
        name: 'Mexico City',
        id: 'MEX',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Mexico City',
      },
      {
        name: 'Miami',
        id: 'MIA',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Miami',
      },
      {
        name: 'Milwaukee',
        id: 'MKE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Milwaukee',
      },
      {
        name: 'Marseille',
        id: 'MRS',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Marseille',
      },
      {
        name: 'Minneapolis',
        id: 'MSP',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Minneapolis',
      },
      {
        name: 'New Orleans',
        id: 'MSY',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'New Orleans',
      },
      {
        name: 'Munich',
        id: 'MUC',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Munich',
      },
      {
        name: 'Milan',
        id: 'MXP',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Milan',
      },
      {
        name: 'Nassau',
        id: 'NAS',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Nassau',
      },
      {
        name: 'Nice',
        id: 'NCE',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Nice',
      },
      {
        name: 'Tokyo',
        id: 'NRT',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Tokyo',
      },
      {
        name: 'Kahului',
        id: 'OGG',
        origin: ['YVR', 'YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Kahului',
      },
      {
        name: 'Omaha',
        id: 'OMA',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Omaha',
      },
      {
        name: 'Porto',
        id: 'OPO',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Porto',
      },
      {
        name: 'Chicago',
        id: 'ORD',
        origin: ['YQR', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YQR', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Chicago',
      },
      {
        name: 'Bucharest',
        id: 'OTP',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Bucharest',
      },
      {
        name: 'Port au Prince',
        id: 'PAP',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Port au Prince',
      },
      {
        name: 'West Palm Beach',
        id: 'PBI',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'West Palm Beach',
      },
      {
        name: 'Portland',
        id: 'PDX',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Portland',
      },
      {
        name: 'Topeka',
        id: 'PEK',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Topeka',
      },
      {
        name: 'Philadelphia',
        id: 'PHL',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Philadelphia',
      },
      {
        name: 'Phoenix',
        id: 'PHX',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Phoenix',
      },
      {
        name: 'Pittsburgh',
        id: 'PIT',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Pittsburgh',
      },
      {
        name: 'Providenciales',
        id: 'PLS',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Providenciales',
      },
      {
        name: 'Puerto Plata',
        id: 'POP',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Puerto Plata',
      },
      {
        name: 'Port of Spain, Trinidad',
        id: 'POS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Port of Spain, Trinidad',
      },
      {
        name: 'Prague',
        id: 'PRG',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Prague',
      },
      {
        name: 'Palm Springs',
        id: 'PSP',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Palm Springs',
      },
      {
        name: 'Point à Pitre',
        id: 'PTP',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Point à Pitre',
      },
      {
        name: 'Panama City',
        id: 'PTY',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Panama City',
      },
      {
        name: 'Punta Cana',
        id: 'PUJ',
        origin: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Punta Cana',
      },
      {
        name: 'Providence',
        id: 'PVD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Providence',
      },
      {
        name: 'Shanghai',
        id: 'PVG',
        origin: ['YUL', 'YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Shanghai',
      },
      {
        name: 'Puerto Vallarta',
        id: 'PVR',
        origin: ['YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Puerto Vallarta',
      },
      {
        name: 'Raleigh/Durham',
        id: 'RDU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Raleigh/Durham',
      },
      {
        name: 'Fort Myers',
        id: 'RSW',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Fort Myers',
      },
      {
        name: 'San Diego',
        id: 'SAN',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'San Diego',
      },
      {
        name: 'San Antonio',
        id: 'SAT',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'San Antonio',
      },
      {
        name: 'Savannah',
        id: 'SAV',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Savannah',
      },
      {
        name: 'Santiago',
        id: 'SCL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Santiago',
      },
      {
        name: 'Seattle',
        id: 'SEA',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Seattle',
      },
      {
        name: 'San Francisco',
        id: 'SFO',
        origin: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'San Francisco',
      },
      {
        name: 'San Jose',
        id: 'SJC',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'San Jose',
      },
      {
        name: 'San Jose, Los Cabos',
        id: 'SJD',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'San Jose, Los Cabos',
      },
      {
        name: 'San Jose',
        id: 'SJO',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'San Jose',
      },
      {
        name: 'San Juan',
        id: 'SJU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'San Juan',
      },
      {
        name: 'St. Kitts',
        id: 'SKB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St. Kitts',
      },
      {
        name: 'Sacramento',
        id: 'SMF',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Sacramento',
      },
      {
        name: 'Santa Clara',
        id: 'SNU',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Santa Clara',
      },
      {
        name: 'Sarasota/ Bradenton',
        id: 'SRQ',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sarasota/ Bradenton',
      },
      {
        name: 'St.  Louis',
        id: 'STL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St.  Louis',
      },
      {
        name: 'St. Vincent',
        id: 'SVD',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St. Vincent',
      },
      {
        name: 'St. Maarten',
        id: 'SXM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'St. Maarten',
      },
      {
        name: 'Sydney',
        id: 'SYD',
        origin: ['YVR', 'YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Sydney',
      },
      {
        name: 'Tel Aviv Yafo, Sde Dov',
        id: 'TLV',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Tel Aviv Yafo, Sde Dov',
      },
      {
        name: 'Tampa',
        id: 'TPA',
        origin: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Tampa',
      },
      {
        name: 'Taipei',
        id: 'TPE',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Taipei',
      },
      {
        name: 'Berlin',
        id: 'TXL',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Berlin',
      },
      {
        name: 'Quito',
        id: 'UIO',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Quito',
      },
      {
        name: 'Vieux Fort',
        id: 'UVF',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Vieux Fort',
      },
      {
        name: 'Venice',
        id: 'VCE',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Venice',
      },
      {
        name: 'Vienna',
        id: 'VIE',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Vienna',
      },
      {
        name: 'Varadero',
        id: 'VRA',
        origin: ['YOW', 'YUL', 'YYZ'],
        destination: ['YOW', 'YUL', 'YYZ'],
        city: 'Varadero',
      },
      {
        name: 'Warsaw',
        id: 'WAW',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Warsaw',
      },
      {
        name: 'Sault  Ste. Marie',
        id: 'YAM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sault  Ste. Marie',
      },
      {
        name: 'Baie-Comeau',
        id: 'YBC',
        origin: ['YUL', 'YYY'],
        destination: ['YUL', 'YYY'],
        city: 'Baie-Comeau',
      },
      {
        name: 'Mont-Joli',
        id: 'YYY',
        origin: ['YQB', 'YUL'],
        destination: ['YBC', 'YQB', 'YUL'],
        city: 'Mont-Joli',
      },
      {
        name: 'Bagotville',
        id: 'YBG',
        origin: ['YUL', 'YZV'],
        destination: ['YUL', 'YZV'],
        city: 'Bagotville',
      },
      {
        name: 'Sept-Iles',
        id: 'YZV',
        origin: ['YQB', 'YUL', 'YWK'],
        destination: ['YBG', 'YQB', 'YUL', 'YWK'],
        city: 'Sept-Iles',
      },
      {
        name: 'Nanaimo Airport',
        id: 'YCD',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Nanaimo Airport',
      },
      {
        name: 'Castlegar',
        id: 'YCG',
        origin: ['YVR', 'YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Castlegar',
      },
      {
        name: 'Deer Lake',
        id: 'YDF',
        origin: ['YHZ', 'YQT', 'YQX', 'YUL', 'YYR', 'YYT', 'YYZ'],
        destination: ['YHZ', 'YQT', 'YQX', 'YUL', 'YYR', 'YYT', 'YYZ'],
        city: 'Deer Lake',
      },
      {
        name: 'Thunder Bay',
        id: 'YQT',
        origin: ['YWG', 'YYT', 'YYZ'],
        destination: ['YDF', 'YWG', 'YYT', 'YYZ'],
        city: 'Thunder Bay',
      },
      {
        name: 'Gander',
        id: 'YQX',
        origin: ['YHZ', 'YWK', 'YYR', 'YYT', 'YYZ'],
        destination: ['YDF', 'YHZ', 'YWK', 'YYR', 'YYT', 'YYZ'],
        city: 'Gander',
      },
      {
        name: 'Goose Bay',
        id: 'YYR',
        origin: ['YHZ', 'YQX', 'YWK', 'YYT'],
        destination: ['YDF', 'YHZ', 'YQX', 'YWK', 'YYT'],
        city: 'Goose Bay',
      },
      {
        name: 'Kelowna',
        id: 'YLW',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YVR', 'YYC', 'YYZ'],
        city: 'Kelowna',
      },
      {
        name: 'Fort McMurray',
        id: 'YMM',
        origin: ['YQR', 'YYC', 'YYZ'],
        destination: ['YEG', 'YQR', 'YYC', 'YYZ'],
        city: 'Fort McMurray',
      },
      {
        name: 'Grande Prairie',
        id: 'YQU',
        origin: ['YYC'],
        destination: ['YEG', 'YYC'],
        city: 'Grande Prairie',
      },
      {
        name: 'Saskatoon',
        id: 'YXE',
        origin: ['YOW', 'YQR', 'YVR', 'YWG', 'YYC', 'YYZ'],
        destination: ['YEG', 'YOW', 'YQR', 'YVR', 'YWG', 'YYC', 'YYZ'],
        city: 'Saskatoon',
      },
      {
        name: 'Yellowknife',
        id: 'YZF',
        origin: ['YVR', 'YYC'],
        destination: ['YEG', 'YVR', 'YYC'],
        city: 'Yellowknife',
      },
      {
        name: 'Fredericton',
        id: 'YFC',
        origin: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Fredericton',
      },
      {
        name: 'Kingston',
        id: 'YGK',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Kingston',
      },
      {
        name: 'Gaspe',
        id: 'YGP',
        origin: ['YGR', 'YQB'],
        destination: ['YGR', 'YQB'],
        city: 'Gaspe',
      },
      {
        name: 'Cap-aux-Meules,',
        id: 'YGR',
        origin: ['YQB', 'YUL'],
        destination: ['YGP', 'YQB', 'YUL'],
        city: 'Cap-aux-Meules,',
      },
      {
        name: 'Hamilton',
        id: 'YHM',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Hamilton',
      },
      {
        name: 'Moncton',
        id: 'YQM',
        origin: ['YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Moncton',
      },
      {
        name: 'Sydney, Cape Breton Island',
        id: 'YQY',
        origin: ['YUL', 'YYZ'],
        destination: ['YHZ', 'YUL', 'YYZ'],
        city: 'Sydney, Cape Breton Island',
      },
      {
        name: 'Saint John',
        id: 'YSJ',
        origin: ['YOW', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YUL', 'YYZ'],
        city: 'Saint John',
      },
      {
        name: 'Rouyn-Noranda',
        id: 'YUY',
        origin: ['YUL'],
        destination: ['YUL', 'YVO'],
        city: 'Rouyn-Noranda',
      },
      {
        name: 'Charlottetown',
        id: 'YYG',
        origin: ['YOW', 'YQB', 'YUL', 'YYZ'],
        destination: ['YHZ', 'YOW', 'YQB', 'YUL', 'YYZ'],
        city: 'Charlottetown',
      },
      {
        name: 'Kamloops',
        id: 'YKA',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YVR', 'YYC', 'YYZ'],
        city: 'Kamloops',
      },
      {
        name: 'London',
        id: 'YXU',
        origin: ['YUL', 'YYZ'],
        destination: ['YOW', 'YUL', 'YYZ'],
        city: 'London',
      },
      {
        name: 'Prince Rupert',
        id: 'YPR',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Prince Rupert',
      },
      {
        name: 'Windsor',
        id: 'YQG',
        origin: ['YUL', 'YYZ'],
        destination: ['YUL', 'YYZ'],
        city: 'Windsor',
      },
      {
        name: 'Lethbridge',
        id: 'YQL',
        origin: ['YYC'],
        destination: ['YYC'],
        city: 'Lethbridge',
      },
      {
        name: 'Vancouver Island',
        id: 'YQQ',
        origin: ['YVR'],
        destination: ['YVR'],
        city: 'Vancouver Island',
      },
      {
        name: 'Wabush',
        id: 'YWK',
        origin: ['YUL', 'YYR', 'YZV'],
        destination: ['YQX', 'YUL', 'YYR', 'YZV'],
        city: 'Wabush',
      },
      {
        name: 'Sudbury',
        id: 'YSB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Sudbury',
      },
      {
        name: 'Mont-Tremblant',
        id: 'YTM',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Mont-Tremblant',
      },
      {
        name: 'Timmins',
        id: 'YTS',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'Timmins',
      },
      {
        name: 'Toronto, ON',
        id: 'YTZ',
        origin: ['YUL'],
        destination: ['YUL'],
        city: 'Toronto, ON',
      },
      {
        name: 'Bourlamaq/Val D’or',
        id: 'YVO',
        origin: ['YUY'],
        destination: ['YUL'],
        city: 'Bourlamaq/Val D’or',
      },
      {
        name: 'Victoria International',
        id: 'YYJ',
        origin: ['YVR', 'YYC', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYC', 'YYZ'],
        city: 'Victoria International',
      },
      {
        name: 'Bathurst',
        id: 'ZBF',
        origin: [],
        destination: ['YUL'],
        city: 'Bathurst',
      },
      {
        name: 'Zihuatanejo',
        id: 'ZIH',
        origin: ['YVR', 'YYZ'],
        destination: ['YUL', 'YVR', 'YYZ'],
        city: 'Zihuatanejo',
      },
      {
        name: 'San Salvador Island',
        id: 'ZSA',
        origin: [],
        destination: ['YUL'],
        city: 'San Salvador Island',
      },
      {
        name: 'Cranbrook',
        id: 'YXC',
        origin: ['YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Cranbrook',
      },
      {
        name: 'Fort St. John',
        id: 'YXJ',
        origin: [],
        destination: ['YVR'],
        city: 'Fort St. John',
      },
      {
        name: 'Prince George',
        id: 'YXS',
        origin: [],
        destination: ['YVR'],
        city: 'Prince George',
      },
      {
        name: 'Terrace',
        id: 'YXT',
        origin: ['YYC'],
        destination: ['YVR', 'YYC'],
        city: 'Terrace',
      },
      {
        name: 'Whitehorse',
        id: 'YXY',
        origin: [],
        destination: ['YVR'],
        city: 'Whitehorse',
      },
      {
        name: 'Smithers',
        id: 'YYD',
        origin: [],
        destination: ['YVR'],
        city: 'Smithers',
      },
      {
        name: 'Penticton',
        id: 'YYF',
        origin: [],
        destination: ['YVR'],
        city: 'Penticton',
      },
      {
        name: 'Sandspit, Queen Charlotte Islands',
        id: 'YZP',
        origin: [],
        destination: ['YVR'],
        city: 'Sandspit, Queen Charlotte Islands',
      },
      {
        name: 'Zurich',
        id: 'ZRH',
        origin: ['YYZ'],
        destination: ['YVR', 'YYZ'],
        city: 'Zurich',
      },
      {
        name: 'Medicine Hat',
        id: 'YXH',
        origin: ['YYC'],
        destination: ['YYC'],
        city: 'Medicine Hat',
      },
      {
        name: 'North Bay',
        id: 'YYB',
        origin: ['YYZ'],
        destination: ['YYZ'],
        city: 'North Bay',
      },
      {
        name: 'Sarnia',
        id: 'YZR',
        origin: [],
        destination: ['YYZ'],
        city: 'Sarnia',
      },
      {
        name: 'Zagreb',
        id: 'ZAG',
        origin: [],
        destination: ['YYZ'],
        city: 'Zagreb',
      },
    ],
  },
  'upgrading': {
    MK: [
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Paris',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: ['LGW', 'FCO', 'GVA', 'BOM', 'DEL', 'MAA', 'JNB', 'CPT', 'KUL', 'PER', 'TNR'],
        destination: ['BOM', 'CDG', 'CPT', 'DEL', 'FCO', 'GVA', 'JNB', 'KUL', 'LGW', 'MAA', 'PER', 'TNR'],
        city: 'Mauritius',
      },
      {
        name: 'London',
        id: 'LGW',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'London',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Rome',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Geneva',
      },
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Mumbai',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Delhi',
      },
      {
        name: 'Chennai',
        id: 'MAA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Chennai',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Johannesburg',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Cape Town',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'Perth',
        id: 'PER',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Perth',
      },
      {
        name: 'Antannarivo',
        id: 'TNR',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Antannarivo',
      },
    ],
  },
  'excess-luggage': {
    MK: [
      {
        name: 'Paris',
        id: 'CDG',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Paris',
      },
      {
        name: 'Mauritius',
        id: 'MRU',
        origin: ['LGW', 'GVA', 'FCO', 'JNB', 'CPT', 'BOM', 'DEL', 'KUL', 'MAA', 'PER', 'RRG', 'RUN', 'TNR'],
        destination: ['CDG', 'LGW', 'FCO', 'GVA', 'BOM', 'DEL', 'MAA', 'JNB', 'CPT', 'KUL', 'PER', 'RRG', 'RUN', 'TNR'],
        city: 'Mauritius',
      },
      {
        name: 'London',
        id: 'LGW',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'London',
      },
      {
        name: 'Geneva',
        id: 'GVA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Geneva',
      },
      {
        name: 'Rome',
        id: 'FCO',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Rome',
      },
      {
        name: 'Johannesburg',
        id: 'JNB',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Johannesburg',
      },
      {
        name: 'Cape Town',
        id: 'CPT',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Cape Town',
      },
      {
        name: 'Mumbai',
        id: 'BOM',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Mumbai',
      },
      {
        name: 'Delhi',
        id: 'DEL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Delhi',
      },
      {
        name: 'Kuala Lumpur',
        id: 'KUL',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Kuala Lumpur',
      },
      {
        name: 'Chennai',
        id: 'MAA',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Chennai',
      },
      {
        name: 'Perth',
        id: 'PER',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Perth',
      },
      {
        name: 'Rodrigues',
        id: 'RRG',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Rodrigues',
      },
      {
        name: 'Reunion',
        id: 'RUN',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Reunion',
      },
      {
        name: 'Antannarivo',
        id: 'TNR',
        origin: ['MRU'],
        destination: ['MRU'],
        city: 'Antannarivo',
      },
    ],
  },
};
