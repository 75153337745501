import PropTypes from 'prop-types';
import { passengerShape, priceValuesShape, segmentShape } from '../main';

export const selectedBagsShape = PropTypes.arrayOf(
  PropTypes.shape({
    passenger: passengerShape,
    segmentKey: PropTypes.string,
    ssrCode: PropTypes.string,
    weight: PropTypes.number,
    quantity: PropTypes.number,
    price: PropTypes.number
  })
);

export const bagsCombinedSummaryShape = PropTypes.arrayOf(
  PropTypes.shape({
    ssrCode: PropTypes.string,
    weight: PropTypes.number,
    numberOfBagsSelected: PropTypes.number
  })
);

export const bagShape = PropTypes.shape({
  ssrCode: PropTypes.string,
  weight: PropTypes.string,
  prices: priceValuesShape,
  numberOfBagsSelected: PropTypes.number,
  numberOfPaidBags: PropTypes.number,
  minNumberOfBagsSelected: PropTypes.number,
  isIncludedInBundle: PropTypes.bool
});

export const bagsShape = PropTypes.arrayOf(bagShape);

export const bagsPerSegmentShape = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    origin: PropTypes.string,
    destination: PropTypes.string,
    originCity: PropTypes.string,
    destinationCity: PropTypes.string,
    bags: bagsShape
  })
);

export const bagsSummaryPerItineraryPartShape = PropTypes.arrayOf(
  PropTypes.shape({
    bagsSummaryPerSegment: bagsPerSegmentShape
  })
);

export const bagsPerPaxShape = PropTypes.arrayOf(
  PropTypes.shape({
    passenger: passengerShape,
    bagsPerItineraryPart: PropTypes.arrayOf(
      PropTypes.shape({
        bagsPerSegment: PropTypes.arrayOf(
          PropTypes.shape({
            segment: segmentShape,
            bags: bagsShape
          })
        ),
        bagsCombined: PropTypes.shape({
          stopsAirports: PropTypes.arrayOf(PropTypes.string),
          segmentKeys: PropTypes.arrayOf(PropTypes.string),
          bags: bagsShape
        }),
        couldCombineSegments: PropTypes.bool
      })
    ),
    allBagsCombined: bagsShape
  })
);

export const optionGroupsShape = PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string }));
