import _ from 'lodash';
import { useIntl as useReactIntl } from 'react-intl';
import { useMessage } from './useMessage';

const substitutionRegex = /\{\w+\}/g;

export const useIntl = () => {
  const intl = useReactIntl();

  const formatMessage = ({ id, defaultMessage, description = '' }, values = []) => {
    const { defaultText } = useMessage(id, defaultMessage);
    return intl.formatMessage({ id, defaultMessage: defaultText, description }, values);
  };

  const formatDynamicMessage = ({ id, defaultMessage }, values) => {
    const placeholders = id.match(substitutionRegex);
    const newId = _.reduce(
      placeholders,
      (resultId, placeholder) => {
        const name = placeholder.substring(1, placeholder.length - 1);
        const value = values[name];
        return resultId.replace(placeholder, value);
      },
      id
    );

    return intl.formatMessage({ id: newId, defaultMessage }, values);
  };

  return { ...intl, formatMessage, formatDynamicMessage };
};
