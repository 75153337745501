import styled from 'styled-components';
import { AlignItem, getPositionStyling } from 'utils/DOMmanupulationUtils';
import { getTransitions, rem } from 'utils/commonUtils';

const DayCellSize = 44;
const DayCellSizeMedium = 38;

export const DatePickerWrapper = styled.div`
  position: relative;
`;

export const DatePickerBox = styled.div<{ $alignment: AlignItem; $width: number; $overflowItsAncestors: boolean; $column: boolean }>`
  z-index: -1;
  opacity: 0;
  position: ${({ $overflowItsAncestors }) => ($overflowItsAncestors ? 'fixed' : 'absolute')};
  width: max-content;
  ${({ $alignment, $overflowItsAncestors }) => ($overflowItsAncestors ? '' : getPositionStyling($alignment))};
  &:not(:empty) {
    z-index: 16;
    opacity: 1;
    transition: ${({ theme }) => getTransitions(theme.animationSpeed, 'height', 'opacity')};
  }
  & .react-datepicker {
    box-shadow: ${({ theme }) => theme.shadow};
    display: flex;
    gap: 2.4rem;
    padding: 1.6rem;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    background: ${({ theme }) => theme.backgroundColor};
    ${({ $column }) => ($column ? 'flex-direction: column; gap: 1.6rem;' : '')}
    .react-datepicker__children-container {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  & .react-datepicker__header.react-datepicker__header--custom {
    background: ${({ theme }) => theme.backgroundColor};
    padding: 0;
    margin-bottom: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.borderColor};
    .react-datepicker__day-names {
      margin-bottom: 0.8rem;
      .react-datepicker__day-name {
        width: ${rem(DayCellSize)};
        margin: 0;
        text-transform: uppercase;
        ${({ theme }) => theme.textStyles.caption.regular};
      }
    }
  }
  & .react-datepicker__month {
    margin: 0;
    .react-datepicker__day,
    .react-datepicker__month-text {
      ${({ theme }) => theme.textStyles.button.small};
      width: ${rem(DayCellSize)};
      line-height: ${rem(DayCellSize)};
      margin: 0;
      padding: 0.4rem 0;
      border-radius: ${({ theme }) => theme.borderRadius};
    }
    .react-datepicker__month-text {
      width: ${rem(DayCellSize * 1.75)};
      &:hover {
        color: ${({ theme }) => theme.colorAccent};
        background: ${({ theme }) => theme.textColor};
      }
    }
    .react-datepicker__day--outside-month {
      visibility: hidden;
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled {
      color: ${({ theme }) => theme.colors.disabledBackgroundColor || 'rgba(7, 59, 80, 0.20)'};
    }
    .react-datepicker__day--selected {
      color: ${({ theme }) => theme.colorAccent};
      background: ${({ theme }) => theme.textColor};
    }
  }

  & .react-datepicker__year {
    margin: 0;
    .react-datepicker__year-wrapper {
      ${({ $width }) => ($width > 180 ? `max-width: calc(${rem($width)} - 1.6rem * 2);` : '')}
      justify-content: center;
    }
    .react-datepicker__year-text {
      ${({ theme }) => theme.textStyles.button.small};
      width: ${rem(DayCellSize * 1.75)};
      line-height: ${rem(DayCellSize)};
      margin: 0;
      padding: 0.4rem 0;
      border-radius: ${({ theme }) => theme.borderRadius};
    }
    .react-datepicker__year-text--disabled {
      color: ${({ theme }) => theme.colors.disabledBackgroundColor || 'rgba(7, 59, 80, 0.20)'};
    }
  }
  ${({ theme, $width }) => theme.mediaQueries.LG`
  & .react-datepicker__year {
      .react-datepicker__year-wrapper { ${$width > 180 ? `max-width: calc((${rem($width)} * .7) - (1.6rem * 2));` : ''}
    }`}
  ${({ theme, $width }) => theme.mediaQueries.MD`
    & .react-datepicker__month-container {
      .react-datepicker__header.react-datepicker__header--custom {
        .react-datepicker__day-names .react-datepicker__day-name { width: ${rem(DayCellSizeMedium)}; }
      }
      .react-datepicker__month .react-datepicker__day {
        width: ${rem(DayCellSizeMedium)};
        line-height: ${rem(DayCellSizeMedium * 0.8)};
      }
      .react-datepicker__month-text { width: ${rem(DayCellSizeMedium * 1.75)}; }
    }
    & .react-datepicker__year {
      .react-datepicker__year-text { width: ${rem(DayCellSizeMedium * 1.75)}; }
      line-height: ${rem(DayCellSizeMedium)};
    }
  `}
  ${({ theme }) => theme.mediaQueries.SM`
    & .react-datepicker {
      flex-direction: column; 
    }
  `}
`;

export const DatePickerHeader = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const DatePickerHeaderMonth = styled.div`
  flex-grow: 1;
  ${({ theme }) => theme.textStyles.subtitle.s4};
`;

export const GoUp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
