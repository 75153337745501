import * as ui from 'pages/DashboardPage/components/DashboardCard.ui';
import { FormattedMessage, useIntl } from '@triplake/lib-intl';
import { messages } from 'pages/DashboardPage/DashboardPage.translations';
import Icon from 'components/Icon';
import { Theme } from 'types/themes';
import { useTheme } from 'styled-components';
import { useEffect, useRef } from 'react';

const CardDialog = ({ currentLevel, card, tier, closeDialog }) => {
  const theme: Theme = useTheme() as Theme;
  const { formatMessage, locale } = useIntl();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const aRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    const canvas: HTMLCanvasElement = document.createElement('canvas')!;
    const img = new Image();

    img.onload = function () {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      ctx.drawImage(img, 0, 0);

      // Add name, memberNumber and expiration date to the canvas
      const fontSize = height / 18;
      ctx.textAlign = 'right';
      ctx.font = `${fontSize}px ${theme.fontFamilySecondary}`;
      const lines: Array<string> = [card?.nameOnCard || `${card?.firstName ?? ''} ${card?.lastName || ''}`, card?.accountId];
      if (currentLevel !== 'level1' && card?.cardExpiryDate) lines.push(`${formatMessage(messages.expiryDateLabel)}: ${card.cardExpiryDate}`);

      const rightPositionOfText: number = width * 0.9;
      const topPositionOfText: number = height / 1.9;
      lines.forEach((line: string, lineNo: number) => {
        ctx.fillText(line, rightPositionOfText, topPositionOfText + lineNo * (fontSize * 1.6));
      });
      const base64Image = canvas.toDataURL('image/png');
      if (imgRef.current) imgRef.current.src = base64Image;
      if (aRef.current) aRef.current.href = base64Image;
    };

    img.src = theme.images?.[`card_${currentLevel}`] ?? '';
  }, [theme, currentLevel, card, formatMessage, locale]);

  return (
    <ui.ModalComponents.Wrapper>
      <ui.ModalComponents.Image alt="Card" ref={imgRef} />
      <ui.ModalComponents.Message download={`${tier?.name}_card`} href={theme.images?.[`card_${currentLevel}`]} onClick={closeDialog} ref={aRef}>
        <FormattedMessage {...messages.sendCardViaEmail} />
        <Icon name="download" />
      </ui.ModalComponents.Message>
    </ui.ModalComponents.Wrapper>
  );
};

export default CardDialog;
