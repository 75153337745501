import { colors } from './colors';
import { fontSizes, getFont } from '../utils';

const defaultStyles = {
  color: colors.darkGray,
  style: 'normal',
  weight: fontSizes.regular,
  size: '1.4rem',
  lineHeight: '2.1rem',
  family: 'Poppins, sans-serif',
};

export default {
  header: {
    h1: getFont(
      {
        lineHeight: '6rem',
        size: '4rem',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
    h2: getFont(
      {
        lineHeight: '3.5rem',
        size: '2.4rem',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
    h3: getFont(
      {
        lineHeight: '3rem',
        size: '2rem',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
    h4: getFont(),
  },
  subtitle: {
    s1: getFont(
      {
        lineHeight: '2.1rem',
        size: '1.4rem',
      },
      defaultStyles,
    ),
    s1SemiBold: getFont(),
    s2: getFont(),
    s2SemiBold: getFont(),
    s3: getFont(),
    s4: getFont(),
    s5: getFont(),
  },
  body: {
    regular: getFont(
      {
        lineHeight: '2.4rem',
        size: '1.6rem',
      },
      defaultStyles,
    ),
    large: getFont(),
    small: getFont(),
  },
  caption: {
    large: getFont(
      {
        lineHeight: '1.8rem',
        size: '1.2rem',
      },
      defaultStyles,
    ),
    regular: getFont(),
    small: getFont(),
  },
  button: {
    regular: getFont(
      {
        lineHeight: '1.6rem',
        size: '1.6rem',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
    large: getFont(),
    small: getFont(),
  },
};
