import { request } from '../request/requestActions';
import { requestStatusSelector } from '../request/requestSelectors';
import { action } from '../utils/actionHelpers';
import { getMessages } from './intlApi';
import { INTL_GET_MESSAGES, SET_PREFERRED_LOCALE, SET_PREFERRED_REGION, SAVE_PREFERRED_LOCALE, SAVE_PREFERRED_REGION } from './intlConstants';

export const setPreferredLocale = (locale: string) => action(SET_PREFERRED_LOCALE, { locale });
export const setPreferredRegion = (region: string) => action(SET_PREFERRED_REGION, { region });
export const savePreferredLocale = (locale: string) => action(SAVE_PREFERRED_LOCALE, { locale });
export const savePreferredRegion = (region: string) => action(SAVE_PREFERRED_REGION, { region });

export const intlGetMessagesRequestStatus = requestStatusSelector(INTL_GET_MESSAGES);

export const intlGetMessagesRequest = (locale: string) =>
  request({
    actionBase: INTL_GET_MESSAGES,
    apiMethod: getMessages,
    apiMethodArg: locale,
  });
