export enum MenuView {
  MAIN_MENU = 0,
  SUB_MENU = 1,
}

export enum BottomMenuContent {
  NONE,
  HELP,
  SEARCH,
}
