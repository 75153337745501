import { useEffect, useMemo, useRef } from 'react';
import { ImageSize, SourceProps } from 'types/Image';
import { generateSourcesForDynamicImages } from 'utils/commonUtils';
import { useTheme } from 'styled-components';
import { Theme } from 'types/themes';

interface ImageProps {
  src: string;
  alt?: string;
  className?: string;
  srcset?: string | Array<{ image: string; condition?: string }>;
  sizes?: string | Array<{ mediaCondition?: string; slotWidth: string }>;
  sources?: Array<SourceProps>;
  lazyLoad?: boolean;
  onImageSizeChange?: (imageSize: ImageSize) => void;
  imgProps?: { [key: string]: string | ((e: any) => void) };
  generateDynamicImages?: string;
}

export const Image = ({
  src,
  alt,
  className,
  srcset,
  sizes,
  sources,
  lazyLoad,
  onImageSizeChange,
  imgProps = {},
  generateDynamicImages,
}: ImageProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const { images } = useTheme() as Theme;
  const sourcesArray = useMemo(() => {
    if (sources) return sources;
    if (typeof generateDynamicImages === 'string' && generateDynamicImages && images)
      return generateSourcesForDynamicImages(images, generateDynamicImages);
  }, [sources, generateDynamicImages, images]);

  const imgSrcSet = !srcset ? undefined : typeof srcset === 'string' ? srcset : srcset.map(src => `${src.image} ${src.condition || ''}`).join(', ');
  const imgSizes = !sizes ? undefined : typeof sizes === 'string' ? sizes : sizes.map(size => `${size.mediaCondition} ${size.slotWidth}`).join(', ');
  const picture = (
    <picture>
      {sourcesArray &&
        sourcesArray.map((source, sourceKey) => {
          const srcset =
            typeof source.srcset === 'string' ? source.srcset : source.srcset.map(src => `${src.image} ${src.condition || ''}`).join(', ');
          return <source srcSet={srcset} type={source.type} media={source.mediaCondition} key={sourceKey} />;
        })}
      <img
        src={src}
        alt={alt}
        className={className}
        loading={lazyLoad ? 'lazy' : undefined}
        ref={imgRef}
        srcSet={imgSrcSet}
        sizes={imgSizes}
        {...imgProps}
      />
    </picture>
  );

  useEffect(() => {
    const img = imgRef.current;
    if (!img) return;
    const sizeAvailable = () =>
      onImageSizeChange
        ? onImageSizeChange({
            width: img.naturalWidth,
            height: img.naturalHeight,
            aspectRatio: img.naturalWidth / img.naturalHeight,
          })
        : null;
    if (img.complete) sizeAvailable();
    else img.onload = sizeAvailable;
  }, [src, srcset, sources, onImageSizeChange]);

  return picture;
};
