import { AnyAction } from 'redux';

export interface TActionToReducerMap {
  [key: string]: any;
}

export const createReducer =
  (actionToReducerMap: TActionToReducerMap, initialState = {}) =>
  (state = initialState, action: AnyAction) => {
    if (actionToReducerMap[action.type]) {
      return actionToReducerMap[action.type](state, action);
    }
    return state;
  };
