import PropTypes from 'prop-types';
import { passengerShape, passengersShape, priceValuesShape, segmentShape } from '../main';

export * from './bagsPropTypes';
export * from './insurancePropTypes';
export * from './seatsPropTypes';

export const offerShape = PropTypes.shape({
  ssrCode: PropTypes.string,
  imageUrl: PropTypes.string,
  prices: priceValuesShape,
  isPaid: PropTypes.bool
});

export const offersShape = PropTypes.arrayOf(offerShape);

export const offerPerSegmentShape = PropTypes.arrayOf(
  PropTypes.shape({
    bundledItems: PropTypes.arrayOf(offerShape),
    lowestPrice: priceValuesShape,
    offersForAllPassengers: PropTypes.arrayOf(offerShape),
    offersPerPassenger: PropTypes.arrayOf(
      PropTypes.shape({
        passenger: passengerShape,
        offers: PropTypes.arrayOf(offerShape)
      })
    ),
    passengersWithCancelEnabled: passengersShape,
    segment: segmentShape,
    selectedItems: PropTypes.arrayOf(
      PropTypes.shape({
        passenger: passengerShape,
        price: PropTypes.number,
        ssrCode: PropTypes.string
      })
    ),
    total: priceValuesShape,
    isPaid: PropTypes.bool,
    isAnyAvailable: PropTypes.bool
  })
);

export const includedAncillaryShape = PropTypes.shape({
  code: PropTypes.string,
  type: PropTypes.string
});

export const bundleShape = PropTypes.shape({
  imageUrl: PropTypes.string,
  includedAncillaries: PropTypes.arrayOf(includedAncillaryShape),
  passenger: passengerShape,
  price: PropTypes.number,
  prices: priceValuesShape,
  segmentKey: PropTypes.string,
  ssrCode: PropTypes.string
});

export const bundlesShape = PropTypes.arrayOf(bundleShape);
