import { createGradient } from '../utils';
import { ThemeGradients, ThemeColors } from 'types/themes';

const blue20 = '#073B501A';
const blue10 = '#073B5033';
const sand20 = '#EEE6DE1A';
const burgundy = '#801727';
const cardinal = '#A61E32';
const crimson = '#E32945';
const mauritiusRed: string = '#BE2239'; // <-> primaryColor
const magenta = '#9C3461';
const eggPlant = '#7A4178';
const violet = '#534A7B';
const deepBlue = '#394B6D';
const charcoal = '#334756';
const sand = '#EEE6DE';

export const colors: ThemeColors = {
  primaryColor: mauritiusRed,
  textColor: '#073B50',
  darkSiteBackgroundColor: '#F5F6F6',
  homeBackgroundColor: sand,
  innerPanelsBgColor: '#F8F5F2',
  backgroundColor: '#FFF',
  bodyColor: '#f8f5f2',
  black: '#000000',
  white: '#ffffff',
  level1: mauritiusRed,
  level2: '#7b7574',
  level3: '#886e4e',
  overlayColor: '#00000088',
  borderColor: blue10,
  disabledBackgroundColor: blue20,
  dropDownCategoryTextColor: eggPlant,

  /* Buttons */
  primaryButtonBackground: mauritiusRed,
  primaryButtonHoverBackground: cardinal,
  primaryButtonForeground: '#fff',
  primaryButtonHoverForeground: '#fff',
  secondaryButtonForeground: mauritiusRed,
  secondaryButtonBorder: mauritiusRed,
  secondaryButtonHoverForeground: cardinal,
  secondaryButtonHoverBorder: cardinal,
  linkButtonForeground: mauritiusRed,
  linkButtonHoverForeground: cardinal,
  actionButtonColor: deepBlue,

  /* Notifications */
  infoDark: '#4175BE',
  infoLight: '#D9E3F2',
  successDark: '#3F844E',
  successLight: '#D9E6DC',
  warningDark: '#DA924F',
  warningLight: '#F8E9DC',
  errorDark: '#BE2239',
  errorLight: '#F2D3D7',
  pending: violet,

  sand20,
  sand,
};

export const gradients: ThemeGradients = {
  hero: createGradient(164, { color: burgundy, position: 11 }, { color: '#b4273d', position: 48 }, { color: mauritiusRed, position: 69 }),
  defaultImageOverlay: '',
  footer: createGradient(225, eggPlant, mauritiusRed),
  textOverImage: createGradient(
    180,
    { color: '#EEE6DEa1', position: 0 },
    { color: '#EEE6DE64', position: 75 },
    { color: '#EEE6DE00', position: 100 },
  ),
};

export const levelGradients = [
  createGradient(172, { color: burgundy, position: 6 }, { color: '#b4273d', position: 77 }, { color: mauritiusRed, position: 118 }),
  createGradient(172, { color: '#655c5a', position: 5 }, { color: '#8b8584', position: 67 }, { color: '#aba5a4', position: 120 }),
  createGradient(
    172,
    { color: '#877766', position: 8 },
    { color: '#8c7863', position: 30 },
    { color: '#a28d78', position: 72 },
    { color: '#cbb59d', position: 120 },
  ),
];

/*
  brownGradient: createGradient(22, '#6B5646', { color: '#49392D', position: 48.66 }, { color: '#392C23', position:70 }),
  crimsonBurgundyGradient: createGradient(90, colors.crimson, colors.burgundy),
  cardinalEggPlantGradient: createGradient(90, colors.cardinal, colors.eggPlant),
  redEggPlantGradient: createGradient(90, colors.mauritiusRed, colors.eggPlant),
  redVioletGradient: createGradient(90, colors.mauritiusRed, colors.violet),
  crimsonDeepBlueGradient: createGradient(90, colors.crimson, colors.deepBlue),
  violetEggPlantGradient: createGradient(90, colors.violet, colors.eggPlant),
  deepBlueEggPlantGradient: createGradient(90, colors.deepBlue, colors.eggPlant),
  violetCharcoalGradient: createGradient(90, colors.violet, colors.charcoal),
 */
