import React from 'react';
import { useRedirectUrls } from 'src/hooks';
import { Theme } from 'types/themes';
import { useTheme } from 'styled-components';
import * as ui from './Header.templateB.ui';

export const HeaderTemplateB = () => {
  const theme: Theme = useTheme() as Theme;
  const { homePage } = useRedirectUrls();

  return (
    <ui.OmanHeaderWrapper>
      <ui.OmanLogo href={homePage}>
        <img alt="logo link" src={theme?.images?.logo} />
      </ui.OmanLogo>
    </ui.OmanHeaderWrapper>
  );
};
