import { LevelTheme, PointsPartOrder } from 'types/themes';

export const getLevel = (levelType: string, levelsTheme: Array<LevelTheme>): number => {
  let i = levelsTheme.length - 1;
  for (; i > -1; --i) if (levelType === levelsTheme[i].tierKey) return i;

  return 0;
};

export const getPointsBoxesAlign = (pointsPartOrder: PointsPartOrder | undefined) => {
  const align = {
    current: false,
    expiringMiles: true,
    progressBar: false,
    miles: false,
    nextLevel: true,
    _: false,
  };
  if (!pointsPartOrder) return align;
  const mappingArray: Array<string> = Array.from({ length: 5 }, () => '_');
  Object.keys(pointsPartOrder).forEach((partName: string): void => {
    const index = pointsPartOrder[partName];
    if (index < 1) return;
    mappingArray[index] = partName;
  });
  const progressBarPosition = pointsPartOrder.progressBar;
  switch (progressBarPosition) {
    case 1:
      align[mappingArray[3]] = align[mappingArray[5]] = true;
      align[mappingArray[2]] = align[mappingArray[4]] = false;
      break;
    case 2:
      align[mappingArray[4]] = true;
      align[mappingArray[1]] = align[mappingArray[3]] = align[mappingArray[5]] = false;
      break;
    case 3:
      align[mappingArray[2]] = align[mappingArray[5]] = true;
      align[mappingArray[1]] = align[mappingArray[4]] = false;
      break;
    case 4:
      align[mappingArray[2]] = true;
      align[mappingArray[1]] = align[mappingArray[3]] = align[mappingArray[5]] = false;
      break;
    case 5:
      align[mappingArray[2]] = align[mappingArray[4]] = true;
      align[mappingArray[1]] = align[mappingArray[3]] = false;
      break;
  }
  return align;
};
