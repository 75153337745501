const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;

enum Periods {
  SECOND = second,
  MINUTE = minute,
  HOUR = hour,
  DAY = day,
  WEEK = week,
}

const addToDate = (date: Date, amount: number, period: Periods, modify: boolean = false): Date => {
  // if (!date || isNaN(date.getTime())) throw new Error('Invalid date provided');
  const time = amount * period + date.getTime();
  return modify ? (date.setTime(time), date) : new Date(time);
};
export const addMinutes = (date: Date, minutes: number, modify: boolean = false): Date => addToDate(date, minutes, Periods.MINUTE, modify);
export const addHours = (date: Date, hours: number, modify: boolean = false): Date => addToDate(date, hours, Periods.HOUR, modify);
export const addDays = (date: Date, days: number, modify: boolean = false): Date => addToDate(date, days, Periods.DAY, modify);
export const addWeeks = (date: Date, weeks: number, modify: boolean = false): Date => addToDate(date, weeks, Periods.WEEK, modify);
export const addMonths = (date: Date, months: number, modify: boolean = false): Date => {
  const dateCopy = new Date(date.getTime());
  const month = dateCopy.getMonth() + months;
  return modify ? (date.setMonth(month), date) : new Date(dateCopy.setMonth(month));
};
export const addYears = (date: Date, years: number, modify: boolean = false): Date => {
  const year = date.getFullYear() + years;
  return modify ? (date.setFullYear(year), date) : new Date(date.toJSON().replace(/^[0-9]{4}(.+)$/, `${year}$1`));
};

export const normalizeDate = (date: Date, noTimeZone: boolean = false): Date => {
  const timezoneCorrected = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return new Date((noTimeZone ? timezoneCorrected : date).toISOString().substring(0, 10));
};

export const isCorrectDate = (date: Date | null, min?: Date, max?: Date): boolean => {
  if (date === null || (date instanceof Date && Number.isNaN(date.getTime()))) return false;
  return !((min && +date < +min) || (max && +date > +max));
};
