import { TAppState } from '../constants';

export const getAccountId = (state: TAppState) => state.user.accountId.replace(/^([^:]+:){2}([0-9]+)$/, '$2');
export const getFullAccountId = (state: TAppState) => state.user.accountId;

export const getUserProfile = (state: TAppState) => state.user.profile;

export const getUserDataByName =
  (dataNames: Array<string> = []) =>
  (state: TAppState) => {
    const data = {};
    dataNames.forEach((dataName: string) => {
      data[dataName] = state.user[dataName];
    });
    return data;
  };
