import { transparentize } from 'polished';
import { colors } from './colors';

const breakPoints = {
  XXS: 480,
  XS: 600,
  SM: 840,
  MD: 960,
  LG: 1280,
  XL: 1440,
  tabletSM: 720,
  tabletLG: 1024
};

const materialIcons = {
  fontFamily: 'Material Icons',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: '2.5rem',
  display: 'inline-block',
  lineHeight: 1,
  textTransform: 'none',
  letterSpacing: 'normal',
  wordWrap: 'normal',
  whiteSpace: 'nowrap',
  direction: 'ltr',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  textRendering: 'optimizeLegibility',
  fontFeatureSettings: 'liga'
};

export const rem = r => `${r}rem`;

export const themes = {
  materialIcons,
  // COLORS ------------------------
  colorPrimary: colors.teal500,
  colorSecondary: colors.deepPurple900,
  colorAccent: colors.lime,

  refreshColorPrimary: colors.teal500,
  refreshColorSecondary: colors.darkLime,
  refreshColorAccent: colors.veryDarkGrey,
  refreshColorPrimaryLighter: colors.lighterMagenta,

  colorBackgroundWhite: colors.white,
  colorBackgroundPrimary: colors.grey000,
  colorBackgroundSecondary: colors.grey100,
  colorBackgroundTertiary: colors.teal800,
  colorBackgroundDisabled: colors.grey100,

  colorShadow: colors.black,
  colorText: colors.darkGrey,
  colorTextPrimary: colors.deepPurple900,
  colorTextSecondary: colors.grey600,
  colorLinkHover: colors.darkCyan,
  colorTextDisabled: colors.grey500,
  colorTextInversed: colors.white,
  colorTextError: colors.red900,
  colorTextWarning: colors.amberA700,
  colorTextSuccess: colors.lime,
  colorTextDarkest: colors.black,

  colorTextIcon: colors.grey500,
  colorLinkPrimary: colors.teal500,

  colorBorderLight: colors.grey200,
  colorBorderMedium: colors.grey,
  colorBorderDark: colors.grey600,
  colorBorderDarkest: colors.deepPurple999,
  colorBorderError: colors.red900,
  colorBorderInput: colors.lightGrayishOrange,

  colorTextPlaceholder: colors.grey400,

  colorNavigationBar: colors.white,
  colorBackgroundHeader: colors.darkBlue,
  colorBackgroundPage: colors.lightGrayishBlue,
  colorMenuBorder: colors.lightGrayishOrange,
  colorIncludedPriceBackground: colors.deepYellow,

  colorLabel: colors.deepPurple999,
  colorDefaultBar: colors.lightBlue,

  colorDivider: transparentize(0.5, colors.grey100),

  colorIconDefault: colors.darkGrey,

  colorDateItem: colors.grey600,

  // FONTS --------------------------
  fontFamilyPrimary: `Nunito Sans, sans-serif`,
  fontFamilySecondary: `Brandon Grotesque, sans-serif`,

  fontSizeDisplay5: rem(3.6),
  fontSizeDisplay4: rem(3.2),
  fontSizeDisplay3: rem(2.8),
  fontSizeDisplay2: rem(2.4),
  fontSizeDisplay1: rem(2.2),
  fontSizeHeadline: rem(2.0),
  fontSizeTitle: rem(1.8),
  fontSizeTitleSmall: rem(1.6),
  fontSizeBody: rem(1.4),
  fontSizeButton: rem(1.4),
  fontSizeCaption: rem(1.2),
  fontSizeTiny: rem(1.1),
  fontSizeSubhead2: rem(1.6),
  fontSizeSubhead3: rem(1.7),

  fontWeightThin: 200,
  fontWeightSemiThin: 300,
  fontWeightNormal: 400,
  fontWeightSemiBold: 500,
  fontWeightBold: 600,

  lineHeightNormal: 1.35,

  // SHADOWS ------------------------
  shadow2dp: `0 ${rem(0.2)} ${rem(0.2)} 0 ${colors.shadow}`,
  shadow16dp: `0 ${rem(16)} ${rem(2.4)} ${rem(0.2)} ${colors.shadow}`,

  // SIZES --------------------------
  breakPoints,
  mq: Object.keys(breakPoints).reduce(
    (acc, key) =>
      Object.assign(acc, {
        [key]: `@media screen and (max-width: ${breakPoints[key]}px)`
      }),
    {}
  ),

  headlineVerticalMargin: rem(2.0),
  headlineVerticalMarginSmall: rem(1.6),

  borderRadius: rem(0.4),
  borderSize: rem(0.1),

  inputHeight: rem(4.8),
  checkBoxSize: '2rem',

  // SELECT
  colorSelectIcon: colors.deepPurple900,
  fontWeightSelect: 300,

  // CHECKBOX
  colorCheckboxChecked: colors.lime,
  colorCheckboxBorder: colors.grey200,

  // INPUT
  colorInputFocus: colors.teal500,

  // SLIDER
  sliderVerticalLine: `0.2rem solid ${colors.teal500}`,
  colorSliderNotChecked: colors.grey200,
  colorSliderChecked: colors.teal300,

  // LABEL
  fontWeightLabel: 600,

  // FIELD
  fontWeightFieldRequired: 400,

  // TABLE
  colorTableBorder: colors.grey200,
  colorTableHeader: colors.lightGrayishBrown,
  textTransformTableHeader: 'uppercase',
  fontSizeTable: rem(1.4),
  colorTableText: 'inherit',
  fontSizeTableHeader: rem(1.6),
  fontWeightTableHeader: 600,
  colorTableHeaderText: 'inherit',
  fontSizeTableHeaderRow: rem(1.4),
  fontWeightTableHeaderRow: 400,
  colorTableHeaderRowText: 'inherit',
  fontSizeTableBody: rem(1.4),
  fontWeightTableBody: 400,
  colorTableBodyText: 'inherit',
  fontSizeTableRow: rem(1.4),
  fontWeightTableRow: 400,
  colorTableRowText: 'inherit',
  fontSizeTableCell: rem(1.4),
  fontWeightTableCell: 400,
  colorTableCellText: 'inherit',

  // MODAL
  colorBackgroundModalHeader: colors.white,
  colorBackgroundModalFooter: colors.white,
  colorBackgroundModalContent: colors.grey100,
  fontSizeModalHeader: '2.0rem',
  borderShadowModalHeader: 'rgba(0,0,0,0.1)',
  tooltipBackground: 'rgba(53,53,53,0.75)',

  // LIST
  colorBackgroundList: colors.white,
  colorBackgroundListRowHover: 'rgba(50, 154, 155, 0.05)',
  colorBorderListRow: '#E3E2E1',
  listColumnPadding: '1.6rem 2.5rem 1.6rem 0',
  listFirstColumnPadding: '1.6rem 2.5rem 1.6rem 1.4rem',
  listLastColumnPadding: '1.6rem 0',
  borderRadiusList: '0.4rem',
  borderShadowList: 'rgba(0, 0, 0, 0.1)',
  lineHeightList: '2.0rem',
  borderShadowInlineTextEdit: 'rgba(0,0,0,0.1)',
  listRowMinHeight: '7.2rem',

  // BUTTON
  buttonLargeHeight: '5.6rem',
  buttonMediumHeight: '4.8rem',
  buttonSmallHeight: '4.0rem',
  overlayBackground: 'rgba(0, 0, 0, 0.75)',
  borderRadiusButton: rem(999),
  borderStyleButton: 'solid',
  colorBackgroundSecondaryHover: transparentize(0.9, colors.teal500),
  colorButtonBackgroundDisabled: colors.lightGrayishOrange,
  colorButtonSecondaryDisabled: colors.grey300,
  fontSizeButtonLarge: rem(1.8),
  fontSizeButtonMedium: rem(1.6),
  fontSizeButtonSmall: rem(1.4),

  // TOGGLE
  colorToggleNotChecked: colors.grey500,
  colorToggleChecked: colors.teal500,
  colorToggleSliderChecked: colors.teal300,
  colorToggleSliderNotChecked: colors.grey200,

  // SIZE TYPES:
  iconSizeMedium: rem(3.6),
  iconSizeLarge: rem(4.8),
  iconSizeExtraLarge: rem(8.4),
  iconSizeSmall: rem(2),
  iconSizeExtraSmall: rem(1),
  iconSizeSuperExtraSmall: rem(0.8),
  iconSizeDefault: rem(2.4),

  boxShadow: `0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.08)`,

  // DIALOG
  dialogTitleFontSize: rem(2.4),
  dialogTitleFontWeight: 600,
  dialogFontColor: colors.darkGray,
  dialogPadding: `${rem(3)} ${rem(4)}`,
  dialogBorderRadius: rem(0.4),
  dialogButtonMinWidth: rem(25),
  dialogButtonMinHeight: rem(0),

  // Alerts
  alertBorderRadius: '1rem',
  colorTextAlert: colors.darkGray,
  colorBackgroundAlertSuccessBackground: transparentize(0.9, colors.greenHaze),
  colorBackgroundAlertSuccess: colors.greenHaze,
  colorBackgroundAlertWarningBackground: transparentize(0.9, colors.amberA700),
  colorBackgroundAlertWarning: colors.amberA700,
  colorBackgroundAlertErrorBackground: transparentize(0.9, colors.red900),
  colorBackgroundAlertError: colors.red900,
  colorBackgroundAlertInfoBackground: transparentize(0.9, colors.blue900),
  colorBackgroundAlertInfo: colors.blue900,
  colorBackgroundAlertBundleBackground: transparentize(0.9, colors.blueWhale),
  colorBackgroundAlertBundle: colors.blueWhale,
  colorAlertGrayBackground: transparentize(0.9, colors.darkGray),
  colorAlertGrayBorder: colors.grey400,
  colorAlertGrayIcon: colors.darkGray,

  // Loading Mask
  colorBackgroundLoadingMask: transparentize(0.25, colors.grey000),

  // Seat
  seatMobileIconWidth: rem(2.8),
  seatMobileIconHeight: rem(2.8)
};
