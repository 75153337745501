import { ReactNode, useMemo } from 'react';
import * as ui from './BreadCrumbs.ui';
import { ROUTING_PATHS } from 'src/router/routingPaths';
import Icon from 'components/Icon';
import { PageNames } from 'src/Common.translations';
import { FormattedMessage } from '@triplake/lib-intl';
import { useSelector } from 'react-redux';
import { getBaseUrl } from 'src/store/config/configSelectors';

interface BreadCrumbsProps {
  path: string;
  customStyles?: string;
}

interface Crumb {
  path: string;
  label: {
    id: string;
    defaultMessage: string;
  };
}

const link = (path: string) => ({ label: PageNames[path], path });

const Crumbs: { [key: string]: Array<Crumb> } = {
  [ROUTING_PATHS.login]: [],
  [ROUTING_PATHS.registration]: [],
  [ROUTING_PATHS.forgotPassword]: [],
  [ROUTING_PATHS.newsletter]: [],
  [ROUTING_PATHS.user.dashboard]: [],
  [ROUTING_PATHS.user.profile]: [link(ROUTING_PATHS.user.dashboard)],
  [ROUTING_PATHS.user.myTransactions]: [link(ROUTING_PATHS.user.dashboard)],
  [ROUTING_PATHS.user.myBeneficiaries]: [link(ROUTING_PATHS.user.dashboard)],
  [ROUTING_PATHS.user.myNominees]: [link(ROUTING_PATHS.user.dashboard)],
  [ROUTING_PATHS.user.claimMiles]: [link(ROUTING_PATHS.user.dashboard)],
  [ROUTING_PATHS.user.useMiles.bookAwardTicket]: [link(ROUTING_PATHS.user.dashboard)],
  [ROUTING_PATHS.user.useMiles.useMilesForExtraBaggage]: [link(ROUTING_PATHS.user.dashboard)],
  [ROUTING_PATHS.user.useMiles.useMilesForUpgrading]: [link(ROUTING_PATHS.user.dashboard)],
};
export const BreadCrumbs = ({ path, customStyles }: BreadCrumbsProps) => {
  const baseUrl = useSelector(getBaseUrl);

  const crumbs: Array<ReactNode> = useMemo(() => {
    if (Crumbs[path]) {
      const crumbs = Crumbs[path].map((crumb: Crumb) => (
        <ui.ClickableBreadCrumb to={crumb.path} key={crumb.label.id}>
          <FormattedMessage {...crumb.label} />
        </ui.ClickableBreadCrumb>
      ));
      crumbs.unshift(
        <ui.BreadCrumbAnchor href={baseUrl} key="homeLink" data-testid="homeLink">
          <FormattedMessage {...PageNames[ROUTING_PATHS.home]} />
        </ui.BreadCrumbAnchor>,
      );
      crumbs.push(
        <ui.BreadCrumb key={PageNames[path].id}>
          <FormattedMessage {...PageNames[path]} />
        </ui.BreadCrumb>,
      );
      const length: number = crumbs.length - 1;
      for (let i = 0; i < length; ++i) crumbs.splice(i * 2 + 1, 0, <Icon name="chevronRightSmallDark" key={`separator-${i}`} />);

      return crumbs;
    }
    return [];
  }, [path, baseUrl]);

  return <ui.BreadCrumbsWrapper $styles={customStyles}>{crumbs}</ui.BreadCrumbsWrapper>;
};
