import { join } from '../../utils/urlUtils';
import { callApi, HTTPMethod } from '../utils/apiHelpers';

export const requestCreateSession = (tenant: string) => {
  const endpoint = join('acquireSession', tenant);
  return callApi(endpoint, HTTPMethod.POST);
};

export const extendSession = () => {
  return callApi('session/extend', HTTPMethod.POST);
};
