import styled, { Keyframes, keyframes } from 'styled-components';
import { Overlay as DialogOverlay } from 'components/Dialog/Dialog.ui';
import { HeaderDrawer } from '../components/Drawer/desktop/Drawer.desktop.ui';
import { LogoHeight, MobileLogoHeight, HeaderVerticalPadding, MobileHeaderVerticalPadding } from '../shared/Header.constants';

const disappear: Keyframes = keyframes`
    0% {
      opacity: 1;
      pointer-events: all;
    }  
    100% {
      opacity: 0;
      pointer-events: none;
    }
`;
const appear: Keyframes = keyframes`
    0% {
      opacity: 0;
      pointer-events: none;
    }  
    100% {
      opacity: 1;
      pointer-events: all;
    }
`;

export const Header = styled.div`
  padding: ${HeaderVerticalPadding} calc(${HeaderVerticalPadding} * 2);
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.secondaryTextColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.SM`padding: ${MobileHeaderVerticalPadding} calc(${MobileHeaderVerticalPadding} * 1.714);`};
`;

const menuWidth = '128rem';
const lgMenuWidth = '102rem';
const tabletLgMenuWidth = '90vw';
const mdMenuWidth = 'calc(100vw - 3.2rem)';
export const HeaderCenter = styled.div`
  margin: 0 8rem;
  width: ${menuWidth};
  ${({ theme }) => theme.mediaQueries.LG`
    width: ${lgMenuWidth};
    margin: 0 auto;
  `}
  ${({ theme }) => theme.mediaQueries.tabletLG`
    width: ${tabletLgMenuWidth};
    & > div:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    & > div:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
  ${({ theme }) => theme.mediaQueries.MD`width: ${mdMenuWidth};`};
  & > div:first-child {
    border-top-left-radius: ${props => props.theme.borderRadius};
    border-top-right-radius: ${props => props.theme.borderRadius};
  }
  & > div:last-child {
    border-bottom-left-radius: ${props => props.theme.borderRadius};
    border-bottom-right-radius: ${props => props.theme.borderRadius};
  }
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  font-size: 1.6rem;
  top: 0.8rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 8;
  &.disappear {
    animation: ${disappear} 1.3s ease 1 forwards;
  }
  &.appear {
    animation: ${appear} 1.3s ease 1 forwards;
  }
  &.smallDrawer {
    ${HeaderCenter} {
      display: flex;
      flex-direction: column;
      ${({ theme }) => (theme.isMobile ? 'margin: 0; width: 100vw;' : '')}
    }
    ${Header} {
      border-bottom-left-radius: ${props => props.theme.borderRadius};
    }
    ${HeaderDrawer} {
      align-self: flex-end;
    }
    ${({ theme }) => theme.mediaQueries.MD`
      div:first-child { border-bottom-left-radius: 0; }
      ${HeaderDrawer} { width: ${mdMenuWidth}; }
    `};
  }
`;

export const LogoLink = styled.a`
  display: flex;
  height: ${LogoHeight}; // 2rem
  //align-items: center;
  ${({ theme }) => theme.mediaQueries.SM`height: ${MobileLogoHeight};`};
`;

export const HeaderMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  ${({ theme }) => theme.mediaQueries.tabletLG`display: none;`};
`;

export const HeaderSecondaryMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.4rem;
`;

export const HeaderIconButton = styled.button<{ $active: boolean }>`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  position: relative;
  color: ${props => props.theme.secondaryTextColor};
  cursor: pointer;
  padding: 0.6rem 0;
  line-height: 1.3em;
  font-weight: ${props => props.theme.fontWeights.medium};
  ${props =>
    props.$active
      ? `
    &::before {
      background: ${props.theme.invertedTextColor};
      bottom: 0;
      content: "";
      height: .2rem;
      left: 0;
      position: absolute;
      width: 100%;
    }`
      : ''}
  img {
    height: 2.2rem;
  }
  svg {
    display: block;
  }
  & .languageCode {
    ${({ theme }) => (theme.isMobile ? 'display: none;' : '')}
  }
  ${({ theme }) => theme.mediaQueries.SM`padding: 0;`};
`;

export const MobileIconWrapper = styled(HeaderIconButton)`
  display: none;
  ${({ theme }) => theme.mediaQueries.tabletLG`display: block;`};
`;

export const DesktopIconWrapper = styled(HeaderIconButton)`
  ${({ theme }) => theme.mediaQueries.tabletLG`display: none;`};
`;

export const HeaderButton = styled(HeaderIconButton)`
  margin: 0 1rem;
`;

export const Overlay = styled(DialogOverlay)<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  z-index: -1;
`;
