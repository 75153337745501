import { ReactNode } from 'react';
import * as ui from './HeroBanner.ui';

export interface HeroBannerProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  color?: string;
  image?: string;
  background?: string;
  backgroundSize?: 'cover' | 'contain';
  backgroundPosition?: string;
  customStyles?: string;
}
export const HeroBanner = ({
  title,
  subtitle,
  color,
  background,
  backgroundSize,
  backgroundPosition,
  customStyles,
  image = 'header_level1_bg',
}: HeroBannerProps) => {
  return (
    <ui.HeroWrapper $background={background} $customStyles={customStyles}>
      <ui.HeroBackground $image={image} $backgroundSize={backgroundSize} $backgroundPosition={backgroundPosition}>
        <ui.Hero>
          {title && <ui.HeroTitle $color={color}>{title}</ui.HeroTitle>}
          {subtitle ? <ui.HeroSubtitle $color={color}>{subtitle}</ui.HeroSubtitle> : null}
        </ui.Hero>
      </ui.HeroBackground>
    </ui.HeroWrapper>
  );
};
