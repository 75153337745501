import styled from 'styled-components';

export const Label = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.textColor};
  width: 100%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.backgroundColor};
  position: relative;
  z-index: 1;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  svg {
    transform: rotate(90deg);
    transition: transform ${({ theme }) => theme.animationSpeed};
  } 
  /*&:focus {
    border: .1rem solid ${({ theme }) => theme.textColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.colors.primaryColor};
  }*/
`;

export const Content = styled.div`
  padding-left: 1.6rem;
  padding-top: 1.6rem;
  box-sizing: content-box;
  position: absolute;
  bottom: 0;
`;

export const ExpandableMenu = styled.div<{ $openedHeight: number; $closedHeight: number }>`
  overflow: hidden;
  width: 100%;
  position: relative;
  transition: height ${({ theme }) => theme.animationSpeed};
  height: ${({ $closedHeight }) => ($closedHeight ? `${$closedHeight}px` : 'auto')};
  &.openState {
    ${Label} svg {
      transform: rotate(-90deg);
    }
    height: ${({ $openedHeight }) => ($openedHeight ? `${$openedHeight}px` : 'auto')};
  }
`;
