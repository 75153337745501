import { defineMessages } from '@triplake/lib-intl';
import { ROUTING_PATHS } from 'src/router/routingPaths';

export const Common = defineMessages({
  add: {
    id: 'Common.add',
    defaultMessage: 'Add',
  },
  cancel: {
    id: 'Common.cancel',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'Common.close',
    defaultMessage: 'Close',
  },
  save: {
    id: 'Common.save',
    defaultMessage: 'Save',
  },
  continue: {
    id: 'Common.continue',
    defaultMessage: 'Continue',
  },
  ok: {
    id: 'Common.ok',
    defaultMessage: 'Ok',
  },
  remove: {
    id: 'Common.remove',
    defaultMessage: 'Remove',
  },
  edit: {
    id: 'Common.edit',
    defaultMessage: 'Edit',
  },
  confirm: {
    id: 'Common.confirm',
    defaultMessage: 'Confirm',
  },
  submit: {
    id: 'Common.submit',
    defaultMessage: 'Submit',
  },
  yes: {
    id: 'Common.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'Common.no',
    defaultMessage: 'No',
  },
  premiumEconomyClass: {
    id: 'Common.TravelClass.premiumEconomyClass',
    defaultMessage: 'Premium Economy',
  },
  businessClass: {
    id: 'Common.TravelClass.businessClass',
    defaultMessage: 'Business',
  },
  economyClass: {
    id: 'Common.TravelClass.economyClass',
    defaultMessage: 'Economy',
  },
  goBackTo: {
    id: 'Common.goBackTo',
    defaultMessage: 'Go back to {page}',
  },
  nominees: {
    id: 'Common.nominees',
    defaultMessage: 'Nominees',
  },
  beneficiaries: {
    id: 'Common.beneficiaries',
    defaultMessage: 'Beneficiaries',
  },
  emptyFieldError: {
    id: 'FormError.emptyFieldError',
    defaultMessage: 'The field can not be empty',
  },
  tooShortEntryError: {
    id: 'FormError.tooShortEntryError',
    defaultMessage: 'Entry too short. Minimum length is {length}',
  },
  tooLongEntryError: {
    id: 'FormError.tooLongEntryError',
    defaultMessage: 'Entry too long. Maximum length is {length}',
  },
  optional: {
    id: 'common.optional',
    defaultMessage: ' (Optional)',
  },
  terms: {
    id: 'Common.terms',
    defaultMessage: 'Terms and Conditions',
  },
});

export const PageNames = defineMessages({
  [ROUTING_PATHS.home]: {
    id: 'Common.PathName.home',
    defaultMessage: 'Home',
  },
  [ROUTING_PATHS.user.dashboard]: {
    id: 'Common.PathName.dashboard',
    defaultMessage: 'Dashboard',
  },
  [ROUTING_PATHS.login]: {
    id: 'Common.PathName.login',
    defaultMessage: 'Login',
  },
  [ROUTING_PATHS.registration]: {
    id: 'Common.PathName.registration',
    defaultMessage: 'Create your account',
  },
  [ROUTING_PATHS.forgotPassword]: {
    id: 'Common.PathName.forgotPassword',
    defaultMessage: 'Forgot password',
  },
  [ROUTING_PATHS.user.profile]: {
    id: 'Common.PathName.profile',
    defaultMessage: 'My profile',
  },
  [ROUTING_PATHS.user.myTransactions]: {
    id: 'Common.PathName.transactions',
    defaultMessage: 'My transactions',
  },
  [ROUTING_PATHS.user.myBeneficiaries]: {
    id: 'Common.PathName.beneficiaries',
    defaultMessage: 'My Beneficiaries',
  },
  [ROUTING_PATHS.user.myNominees]: {
    id: 'Common.PathName.nominees',
    defaultMessage: 'My Nominees',
  },
  [ROUTING_PATHS.user.claimMiles]: {
    id: 'Common.PathName.claimMiles',
    defaultMessage: 'Claim Missing Miles',
  },
  [ROUTING_PATHS.user.milesCalculator]: {
    id: 'Common.PathName.milesCalculator',
    defaultMessage: 'Miles Calculator',
  },
  [ROUTING_PATHS.newsletter]: {
    id: 'Common.PathName.newsletter',
    defaultMessage: 'Newsletter',
  },
  [ROUTING_PATHS.user.useMiles.bookAwardTicket]: {
    id: 'Common.PathName.bookAwardTicket',
    defaultMessage: 'Book Award Ticket',
  },
  [ROUTING_PATHS.user.useMiles.useMilesForExtraBaggage]: {
    id: 'Common.PathName.useMilesForExtraBaggage',
    defaultMessage: 'Extra baggage',
  },
  [ROUTING_PATHS.user.useMiles.useMilesForUpgrading]: {
    id: 'Common.PathName.useMilesForUpgrading',
    defaultMessage: 'Upgrading',
  },
});
