import styled from 'styled-components';
import { InputField, ClearButton, InputPlaceholder } from '../Input/Input.ui';

export const InputFileField = styled(InputField)`
  cursor: pointer;
`;

export const FileField = styled.input.attrs(() => ({ type: 'file' }))`
  visibility: hidden;
  width: 0;
  padding: 0;
  height: 0;
  position: absolute;
`;

const iconWidth = '2rem';
const rightMargin = '1.8rem';
const gap = '1.6rem';

export const FilePlaceholder = styled(InputPlaceholder)<{ $small: boolean }>`
  ${({ $small, theme }) =>
    $small
      ? `
    top: 1.1rem;
    ${theme.textStyles.caption.regular.join(' ')};
    ${theme.mediaQueries.MD`top: 1rem`}
  `
      : ''}
`;

export const FileClearButton = styled(ClearButton)`
  right: calc(${iconWidth} + ${rightMargin} + ${gap});
`;

export const UploadIcon = styled(ClearButton)`
  opacity: 1;
  & * {
    pointer-events: auto;
  }
  cursor: pointer;
`;
