import styled from 'styled-components';
import { getTransitions } from 'utils/commonUtils';

export const ChordTitle = styled.div`
  ${({ theme }) => theme.textStyles.header.h3};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.8rem;
  span {
    display: block;
    ${({ theme }) => theme.textStyles.subtitle.s3};
    font-weight: ${({ theme }) => theme.fontWeights.light || '400'};
  }
  &.clickable {
    cursor: pointer;
  }
  transition: ${({ theme }) => getTransitions(theme, 'color')};
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.header.h5.join(' ')}
    span {
      ${theme.textStyles.subtitle.s4};
      font-weight: ${theme.fontWeights.light};
    }
  `};
`;

export const ChordTitleBar = styled.div<{ $active: boolean }>`
  padding: ${({ theme }) => theme.defaultPadding} 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.4rem;
  background: ${({ theme }) => theme.backgroundColor || '#fff'};
  z-index: 2;
  position: relative;
  transition: ${({ theme }) => getTransitions(theme, 'padding-bottom')};
  &[aria-disabled='true'] ${ChordTitle} {
    color: ${({ theme }) => theme.disabledColor};
  }
  ${({ $active, theme }) =>
    $active
      ? `padding-bottom: 2.4rem;
  ${ChordTitle} {
    color: ${theme.primaryColor};
    span { color: ${theme.textColor}; }
  }`
      : `color: ${theme.textColor};`}
  & > button {
    min-height: calc(2.4rem * 1.3);
  }
  & .defaultIcon {
    transition: ${({ theme }) => getTransitions(theme, 'transform')};
  }
  ${({ theme, $active }) => theme.mediaQueries.SM`
    padding: ${theme.defaultMobilePadding} 0;
    ${$active && 'padding-bottom: 1.6rem;'}
    & > button { 
      min-height: calc(1.6rem * 1.3);
      max-height: calc(1.6rem * 1.3); 
    }
  `}
`;

export const ChordContent = styled.div`
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.defaultPadding || '4rem'};
  box-sizing: content-box;
  position: absolute;
  bottom: 0;
  ${({ theme }) => theme.mediaQueries.MD`
    padding-bottom: ${theme.defaultMobilePadding || '2.4rem'};
  `}
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme.backgroundColor || '#fff'};
  padding: 0 ${({ theme }) => theme.defaultPadding || '4rem'};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius || '1rem'};
  & > div:not(:first-child) {
    border-top: 0.1rem solid ${({ theme }) => theme.borderColor || '#888'};
  }
  ${({ theme }) => theme.mediaQueries.MD`
    padding: 0 ${theme.defaultMobilePadding || '2.4rem'};
  `}
`;

export const ChordWrapper = styled.div<{ $contentHeight?: number }>`
  position: relative;
  padding: 0;
  overflow: hidden;
  transition: ${({ theme }) => getTransitions(theme, 'padding-bottom')};
  &.active {
    padding-bottom: ${({ $contentHeight }) => $contentHeight || 0}px;
    ${ChordTitleBar} .defaultIcon {
      transform: rotate(-90deg);
    }
  }
  &:not(.active) {
    padding-bottom: 0 !important;
    ${ChordTitleBar} .defaultIcon {
      transform: rotate(90deg);
    }
  }
`;

export const ChordPanelItem = styled.div`
  padding: ${({ theme }) => theme.defaultPadding} 0;
  background: ${({ theme }) => theme.backgroundColor || '#fff'};
  z-index: 2;
  position: relative;
  ${({ theme }) => theme.mediaQueries.MD`
    padding: ${theme.defaultMobilePadding} 0;
  `}
  &:not(:first-child) {
    padding-top: 0;
  }
`;
