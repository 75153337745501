export const getCurrentUrl = (): string => window && window.location.origin;
export const getLocationHref = (): string => window && window.location.href;
export const getLocationPath = (): string => window && window.location?.pathname;
export const redirectToUrl = (url: string): void => window && window.location.replace(url);
export const getSearchParams = (): URLSearchParams => new URLSearchParams(window && window.location.search);

export const disableFingerDragCallback = (event: TouchEvent, draggableContainer?: HTMLElement | null): any => {
  if (draggableContainer instanceof HTMLElement && event.target instanceof HTMLElement && draggableContainer.contains(event.target)) return true;
  if (event.preventDefault) event.preventDefault();
  if (event.stopPropagation) event.stopPropagation();
  return false;
};

export const getMessageFrom500HTMLPage = (html: string): any => {
  if (!/<html>/i.test(html) && html.length < 1024) return html;
  const message = /<b>Message<\/b>\s*(.*?)\s*<\/p>\s*<p>\s*<b>Description<\/b>/s.exec(html)?.[1] ?? /<title>(.*?)<\/title>/s.exec(html)?.[1] ?? '';
  return message ? new DOMParser().parseFromString(message, 'text/html').body.textContent : message;
};
