import styled from 'styled-components';
import { getTransitions } from 'utils/commonUtils';
import { ChordTitle, ChordWrapper } from 'components/Accordion/Accordion.ui';
import { LogoHeight, HeaderVerticalPadding } from 'components/Header/shared/Header.constants';
import { MenuView } from 'components/Header/components/Drawer/mobile/Drawer.mobile.constants';

export const MobileDrawer = styled.div<{ $menu: MenuView }>`
  display: none;
  ${({ theme }) => theme.mediaQueries.tabletLG`
    display: block;
  `};
  background-color: ${({ theme }) => theme.backgroundColor || '#fff'};
  color: ${({ theme }) => theme.textColor};
  position: fixed;
  top: 0;
  left: ${({ $menu }) => {
    if ($menu === MenuView.MAIN_MENU) {
      return 0;
    }
    if ($menu === MenuView.SUB_MENU) {
      return -100;
    }
  }}vw;
  width: 200vw;
  height: 100vh;
  bottom: 0;
  transition: ${({ theme }) => getTransitions(theme.animationSpeed, 'left')};
  &&& {
    border-radius: 0;
  }
`;

export const MobileDrawerCloseButton = styled.div`
  position: fixed;
  top: 1.9rem;
  right: 2.3rem;
  cursor: pointer;
`;

export const MobileDrawerHeader = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  padding: ${HeaderVerticalPadding} calc(${HeaderVerticalPadding} * 2);
  padding-left: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(${LogoHeight} + (${HeaderVerticalPadding} * 2));
  button {
    justify-content: flex-start;
    width: calc(100% - 2.4rem);
  }
  .slideDown {
    transform: rotate(90deg);
    height: 24px;
  }
  .slideLeft {
    transform: rotate(-180deg);
    width: 22px;
  }
`;

export const MobileDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: ${({ theme }) => theme.defaultMobilePadding} 0;
  height: calc(100% - (${LogoHeight} + (${HeaderVerticalPadding} * 2)));
  overflow-y: scroll;
  ${ChordTitle} {
    ${({ theme }) => theme.textStyles.header.h4 || ''};
    & > button {
      min-height: calc(2rem * 1.3);
    }
  }
  ${ChordWrapper}:first-child {
    border-top: 0.1rem solid ${({ theme }) => theme.borderColor};
  }
  ${ChordWrapper}:last-child {
    border-bottom: 0.1rem solid ${({ theme }) => theme.borderColor};
  }
  a.linkButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.textStyles.header.h4 || ''};
  }
`;

export const MobileMyAccountMenuButton = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: flex-start;
  align-items: center;
  margin: 0 2.4rem;
  div {
    ${({ theme }) => theme.textStyles.subtitle.s3}
  }
`;

export const MobileMainMenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2.4rem;
`;

export const MobileMainMenuButton = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 2.4rem 0;
  ${({ theme }) => theme.textStyles.header.h4}
`;

export const MobileMenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  & > a,
  & > button {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 1.6rem;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const MobileMenuImageButton = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  width: 100%;
  max-height: 22rem;
  img {
    display: block;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  &::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    border-radius: ${({ theme }) => theme.borderRadius};
    content: ' ';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
`;

export const MobileMenuImageButtonContent = styled.div`
  position: absolute;
  bottom: 1.6rem;
  left: 1.6rem;
  right: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  & * {
    color: ${({ theme }) => theme.invertedTextColor};
  }
  button {
    width: 100%;
    border: 0.1rem solid ${({ theme }) => theme.invertedTextColor};
    justify-content: center;
  }
`;

export const MobileHelpAndSearchBox = styled.div`
  padding: 0 2.4rem;
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  button {
    padding: 1.6rem 0;
  }
`;

export const MobileBottomMenu = styled.div<{ $show: boolean }>`
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: ${({ $show }) => ($show ? '0' : '100vh')};
  transition: ${({ theme }) => getTransitions(theme.animationSpeed, 'top')};
  background: ${({ theme }) => theme.backgroundColor};
`;

export const MobileHelpMenu = styled.div`
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  header {
    ${({ theme }) => theme.textStyles.subtitle.s3}
  }
`;

export const MobileDrawerContentMenu = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
`;

export const MainMenu = styled(MobileDrawerContentMenu)`
  left: 0;
`;

export const SubMenu = styled(MobileDrawerContentMenu)`
  left: 100vw;
`;
