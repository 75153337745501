import { createGradient } from '../utils';
import { ThemeGradients } from 'types/themes';

const tamarillo = '#a6192e';
const secondaryRed = '#8c1427';
const redDropdown = '#DD001F';
const blackcurrant = '#2e2637';
const whiteSand = '#f6f6f6';
const silverChalice = '#a9a9a9';
const goldenSand = '#f6cd7f';
const greenHaze = '#00aa32';

export const colors = {
  secondaryRed,
  tamarillo,
  roman: '#db5259',
  cardinal: '#d22730',
  pippin: '#f8dcde',
  black: '#000000',
  mineShaft: '#353535',
  darkGray: '#525252',
  dustyGray: '#979797',
  silverChalice,
  alto: '#dddddd',
  eiderWhite: '#f1f1f1',
  alabaster: '#f8f8f8',
  white: '#ffffff',
  orangeRoughly: '#cb541c',
  orange: '#ff6720',
  flushOrange: '#fe7600',
  yellowOrange: '#ffa043',
  orangePeel: '#ee9c00',
  webOrange: '#f2a900',
  goldenGlass: '#dfac1e',
  dawnPink: '#f7f1ec',
  dodgerBlue: '#1e90ff',
  cerulean: '#009ee3',
  robinsEggBlue: '#03c6eb',
  malibu: '#91e1f9',
  blackSqueeze: '#e1eef4',
  heather: '#bcc8d7',
  eucalyptus: '#299147',
  greenHaze,
  pistachio: '#82c800',
  zanah: '#d4eed9',
  nebula: '#d7dfde',
  blackcurrant,
  mako: '#424853',
  rockBlue: '#9cb2ce',
  greyLight: '#53565A',
  greySecondary: '#353535',
  grey: '#424853',
  grey3: '#75787B',
  grey5: '#353535',
  greyBackground: '#F8F8F8',
  red: '#D22730',
  redDropdown,
  redBright: '#FF000C',
  redDark: '#B00018',
  lightBlue: '#009EE3',
  yellow: '#EE9C00',
  green: '#00AA32',
  lightOrange: '#FE7600',
  transparent: '#fff0',
  blueMist: '#a2cbce',
  whiteSand: '#f6f6f6',

  primaryColor: tamarillo,
  textColor: blackcurrant,
  darkSiteBackgroundColor: blackcurrant,
  homeBackgroundColor: whiteSand,
  innerPanelsBgColor: whiteSand,
  backgroundColor: '#fff',
  bodyColor: whiteSand,
  overlayColor: '#00000088',
  borderColor: blackcurrant,
  disabledBackgroundColor: silverChalice,
  dropDownCategoryTextColor: blackcurrant,
  level1: secondaryRed,
  level2: greenHaze,
  level3: goldenSand,

  primaryButtonBackground: tamarillo,
  primaryButtonHoverBackground: secondaryRed,
  primaryButtonForeground: '#fff',
  primaryButtonHoverForeground: '#fff',
  secondaryButtonForeground: tamarillo,
  secondaryButtonBorder: tamarillo,
  secondaryButtonHoverForeground: secondaryRed,
  secondaryButtonHoverBorder: secondaryRed,
  linkButtonForeground: tamarillo,
  linkButtonHoverForeground: secondaryRed,
  actionButtonColor: redDropdown,

  /* Notifications */
  infoDark: '#4175BE',
  infoLight: '#D9E3F2',
  successDark: '#3F844E',
  successLight: '#D9E6DC',
  warningDark: '#DA924F',
  warningLight: '#F8E9DC',
  errorDark: '#BE2239',
  errorLight: '#F2D3D7',
  pending: '#424853',
};

export const gradients: ThemeGradients = {
  hero: createGradient(90, colors.cardinal, colors.tamarillo),
  defaultImageOverlay: '',
  footer: createGradient(180, colors.alabaster, colors.alto),
  textOverImage: createGradient(
    180,
    { color: '#EEE6DEa1', position: 0 },
    { color: '#EEE6DE64', position: 75 },
    { color: '#EEE6DE00', position: 100 },
  ),
};
