import styled from 'styled-components';
import { CommonErrorStyles } from '../Form.ui';

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
export const CheckBoxWrapper = styled.div<{ $dimLabelWhenOff?: boolean; disabled: boolean; $error: boolean }>`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.1rem;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ $dimLabelWhenOff }) =>
    $dimLabelWhenOff &&
    `input:not(:checked) + label {
		opacity: .5;
	}`};
  ${({ $error, theme }) =>
    $error
      ? `
    border-radius: ${theme.borderRadius};
    outline: 0.1rem solid ${theme.colors.errorDark};
    outline-offset: 1rem;`
      : ''}
`;

const chkBoxSize = '2.4rem';
export const CheckboxInput = styled.input.attrs(({ disabled }) => {
  const attributes = { type: 'checkbox' };
  if (disabled) attributes['aria-disabled'] = true;
  return attributes;
})<{ $alignToTop: boolean }>`
  width: 0;
  height: ${chkBoxSize};
  margin-right: ${chkBoxSize};
  border-radius: 0.4rem;
  position: relative;
  top: -1.2rem;
  ${({ $alignToTop }) => ($alignToTop ? 'align-self: flex-start; top: 0;' : '')}
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${chkBoxSize};
    height: ${chkBoxSize};
    background: ${({ theme }) => theme.backgroundColor || '#fff'};
    border-radius: 0.4rem;
    border: solid 0.2rem ${({ theme, disabled }) => (disabled ? theme.disabledColor || '#073B5014' : theme.primaryColor || '#000')};
    pointer-events: none;
  }
  &:checked {
    &::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0.4rem;
      width: 1.6rem;
      height: 0.9rem;
      border: 0.2rem ${({ theme }) => theme.backgroundColor || '#fff'};
      border-radius: 0.1rem;
      border-style: none none solid solid;
      transform: rotate(-45deg);
      z-index: 1;
      pointer-events: none;
    }
    &::after {
      background: ${({ theme, disabled }) => (disabled ? theme.disabledColor || '#073B5014' : theme.primaryColor || '#000')};
    }
  }
  &:focus::after {
    outline: 0.1rem solid ${({ theme }) => theme.colorAccent};
    outline-offset: -0.2rem;
    border-width: 0.3rem;
  }
`;

export const SwitchInput = styled.input.attrs(() => ({ type: 'checkbox' }))`
  width: 2.8rem;
  height: 1.4rem;
  border-radius: 0.6rem;
  position: relative;
  appearance: none;
  &::before {
    content: '';
    position: absolute;
    top: -0.1rem;
    left: -0.1rem;
    width: 3rem;
    height: 1.6rem;
    border-radius: 0.8rem;
    pointer-events: none;
    transition: background ${({ theme }) => theme.animationSpeed || '512ms'};
    background: ${({ theme, disabled }) => (disabled ? theme.disabledColor || '#073B5014' : theme.borderColor || '#073B500A')};
  }
  &:checked::before {
    background: ${({ theme, disabled }) => (disabled ? theme.disabledColor || '#073B5014' : theme.primaryColor || '#000')};
  }
  &::after {
    content: '';
    position: absolute;
    transition: left ${({ theme }) => theme.animationSpeed || '512ms'};
    top: 0;
    left: 0;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white || '#fff'};
    pointer-events: none;
  }
  &:checked::after {
    left: calc(100% - 1.4rem);
  }
`;

export const CheckboxLabel = styled.label`
  ${({ theme }) => theme.textStyles.body.regular}
  transition: opacity ${({ theme }) => theme.animationSpeed || '512ms'};
`;

export const CheckboxErrorMessage = styled(CommonErrorStyles)`
  padding: 0 1.6rem;
`;
