import { useMemo, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import LoadingMask from 'components/LoadingMask';
import { Layout } from 'components/Layout';
import { PageFooter } from 'components/Footer';
import { useAuthWrapper } from 'src/hooks/useAuthWrapper';
import { SplashScreen } from 'components/SplashScreen';
import { PageHeader } from 'components/Header';
import { pages, TPageComponent } from './pages';

const paths: string[] = Object.keys(pages);

export const Router = () => {
  const auth = useAuthWrapper();

  const routes = useMemo(() => {
    return paths.map((path: string) => (
      <Route
        key={path}
        path={path}
        Component={(routeProps: any) => {
          const DynamicComponent: TPageComponent = pages[path];
          return (
            <Layout path={path}>
              <Suspense fallback={<LoadingMask />}>
                <DynamicComponent {...routeProps} />
              </Suspense>
            </Layout>
          );
        }}
      />
    ));
  }, []);

  if (!auth.isInitialized) return <SplashScreen />;

  return (
    <BrowserRouter>
      <PageHeader />
      <Routes>{routes}</Routes>
      <PageFooter />
    </BrowserRouter>
  );
};
