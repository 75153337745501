import { MegaMenuItem, MegaMenuTabColumn } from 'types/Layout';
import { useBaseUrl } from 'src/hooks/useBaseUrl';
import Icon from 'components/Icon';
import Image from 'components/Image';
import { getSourcesFromThumbImage } from 'components/Header/shared/Header.utils';
import * as ui from './Drawer.desktop.ui';

interface DrawerContentProps {
  content: Array<MegaMenuTabColumn>;
}

const DrawerContent = ({ content }: DrawerContentProps) => {
  const getUrl = useBaseUrl();

  const columns = content.map((column: Array<MegaMenuItem>, columnKey) => (
    <ui.Column key={columnKey}>
      {column.map((item, elemKey) => {
        const title = item.title;
        const icon = title.add_icon ? <img alt={title.title_icon} src={getUrl(`build/images/${title.title_icon}-icon.svg`)} /> : null;
        const label = title.is_url ? title.url.text : title.title;
        const titleComponent = !label ? null : title.is_url ? (
          <ui.ColumnLinkHeader href={getUrl(title.url.target)} target={title.url.target || '_self'}>
            {icon}
            {label}
          </ui.ColumnLinkHeader>
        ) : (
          <ui.ColumnHeader>
            {icon}
            {label}
          </ui.ColumnHeader>
        );
        const linkGroup = item.childLinks.length ? (
          <ui.LinksGroup $hasIndent={title.add_icon}>
            {item.childLinks.map((link, linkKey) => (
              <ui.ColumnLink key={linkKey} href={getUrl(link.path)} target={link.target || '_self'}>
                {link.text}
              </ui.ColumnLink>
            ))}
          </ui.LinksGroup>
        ) : null;

        const childButton = item.childButton;
        const buttonImageSources = /-thumb/.test(childButton.imagePath) ? getSourcesFromThumbImage(getUrl(childButton.imagePath)) : undefined;
        const button = childButton.text ? (
          <>
            <ui.ImageHolder $width={255} $height={336}>
              <Image src={getUrl(childButton.imagePath)} alt={childButton.description} lazyLoad sources={buttonImageSources} />
            </ui.ImageHolder>
            <ui.TileDescriptionDiv>
              <ui.TileDescription>{childButton.description}</ui.TileDescription>
              <ui.TileLinkButton href={childButton.path} target={childButton.target}>
                {childButton.text}
              </ui.TileLinkButton>
            </ui.TileDescriptionDiv>
          </>
        ) : null;

        const tiles = item.childTiles.length ? (
          <ui.SimpleColumnTilesDiv>
            {item.childTiles.map((tile, tileKey) => {
              const sources = /-thumb/.test(tile.imagePath) ? getSourcesFromThumbImage(getUrl(tile.imagePath)) : undefined;
              return (
                <ui.SimpleColumnTileElement key={tileKey} href={getUrl(tile.path)} target={tile.target || '_self'}>
                  <ui.ImageHolder>
                    <Image src={getUrl(tile.imagePath)} alt={tile.text} lazyLoad sources={sources} />
                  </ui.ImageHolder>
                  <ui.SimpleColumnTileLabel>
                    <div>{tile.text}</div>
                    <Icon name="chevronRightSmallLight" />
                  </ui.SimpleColumnTileLabel>
                </ui.SimpleColumnTileElement>
              );
            })}
          </ui.SimpleColumnTilesDiv>
        ) : null;

        return (
          <div key={elemKey}>
            {titleComponent}
            {linkGroup}
            {tiles}
            {button}
          </div>
        );
      })}
    </ui.Column>
  ));

  return <>{columns}</>;
};

export default DrawerContent;
