export const FLOWS = {
  BASIC: 'booking',
  CHECKIN: 'checkin',
  MULTICITY: 'multicity',
  MMB: 'mmb',
  MMB_EXCHANGE: 'mmbExchange'
};

export const FLOWS_BACKEND_MAPPING = {
  [FLOWS.BASIC]: 'BOOKING',
  [FLOWS.CHECKIN]: 'CHECKIN',
  [FLOWS.MMB]: 'MMB'
};

export const TRIP_TYPES = {
  ONE_WAY: 'OW',
  ROUND_TRIP: 'RT',
  MULTICITY: 'MULTICITY'
};

// temporary; Will be changed with multicity impl
export const BOUND_TYPE = [['bound'], ['outbound', 'inbound', 'inbound', 'inbound'], ['bound1', 'bound2', 'bound3', 'bound4']];

export const SEAT_MAP_SLOT_TYPES = {
  SEAT: 'seat',
  EMPTY: 'empty',
  AISLE: 'aisle',
  TOILET: 'toilet',
  TOILET_BABY_CHANGE: 'toilet_with_baby_change',
  BULKHEAD: 'bulkhead',
  GALLEY: 'galley'
};

export const SEAT_MAP_SLOT_FEATURES = {
  EXIT_ROW: 'EXIT_ROW',
  WINDOW: 'WINDOW',
  AISLE: 'AISLE',
  PET: 'PET',
  EXTRA_LEG_ROOM: 'EXTRA_LEG_ROOM'
};

export const SSR_TYPES = {
  PET_IN_CABIN: 'PETC'
};

export const SEAT_MAP_PLANE_SIDE = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
};

export const PHONE_NUMBER_TYPES = {
  PRIMARY: 'phoneNumber',
  EMERGENCY: 'emergencyPhoneNumber'
};

export const PAYMENT_TYPES = {
  // must be the same enum as from API - GET /payment/methods
  CREDIT_CARD: 'CREDIT_CARD',
  PAY_PAL: 'PAY_PAL',
  CASH_DEPOSIT: 'CASH_DEPOSIT',
  ONLINE_BANK_TRANSFER: 'ONLINE_BANK_TRANSFER',
  OFFLINE_BANK_TRANSFER: 'OFFLINE_BANK_TRANSFER',
  KIOSK: 'KIOSK',
  VOUCHER: 'VOUCHER',
  LOYALTY_PROGRAM: 'LOYALTY_PROGRAM',
  FREE_OF_CHARGE: 'FREE_OF_CHARGE',
  PAY_LATER: 'PAY_LATER',
  GENERAL: 'GENERAL'
};

export const PAYMENT_SUBTYPES = {
  MASTER_CARD_INGENICO: 'MASTER_CARD_INGENICO',
  VISA_INGENICO: 'VISA_INGENICO',
  MAESTRO_INGENICO: 'MAESTRO_INGENICO',
  MASTER_CARD_PAYU: 'MASTER_CARD_PAYU',
  VISA_PAYU: 'VISA_PAYU',
  MAESTRO_PAYU: 'MAESTRO_PAYU',
  MAESTRO_PAYSAFE: 'MAESTRO_PAYSAFE',
  MASTERCARD_PAYSAFE: 'MASTERCARD_PAYSAFE',
  VISA_PAYSAFE: 'VISA_PAYSAFE',
  MASTERCARD_PAYONE: 'MASTERCARD_PAYONE',
  VISA_PAYONE: 'VISA_PAYONE',
  MAESTRO_PAYONE: 'MAESTRO_PAYONE',
  DINERS_CLUB_PAYONE: 'DINERS_CLUB_PAYONE',
  JCB_PAYONE: 'JCB_PAYONE',
  UATP: 'UATP',
  AIR_PLUS: 'AIR_PLUS',
  CARD_AVANTAJ: 'CARD_AVANTAJ',
  PAY_PAL: 'PAY_PAL',
  IDEAL: 'IDEAL',
  SOFORT: 'SOFORT',
  GIRO_PAY: 'GIRO_PAY',
  RAIFFEISEN_BANK: 'RAIFFEISEN_BANK',
  BANCPOST: 'BANCPOST',
  ROM_POST: 'ROM_POST',
  BRD: 'BRD',
  BRD_BANK_TRANSFER: 'BRD_BANK_TRANSFER',
  BCR: 'BCR',
  QIWI: 'QIWI',
  ZEBRA: 'ZEBRA',
  PAY_POINT: 'PAY_POINT',
  VOUCHER: 'VOUCHER',
  ROM_PAY: 'ROM_PAY',
  INGENICO_BANK_TRANSFER: 'INGENICO_BANK_TRANSFER',
  BNP: 'BNP',
  BANCONTACT: 'BANCONTACT',
  MASTER_CARD_TWISPAY: 'MASTER_CARD_TWISPAY',
  MAESTRO_TWISPAY: 'MAESTRO_TWISPAY',
  LOYALTY_PROGRAM_SPLIT_PAYMENT: 'LOYALTY_PROGRAM_SPLIT_PAYMENT',
  APCO: 'APCO',
  INGENICO: 'INGENICO',
  FREE_OF_CHARGE: 'FREE_OF_CHARGE',
  IATA_PAY: 'IATA_PAY',
  PAY_LATER: 'PAY_LATER',
  GTBANK: 'GTBANK',
  CELLPOINT_DIGITAL: 'CELLPOINT_DIGITAL'
};

export const PAYMENT_STATUS = {
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  REFUSED: 'REFUSED',
  ACCEPTED: 'ACCEPTED',
  UNKNOWN: 'UNKNOWN'
};

export const FORMS = {
  PASSENGERS_PAGE_FORM_ID: 'PassengersPageForm',
  PAYMENT_PAGE_FORM_ID: 'PaymentPageForm',
  MANAGE_MY_BOOKING_FORM_ID: 'ManageMyBookingForm',
  MMB_PASSENGERS_PAGE_FORM_ID: 'MMBPassengersPageForm',
  MMB_CONTACT_PAGE_FORM_ID: 'MMBContactDetailsPageForm',
  PAYSAFE_CARDHOLDER_FORM_ID: 'PaysafeCardholderForm',
  CHECKIN_SEARCH_FORM_ID: 'CheckinSearchForm',
  REGISTER_ACCOUNT_ID: 'RegisterAccount'
};

export const REQUEST_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  NONE: 'NONE'
};

export const BOOKING_STATUS = {
  DEFAULT: 'DEFAULT',
  HOLD: 'HOLD',
  CONFIRMED: 'CONFIRMED'
};

export const ITINERARY_BREAKDOWN_TYPES = {
  SEGMENT: 'SEGMENT',
  ITINERARY_PART: 'ITINERARY_PART',
  ITINERARY: 'ITINERARY'
};

export const OFFERING_TYPE = {
  PER_ITINERARY_PART: 'ITINERARY_PART',
  PER_ITINERARY: 'ITINERARY',
  PER_SEGMENT: 'SEGMENT'
};

export const ANCILLARY_TYPES = {
  SEATS: 'SEAT',
  BAGS_INCLUDED: 'BAGS_INCLUDED',
  BAGS: 'BAGGAGE',
  BAG_UPGRADES: 'BAG_UPGRADES',
  MEALS: 'MEAL',
  MEAL_BUNDLES: 'MEAL_BUNDLE',
  PETS: 'PET',
  EQUIPMENT: 'EQUIPMENT',
  FAST_TRACKS: 'FAST_TRACK',
  LOUNGES: 'LOUNGE',
  INSURANCE: 'INSURANCE',
  PRIORITY_EXTRAS: 'PRIORITY_EXTRAS',
  BUNDLE: 'BUNDLE'
};

export const EXTRAS_PATHS = {
  SEAT: 'seat',
  BAGS_INCLUDED: 'includedBags',
  BAGGAGE: 'bags',
  BAG_UPGRADES: 'bagUpgrades',
  MEAL: 'meals',
  MEAL_BUNDLE: 'mealBundles',
  PET: 'pets',
  EQUIPMENT: 'equipment',
  PRIORITY_EXTRAS: 'priorityExtras',
  BUNDLE: 'bundle',
  INSURANCE: 'insurance'
};

/**
 * Mapping for backend bundle response
 */
export const EXTRAS_IN_BUNDLES = {
  SEAT: 'SEAT',
  BAG: 'BAGGAGE',
  PRIORITY_EXTRA: 'PRIORITY_EXTRAS',
  EQUIPMENT: 'EQUIPMENT'
};

export const PASSENGER_SSR_TYPES = {
  UNACCOMPANIED_MINORS: 'UNACCOMPANIED_MINOR',
  SPECIAL_ASSISTANCE: 'SPECIAL_ASSISTANCE'
};

export const ALL_EXTRAS_TYPES = {
  BUNDLES: 'BUNDLES',
  ...ANCILLARY_TYPES,
  ...PASSENGER_SSR_TYPES
};

export const ALL_EXTRAS_TYPES_NO_BUNDLES = {
  ...ANCILLARY_TYPES,
  ...PASSENGER_SSR_TYPES
};

export const MMB_ENABLED_EXTRAS = {
  bags: ANCILLARY_TYPES.BAGS,
  equipments: ALL_EXTRAS_TYPES.EQUIPMENT,
  meals: ALL_EXTRAS_TYPES.MEALS,
  mealBundles: ALL_EXTRAS_TYPES.MEAL_BUNDLES,
  // pets: ALL_EXTRAS_TYPES.PETS, Disabled for release
  priorityExtras: ANCILLARY_TYPES.PRIORITY_EXTRAS,
  seats: ANCILLARY_TYPES.SEATS
};

// used by defineDynamicMessages and webpack/babel-plugin-dynamic-messages.js
// keys must be equal to enum keys in alloy-admin-rest
export const REFERENCE_DATA_TYPES = {
  AIRPORT: 'AIRPORT',
  CITY: 'CITY',
  COUNTRY: 'COUNTRY',
  NATIONALITY: 'NATIONALITY',
  LANGUAGE: 'LANGUAGE',
  CURRENCY: 'CURRENCY',
  FARE_FAMILY: 'FARE_FAMILY',
  FARE_FAMILY_FEATURE: 'FARE_FAMILY_FEATURE',
  PASSENGER_TYPE: 'PASSENGER_TYPE',
  PASSENGER_TITLE: 'PASSENGER_TITLE',
  FREQUENT_FLYER_PROGRAM: 'FREQUENT_FLYER_PROGRAM',
  GENDER: 'GENDER',
  FEE_TYPE: 'FEE_TYPE',
  ANCILLARY_MEAL: 'ANCILLARY_MEAL',
  ANCILLARY_PET: 'ANCILLARY_PET',
  ANCILLARY_EQUIPMENT: 'ANCILLARY_EQUIPMENT',
  ANCILLARY_FAST_TRACK: 'ANCILLARY_FAST_TRACK',
  ANCILLARY_LOUNGE: 'ANCILLARY_LOUNGE',
  ANCILLARY_PRIORITY_EXTRA: 'ANCILLARY_PRIORITY_EXTRA',
  SEAT_SERVICE: 'SEAT_SERVICE',
  SEAT_RESTRICTION: 'SEAT_RESTRICTION',
  SPECIAL_ASSISTANCE: 'SPECIAL_ASSISTANCE',
  UNACCOMPANIED_MINOR: 'UNACCOMPANIED_MINOR',
  BUNDLE: 'BUNDLE',
  PAYMENT: 'PAYMENT',
  INSURANCE: 'INSURANCE',
  CARRIER: 'CARRIER',
  RELATION: 'RELATION'
};

export const PAYMENT_SUMMARY_TYPES = {
  PAYMENT_TOTAL: 'paymentTotal',
  BALANCE_DUE: 'balanceDue',
  PAYMENT_RECEIVED: 'paymentReceived',
  DISCOUNT: 'discount',
  FARE_EXCLUDING_FEES: 'fareExcludingFees',
  TAXES_AND_FEES: 'taxesAndFees',
  SURCHARGE: 'surcharge',
  YQ: 'YQ',
  YR: 'YR',
  BUS: 'BUS'
};

export const BUNDLES = 'bundles';

export const BUNDLE_TYPES = {
  INCLUDED: 'Included',
  ADD_ON: 'AddOn'
};

export const BUNDLE_DESELECT_OPTIONS = {
  ORIGINAL: 'ORIGINAL',
  INCLUDED: 'INCLUDED'
};

export const BUNDLE_UPGRADE_TYPES = {
  NONE: 'none',
  ONE_WAY: 'oneWayTrip',
  ROUND_TRIP: 'roundTrip',
  INBOUND: 'inbound',
  OUTBOUND: 'outbound'
};

export const DEEPLINK_STATUS = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
  NONE: 'NONE'
};

export const DEEPLINK_KEY = {
  PASSENGERS: 'deeplinkPassengers'
};

export const DISPLAY_TYPES = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET'
};

export const VALIDATION_STATUS = {
  FAILURE: `FAILURE`,
  SUCCESS: `SUCCESS`
};

export const PAYONE_STATUS_TYPES = {
  FORM_LOADED: 'FORM_LOADED',
  FORM_INCOMPLETE: 'FORM_INCOMPLETE',
  CREDIT_CARD_CHECK_IN_PROGRESS: 'CREDIT_CARD_CHECK_IN_PROGRESS',
  CREDIT_CARD_CHECK_FAILURE: 'CREDIT_CARD_CHECK_FAILURE',
  PAYMENT_IN_PROGRESS: 'PAYMENT_IN_PROGRESS',
  PAYMENT_3DS: 'PAYMENT_3DS',
  PAYMENT_3DS_ERROR: 'PAYMENT_3DS_ERROR',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS'
};

export const PAYMENT_STATUS_TYPES = {
  FORM_LOADED: 'FORM_LOADED',
  FORM_INCOMPLETE: 'FORM_INCOMPLETE',
  CREDIT_CARD_CHECK_IN_PROGRESS: 'CREDIT_CARD_CHECK_IN_PROGRESS',
  CREDIT_CARD_CHECK_FAILURE: 'CREDIT_CARD_CHECK_FAILURE',
  PAYMENT_IN_PROGRESS: 'PAYMENT_IN_PROGRESS',
  PAYMENT_3DS: 'PAYMENT_3DS',
  PAYMENT_3DS_ERROR: 'PAYMENT_3DS_ERROR',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_FORM_ERROR: 'PAYMENT_FORM_ERROR'
};

export const DATE_ERROR_TYPES = {
  INVALID_FORMAT: 'invalidDateFormat',
  START_DATE_AFTER_END_DATE: 'startDateAfterEndDate'
};

export const DATE_FORMATS = ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD MM YYYY', 'YYYY-MM-DD', 'YYYY MM DD'];

export const ANCILLARIES_PATH_IN_BOOKING = {
  [ALL_EXTRAS_TYPES.SEATS]: 'ancillaries.seats',
  [ALL_EXTRAS_TYPES.BAGS]: 'ancillaries.bags',
  [ALL_EXTRAS_TYPES.BAG_UPGRADES]: 'ancillaries.bagUpgrades',
  [ALL_EXTRAS_TYPES.BAGS_INCLUDED]: 'ancillaries.includedBags',
  [ALL_EXTRAS_TYPES.MEALS]: 'ancillaries.meals',
  [ALL_EXTRAS_TYPES.MEAL_BUNDLES]: 'ancillaries.mealBundles',
  [ALL_EXTRAS_TYPES.PETS]: 'ancillaries.pets',
  [ALL_EXTRAS_TYPES.EQUIPMENT]: 'ancillaries.equipments',
  [ALL_EXTRAS_TYPES.LOUNGES]: 'ancillaries.lounges',
  [ALL_EXTRAS_TYPES.FAST_TRACKS]: 'ancillaries.fastTracks',
  [ALL_EXTRAS_TYPES.INSURANCE]: 'ancillaries.insurances',
  [ALL_EXTRAS_TYPES.SPECIAL_ASSISTANCE]: 'ancillaries.specialAssistance',
  [ALL_EXTRAS_TYPES.UNACCOMPANIED_MINORS]: 'ancillaries.unaccompaniedMinors',
  [ALL_EXTRAS_TYPES.PRIORITY_EXTRAS]: 'ancillaries.priorityExtras',
  [ALL_EXTRAS_TYPES.BUNDLES]: 'ancillaries.bundles'
};

export const SCHEDULE_STATUS = {
  SOLD_OUT: 'SOLD_OUT',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  AVAILABLE: 'AVAILABLE'
};

export const HOTELS_PROVIDERS = {
  BOOKING_COM: 'BOOKING_COM',
  HOTELS_COM: 'HOTELS_COM'
};

export const ITINERARY_PART_TYPES = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound'
};

export const BOUND_TYPES = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
  ONEWAY: 'oneway'
};

export const ERROR_TYPES = {
  INCORRECT_CREDENTIALS: 'INCORRECT_CREDENTIALS',
  CLASS_SOLD_OUT: 'The requested class of service is sold out.',
  PRICE_FETCH_FAIL: 'Error calling POST itinerary/price',
  BOOKING_FETCH_FAIL: 'Error calling POST booking/retrieve',
  MMB_ERROR: 'manageMyBooking.unableToModifyNotTicketedBooking',
  INGENICO_PAY_ERROR: 'Error calling POST payment/pay/CREDIT_CARD/INGENICO',
  INGENICO_CONFIRM_PAYMENT_ERROR: 'Error calling POST payment/status/CREDIT_CARD/INGENICO',
  MMB_RESERVATION_RETRIEVAL: 'ERR.SSW.PNR.NOT_FOUND'
};

export const WARNING_TYPES = {
  INVALID_FFP_CARD: 'Invalid FFP card'
};

export const MMB_ACTION_TYPES = {
  MODIFY_FLIGHTS: 'MODIFY_FLIGHTS',
  MODIFY_EXTRAS: 'MODIFY_EXTRAS',
  MODIFY_PASSENGERS: 'MODIFY_PASSENGERS',
  MODIFY_CONTACT_DETAILS: 'MODIFY_CONTACT_DETAILS',
  VIEW_ORIGINAL_DOCUMENTS: 'VIEW_ORIGINAL_DOCUMENTS',
  REFUND_BOOKING: 'REFUND_BOOKING'
};

export const BOOKING_CATEGORIES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  GDS: 'GDS'
};

export const LOADER_TYPES = {
  SMALL: 'SMALL',
  LARGE: 'LARGE'
};

export const LOADER_BY_PAGE_STATUS = {
  PAGE_LOAD: 'PAGE_LOAD',
  PAGE_SUBMIT: 'PAGE_SUBMIT',
  PAGE_REDIRECT: 'PAGE_REDIRECT'
};
