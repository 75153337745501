import { createGlobalStyle } from 'styled-components';
import { AirlineTheme } from 'types/themes';

export const GlobalStyle = createGlobalStyle<{ theme: AirlineTheme }>`

    @-ms-viewport {
      width: device-width;
    }

    html {
      line-height: initial;
      font-size: ${props => props.theme.fontBaseSize || '62.5%'};
      box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    body {
      font-family: ${({ theme }) => theme.fontFamilyPrimary};
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
      padding: 0;
    }

    h1 small,
    h2 small,
    h3 small,
    h4 small,
    h5 small,
    h6 small {
      opacity: 0.54;
      font-size: 0.6em;
    }

    h1 {
      ${({ theme }) => theme.textStyles.header.h1};
    }

    h2 {
      ${({ theme }) => theme.textStyles.header.h2};
    }

    h3 {
      ${({ theme }) => theme.textStyles.header.h3};
    }

    h4 {
      ${({ theme }) => theme.textStyles.header.h4};
    }

    h5 {
      ${({ theme }) => theme.textStyles.header.h5};
    }

    p {
      ${({ theme }) => theme.textStyles.body.regular};
    }

    a {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: none;
    }

    input,
    select {
      color: inherit;
      font-family: inherit;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    }

    button, input, optgroup, select, textarea {
      line-height: initial;
    }

    button {
      margin: 0;
      padding: 0;
      background: none;
      border: 0;
      outline: none;
      display: inline-block;
      vertical-align: middle;
      font-family: inherit;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-align: center;

      &:focus {
        outline: none;
      }
    }
    svg {
      flex-shrink: 0;
    }
    
    body.noScroll {
      overflow: hidden;
    }

    hr {
          width: 100%;
          box-sizing: inherit;
          margin: 0;
          border: none;
          border-top: 0.1rem solid ${({ theme }) => theme.borderColor};
    }
`;
