/* eslint-disable no-param-reassign */

const normalize = (str: string) => {
  str = str.replace(/:\//g, '://'); // make sure protocol is followed by two slashes
  str = str.replace(/([^:\s])\/+/g, '$1/'); // remove consecutive slashes
  str = str.replace(/\/(\?|&|#[^!])/g, '$1'); // remove trailing slash before parameters or hash
  str = str.replace(/(\?.+)\?/g, '$1&'); // replace ? in parameters with &

  return str;
};

export const join = (...args: any) => {
  const joined = args.join('/');
  return normalize(joined);
};

export const ABSOLUTE_URL_REGEX = /^https?:\/\/|^\/\//i;
