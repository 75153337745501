import { AirlineTheme, LevelTheme } from 'types/themes';
import { colors, gradients, levelGradients } from './colors';
import fonts from './fonts';
import { fontSizes } from '../utils';

const cardLevels: Array<LevelTheme> = [
  {
    tierKey: 'tier-type.red',
    entryPoint: 0,
    color: colors.level1,
    heroGradient: levelGradients[0],
    dashboardGradient: levelGradients[0],
  },
  {
    tierKey: 'tier-type.silver',
    entryPoint: 24000,
    color: colors.level2,
    heroGradient: levelGradients[1],
    dashboardGradient: levelGradients[1],
  },
  {
    tierKey: 'tier-type.gold',
    entryPoint: 100000,
    color: colors.level3,
    heroGradient: levelGradients[2],
    dashboardGradient: levelGradients[2],
  },
];

const footerLinks = {
  appleStoreLink: 'https://apps.apple.com/us/app/air-mauritius/id1284696683',
  googlePlayStoreLink: 'https://play.google.com/store/apps/details?id=com.mk.mob',
  facebookLink: 'https://www.facebook.com/AirMauritius/',
  instagramLink: 'https://www.instagram.com/airmauritius/',
  tweeterLink: 'https://twitter.com/airmauritius',
  youtubeLink: 'https://www.youtube.com/user/MyAirMauritius',
  // award1Link: 'https://www.airlinequality.com/',
};

const termsAndConditions = {
  en: {
    termsAndConditions: 'en/frequent-flyer/about-kestrelflyer/programme/kestrelflyer-terms-and-conditions',
    privacy: 'en/legal-regulations-links/privacy',
    contact: 'https://kestrelflyer.airmauritius.com/contactus',
  },
  fr: {
    termsAndConditions: 'fr/programme-de-fidelisation/kestrelflyer/programme/kestrelflyer-termes-et-conditions',
    privacy: 'fr/legal-regulations-links/privacy',
    contact: 'https://kestrelflyer.airmauritius.com/contactus_fr',
  },
};

const theme: AirlineTheme = {
  textColor: colors.textColor,
  primaryColor: colors.primaryColor,
  primaryTextColor: colors.textColor,
  secondaryTextColor: colors.white,
  fontFamilyPrimary: 'Kumbh Sans',
  fontFamilySecondary: 'Lora, sans-serif',
  fontSizeBody: '1rem',
  fonts: {
    primary: 'Lora:100,200,300,400,500,600,700,800',
    secondary: 'Kumbh Sans:100,200,300,400,500,600,700,800',
  },
  invertedTextColor: colors.white,
  colorAccent: colors.sand || '#EEE6DE',
  colorLinkHover: colors.primaryColor,
  borderRadius: '1rem',
  boxShadow: 'none',
  defaultPadding: '4rem',
  defaultMobilePadding: '2.4rem',
  //
  inputFontSize: '1.125rem',
  //
  borderColor: colors.borderColor,
  disabledColor: '#517684',
  buttonColorPrimary: colors.primaryColor,
  backgroundColor: colors.backgroundColor,
  // fontBaseSize: '16px',
  shadow: '0 10px 20px 0 #3f322b26',
  gradients,
  textStyles: fonts,
  colors,
  fontWeights: fontSizes,
  tableHeaderTextColor: '#EEE6DE',
  tableHeaderBackgroundColor: '#534a7b',
  tableRowBackgroundColor: colors.white,
  tableAltRowBackgroundColor: colors.white, // '#EFF2F5'

  layoutTheme: {
    order: {
      extras: 1,
      breadCrumbs: 2,
      apiErrors: 3,
      contentWrapper: 4,
    },
  },
  dashboardTheme: {
    order: {
      mainContainer: 1,
      actionButtons: 2,
    },
    dashboardButtonRows: 3,
    actionButtons: {
      milesCalculator: { hide: true },
    },
    customStyles: {
      card: 'margin-bottom: -9.6rem',
    },
  },

  animationSpeed: '512ms',
  cardLevels,
  footerLinks,
  termsAndConditions,
};

export default theme;
