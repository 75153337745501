import { FormattedMessage } from '@triplake/lib-intl';
import * as ui from './SplashScreen.ui';
import { messages } from './SplashScreen.translations';
import { useTheme } from 'styled-components';
import { Theme } from 'types/themes';

export const SplashScreen = () => {
  const { images } = useTheme() as Theme;

  return (
    <ui.SplashScreenBackground>
      <ui.SplashScreenContent>
        <ui.LogoWithMessage>
          <img src={images?.logo ?? ''} alt="logo" />
          <FormattedMessage {...messages.welcomeMessage} />
        </ui.LogoWithMessage>
        <ui.ProgressBarHolder>
          <FormattedMessage {...messages.authenticating} />
          <ui.Progress />
        </ui.ProgressBarHolder>
      </ui.SplashScreenContent>
    </ui.SplashScreenBackground>
  );
};
