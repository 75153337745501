import { defineDynamicMessages, defineMessages } from '@triplake/lib-intl';
import { REFERENCE_DATA_TYPES } from '@triplake/lib-types';

export const messages = defineMessages({
  newsletterTitle: {
    id: 'Footer.newsletterTitle',
    defaultMessage: 'Get our exclusive deals',
  },
  subscribePlaceholder: {
    id: 'Footer.newsletterTisubscribePlaceholdertle',
    defaultMessage: 'Enter your e-mail address',
  },
  downloads: {
    id: 'Footer.downloads',
    defaultMessage: 'Download our app',
  },
  subscribeTitle: {
    id: 'Footer.subscribe.title',
    defaultMessage: 'Get our exclusive deals',
  },
  subscribeMessage: {
    id: 'Footer.subscribe.message',
    defaultMessage: 'All fields are required',
  },
  subscribeButtonLabel: {
    id: 'Footer.subscribe.buttonLabel',
    defaultMessage: 'Subscribe',
  },
  subscribePolicy: {
    id: 'Footer.subscribe.policy',
    defaultMessage: 'policy',
  },
  subscribeTermsAndConditions: {
    id: 'Footer.subscribe.termsAndConditions',
    defaultMessage: 'terms and conditions',
  },
  subscribeFirstNamePlaceholder: {
    id: 'Form.firstName',
    defaultMessage: 'First name',
  },
  subscribeLastNamePlaceholder: {
    id: 'Form.lastName',
    defaultMessage: 'Last name',
  },
  subscribeEmailPlaceholder: {
    id: 'Footer.subscribe.placeholders.email',
    defaultMessage: 'E-mail address',
  },
  subscribeCountryPlaceholder: {
    id: 'Footer.subscribe.placeholders.country',
    defaultMessage: 'Country',
  },
  subscribeConfirmLabel: {
    id: 'Footer.subscribe.placeholders.confirmLabel',
    defaultMessage: 'I confirm that I have read and agreed to the website {termsAndConditions} and privacy {policy}.',
  },
  invalidEmail: {
    id: 'Footer.subscribe.invalidEmail',
    defaultMessage: 'Please provide valid email address.',
  },
});
