import { AnyAction } from 'redux';
import { CHECK_POINTS_FOR_AWARD_TICKET, CHECK_POINTS_FOR_UPGRADING } from './selfServiceConstants';
import { createReducer } from '../utils/createReducer';
import { TSelfServiceState } from './selfServiceTypes';

const initialState: TSelfServiceState = {};

const resetCheck = (state: TSelfServiceState) => ({ ...state, checkForPoints: undefined });
const setCheckError = (state: TSelfServiceState, action: AnyAction) => ({ ...state, checkForPoints: action.error.json });
const setCheck = (state: TSelfServiceState, action: AnyAction) => ({ ...state, checkForPoints: action.response });

const selfServiceReduces = {
  [CHECK_POINTS_FOR_AWARD_TICKET.SUCCESS]: setCheck,
  [CHECK_POINTS_FOR_AWARD_TICKET.FAILURE]: setCheckError,
  [CHECK_POINTS_FOR_AWARD_TICKET.REQUEST]: resetCheck,
  [CHECK_POINTS_FOR_UPGRADING.SUCCESS]: setCheck,
  [CHECK_POINTS_FOR_UPGRADING.FAILURE]: setCheckError,
  [CHECK_POINTS_FOR_UPGRADING.REQUEST]: resetCheck,
};

export default createReducer(selfServiceReduces, initialState);
