import { TAccessSecuredApiResource, TAppState } from './constants';
import { getAccountId } from './user/userSelectors';
import { getSecuredApiAccessToken } from './session/sessionSelectors';
import { getPreferredLocale, getDefaultLocale } from './intl/intlSelectors';

export const getSecuredApiResourceAccesses: (state: TAppState) => TAccessSecuredApiResource = (state: TAppState) => {
  const preferredLanguage = getPreferredLocale(state);
  const defaultLanguage = getDefaultLocale(state);

  const acceptLanguage = preferredLanguage
    ? `${preferredLanguage}${preferredLanguage === defaultLanguage ? '' : `,${defaultLanguage}`}`
    : defaultLanguage;

  return {
    accountId: getAccountId(state),
    headers: {
      'Accept-Language': acceptLanguage,
      'Authorization': `Bearer ${getSecuredApiAccessToken(state)}`,
    },
  };
};

export const getAcceptLanguage: (state: TAppState) => { headers: { [headerName: string]: string } } = (state: TAppState) => {
  const preferredLanguage = getPreferredLocale(state);
  const defaultLanguage = getDefaultLocale(state);

  const acceptLanguage = preferredLanguage
    ? `${preferredLanguage}${preferredLanguage === defaultLanguage ? '' : `,${defaultLanguage}`}`
    : defaultLanguage;

  return { headers: { 'Accept-Language': acceptLanguage } };
};
