import { request } from '../request/requestActions';
import { action } from '../utils/actionHelpers';
import { extendSession } from './sessionApi';
import {
  CLEAR_SECURED_API_ACCESS_TOKEN,
  CLEAR_SESSION,
  EXTEND_SESSION,
  SESSION_ABOUT_TO_EXPIRE,
  SESSION_CREATE,
  SESSION_EXPIRED,
  SESSION_EXTENDED,
  SET_SECURED_API_ACCESS_TOKEN,
} from './sessionConstants';

export const sessionCreate = {
  request: (tenant: string) => action(SESSION_CREATE.REQUEST, { tenant }),
  success: () => action(SESSION_CREATE.SUCCESS),
  failure: (error: any) => action(SESSION_CREATE.FAILURE, { error }),
};

export const sessionExpired = () => action(SESSION_EXPIRED);

export const clearSession = () => action(CLEAR_SESSION);

export const sessionExtended = (timestamp: string) => action(SESSION_EXTENDED, { timestamp });

export const sessionAboutToExpire = () => action(SESSION_ABOUT_TO_EXPIRE);

export const extendSessionRequest = () =>
  request({
    actionBase: EXTEND_SESSION,
    apiMethod: extendSession,
  });

export const setSecuredApiAccessToken = (params: { accessToken: string }) => action(SET_SECURED_API_ACCESS_TOKEN, { params });

export const clearSecuredApiAccessToken = () => action(CLEAR_SECURED_API_ACCESS_TOKEN);
