import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CardLevels } from 'types/types';
import { OrderedElement } from 'src/components/Layout/Layout.ui';

export const CardPartContent = styled.div<{ $grow?: number; $dir?: string; $align?: 'start' | 'end' }>`
  display: flex;
  flex-direction: ${({ $dir }) => $dir || 'column'};
  gap: 1.6rem;
  ${props => (props.$grow ? `flex-grow: ${props.$grow};` : '')}
  ${({ $align }) => ($align ? `align-items: ${$align};` : '')}
  ${({ theme }) => theme.textStyles.body.regular}
  h2 {
    ${({ theme }) => theme.textStyles.header.h2}
  }
  ${({ theme }) => theme.mediaQueries.SM`
    h2 { ${theme.textStyles.header.h3.join(' ')} }
  `}
`;
export const CardPart = styled.div`
  display: flex;
  padding: ${({ theme }) => (theme.isMobile ? '2.4rem' : '4rem')};
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: space-between;
  gap: 0.8rem;
  h2 {
    margin: 0;
  }
  ${({ theme }) => theme.mediaQueries.MD`
    flex-direction: column;
    gap: 1.6rem;
    ${CardPartContent} {
      align-items: start;
      flex-grow: 1;
    }
  `}
`;
export const MobileHorizontalSeparator = styled.div`
  border-top: 0.1rem solid ${({ theme }) => theme.borderColor || '#000'};
  height: 0;
  margin: 1.6rem 0;
`;
export const CardLink = styled(Link)`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  ${({ theme }) => theme.textStyles.button.large}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.button.regular.join(' ')}`}
`;
export const CardButton = styled.button`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  ${({ theme }) => theme.textStyles.button.large}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.button.regular.join(' ')}`}
`;
const upShift = '-8rem';
export const CardWrapper = styled.div<{ $styles?: string }>`
  background: ${props => props.theme.colors.white || '#fff'};
  border-radius: ${props => props.theme.borderRadius || 0};
  border: solid 0.1rem rgba(7, 59, 80, 0.2);
  width: 100%;
  max-width: 111rem;
  margin: 0 auto ${upShift};
  display: flex;
  flex-direction: column;
  position: relative;
  transform: translateY(${upShift});
  & > div:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 0;
    left: ${({ theme }) => (theme.isMobile ? '2.4rem' : '4rem')};
    right: ${({ theme }) => (theme.isMobile ? '2.4rem' : '4rem')};
    border-top: 1px solid ${props => props.theme.borderColor || 'gray'};
  }
  ${props => props.$styles || ''}
`;

export const CardImage = styled.div<{ $cardlevel: CardLevels }>`
  width: 10rem;
  height: 6.4rem;
  background-image: url(${({ theme, $cardlevel }) => theme.images[`card_${$cardlevel || 'level1'}`]});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const CardName = styled.div<{ $color: string }>`
  ${({ theme }) => theme.textStyles.subtitle.s3}
  color: ${({ theme, $color }) => $color || theme.primaryColor};
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.subtitle.s4.join(' ')}
    color: ${({ theme, $color }) => $color || theme.primaryColor};
  `}
`;

export const CardPoints = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const CardPointsBox = styled(OrderedElement)<{ $fullWidth?: boolean; $alignRight?: boolean }>`
  ${props => (props.$alignRight ? `text-align: right` : '')};
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%' : 'flex-basis: fit-content')};
  label {
    ${({ theme }) => theme.textStyles.body.regular}
  }
  ${({ theme }) => theme.mediaQueries.SM`
    label { ${theme.textStyles.caption.regular.join(' ')} }
  `}
`;
export const CardTierPoints = styled.div`
  ${({ theme }) => theme.textStyles.subtitle.s1SemiBold}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.subtitle.s2SemiBold.join('')}`}
`;
export const CardMilesAndNextLevel = styled.div`
  ${props => props.theme.textStyles.subtitle.s3}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.subtitle.s4.join('')}`}
`;
export const CardExpiringPoints = styled.div`
  ${props => props.theme.textStyles.subtitle.s4}
  ${({ theme }) => theme.mediaQueries.SM`font-size: 1.4rem;`}
`;
export const ModalComponents = {
  Image: styled.img`
    max-width: 50rem;
    align-self: center;
  `,
  Message: styled.a`
    display: flex;
    gap: 0.8rem;
    align-items: center;
    ${props => props.theme.textStyles.body.regular || ''}
    cursor: pointer;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
    max-width: 100%;
  `,
};
