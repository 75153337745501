import { createGlobalStyle, css } from 'styled-components';
import './fonts/brandongrotesque';

const setFont = (fontSize, margin, fontFamily) => theme =>
  css`
    font-size: ${fontSize};
    font-family: ${fontFamily || theme.fontFamilySecondary};
    font-weight: ${theme.fontWeightBold};
    line-height: 1;
    margin-top: ${margin};
    margin-bottom: ${margin};
  `;

const materialIcons = css`
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('https://fonts.gstatic.com/s/materialicons/v76/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('https://fonts.gstatic.com/s/materialiconsoutlined/v44/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Material Icons Two Tone';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('https://fonts.gstatic.com/s/materialiconstwotone/v42/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu0NjbrHg.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Material Icons Round';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/materialiconsround/v53/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmPq_HTTw.woff2) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-two-tone {
    font-family: 'Material Icons Two Tone';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-round {
    font-family: 'Material Icons Round';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
`;

export const GlobalStyle = createGlobalStyle`
    ${materialIcons}

    @-ms-viewport {
      width: device-width;
    }

    html {
      line-height: initial;
      box-sizing: border-box;
      overscroll-behavior: none;
      
      font-size: 62.5%;

      @media (max-width: 359px) {
        font-size: 50%;
      }
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    body {
      font-family: ${({ theme }) => theme.fontFamilyPrimary};
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
      padding: 0;
    }

    h1 small,
    h2 small,
    h3 small,
    h4 small,
    h5 small,
    h6 small {
      opacity: 0.54;
      font-size: 0.6em;
    }

    h1 {
      ${({ theme }) => setFont(theme.fontSizeDisplay5, theme.headlineVerticalMargin)(theme)};
    }

    h2 {
      ${({ theme }) => setFont(theme.fontSizeDisplay3, theme.headlineVerticalMargin)(theme)};
    }

    h3 {
      ${({ theme }) => setFont(theme.fontSizeDisplay2, theme.headlineVerticalMargin)(theme)};
    }

    h4 {
      ${({ theme }) => setFont(theme.fontSizeDisplay1, theme.headlineVerticalMarginSmall)(theme)};
    }

    h5 {
      ${({ theme }) => setFont(theme.fontSizeHeadline, theme.headlineVerticalMarginSmall)(theme)};
    }

    h6 {
      ${({ theme }) => setFont(theme.fontSizeTitle, theme.headlineVerticalMarginSmall)(theme)};
    }

    p {
      ${({ theme }) => setFont(theme.fontSizeBody, theme.headlineVerticalMarginSmall, theme.fontFamilyPrimary)(theme)};
    }

    a {
      color: ${({ theme }) => theme.colorPrimary};
      text-decoration: none;
    }

    input,
    select {
      color: inherit;
      font-family: inherit;
      font-weight: ${({ theme }) => theme.fontWeightSemiThin};
    }

    button, input, optgroup, select, textarea {
      line-height: initial;
    }

    button {
      margin: 0;
      padding: 0;
      background: none;
      border: 0;
      outline: none;
      display: inline-block;
      vertical-align: middle;
      font-family: inherit;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-align: left;
      font-weight: ${({ theme }) => theme.fontWeightSemiThin};

      &:focus {
        outline: none;
      }
    }
  `;
