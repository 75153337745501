import { AnyAction } from 'redux';
import { GET_LANGUAGES, GET_FOOTER, GET_HEADER, GET_DICTIONARIES, GET_AIRLINES, GET_ROUTES } from './configConstants';
import { createReducer } from '../utils/createReducer';
import { AirlinesAirports, TAirline, TAirport, TConfigState, TFetchFooterAction, TFetchMegaMenuAction } from 'types/store/Config';
import { FooterDefinition, FooterResponse, MegaMenuDefinition, MegaMenuHelpTab, MegaMenuOptions, MegaMenuTabColumn } from 'types/Layout';
import config from 'src/config';
import { International } from 'src/store/intl/intlConstants';
import { GrantTypeMapping } from 'pages/UsePointsPages/Constants';
import { Airlines, Routes } from 'src/store/config/MOCK';

const AIRPORT_PROP_NAME = 'name';
const initialState: TConfigState = {
  redirectUrls: [],
  baseUrl: '',
  menuOptions: {},
  footer: {
    bigLinks: [],
    copyright: '',
    images: [],
    links: [],
    regulations: [],
  },
  dictionaries: {},
  regions: {},
  airports: Routes,
  airlines: Airlines,
};

const setLanguages = (state: TConfigState, action: AnyAction) => {
  const languages = Object.keys(action.response.language).reduce(
    (acc: Array<{ languageName: string; languageCode: string }>, languageCode: string) => {
      const languageName = action.response.language[languageCode];
      acc.push({ languageName, languageCode });
      return acc;
    },
    [],
  );
  return {
    ...state,
    dictionaries: {
      ...state.dictionaries,
      language: action.response.language,
    },
    languages,
  };
};

const setHeader = (state: TConfigState, action: TFetchMegaMenuAction) => {
  const baseUrl = getBaseUrl(action.response.baseUrl).replace(/(^https?:\/\/[^\/]+)\/?.*/i, '$1');
  const menuOptions: MegaMenuOptions = parseResponseToMegaMenuOptions(action.response);
  const helpMenu = fixHelpData(action.response.help);
  const regions = parseLocales(action.response.locales);

  return {
    ...state,
    baseUrl: state.baseUrl || baseUrl,
    menuOptions,
    helpMenu,
    regions,
  };
};

const setFooter = (state: TConfigState, action: TFetchFooterAction) => {
  const baseUrl = state.baseUrl || getBaseUrl(action.response.baseUrl).replace(/(^https?:\/\/[^\/]+)\/?.*/i, '$1');
  const footer = parseFooterResponse(action.response);
  return {
    ...state,
    baseUrl,
    footer,
  };
};

const setDictionaries = (state: TConfigState, action: AnyAction) => ({
  ...state,
  dictionaries: {
    ...state.dictionaries,
    ...action.response,
  },
});

const setAirlines = (state: TConfigState, action: AnyAction) => ({
  ...state,
  airlines: mapAirlines(Object.values(action.response)),
});

const setRoutes = (state: TConfigState, action: AnyAction) => {
  const service = GrantTypeMapping[action.originalAction.apiMethodArg.priceList];
  const airportsMapping = action.response.airports;
  const airlineAirports = Object.keys(action.response.routes).reduce((accumulator: AirlinesAirports, currentAirline): AirlinesAirports => {
    const airportsData = action.response.routes[currentAirline];
    const airportsMap = Object.keys(airportsData).reduce(
      (airports: { [key: string]: TAirport }, airportCode: string): { [key: string]: TAirport } => {
        if (!airports[airportCode])
          airports[airportCode] = {
            name: airportsMapping[airportCode]?.[AIRPORT_PROP_NAME] ?? airportCode,
            id: airportCode,
            destination: airportsData[airportCode] ?? [],
            origin: airports[airportCode]?.origin ?? [],
            city: airportsMapping[airportCode]?.city,
          };
        airportsData[airportCode].forEach(destination => {
          if (airports[destination]) airports[destination].origin.push(airportCode);
          else
            airports[destination] = {
              origin: [airportCode],
              name: airportsMapping[destination]?.[AIRPORT_PROP_NAME] ?? destination,
              id: destination,
              destination: airportsData[destination] ?? [],
              city: airportsMapping[destination]?.city,
            };
        });
        return airports;
      },
      {},
    );
    accumulator[currentAirline] = Object.values(airportsMap);
    return accumulator;
  }, {});

  return {
    ...state,
    airlines: mapAirlines(Object.values(action.response.airlines), action.response.constraints),
    airports: { ...state.airports, [service]: airlineAirports },
  };
};

const configReducer = {
  [GET_LANGUAGES.SUCCESS]: setLanguages,
  [GET_AIRLINES.SUCCESS]: setAirlines,
  [GET_ROUTES.SUCCESS]: setRoutes,
  [GET_DICTIONARIES.SUCCESS]: setDictionaries,
  [GET_HEADER.SUCCESS]: setHeader,
  [GET_FOOTER.SUCCESS]: setFooter,
};

export default createReducer(configReducer, initialState);

const mapAirlines = (airlines, constraints?): Array<TAirline> => {
  const airlinesMap = airlines.map(
    (airline): TAirline => ({
      name: airline.name,
      id: airline.iataCode,
      milesQualifySince: constraints?.airlines?.[airline.iataCode]?.milesQualifySince,
    }),
  );
  const swapIndex = airlinesMap.findIndex(airline => airline.id === config.THEME_DEFAULT);
  if (swapIndex > 0) {
    const tmp = airlinesMap[0];
    airlinesMap[0] = airlinesMap[swapIndex];
    airlinesMap[swapIndex] = tmp;
  }

  return airlinesMap;
};

const getBaseUrl = function (baseUrl: string): string {
  const defaultUrl =
    !config.PIMCORE_URL || /^(https?:\/\/localhost.*)?$/.test(config.PIMCORE_URL) ? 'https://integration-mk-triplake.com' : config.PIMCORE_URL;
  const url = /^https?:\/\/[a-z]+([a-z0-9_-]+\.)+([a-z0-9]{2-4})+$/.test(baseUrl) ? baseUrl : defaultUrl.replace(/\/[a-z]+$/i, '/');
  return /\/$/.test(url) ? url : `${url}/`;
};

const parseResponseToMegaMenuOptions = (response: MegaMenuDefinition): MegaMenuOptions => {
  const { help, baseUrl, locales, ...out } = response;

  Object.keys(out).forEach(key => {
    out[key].items.forEach((column: MegaMenuTabColumn, columnIndex) =>
      column.forEach((item, index) => {
        if (typeof item.childLinks === 'undefined' && typeof (item as any).childlinks !== 'undefined') {
          item.childLinks = (item as any).childlinks;
          delete (item as any).childlinks;
        }
        if (/\/frequent-flyer\/join-now-login$/.test(item.title.url.path)) {
          delete column[index];
          if (column.length === 0) delete out[key][columnIndex];
        }
        if (!Array.isArray(item.childLinks)) item.childLinks = Object.keys(item.childLinks).map(key => item.childLinks[key]);
        if (!Array.isArray(item.childTiles)) item.childTiles = Object.keys(item.childTiles).map(key => item.childTiles[key]);
      }),
    );
  });

  return out;
};
const fixHelpData = (helpData): MegaMenuHelpTab => {
  return {
    title: helpData.title,
    items: Object.keys(helpData.items).map(key => helpData.items[key]),
  };
};

const parseFooterResponse = (response: FooterResponse): FooterDefinition => {
  const out: FooterDefinition = {
    links: Object.keys(response.links).map(key => {
      const links = response.links[key];
      links.items = Object.keys(links.items).map(linkKey => links.items[linkKey]);
      return links;
    }),
    regulations: Object.keys(response.regulations)
      .map(key => response.regulations[key])
      .filter(regulation => !/site-map$/.test(regulation.path)),
    images: Object.keys(response.images).map(key => response.images[key]),
    bigLinks: Object.keys(response.bigLinks).map(key => response.bigLinks[key]),
    copyright: response.copyright,
  };

  return out as FooterDefinition;
};

const parseLocales = (locales: Array<string>): { [region: string]: Array<string> } => {
  const map = locales.reduce((accumulator: Map<string, Array<string>>, locale: string): Map<string, Array<string>> => {
    const [language, region = International] = locale.split('_');
    if (accumulator.has(region)) {
      const ary = accumulator.get(region)!;
      ary.push(language);
      accumulator.set(region, ary);
    } else accumulator.set(region, [language]);
    return accumulator;
  }, new Map<string, Array<string>>());
  return Object.fromEntries(map.entries());
};
