import { fork, all } from 'redux-saga/effects';

import intlSaga from './intl/intlSaga';
import requestSaga from './request/requestSaga';
import sessionSaga from './session/sessionSaga';
import userSaga from './user/userSaga';
import { selfServiceSaga } from 'src/store/selfService/selfServiceSaga';

export const sagas = function* () {
  yield all([fork(requestSaga), fork(sessionSaga), fork(userSaga), fork(intlSaga), fork(selfServiceSaga)]);
};
