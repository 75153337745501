import { useCallback, useEffect, useMemo, useRef, useState, ReactNode, useContext, MouseEvent, RefObject } from 'react';
import { Portal } from 'components/Portal';
import Icon from '../Icon';
import Button from '../Button';
import * as ui from './Dialog.ui';
import { DeviceTypeContext } from '@triplake/lib-hooks';
import { DeviceContextType } from 'types/types';
import { disableFingerDragCallback } from 'utils/browserUtils';

interface DialogProps {
  id?: string;
  isOpen: boolean;
  title?: ReactNode;
  closeOnOverlayClick?: boolean;
  onClose?: () => boolean | void;
  onConfirm?: () => void;
  onCancel?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  showCloseButton?: boolean;
  onOpen?: (modalId: string) => void;
  children: ReactNode;
  contentPadding?: string;
  disableConfirmButton?: boolean;
  loadingConfirmButton?: boolean;
  disableCancelButton?: boolean;
  buttonsJustify?: 'space-between' | 'start' | 'center' | 'end';
}

export const Dialog = ({
  id,
  isOpen,
  title,
  onClose,
  onConfirm,
  onOpen,
  children,
  confirmLabel,
  cancelLabel,
  showCloseButton,
  contentPadding,
  disableConfirmButton = false,
  loadingConfirmButton = false,
  disableCancelButton = false,
  closeOnOverlayClick = true,
  buttonsJustify = 'space-between',
}: DialogProps) => {
  const device = useContext(DeviceTypeContext) as DeviceContextType;
  const [open, setOpenState] = useState<boolean>(isOpen);

  const overlayRef: RefObject<HTMLDivElement> = useRef(null);
  const dialogBodyRef: RefObject<HTMLDivElement> = useRef(null);

  const dialogId: string = useMemo(() => id || `${title || '_'}-${Math.random()}`, [id, title]);

  // Callbacks
  const closeCallback = useCallback(
    (e: MouseEvent): void => {
      setOpenState((currentState: boolean): boolean => {
        if (!currentState) return currentState;
        const shouldClose = typeof onClose === 'function' ? onClose() : true;
        if (!shouldClose) return currentState;
        return false;
      });
    },
    [onClose],
  );

  const overLayCloseCallback = useCallback(
    (e: MouseEvent): void => {
      if (overlayRef.current === e.nativeEvent.srcElement) closeCallback(e);
    },
    [closeCallback],
  );

  const disableDragCallback = useCallback(ev => disableFingerDragCallback(ev, dialogBodyRef.current), []);

  // Effects
  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('touchmove', disableDragCallback, { passive: false });
      document.body.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
      document.body.removeEventListener('touchmove', disableDragCallback);
    }
    setOpenState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (open && typeof onOpen === 'function') onOpen(dialogId);
  }, [open, onOpen, dialogId]);
  // End of Hooks

  if (!open) return null;

  const headerComponent: ReactNode = device.isMobile ? (
    (title || showCloseButton) && (
      <ui.MobileDialogHeader>
        {title && <ui.DialogTitle>{title}</ui.DialogTitle>}
        {showCloseButton && (
          <ui.DialogClose>
            <button onClick={closeCallback}>
              <Icon name="close" />
            </button>
          </ui.DialogClose>
        )}
      </ui.MobileDialogHeader>
    )
  ) : (
    <>
      {showCloseButton && (
        <ui.DialogClose>
          <button onClick={closeCallback}>
            <Icon name="closeDark" />
          </button>
        </ui.DialogClose>
      )}
      {title && <ui.DialogTitle>{title}</ui.DialogTitle>}
    </>
  );

  return (
    <Portal>
      <ui.Overlay ref={overlayRef} onClick={closeOnOverlayClick ? overLayCloseCallback : undefined}>
        <ui.DialogWrapper $isMobile={device.isMobile}>
          {headerComponent}
          <ui.DialogBody ref={dialogBodyRef}>
            <ui.DialogContent $contentPadding={contentPadding}>{children}</ui.DialogContent>
            {confirmLabel || cancelLabel ? (
              <ui.DialogActionButtons $justify={buttonsJustify}>
                {cancelLabel && (
                  <Button onClick={onClose} disabled={disableCancelButton}>
                    {cancelLabel}
                  </Button>
                )}
                {confirmLabel && (
                  <Button type="primary" onClick={onConfirm} disabled={disableConfirmButton} loading={loadingConfirmButton}>
                    {confirmLabel}
                  </Button>
                )}
              </ui.DialogActionButtons>
            ) : null}
          </ui.DialogBody>
        </ui.DialogWrapper>
      </ui.Overlay>
    </Portal>
  );
};
