import { Dialog } from 'components/Dialog';
import { FormattedMessage, useIntl } from '@triplake/lib-intl';
import { useCallback, useMemo, useState } from 'react';
import { messages } from './Footer.translations';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTING_PATHS } from 'src/router/routingPaths';
import * as ui from './Footer.ui';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeAction } from 'src/store/config/configActions';
import { Alert, AlertType } from 'components/Alert';
import { FieldTypes, useFormData } from 'src/hooks/useFormData';
import { FormDataOptions } from 'types/FormHooks';
import { getDictionaries } from 'src/store/config/configSelectors';
import { convertDictToSimpleOptions } from 'utils/commonUtils';
import { useBaseUrl } from 'src/hooks/useBaseUrl';
import { useTheme } from 'styled-components';
import { Theme } from 'types/themes';

enum Field {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  COUNTRY = 'country',
  CONFIRM = 'confirm',
}

const SubscribeModal = ({ emailValue, onClose, isOpen }) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { termsAndConditions } = useTheme() as Theme;
  const getUrl = useBaseUrl();
  const [error, setError] = useState('');
  const { country } = useSelector(getDictionaries('country'));
  const countries = useMemo(() => convertDictToSimpleOptions(country), [Object.keys(country || {}).length]);
  const defaultCountry = useMemo(() => {
    const dc = countries.find(country => country.value === 'MU');
    return dc && dc.value;
  }, [countries]);

  const confirmLabel = useMemo(
    () =>
      formatMessage(messages.subscribeConfirmLabel, {
        termsAndConditions: (
          <a
            href={
              termsAndConditions?.[locale.substring(0, 2)]?.termsAndConditions
                ? getUrl(termsAndConditions[locale.substring(0, 2)].termsAndConditions)
                : '#'
            }
            target="_blank"
            rel="noreferrer">
            {formatMessage(messages.subscribeTermsAndConditions)}
          </a>
        ),
        policy: (
          <a
            href={termsAndConditions?.[locale.substring(0, 2)]?.privacy ? getUrl(termsAndConditions[locale.substring(0, 2)].privacy) : '#'}
            rel="noreferrer"
            target="_blank">
            {formatMessage(messages.subscribePolicy)}
          </a>
        ),
      }),
    [termsAndConditions, locale, getUrl],
  );

  const fieldOptions: FormDataOptions<typeof Field> = useMemo(
    () => ({
      [Field.FIRST_NAME]: {
        placeholder: formatMessage(messages.subscribeFirstNamePlaceholder),
      },
      [Field.LAST_NAME]: {
        placeholder: formatMessage(messages.subscribeLastNamePlaceholder),
      },
      [Field.EMAIL]: {
        placeholder: formatMessage(messages.subscribeEmailPlaceholder),
        value: emailValue,
        validators: [
          {
            validator: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            errorMessage: messages.invalidEmail,
          },
        ],
      },
      [Field.COUNTRY]: {
        type: FieldTypes.SELECT,
        value: defaultCountry,
        placeholder: formatMessage(messages.subscribeCountryPlaceholder),
        fieldProps: {
          overflowItsAncestors: true,
          searchableSelect: true,
          sortOptions: true,
          options: countries,
        },
      },
      [Field.CONFIRM]: {
        type: FieldTypes.CHECKBOX,
        isRequired: true,
        fieldProps: { label: <ui.SubscribeCheckboxLabel>{confirmLabel}</ui.SubscribeCheckboxLabel> },
      },
    }),
    [emailValue, countries, confirmLabel, defaultCountry],
  );
  const { getFields, getData, valid } = useFormData(Field, fieldOptions);

  // Memos
  const title = useMemo(() => formatMessage(messages.subscribeTitle), [formatMessage]);
  const buttonLabel = useMemo(() => formatMessage(messages.subscribeButtonLabel), [formatMessage]);

  const onSubmitCallback = useCallback(() => {
    dispatch(
      subscribeAction(getData(), {
        onSuccessAction: () => {
          onClose();
          navigate(ROUTING_PATHS.newsletter, { state: { prevLocation: location } });
        },
        onError: error => setError(error.message),
      }),
    );
  }, [location, navigate, onClose, getData]);

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      onConfirm={onSubmitCallback}
      confirmLabel={buttonLabel}
      contentPadding=".8rem 0 4rem 0"
      buttonsJustify="center"
      disableConfirmButton={!valid}>
      <FormattedMessage {...messages.subscribeMessage} />
      {error ? (
        <Alert type={AlertType.ERROR} onClose={() => setError('')}>
          {error}
        </Alert>
      ) : null}
      <ui.SubscribeModalForm>{getFields(Field.FIRST_NAME, Field.LAST_NAME, Field.EMAIL, Field.COUNTRY, Field.CONFIRM)}</ui.SubscribeModalForm>
    </Dialog>
  );
};

export default SubscribeModal;
