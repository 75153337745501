import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BreadCrumbsWrapper = styled.div<{ $styles?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  ${props => props.$styles || ''}
  .chevron {
    width: 2.4rem;
    height: 2.4rem;
    vertical-align: bottom;
    text-align: center;
    margin: 0 0.4rem;
    color: ${props => props.theme.primaryColor || '#000'};
  }
`;

export const BreadCrumb = styled.div`
  ${({ theme }) => theme.textStyles.button.regular}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.button.small.join(' ')}`}
`;

export const BreadCrumbAnchor = styled.a`
  &:hover {
    text-decoration: underline;
  }
  ${({ theme }) => theme.textStyles.button.regular}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.button.small.join(' ')}`}
`;

export const ClickableBreadCrumb = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
  ${({ theme }) => theme.textStyles.button.regular}
  ${({ theme }) => theme.mediaQueries.SM`${theme.textStyles.button.small.join(' ')}`}
`;
