import { callApi, callSecuredApi, HTTPMethod, pimcoreRequest } from '../utils/apiHelpers';
import { Endpoints } from './configConstants';

export const getLanguage = ({ headers }) => {
  const path = '/config/dictionaries/language';
  return callApi(path, HTTPMethod.GET, undefined, undefined, headers);
};

export const getAirlines = ({ priceList, headers }) => {
  const path = '/config/airlines';
  return callApi(path, HTTPMethod.GET, undefined, { for: priceList }, headers);
};

export const getRoutes = ({ priceList, headers }) => {
  const path = '/config/airlines/routes';
  return callApi(path, HTTPMethod.GET, undefined, { for: priceList }, headers);
};

export const getDictionaries = ({ dictionaries, headers }: { dictionaries: Array<string>; headers: { [headerName: string]: string } }) => {
  const path = `/config/dictionaries/${dictionaries}`;
  return callApi(path, HTTPMethod.GET, undefined, undefined, headers);
};

export const subscribe = ({ data }) => {
  const path = '/loyalty/subscriptions';
  return callApi(path, HTTPMethod.POST, data);
};

export const getFooter = (locale: string) => {
  return pimcoreRequest(Endpoints.FOOTER, `language=${locale}`);
};

export const getHeader = (locale: string) => {
  return pimcoreRequest(Endpoints.HEADER, `language=${locale}`);
};
