import { CssProperties } from 'types/themes';
import { colors } from './colors';
import { fontSizes, getFont } from '../utils';

const defaultStyles: CssProperties = {
  color: colors.textColor,
  style: 'normal',
  weight: fontSizes.regular,
  size: '1.6rem',
  lineHeight: 'auto',
  family: 'Kumbh Sans',
};

export default {
  header: {
    h1: getFont(
      {
        size: '4rem',
        weight: fontSizes.semiBold,
        family: 'Lora',
        lineHeight: '130%',
      },
      defaultStyles,
    ),
    h2: getFont(
      {
        size: '3.2rem',
        weight: fontSizes.semiBold,
        family: 'Lora',
        lineHeight: '130%',
      },
      defaultStyles,
    ),
    h3: getFont(
      {
        size: '2.4rem',
        weight: fontSizes.semiBold,
        family: 'Lora',
        lineHeight: '130%',
      },
      defaultStyles,
    ),
    h4: getFont(
      {
        size: '2rem',
        weight: fontSizes.semiBold,
        family: 'Lora',
        lineHeight: '130%',
      },
      defaultStyles,
    ),
    h5: getFont(
      {
        weight: fontSizes.semiBold,
        family: 'Lora',
        lineHeight: '130%',
      },
      defaultStyles,
    ),
  },
  subtitle: {
    s1: getFont(
      {
        lineHeight: '160%',
        size: '3.2rem',
      },
      defaultStyles,
    ),
    s1SemiBold: getFont(
      {
        lineHeight: '160%',
        size: '3.2rem',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
    s2: getFont(
      {
        lineHeight: '160%',
        size: '2.4rem',
      },
      defaultStyles,
    ),
    s2SemiBold: getFont(
      {
        lineHeight: '160%',
        size: '2.4rem',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
    s3: getFont(
      {
        lineHeight: '160%',
        size: '2rem',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
    s4: getFont(
      {
        lineHeight: '160%',
        weight: fontSizes.semiBold,
      },
      defaultStyles,
    ),
  },
  body: {
    large: getFont({ size: '2rem' }, defaultStyles),
    regular: getFont({}, defaultStyles),
    small: getFont({ size: '1.4rem' }, defaultStyles),
    xSmall: getFont({ size: '1.2rem' }, defaultStyles),
  },
  caption: {
    large: getFont({ size: '1.4rem' }, defaultStyles),
    regular: getFont({ size: '1.2rem' }, defaultStyles),
    small: getFont({ size: '1rem' }, defaultStyles),
  },
  button: {
    large: getFont(
      {
        size: '1.8rem',
        weight: fontSizes.medium,
      },
      defaultStyles,
    ),
    regular: getFont(
      {
        weight: fontSizes.medium,
      },
      defaultStyles,
    ),
    small: getFont(
      {
        size: '1.4rem',
        weight: fontSizes.medium,
      },
      defaultStyles,
    ),
  },
};
