import { ROUTING_PATHS } from 'src/router/routingPaths';
import { messages } from 'components/UserMenu/UserMenu.translations';

export const UserMenuStructure = [
  [
    {
      path: ROUTING_PATHS.user.dashboard,
      label: messages.dashboard,
    },
    {
      path: ROUTING_PATHS.user.profile,
      label: messages.profile,
    },
    {
      path: ROUTING_PATHS.user.myTransactions,
      label: messages.transactions,
    },
  ],
  [
    {
      path: ROUTING_PATHS.user.myNominees,
      label: messages.nominees,
    },
    {
      path: ROUTING_PATHS.user.myBeneficiaries,
      label: messages.beneficiaries,
    },
  ],
  [
    {
      path: ROUTING_PATHS.user.claimMiles,
      label: messages.claimMiles,
    },
    {
      label: messages.useMiles,
      items: [
        {
          path: ROUTING_PATHS.user.useMiles.bookAwardTicket,
          label: messages.bookAwardTicket,
        },
        {
          path: ROUTING_PATHS.user.useMiles.useMilesForUpgrading,
          label: messages.upgrade,
        },
        {
          path: ROUTING_PATHS.user.useMiles.useMilesForExtraBaggage,
          label: messages.extraBaggage,
        },
        /* {
          path: ROUTING_PATHS.user.useMiles.useMilesForPremiumLounge,
          label: messages.premiumLounge,
        },*/
      ],
    },
  ],
];
