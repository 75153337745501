import { useCallback, useEffect, useMemo, useState } from 'react';
import Icon from 'components/Icon';
import { MegaMenuTab, MegaMenuItem } from 'types/Layout';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import { FormattedMessage, useIntl } from '@triplake/lib-intl';
import Image from 'components/Image';
import { messages } from 'components/Header/shared/Header.translations';
import { useSelector } from 'react-redux';
import { getHelpMenu } from 'src/store/config/configSelectors';
import { useBaseUrl } from 'src/hooks/useBaseUrl';
import * as ui from './Drawer.mobile.ui';
import { DrawerMobileProps } from '../shared/Drawer.interfaces';
import { MenuView, BottomMenuContent } from './Drawer.mobile.constants';

export const DrawerMobile = ({ closeMenu, content }: DrawerMobileProps) => {
  const { formatMessage } = useIntl();
  const helpMenu = useSelector(getHelpMenu);
  const getUrl = useBaseUrl();
  const [menu, setMenu] = useState(MenuView.MAIN_MENU);
  const [menuName, setMenuName] = useState(formatMessage(messages.menu));
  const [menuContent, setMenuContent] = useState<Array<MegaMenuItem>>([]);
  const [bottomMenu, setBottomMenu] = useState(BottomMenuContent.NONE);

  // Callbacks
  const getMobileMenuColumnContent = useCallback(
    (column: MegaMenuItem) => {
      if (column.childLinks.length + column.childTiles.length)
        return (
          <ui.MobileMenuLinks>
            {[...column.childLinks, ...column.childTiles].map((link, key) => (
              <a href={getUrl(link.path)} target={link.target} key={key}>
                {link.text}
              </a>
            ))}
          </ui.MobileMenuLinks>
        );

      if (column.childButton.text)
        return (
          <ui.MobileMenuImageButton>
            <Image src={getUrl(column.childButton.imagePath)} alt={column.childButton.description} />
            <ui.MobileMenuImageButtonContent>
              <h4>{column.childButton.description}</h4>
              <Button type="secondary">
                <a href={column.childButton.path} target={column.childButton.target}>
                  {column.childButton.text}
                </a>
              </Button>
            </ui.MobileMenuImageButtonContent>
          </ui.MobileMenuImageButton>
        );

      if (column.title.is_url)
        return (
          <a href={getUrl(column.title.url.path)} target={column.title.url.target} className="linkButton">
            {column.title.url.text}
            <Icon name="chevronRightLargeDark" />
          </a>
        );
    },
    [getUrl],
  );

  // Memos
  const mainMenuTopics = useMemo(
    () =>
      Object.keys(content).map(optionKey => {
        const option: MegaMenuTab = content[optionKey];
        const onClick = () => {
          setMenu(MenuView.SUB_MENU);
          setMenuName(option.title);
          setMenuContent(option.items.reduce((accumulator, item) => accumulator.concat(item), []));
        };
        return (
          <ui.MobileMainMenuButton key={optionKey} onClick={onClick}>
            {option.title} <Icon name="chevronRightLargeDark" />
          </ui.MobileMainMenuButton>
        );
      }),
    [content],
  );

  const menuContentComponent = useMemo(() => {
    if (!menuContent) return [];
    return menuContent.map((column, index) => {
      const justATitleBar = (!column.childTiles.length && !column.childLinks.length) || !!column.childButton.text;
      const title = justATitleBar && column.title.is_url ? '' : column.title.title || column.title.url.text;
      return {
        id: column.title.title || column.title.url.text || column.childButton.text || index.toString(),
        title,
        content: getMobileMenuColumnContent(column),
        justATitleBar,
      };
    });
  }, [menuContent]);

  const bottomMenuContent = useMemo(() => {
    switch (bottomMenu) {
      case BottomMenuContent.HELP:
        return (
          <ui.MobileHelpMenu>
            <header>
              <FormattedMessage {...messages.needHelp} />
            </header>
            {(helpMenu?.items || []).map((item, itemKey) => (
              <a href={item.url} target="_blank" key={itemKey} rel="noreferrer">
                {item.title}
              </a>
            ))}
          </ui.MobileHelpMenu>
        );
      // case BottomMenuContent.SEARCH:
      //   return 'SEARCH';
      default:
        return null;
    }
  }, [helpMenu, bottomMenu]);

  // Effects
  useEffect(() => {
    document.body.classList.add('noScroll');
    return () => {
      document.body.classList.remove('noScroll');
    };
  }, []);
  // end of hooks

  return (
    <ui.MobileDrawer $menu={menu}>
      <ui.MainMenu>
        <ui.MobileDrawerHeader />
        <ui.MobileDrawerContent>
          <ui.MobileMainMenuButtons>{mainMenuTopics}</ui.MobileMainMenuButtons>
          <ui.MobileHelpAndSearchBox>
            <Button type="link-2-colors" onClick={() => setBottomMenu(BottomMenuContent.HELP)}>
              <Icon name="help" />
              {helpMenu?.title}
            </Button>
            {/*<Button type="link-2-colors" onClick={() => setBottomMenu(BottomMenuContent.SEARCH)}>*/}
            {/*  <Icon name="searchDark" />*/}
            {/*  <FormattedMessage {...messages.search} />*/}
            {/*</Button>*/}
          </ui.MobileHelpAndSearchBox>
        </ui.MobileDrawerContent>
      </ui.MainMenu>
      <ui.SubMenu>
        <ui.MobileDrawerHeader>
          <Button
            type="link-2-colors"
            onClick={() => {
              setMenu(MenuView.MAIN_MENU);
              setMenuName(formatMessage(messages.menu));
            }}>
            <Icon name="chevronRightLargeDark" className="slideLeft" />
            {menuName}
          </Button>
        </ui.MobileDrawerHeader>
        <ui.MobileDrawerContent>
          <Accordion chordsData={menuContentComponent} clickableTitle />
        </ui.MobileDrawerContent>
      </ui.SubMenu>
      <ui.MobileBottomMenu $show={bottomMenu !== BottomMenuContent.NONE}>
        <ui.MobileDrawerHeader>
          <Button type="link-2-colors" onClick={() => setBottomMenu(BottomMenuContent.NONE)}>
            <Icon name="chevronRightLargeDark" className="slideDown" />
            <FormattedMessage {...messages.goBack} />
          </Button>
        </ui.MobileDrawerHeader>
        {bottomMenuContent}
      </ui.MobileBottomMenu>

      <ui.MobileDrawerCloseButton onClick={closeMenu}>
        <Icon name="closeDark" />
      </ui.MobileDrawerCloseButton>
    </ui.MobileDrawer>
  );
};
