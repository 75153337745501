import { useCallback, useState, useMemo, useEffect } from 'react';
import {
  ChangeEvent,
  CheckBoxField,
  FileField,
  InputField,
  InputProps,
  RadioField,
  SelectField,
  SwitchField,
  TriplakeDatePickerField,
} from 'types/Form';
import { useIntl } from '@triplake/lib-intl';
import { Common } from 'src/Common.translations';
import { getFromField, getDefaultValueForField, validateField as validateFieldFunction, getNewValueForField } from 'src/utils/formHookUtils';
import { normalizeDate } from 'utils/dateUtils';
import { FormDataOptions, DataValues, FieldKeys, Errors, Value, FieldsElements, FieldOptions } from 'types/FormHooks';

export enum FieldTypes {
  INPUT,
  SELECT,
  CHECKBOX,
  RADIO,
  SWITCH,
  DATE,
  FILE,
}

export const useFormData = <T extends Record<string, string>>(fields: T, options?: FormDataOptions<T>) => {
  const { formatMessage } = useIntl();
  const [data, setData] = useState<DataValues<T>>(() =>
    Object.values(fields).reduce((accumulator, fieldName): DataValues<T> => {
      accumulator[fieldName] = getDefaultValueForField(options?.[fieldName]);
      return accumulator;
    }, {}),
  );
  const [errors, setErrors] = useState<Errors<T>>({});
  const get = (fieldName: FieldKeys<T>): Value | undefined => data?.[fieldName];
  const set = useCallback(
    (event: ChangeEvent<TriplakeDatePickerField | InputField | SelectField | CheckBoxField | RadioField | SwitchField | FileField>) => {
      const dataName = event.targetName ?? '';
      const value = event.value;
      setData((oldData: DataValues<T>): DataValues<T> => ({ ...oldData, [dataName]: value }));
    },
    [],
  );

  const validateField = useCallback(
    (fieldName: FieldKeys<T>, settings?: { returnErrors?: boolean; setErrorToField?: boolean }): boolean | string => {
      const minLength: number | undefined = ((options?.[fieldName]?.fieldProps ?? {}) as InputProps).minLength;
      const maxLength: number | undefined = ((options?.[fieldName]?.fieldProps ?? {}) as InputProps).maxLength;
      const errors = validateFieldFunction(options?.[fieldName], data[fieldName], data, {
        emptyField: options?.[fieldName]?.errorMessages?.emptyField ?? formatMessage(Common.emptyFieldError),
        tooShortEntry: options?.[fieldName]?.errorMessages?.tooShortEntry ?? formatMessage(Common.tooShortEntryError, { length: minLength }),
        tooLongEntry: options?.[fieldName]?.errorMessages?.tooLongEntry ?? formatMessage(Common.tooLongEntryError, { length: maxLength }),
      });
      if (settings?.setErrorToField) setErrors(prevErrors => ({ ...prevErrors, [fieldName]: errors }));
      return settings?.returnErrors ? errors : !errors;
    },
    [options, data],
  );

  const fieldsElements = useMemo<FieldsElements<T>>(
    () =>
      Object.keys(options || {}).reduce((fieldsElements: FieldsElements<T>, fieldName) => {
        const field: FieldOptions<T> = options?.[fieldName] ?? {};
        const error: string | boolean | undefined = errors?.[fieldName];
        const onBlur =
          field.validateOnBlur || error
            ? typeof (field.fieldProps as any)?.onBlur === 'function'
              ? e => {
                  (field.fieldProps as any).onBlur(e);
                  validateField(fieldName as FieldKeys<T>, { setErrorToField: true });
                }
              : () => validateField(fieldName as FieldKeys<T>, { setErrorToField: true })
            : undefined;
        fieldsElements[fieldName] = getFromField({
          fieldName: fieldName,
          error,
          onBlur,
          set,
          options: field,
          value: data[fieldName],
          values: data,
        });

        return fieldsElements;
      }, {}),
    [options, data, errors],
  );
  const valid = useMemo(
    () => Object.keys(options || {}).every(fieldName => validateField(fieldName as FieldKeys<T>)),
    [options, data, validateField],
  );

  const validate = useCallback((): boolean => {
    if (valid) {
      setErrors({});
      return true;
    }
    const errors: Errors<T> = {};
    Object.keys(options || {}).forEach(fieldName => {
      const error = validateField(fieldName as FieldKeys<T>, { returnErrors: true });
      if (error) errors[fieldName] = error;
    });
    setErrors(errors);

    return valid;
  }, [valid, options, data]);

  const getFields = useCallback((...fields: Array<FieldKeys<T>>) => <>{fields.map(field => fieldsElements[field])}</>, [fieldsElements]);

  const getData = useCallback(() => {
    if (options)
      return Object.keys(options).reduce((accumulator, currentFieldName) => {
        if (options[currentFieldName].ignoreOnGetData) return accumulator;
        const keys = currentFieldName.split('.');
        const fieldOptions = options[currentFieldName];
        let nestedObject = accumulator;
        const value =
          typeof fieldOptions?.valueMapper === 'function'
            ? fieldOptions.valueMapper(data[currentFieldName])
            : data[currentFieldName] instanceof Date
            ? normalizeDate(data[currentFieldName]).toISOString()
            : data[currentFieldName];

        keys.forEach((key, i) => {
          if (i == keys.length - 1) nestedObject[key] = value;
          else {
            nestedObject[key] = nestedObject[key] || {};
            nestedObject = nestedObject[key];
          }
        });

        return accumulator;
      }, {});
  }, [data, options]);

  const resetForm = useCallback(() => {
    setData(() =>
      Object.values(fields).reduce((accumulator, fieldName): DataValues<T> => {
        accumulator[fieldName] = getDefaultValueForField(options?.[fieldName]);
        return accumulator;
      }, {}),
    );
  }, [fields, options]);

  const isModified = useMemo(() => {
    if (options)
      return Object.keys(options).some(fieldName => {
        const fieldOptions = options[fieldName];
        switch (fieldOptions?.type) {
          case FieldTypes.SWITCH:
            return fieldOptions.fieldProps?.isOn !== data[fieldName];
          case FieldTypes.CHECKBOX:
            return fieldOptions.fieldProps?.checked !== data[fieldName];
          default:
            return fieldOptions.value !== data[fieldName];
        }
      });
    return Object.values(data).some(value => !value);
  }, [data, options]);

  useEffect(() => {
    if (options)
      setData(() =>
        Object.values(fields).reduce((accumulator, fieldName): DataValues<T> => {
          const val = getNewValueForField(options?.[fieldName]);
          if (val !== undefined) accumulator[fieldName] = val;
          return accumulator;
        }, {}),
      );
  }, [fields, options]);

  return {
    get,
    set,
    data,
    fields: fieldsElements,
    getFields,
    valid,
    getData,
    validate,
    isModified,
    resetForm,
  };
};
