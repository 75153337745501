import { useMemo } from 'react';
import Button, { NavigationButton } from 'components/Button';
import { FormattedMessage, useIntl } from '@triplake/lib-intl';
import Icon from 'components/Icon';
import * as ui from 'components/Header/components/Drawer/mobile/Drawer.mobile.ui';
import Accordion from 'components/Accordion';
import { useAuthWrapper } from 'src/hooks/useAuthWrapper';
import { useUser } from 'src/hooks/useUser';
import { UserMenuStructure } from './UserMenuStructure';
import { messages } from './UserMenu.translations';

const UserMenuMobile = ({ onNavigationCallback }) => {
  const { formatMessage } = useIntl();
  const { profile } = useUser();
  const { logout, login, isAuthenticated } = useAuthWrapper();

  const userMenu = useMemo(() => {
    const userMenuStructure = UserMenuStructure.reduce((accumulator, current) => accumulator.concat(current), []);
    return userMenuStructure.map((item, itemKey) => {
      const justATitleBar = typeof item.items === 'undefined';
      const content = justATitleBar ? (
        <NavigationButton type="link-2-colors" path={item.path} className="linkButton" onClick={onNavigationCallback}>
          <FormattedMessage {...item.label} />
          <Icon name="chevronRightLargeDark" />
        </NavigationButton>
      ) : (
        <ui.MobileMenuLinks>
          {item.items.map((link, key) => (
            <NavigationButton path={link.path} onClick={onNavigationCallback} key={key}>
              <FormattedMessage {...link.label} />
            </NavigationButton>
          ))}
        </ui.MobileMenuLinks>
      );
      const title = justATitleBar ? '' : formatMessage(item.label);
      return {
        id: itemKey.toString(),
        content,
        title,
        justATitleBar,
      };
    });
  }, [onNavigationCallback, formatMessage]);

  const userPart = useMemo(() => {
    if (isAuthenticated)
      return (
        <ui.MobileMyAccountMenuButton>
          <Icon name="user_filled" />
          <div>
            {profile?.personalDetails?.firstName ?? ''} {profile?.personalDetails?.lastName ?? ''}
          </div>
        </ui.MobileMyAccountMenuButton>
      );
    return (
      <ui.MobileMyAccountMenuButton>
        <Button
          type="link-2-colors"
          onClick={() => {
            login();
          }}>
          <FormattedMessage {...messages.login} />
          <Icon name="login" />
        </Button>
      </ui.MobileMyAccountMenuButton>
    );
  }, [isAuthenticated]);

  return (
    <ui.MobileDrawer $menu={0}>
      <ui.MainMenu>
        <ui.MobileDrawerHeader />
        <ui.MobileDrawerContent>
          {userPart}
          <Accordion chordsData={userMenu} clickableTitle />
          {isAuthenticated ? (
            <ui.MobileMyAccountMenuButton>
              <Button type="link-2-colors" onClick={logout}>
                <FormattedMessage {...messages.logout} />
                <Icon name="logout" />
              </Button>
            </ui.MobileMyAccountMenuButton>
          ) : null}
        </ui.MobileDrawerContent>
      </ui.MainMenu>

      <ui.MobileDrawerCloseButton onClick={onNavigationCallback}>
        <Icon name="closeDark" />
      </ui.MobileDrawerCloseButton>
    </ui.MobileDrawer>
  );
};

export default UserMenuMobile;
