import { defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  get_languages_request: {
    id: 'API.error.get_languages_request',
    defaultMessage: 'App Languages request error:',
  },
  get_user_beneficiaries_request: {
    id: 'API.error.get_user_beneficiaries_request',
    defaultMessage: 'Get user beneficiaries error:',
  },
  get_user_nominees_request: {
    id: 'API.error.get_user_nominees_request',
    defaultMessage: 'Get user nominees error:',
  },
  send_request_with_data_request: {
    id: 'API.error.send_request_with_data_request',
    defaultMessage: 'The endpoint do not Exists.',
  },
  send_grant_request_request: {
    id: 'API.error.send_grant_request_request',
    defaultMessage: 'The request failed. Try again later or report the issue.',
  },
  create_account_request: {
    id: 'API.error.create_account_request',
    defaultMessage: 'Creation of account failed. You may try again or report an issue.',
  },
  get_user_profile_request: {
    id: 'API.error.get_user_profile_request',
    defaultMessage: 'Error reading user.',
  },
  get_user_card_request: {
    id: 'API.error.get_user_card_request',
    defaultMessage: 'Error getting card data.',
  },
  failed_to_fetch: {
    id: 'API.error.failed_to_fetch',
    defaultMessage: 'There was an issue fetching the data. Try again later or rise a ticket.',
  },
});

export const getTranslation = (text: string) => {
  const key = text.toLowerCase().replace(/\s/g, '_');
  const message = messages[key];
  return (
    message || {
      id: `API.error.${key}`,
      defaultMessage: text,
    }
  );
};
