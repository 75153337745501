import { setPreferredLocale, setPreferredRegion, savePreferredLocale, savePreferredRegion } from 'src/store/intl/intlActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPreferredLocale,
  getRegion,
  getDefaultLocale,
  getMessages,
  getDefaultMessages,
  getLocaleError,
  getSupportedLocales,
  isLoadingLocale,
} from 'src/store/intl/intlSelectors';
import { TUseLocale } from 'types/store/Intl';
import { International } from 'src/store/intl/intlConstants';
import { getSearchParams } from 'utils/browserUtils';

export const useLocale = (): TUseLocale => {
  const dispatch = useDispatch();

  const preferredLocale = useSelector(getPreferredLocale);
  const defaultLocale = useSelector(getDefaultLocale);
  const messages = useSelector(getMessages);
  const defaultMessages = useSelector(getDefaultMessages);
  const supportedLocales = useSelector(getSupportedLocales);
  const isLoading = useSelector(isLoadingLocale);
  const errorMessage = useSelector(getLocaleError);
  const region = useSelector(getRegion);

  const changeLocale = (locale: string, region: string = International) => {
    dispatch(setPreferredRegion(region));
    dispatch(setPreferredLocale(locale));
    dispatch(savePreferredRegion(region));
    dispatch(savePreferredLocale(locale));

    const searchParams = getSearchParams();
    if (searchParams.has('lang')) {
      searchParams.set('lang', region !== International ? `${locale}_${region}` : locale);
      const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
      window.history.replaceState(null, '', newUrl);
    }
  };

  return {
    preferredLocale,
    defaultLocale,
    messages,
    defaultMessages,
    supportedLocales,
    isLoadingLocale: isLoading,
    errorMessage,
    changeLocale,
    region,
  };
};
