import { useRef, MutableRefObject, useState, useEffect, useCallback, useMemo } from 'react';
import { ChordProps } from 'types/Accordion';
import Icon from 'components/Icon';
import Button from 'components/Button';
import * as ui from './Accordion.ui';

const Chord = ({
  title,
  subtitle,
  content,
  id,
  onChordClick,
  openButton,
  closeButton,
  isActive = false,
  isDisabled = false,
  justATitleBar = false,
  clickableTitle = false,
}: ChordProps) => {
  const [height, setHeight] = useState<number | undefined>();
  // Refs
  const contentRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
  const wrapperRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
  const timeoutIdRef: MutableRefObject<number> = useRef(0);

  const calcContentHeightCallback = useCallback((): void => {
    const contentDiv: HTMLDivElement | null = contentRef.current;
    if (contentDiv) setHeight(contentDiv.getBoundingClientRect().height);
  }, []);

  // Effects
  useEffect((): void => {
    const wrapperDiv: HTMLDivElement | null = wrapperRef.current;
    if (isActive) {
      calcContentHeightCallback();
      if (wrapperDiv) wrapperDiv.classList.add('active');
      if (timeoutIdRef.current) return;
      timeoutIdRef.current = setTimeout(function () {
        const contentDiv: HTMLDivElement | null = contentRef.current;
        const wrapperDiv: HTMLDivElement | null = wrapperRef.current;
        if (!contentDiv || !wrapperDiv) return;
        wrapperDiv.style.transition = 'none';
        wrapperDiv.style.paddingBottom = '0';
        wrapperDiv.style.overflow = 'visible';
        contentDiv.style.position = 'static';
      }, 513) as unknown as number;
    } else {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = 0;
      }
      const contentDiv: HTMLDivElement | null = contentRef.current;
      if (!contentDiv || !wrapperDiv) return;
      wrapperDiv.style.paddingBottom = '';
      contentDiv.style.position = '';
      requestAnimationFrame(function () {
        requestAnimationFrame(function () {
          const wrapperDiv: HTMLDivElement | null = wrapperRef.current;
          if (wrapperDiv) {
            wrapperDiv.style.overflow = 'hidden';
            wrapperDiv.style.transition = '';
            wrapperDiv.classList.remove('active');
          }
          calcContentHeightCallback();
        });
      });
    }
  }, [isActive]);

  useEffect((): void => calcContentHeightCallback(), [calcContentHeightCallback]);

  // Memos
  const defaultButton = useMemo(() => <Icon name="chevronRightLargeDark" className="defaultIcon" />, []);

  const openCloseButton = useMemo(() => {
    if (isActive) return closeButton;
    return openButton;
  }, [isActive, openButton, closeButton]);
  // End of hooks

  if (justATitleBar) {
    return (
      <ui.ChordWrapper ref={wrapperRef} id={`Chord-${id}`}>
        {title && (
          <ui.ChordTitleBar aria-disabled={isDisabled} $active={isActive}>
            <ui.ChordTitle>
              {title}
              {subtitle && <span>{subtitle}</span>}
            </ui.ChordTitle>
          </ui.ChordTitleBar>
        )}
        <ui.ChordPanelItem>{content}</ui.ChordPanelItem>
      </ui.ChordWrapper>
    );
  }

  return (
    <ui.ChordWrapper ref={wrapperRef} id={`Chord-${id}`} $contentHeight={height}>
      <ui.ChordTitleBar role="button" aria-disabled={isDisabled} $active={isActive}>
        <ui.ChordTitle onClick={clickableTitle ? onChordClick : undefined} className={clickableTitle ? 'clickable' : ''}>
          {title}
          {subtitle && <span>{subtitle}</span>}
        </ui.ChordTitle>
        <Button onClick={isDisabled ? undefined : onChordClick} disabled={isDisabled} overwriteFocus={` `}>
          {openCloseButton || defaultButton}
        </Button>
      </ui.ChordTitleBar>
      <ui.ChordContent ref={contentRef}>{content}</ui.ChordContent>
    </ui.ChordWrapper>
  );
};

export default Chord;
