import { createRequestTypes, TRequestTypes } from '../utils/actionHelpers';

export const SET_PREFERRED_LOCALE: string = 'SET_PREFERRED_LOCALE';
export const SET_PREFERRED_REGION: string = 'SET_PREFERRED_REGION';
export const SAVE_PREFERRED_LOCALE: string = 'SAVE_PREFERRED_LOCALE';
export const SAVE_PREFERRED_REGION: string = 'SAVE_PREFERRED_REGION';

export const POST_LANGUAGE: TRequestTypes = createRequestTypes('POST_LANGUAGE');
export const INTL_GET_LANGUAGES: TRequestTypes = createRequestTypes('INTL_GET_LANGUAGES');
export const INTL_GET_MESSAGES: TRequestTypes = createRequestTypes('INTL_GET_MESSAGES');

export const International = 'international';
