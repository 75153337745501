import { AnyAction } from 'redux';
import { GET_USER_PROFILE } from 'src/store/user/userConstants';
import { getSearchParams } from 'utils/browserUtils';
import { TIntlState } from 'types/store/Intl';

import { GET_LANGUAGES } from '../config/configConstants';
import { createReducer } from '../utils/createReducer';
import {
  International,
  INTL_GET_MESSAGES,
  SET_PREFERRED_LOCALE,
  SET_PREFERRED_REGION,
  SAVE_PREFERRED_LOCALE,
  SAVE_PREFERRED_REGION,
} from './intlConstants';
import { saveToBrowserStorage, readFromBrowserStorage } from './utils/intlReducerHelpers';

export const INITIAL_DEFAULT_LOCALE: string = 'en';
const getPreferredLocale = (): { localeCode?: string; regionCode?: string } => {
  const searchParams = getSearchParams();
  if (searchParams.has('lang')) {
    const [localeCode, regionCode = International] = searchParams.get('lang')!.split(/[_-]/);
    return { localeCode, regionCode };
  }
  return {
    localeCode: readFromBrowserStorage('locale') || undefined,
    regionCode: readFromBrowserStorage('region') || undefined,
  };
};

const initialState: TIntlState = {
  supportedLocales: [],
  messages: {
    [INITIAL_DEFAULT_LOCALE]: {},
  },
  isLoadingLocale: false,
  defaultLocale: INITIAL_DEFAULT_LOCALE,
  preferredLocale: getPreferredLocale().localeCode,
  region: getPreferredLocale().regionCode,
};

const languagesSuccess = (state: TIntlState, action: AnyAction) => {
  const { language } = action.response;
  const supportedLocales = Object.keys(language);

  return {
    ...state,
    supportedLocales,
  };
};

const languagesFailure = (state: TIntlState, action: AnyAction) => ({
  ...state,
  supportedLocales: [INITIAL_DEFAULT_LOCALE],
});

const getMessagesRequest = (state: TIntlState, action: AnyAction) => ({
  ...state,
  errorMessage: null,
  requestedLocale: action.apiMethodArg,
  isLoadingLocale: true,
});

const getMessagesSuccess = (state: TIntlState, action: AnyAction) => ({
  ...state,
  messages: {
    ...state.messages,
    [action.originalAction.apiMethodArg]: parseMessages(action.response),
  },
  errorMessage: null,
  isLoadingLocale: false,
});

const savePreferredLocale = (state: TIntlState, action: AnyAction) => {
  saveToBrowserStorage('locale', action.locale);
  return state;
};
const setPreferredLocale = (state: TIntlState, action: AnyAction) => ({ ...state, preferredLocale: action.locale });

const savePreferredRegion = (state: TIntlState, action: AnyAction) => {
  saveToBrowserStorage('region', action.region);
  return state;
};
const setPreferredRegion = (state: TIntlState, action: AnyAction) => ({ ...state, region: action.region });

const setUsersPreferredLocale = (state: TIntlState, action: AnyAction) => {
  if (!!state.preferredLocale) return state;
  const preferredLocale = action.response.personalDetails?.preferredLanguageAlpha2Code ?? state.preferredLocale;

  return {
    ...state,
    preferredLocale,
    requestedLocale: preferredLocale,
    isLoadingLocale: true,
  };
};

const intlReducer = {
  [GET_LANGUAGES.SUCCESS]: languagesSuccess,
  [GET_LANGUAGES.FAILURE]: languagesFailure,
  [GET_USER_PROFILE.SUCCESS]: setUsersPreferredLocale,
  [INTL_GET_MESSAGES.REQUEST]: getMessagesRequest,
  [INTL_GET_MESSAGES.SUCCESS]: getMessagesSuccess,
  [SET_PREFERRED_LOCALE]: setPreferredLocale,
  [SET_PREFERRED_REGION]: setPreferredRegion,
  [SAVE_PREFERRED_LOCALE]: savePreferredLocale,
  [SAVE_PREFERRED_REGION]: savePreferredRegion,
};

export default createReducer(intlReducer, initialState);

const parseMessages = (translations: Array<{ key: string; value: string }>): { [key: string]: string } =>
  translations.reduce((messages, translation) => {
    messages[translation.key] = translation.value;
    return messages;
  }, {});
