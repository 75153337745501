import { FormatDateOptions } from '@formatjs/intl';
import { DateTimeFormatOptions, DateTimePart } from 'types/types';
import { AirlineTheme } from 'types/themes';
import { KeyboardEvent } from 'react';
import { Dictionaries, StringDictionary } from 'types/store/Config';
import { SelectOption } from 'types/Form';
import { ImageSize } from 'src/themes/utils';

export const debounce = (
  foo: (...args: Array<any>) => void,
  timeout: number = 512,
  thisArgument: any = undefined,
): ((...args: Array<any>) => void) => {
  let timerId: number = 0;
  return (...args: Array<any>): void => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout((): void => foo.call(thisArgument, ...args), timeout) as unknown as number;
  };
};

export const debounceLeading = (
  foo: (...args: Array<any>) => void,
  timeout: number = 512,
  thisArgument: any = undefined,
): ((...args: Array<any>) => void) => {
  let timerId: number = 0;
  return (...args: Array<any>): void => {
    if (timerId === 0) foo.call(thisArgument || this, ...args);
    else clearTimeout(timerId);
    timerId = setTimeout((): void => {
      timerId = 0;
    }, timeout) as unknown as number;
  };
};

export const escapeRegExp = (value: string): string => {
  return value.replace(/[\\.*+?^${}()|[\]]/g, '\\$&');
};

export const getDateFormatRepresentation = (
  formatDate: (value: Parameters<Intl.DateTimeFormat['format']>[0] | string, opts?: FormatDateOptions) => string,
  formatOptions: DateTimeFormatOptions = { year: 'numeric', day: '2-digit', month: '2-digit' },
): string => {
  const checkDate = new Date('1410-07-15T09:02:00');
  const formattedDate = formatDate(checkDate, formatOptions);
  return Object.keys(formatOptions).reduce((finalString: string, option: string): string => {
    switch (option) {
      case 'year':
        return formatOptions.year === 'numeric' ? finalString.replace('1410', 'YYYY') : finalString.replace('10', 'YY');
      case 'month':
        return formatOptions.month === 'numeric' ? finalString.replace('7', 'M') : finalString.replace('07', 'MM');
      case 'day':
        return formatOptions.day === 'numeric' ? finalString.replace('15', 'D') : finalString.replace('15', 'DD');
      case 'hour':
        return formatOptions.hour === 'numeric' ? finalString.replace('9', 'H') : finalString.replace('09', 'HH');
      case 'minute':
        return formatOptions.minute === 'numeric' ? finalString.replace('2', 'm') : finalString.replace('02', 'mm');
      case 'second':
        return formatOptions.second === 'numeric' ? finalString.replace('0', 's') : finalString.replace('00', 'ss');
      default:
        return finalString;
    }
  }, formattedDate);
};
export const getDateInFormat = (dateObject?: Date, format: string = 'DD/MM/YYYY'): string => {
  if (!dateObject) return '';
  let formattedString = format;

  const stringify = (value: number, numeric?: boolean): string => {
    if (numeric) return value.toString();
    const string = `0${value}`;
    return string.substring(string.length - 2);
  };

  const getValue = (char: DateTimePart): number => {
    switch (char) {
      case 'Y':
        return dateObject.getFullYear();
      case 'M':
        return dateObject.getMonth() + 1;
      case 'h':
        return dateObject.getHours();
      case 'm':
        return dateObject.getMinutes();
      case 's':
        return dateObject.getSeconds();
      case 'D':
      default:
        return dateObject.getDate();
    }
  };

  function detectFormat(char: DateTimePart) {
    const indexes: Array<number> = [];
    for (let i = 0; i < format.length; ) {
      const index = format.indexOf(char, i);
      if (index < 0) i = format.length;
      else {
        i = index + 1;
        indexes.push(index);
      }
    }
    const replaceWith = indexes
      .reduce((accumulator: Array<number>, current: number, index: number, ary: Array<number>): Array<number> => {
        if (index && ary[index - 1] === current - 1) ++accumulator[accumulator.length - 1];
        else accumulator.push(1);
        return accumulator;
      }, [])
      .map(l => (l === 2 ? stringify(getValue(char)) : stringify(getValue(char), true)));

    formattedString = formattedString.replace(new RegExp(`${char}+`, 'g'), () => replaceWith.shift() ?? '');
  }

  detectFormat('Y');
  detectFormat('M');
  detectFormat('D');
  detectFormat('h');
  detectFormat('m');
  detectFormat('s');

  return formattedString;
};

export const getTransitions = (transitionSpeed: AirlineTheme | string, ...transitionNames: Array<string>): string => {
  if (!transitionNames?.length) return 'none';
  const time = (typeof transitionSpeed === 'string' ? transitionSpeed : transitionSpeed.animationSpeed) || '512ms';
  return transitionNames.map((transition: string): string => `${transition} ${time}`).join(', ');
};

export const rem = (px: number): string => `${px / 10}rem`;

export const actOnEnterAndSpace =
  (callback: (...args: Array<any>) => void, ...args: Array<any>) =>
  (event: KeyboardEvent) => {
    if (event.preventDefault) event.preventDefault();
    if (/(^ $)|(^Enter$)/.test(event.key)) callback.apply(event, args);
  };

const convertKeyName = (key: string): string => {
  return key
    .split('-')
    .map((part, i) => (i ? `${part.substring(0, 1).toUpperCase()}${part.substring(1)}` : part))
    .join('');
};

export const convertDictToSimpleOptions = (dictionary?: StringDictionary): Array<SelectOption> => {
  if (!dictionary) return [];
  return Object.keys(dictionary).map(
    (key: string): SelectOption => ({
      label: dictionary[key],
      value: key,
    }),
  );
};

export const convertDictionariesToSimpleOptions = (dictionaries: Dictionaries, sufix: string = ''): { [key: string]: Array<SelectOption> } => {
  return Object.keys(dictionaries).reduce((acc, key) => {
    acc[`${convertKeyName(key)}${sufix}`] = convertDictToSimpleOptions(dictionaries[key]);
    return acc;
  }, {});
};

export const promisify = async (promises: Array<Promise<any>>, initialItemToMergeTo: object = {}) => {
  return Promise.all(promises).then(results =>
    results.reduce(
      (accumulator, currentItem) => ({
        ...accumulator,
        ...currentItem,
      }),
      initialItemToMergeTo,
    ),
  );
};

const getImageMimeType = (src: string) => {
  if (!src || typeof src !== 'string') return undefined;
  if (/^data:image\/[a-z]{2,4}.*/i.test(src)) return src.replace(/^data:(image\/[a-z]{2,4}).*/i, '$1');
  const extension = src.replace(/^.+\.([a-z]{2,4})$/i, '$1');
  return extension ? `image/${extension.toLowerCase()}` : undefined;
};

const mapSizeToConstraints = (sizeName: string) => {
  if (/^_x[0-9]/.test(sizeName)) return `(min-resolution: ${(72 * parseInt(sizeName.replace('_x', ''), 10)).toString()}dpi)`;
  switch (sizeName) {
    case ImageSize.L:
      return '(max-width: 1440px)';
    case ImageSize.M:
      return '(max-width: 960px)';
    case ImageSize.S:
      return '(max-width: 512px)';
    default:
      return undefined;
  }
};

const getImagesFromTheme = (themeImages: { [key: string]: string } = {}, imageName: string = '') => {
  const compareName = new RegExp(`^${imageName}`);
  return Object.keys(themeImages).reduce((sources, key) => {
    if (compareName.test(key)) {
      const [sizeName, sizeFactor] = key.replace(imageName, '').split(/(?<=.)_/);
      const image = themeImages[key];
      const sourcesKey = /^_x[0-9]/.test(sizeName) ? '' : sizeName;
      const condition = /^_x[0-9]/.test(sizeName) && !sizeFactor ? sizeName.replace('_', '') : sizeFactor;
      sources[sourcesKey] = {
        srcset: [...(sources[sourcesKey]?.srcset ?? []), { image, condition }],
        type: sources[sourcesKey]?.type ?? getImageMimeType(image),
      };
    }
    return sources;
  }, {});
};
export const generateSourcesForDynamicImages = (themeImages: { [key: string]: string } = {}, imageName: string = '') => {
  const images = getImagesFromTheme(themeImages, imageName);
  return Object.keys(images).map(sizeName => {
    const mediaCondition = mapSizeToConstraints(sizeName);
    const srcset = images[sizeName].srcset.map(img => (img.condition ? img : { ...img, condition: 'x1' }));
    const type = images[sizeName].type;
    return {
      mediaCondition,
      srcset,
      type,
    };
  });
};

export const generateCssForDynamicBackgroundImage = (themeImages: { [key: string]: string } = {}, imageName: string = '') => {
  const images = getImagesFromTheme(themeImages, imageName);
  return Object.keys(images)
    .map(sizeName => {
      const mediaCondition = mapSizeToConstraints(sizeName);
      const imageVariants = images[sizeName].srcset.reduce((variants, image) => {
        if (image.condition) variants[(72 * parseInt(image.condition.replace('x', ''), 10)).toString()] = image.image;
        else variants[''] = image.image;
        return variants;
      }, {});
      const resolutions = Object.keys(imageVariants)
        .filter(key => !!key)
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
        .map(
          condition =>
            `@media ${mediaCondition ? `${mediaCondition} and ` : ''}(min-resolution: ${condition}dpi) { background-image: url(${
              imageVariants[condition]
            }); }`,
        );
      const basicBg = `background-image: url(${imageVariants[''] ?? ''});`;
      return `${mediaCondition ? `@media ${mediaCondition} { ${basicBg} }` : basicBg}\n${resolutions.join('')}`;
    })
    .join('\n');
};
