export const colors = {
  teal300: '#70B8B9',
  teal500: '#329a9b',
  teal800: '#2c3f4d',
  lime: '#B1D945',
  deepPurple900: '#2f2838',
  deepPurple999: '#0e0522',
  darkCyan: '#2b8384',
  black: '#000000',
  white: '#ffffff',
  grey: `#D0CFCF`,
  grey000: '#F1F1F1',
  grey100: '#f6f6f6',
  grey200: '#dfdedd',
  grey300: '#c3c2c0',
  grey400: '#bab9b6',
  grey500: '#aeadaa',
  grey600: '#73706c',
  red500: '#b71c1c',
  red800: '#9B3232',
  red900: '#b71c1c',
  lightBlue: '#e6f2f3',
  blue900: '#0D47A1',
  darkBlue: '#193F4A',
  darkerBlue: '#00212C',
  lightGrayishBlue: '#fafafb',
  amberA700: '#FFAB00',
  lightGrayishOrange: '#E3E2E1',
  lightGrayishBrown: '#8F8C88',
  darkGrey: '#353535',
  shadow: 'rgba(0, 0, 0, 0.1)',
  whiteTransparent: 'rgba(0, 0, 0, 0.05)',
  magenta: '#72195A',
  darkMagenta: '#54003C',
  deepYellow: '#E3B23C',
  veryDarkGrey: '#2F2838',
  lighterMagenta: '#A34987',
  darkLime: '#7EA700',
  transparent: 'transparent',
  green: '#7EA700',
  green100: 'rgba(177, 217, 69, 0.5)',
  green200: 'rgba(177, 217, 69, 0.3)',
  red: '#ed0a21',
  red100: '#b71c1c',
  green000: '#19b15d',
  green300: '#b8e6ce',
  green400: '#d0efdf',
  greenHaze: '#00aa32',
  orange: '#a18e57',
  orange100: '#d78100',
  orange200: '#a56400',
  blueWhale: '#032f3c',
  darkGray: '#525252'
};
