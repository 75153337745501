import fetch from 'isomorphic-fetch';
import { getCurrentUrl, getMessageFrom500HTMLPage } from 'utils/browserUtils';
import { join } from 'utils/urlUtils';
import { RequestParams } from 'types/Layout';
import config from '../../config';

export const ALLOY_SESSION_TOKEN: string = 'Alloy-Session-Token';

export enum HTTPMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

const parseParams = (params?: RequestParams): string => {
  if (typeof params === 'string') return `?${params}`;
  if (Array.isArray(params)) return params.length ? `?${params.join('&')}` : '';
  if (typeof params === 'object') {
    return parseParams(
      Object.keys(params).reduce((accumulator: Array<string>, key: string): Array<string> => {
        const value: string = Array.isArray(params[key])
          ? (params[key] as Array<string> | Array<number>).map(e => e.toString()).join(`&${key}=`)
          : params[key].toString();
        accumulator.push(`${key}=${value}`);
        return accumulator;
      }, []),
    );
  }
  return '';
};

export const callApi = (endpoint: string, method: HTTPMethod = HTTPMethod.GET, body?: FormData | {}, queryParams?: any, headers?: {}) => {
  const urlParts = [config.API_URL, endpoint];
  const queryParts = queryParams ? parseParams(queryParams) : '';
  if (queryParts) urlParts.push(queryParts);

  // if API_URL will reuse the current host (e.g. '/api/')
  if (config.API_URL?.startsWith('/')) {
    // manually insert the current host (e.g. https://branchspace.com) without user:pass
    // to stop the browser attempting to add Authorization header again when the base URL has credentials
    urlParts.unshift(getCurrentUrl());
  }

  const url = join(...urlParts);
  const bodyJson = body && method !== HTTPMethod.GET ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined;
  const requestHeaders = {
    Accept: 'application/json',
    ...headers,
  };
  if (!(body instanceof FormData)) requestHeaders['Content-Type'] = 'application/json';

  return fetch(url, {
    headers: requestHeaders,
    method,
    body: bodyJson,
  })
    .then(response => {
      const clonedResponse = response.clone();
      return response
        .json()
        .then(json => ({ json, response }))
        .catch(error => clonedResponse.text().then(text => ({ response, errorBody: text, message: error.message })));
    })
    .then(async ({ json, response, errorBody, message }: any) => {
      if (!response.ok) {
        const result = { httpStatusCode: response.status, statusText: response.statusText, headers, message, body: errorBody, ...({ json } || {}) };
        return Promise.reject(result);
      }
      return { httpStatusCode: response.status, statusText: response.statusText, headers: response.headers, responseBody: json };
    })
    .then(response => ({ response }))
    .catch(err => {
      let error: any = {
        httpStatusCode: err.httpStatusCode,
        statusText: err.statusText || `Error calling [${method}] ${endpoint}`,
        errorMessage: err && err.message,
        message: err && err.httpStatusCode < 500 ? err.body : getMessageFrom500HTMLPage(err.body),
      };
      if (err.json) {
        error = { ...error, json: err.json };
      }
      return { error };
    });
};

export const callSecuredApi = (
  endpoint: string,
  method: HTTPMethod = HTTPMethod.GET,
  headers?: { [key: string]: string },
  body?: FormData | {},
  queryParams?: any,
) => callApi(endpoint, method, body, queryParams, headers);

export const flattenObjectFromArrayForRequestParams = (array: Array<{ [key: string]: any }>, key: string) => {
  if (array.length < 2) return array.length ? array[0][key] : undefined;
  return array.map(object => object[key]);
};

export const pimcoreRequest = (endpoint: string, params?: RequestParams): Promise<any> => {
  const requestParams: string = parseParams(params);
  const url: Array<string> = [config.PIMCORE_URL || `${window.location.origin.replace('member', '')}`, endpoint, requestParams];

  return fetch(url.join(''), {
    method: HTTPMethod.GET,
    redirect: 'follow',
  })
    .then(
      (response: Response): Promise<any> =>
        response
          .json()
          .then((json: JSON): { json: JSON; response: Response } => ({ json, response }))
          .catch((error: Error): { response: Response; error: string } => ({ response, error: error.message })),
    )
    .then(({ response, json, error }: { response: Response; json?: JSON; error?: string }): Promise<any> => {
      if (response.ok)
        return Promise.resolve({
          response: {
            httpStatusCode: response.status,
            statusText: response.statusText,
            headers: response.headers,
            responseBody: json || JSON.parse('{}'),
          },
        });
      const result = json ? { response: json, httpStatusCode: response.status, error } : { httpStatusCode: response.status, error };
      return Promise.reject(result);
    })
    .catch((error: Error): { error: string } => ({ error: `Error calling [GET]${url.join('')}: ${error.message}` }));
};
