import backgroundPattern from '../../assets/images/airmalta/backgroundPattern.png';
import flightNumberLogo from '../../assets/images/airmalta/flightNumberLogo.png';
import noUpcomingBookings from '../../assets/images/airmalta/noUpcomingBookings.svg';
import logoRed from '../../assets/images/airmalta/logo-red.svg';

export default {
  backgroundPattern,
  flightNumberLogo,
  noUpcomingBookings,
  logoRed,
};
