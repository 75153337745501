import { useMemo, useState, useEffect } from 'react';
import { NormalizeStyle, themes } from '@triplake/ui-components/utils';
import { DeviceTypeContext } from '@triplake/lib-hooks';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import WebFont from 'webfontloader';
import { isBrowser as isDesktop, isMobile } from 'react-device-detect';
import config from 'src/config';
import { IntlProvider } from 'src/hoc/intl/IntlProvider';
import { GlobalStyle } from 'src/utils/GlobalStyles';
import { Router } from 'src/router/Router';
import { configureStore } from 'src/store/utils/configureStore';
import AuthContextProvider from './hoc/AuthContextProvider/AuthContextProvider';
import { getTheme, mapClientCodeToTheme, Themes } from './themes';
// import { SessionExpiredModal } from 'components/SessionExpiredModal';

const store = configureStore({});

export const App = () => {
  const [icons, setIcons] = useState({});
  const [images, setImages] = useState({});
  useEffect(() => {
    const themeName: string = mapClientCodeToTheme(config.THEME_DEFAULT) || Themes.AIR_MAURITIUS;
    import(`./assets/iconFiles/${themeName}/index.ts`).then(iconsModule => setIcons(iconsModule.default || null));
    import(`./assets/images/${themeName}/index.ts`).then(imagesModule => imagesModule.default && imagesModule.default.then(imgs => setImages(imgs)));
  }, []);

  const theme = useMemo(() => {
    const themeName: string = mapClientCodeToTheme(config.THEME_DEFAULT) || Themes.AIR_MAURITIUS;
    return { ...themes, isMobile, icons, images, ...getTheme(themeName as Themes) };
  }, [icons, images]);

  useEffect(() => {
    if (theme.fonts) {
      Object.values(theme.fonts as { [key: string]: string }).forEach((font: string) => {
        WebFont.load({
          google: { families: [font!] },
        });
      });
    }
  }, [theme]);

  const deviceInfo = useMemo(() => ({ isMobile, isDesktop }), []);

  return (
    <DeviceTypeContext.Provider value={deviceInfo}>
      <Provider store={store}>
        <IntlProvider>
          <ThemeProvider theme={theme}>
            <NormalizeStyle />
            <GlobalStyle theme={theme} />
            <style>{'iframe { display: none; }'}</style>
            <AuthContextProvider>
              {/* <SessionExpiredModal /> */}
              <Router />
            </AuthContextProvider>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    </DeviceTypeContext.Provider>
  );
};
