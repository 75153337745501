import PropTypes from 'prop-types';

export const accountMemberInfoShape = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  balance: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string
  })
});
