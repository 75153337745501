import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory, History } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { createRootReducer } from '../reducers';
import { sagas } from '../sagas';

const sagaMiddleware = createSagaMiddleware();
export const history: History = createBrowserHistory();

let enhancer = applyMiddleware(routerMiddleware(history), sagaMiddleware); // for dispatching history actions

if (process.env.NODE_ENV !== 'production') {
  const { composeWithDevTools } = require('redux-devtools-extension'); // eslint-disable-line import/no-extraneous-dependencies, global-require
  enhancer = composeWithDevTools(enhancer);
}

export const configureStore = (initialState: {}) => {
  const store = createStore(createRootReducer(history), initialState, enhancer);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept(
        '../reducers',
        () => store.replaceReducer(require('../reducers').default) // eslint-disable-line global-require
      );
    }
  }

  sagaMiddleware.run(sagas);

  return store;
};
