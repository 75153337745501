import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getBaseUrl } from 'src/store/config/configSelectors';

export const useBaseUrl = () => {
  const baseUrl: string = useSelector(getBaseUrl);
  const baseURL = /\/$/.test(baseUrl) ? baseUrl : `${baseUrl}/`;

  return useCallback((url: string): string => (/^https?:\/\//i.test(url) ? url : baseURL + (url || '').replace(/^\//, '')), [baseURL]);
};
