import { AnyAction } from 'redux';
import { SET_ACCOUNT_ID, GET_USER_PROFILE, GET_USER_CARD, GET_USER_BENEFICIARIES, GET_USER_NOMINEES, GET_USER_TRANSACTIONS } from './userConstants';
import { createReducer } from '../utils/createReducer';
import { TFetchAccountResponse, TUserState } from 'types/store/User';

const initialState: TUserState = {
  accountId: '',
  isLogin: false,
};

const setAccountId = (state: TUserState, action: AnyAction) => ({
  ...state,
  accountId: action.params.accountId,
});

const setUserData = (state: TUserState, action: AnyAction) => ({
  ...state,
  ...action.response,
});

const setTransactions = (state: TUserState, action: AnyAction) => ({
  ...state,
  transactions: action.response,
});

const setBeneficiaries = (state: TUserState, action: AnyAction) => ({
  ...state,
  beneficiaries: action.response,
});

const setNominees = (state: TUserState, action: AnyAction) => ({
  ...state,
  nominees: action.response,
});

const setUserCard = (state: TUserState, action: AnyAction) => {
  if (typeof action.callback === 'function') action.callback();
  return {
    ...state,
    card: action.response,
  };
};

const setUserProfile = (state: TUserState, action: TFetchAccountResponse) => {
  return {
    ...state,
    profile: action.response,
  };
};

const userReducer = {
  [SET_ACCOUNT_ID]: setAccountId,
  [GET_USER_CARD.SUCCESS]: setUserCard,
  [GET_USER_BENEFICIARIES.SUCCESS]: setBeneficiaries,
  [GET_USER_NOMINEES.SUCCESS]: setNominees,
  [GET_USER_TRANSACTIONS.SUCCESS]: setTransactions,
  [GET_USER_PROFILE.SUCCESS]: setUserProfile,
};

export default createReducer(userReducer, initialState);
