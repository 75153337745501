import { useRef, useState, useCallback, useMemo } from 'react';
import * as ui from './Radio.ui';
import { RadioProps, RadioOption, RadioField, ChangeEvent } from 'types/Form';

export const Radio: RadioField = ({ value, name, onChange, options, direction = 'column', error = false }: RadioProps) => {
  const [selectedValue, setSelectedState] = useState(value);

  // Refs
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Callbacks
  const changeCheckedCallback = useCallback(
    (option: RadioOption): void => {
      if (option.disabled) return;
      setSelectedState(option.value);
      if (typeof onChange === 'function')
        onChange(
          {
            value: option.value,
            targetName: name,
            targetElement: wrapperRef.current,
          } as unknown as ChangeEvent<RadioField>,
          selectedValue || '',
        );
    },
    [name, onChange],
  );

  // Memos
  const optionElements = useMemo(() => {
    return options.map((option: RadioOption) => {
      const onChangeFoo = function () {
        changeCheckedCallback(option);
        if (typeof option.onClick === 'function') option.onClick.call(option);
      };
      return (
        <ui.FieldWrapper key={option.value}>
          <ui.RadioInput
            value={option.value}
            name={name}
            onChange={onChangeFoo}
            checked={selectedValue === option.value}
            disabled={option.disabled}
          />
          <ui.RadioLabel onClick={onChangeFoo} htmlFor={name}>
            {option.label}
          </ui.RadioLabel>
        </ui.FieldWrapper>
      );
    });
  }, [options, selectedValue, changeCheckedCallback, name]);

  return (
    <ui.RadioContainer>
      <ui.RadioWrapper ref={wrapperRef} $error={!!error} $direction={direction}>
        {optionElements}
      </ui.RadioWrapper>
      {typeof error === 'string' ? <ui.RadioErrorMessage>{error}</ui.RadioErrorMessage> : null}
    </ui.RadioContainer>
  );
};
