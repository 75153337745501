import styled from 'styled-components';
import { AlertType } from './Alert';

export const AlertTitle = styled.span`
  ${({ theme }) => theme.textStyles.subtitle.s4};
  line-height: 130%;
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.button.small.join(' ')};
  `}
  &:empty {
    display: none;
  }
`;

export const AlertCloseButton = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 0.8rem;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  ${({ theme }) => theme.mediaQueries.SM`
    top: .4rem;
    opacity: 1;
  `}
`;

export const Alert = styled.div<{ type?: AlertType }>`
  position: relative;
  display: flex;
  padding: 1.6rem 3.2rem 1.6rem 1.6rem;
  align-items: center;
  gap: 0.8rem;
  align-self: stretch;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme, type }) => (type ? theme.colors[`${type}Light`] : theme.backgroundColor)};
  & ${AlertTitle} {
    color: ${({ theme, type }) => (type ? theme.colors[`${type}Dark`] : theme.textColor)};
  }
  & ${AlertCloseButton} svg {
    transform: scale(0.67);
    path {
      stroke: ${({ theme }) => theme.textColor};
    }
  }
  ${({ theme }) => theme.mediaQueries.SM`
		padding: .8rem;
		svg { transform: scale(calc(16 / 24)); }
	`}
`;

export const AlertContent = styled.span`
  ${({ theme }) => theme.textStyles.body.regular};
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.body.xSmall.join(' ')};
  `}
`;

export const AlertBody = styled.span<{ $column: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  overflow: hidden;
  ${({ $column }) =>
    $column
      ? `    flex-direction: column; 
    align-items: start;
    gap: 0;`
      : 'align-items: flex-start;'}
  &:hover {
    overflow: visible;
  }
  ${({ theme }) => theme.mediaQueries.SM`
    flex-direction: column;
    gap: 0;
  `}
`;
