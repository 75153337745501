export const fiatPriceFields = {
  extrasPriceField: 'price',
  flightsPriceField: 'amount',
  bundlesPriceField: 'price',
  flightSearchPriceField: 'value',
  summaryField: 'fiatAmount',
  itemSummaryField: 'fiatNetAmount'
};

export const loyaltyPriceFields = {
  extrasPriceField: 'loyaltyCurrencyAmount',
  flightsPriceField: 'loyaltyCurrencyAmount',
  bundlesPriceField: 'price',
  flightSearchPriceField: 'loyaltyProgramPrice.amount',
  summaryField: 'loyaltyProgramAmount',
  itemSummaryField: 'loyaltyProgramAmount'
};

export const taxPriceFields = {
  extrasPriceField: 'taxAmount',
  flightsPriceField: 'taxAmount',
  bundlesPriceField: 'taxAmount',
  flightSearchPriceField: 'taxAmount',
  summaryField: 'taxAmount',
  itemSummaryField: 'fiatTaxAmount'
};

export const potentialEarningsFields = {
  extrasPriceField: 'potentialEarning.amount',
  flightsPriceField: 'potentialEarning.amount',
  bundlesPriceField: 'potentialEarning.amount',
  flightSearchPriceField: 'potentialEarning.amount',
  summaryField: 'potentialEarningAmount',
  itemSummaryField: 'potentialEarningAmount'
};

export const priceCartFields = [fiatPriceFields, loyaltyPriceFields, taxPriceFields];
