import PropTypes from 'prop-types';

export const totalPriceShape = PropTypes.shape({
  fiatNetAmount: PropTypes.number,
  loyaltyProgramAmount: PropTypes.number,
  fiatTaxAmount: PropTypes.number,
  potentialEarningAmount: PropTypes.number
});

export const fiatAndLoyaltyAmountShape = PropTypes.shape({
  fiatAmount: PropTypes.number,
  loyaltyProgramAmount: PropTypes.number,
  potentialEarningAmount: PropTypes.number
});
