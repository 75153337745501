import { useMemo } from 'react';
import Icon from 'components/Icon';
import * as ui from './Drawer.desktop.ui';
import DrawerContent from './DrawerContent';
import { DrawerDesktopProps } from '../shared/Drawer.interfaces';

export const DrawerDesktop = ({ closeMenu, content }: DrawerDesktopProps) => {
  const drawerContent = useMemo(() => {
    if (Array.isArray(content)) return <DrawerContent content={content} />;
    return content;
  }, [content]);

  return (
    <ui.HeaderDrawer>
      <ui.HeaderDrawerCloseButton onClick={closeMenu}>
        <Icon name="closeDark" />
      </ui.HeaderDrawerCloseButton>
      <ui.HeaderDrawerContent>{drawerContent}</ui.HeaderDrawerContent>
    </ui.HeaderDrawer>
  );
};
