import { getSearchParams } from 'utils/browserUtils';

export const readFromBrowserStorage = (key: 'locale' | 'region') => {
  const value = sessionStorage.getItem(key); // get locale for current tab
  return value || localStorage.getItem(key);
};

export const saveToBrowserStorage = (key: 'locale' | 'region', value: string) => {
  try {
    sessionStorage.setItem(key, value);
    localStorage.setItem(key, value);
  } catch (ignored) {
    // ignore write errors
  }
};

const getPreferredLanguages = () => {
  if (Array.isArray(navigator.languages)) {
    return navigator.languages;
  }
  // @ts-ignore
  const language = navigator.language || navigator.userLanguage;
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
  return [language, languageWithoutRegionCode];
};

const preferredLanguages = getPreferredLanguages();

export const getPreferredLocale = (supportedLanguages: string[], defaultLanguage: string) => {
  const searchParams = getSearchParams();

  if (searchParams.has('lang')) {
    const langCode: string = searchParams.get('lang')!.substring(0, 2).toLowerCase();

    if (supportedLanguages.includes(langCode)) {
      saveToBrowserStorage('locale', langCode);
      return langCode;
    }
  }

  const savedLocale = readFromBrowserStorage('locale');

  if (savedLocale && supportedLanguages.includes(savedLocale)) {
    return savedLocale;
  }

  return preferredLanguages.find((lang: string) => supportedLanguages.includes(lang)) || defaultLanguage;
};
