import { useState, useRef, useCallback } from 'react';
import * as ui from './Checkbox.ui';
import { SwitchProps, SwitchField, ChangeEvent } from 'types/Form';

export const Switch: SwitchField = ({ value, isOn = false, name, label, tabindex, id, onChange, disabled = false, dimLabelWhenOff }: SwitchProps) => {
  const [onOffState, setOnOffState] = useState<boolean>(isOn);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const setState = useCallback(
    newState => {
      if (disabled) return;
      setOnOffState(newState);
      if (typeof onChange === 'function')
        onChange(
          {
            value: newState,
            prevValue: !newState,
            targetName: name,
            targetElement: wrapperRef.current,
          } as ChangeEvent<SwitchField>,
          newState,
        );
    },
    [disabled, onChange, name],
  );

  return (
    <ui.CheckBoxWrapper $dimLabelWhenOff={dimLabelWhenOff} ref={wrapperRef} $error={false} disabled={disabled}>
      <ui.SwitchInput
        defaultValue={value}
        name={name}
        id={id}
        onChange={() => setState(!onOffState)}
        checked={onOffState}
        {...((disabled && { 'disabled': true, 'aria-disabled': true }) || {})}
        tabIndex={tabindex}
      />
      <ui.CheckboxLabel onClick={() => setState(!onOffState)} htmlFor={name}>
        {label}
      </ui.CheckboxLabel>
    </ui.CheckBoxWrapper>
  );
};
