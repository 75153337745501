import { TUserState } from 'types/store/User';
import { useSelector } from 'react-redux';
import { getAccountId, getUserProfile, getUserDataByName } from '../store/user/userSelectors';
import { useAuthWrapper } from './useAuthWrapper';

export const useUser = (...requiredData: Array<string>): TUserState => {
  const accountId = useSelector(getAccountId);
  const profile = useSelector(getUserProfile);
  const { isAuthenticated } = useAuthWrapper();
  const data = useSelector(getUserDataByName(requiredData));

  return {
    isLogin: isAuthenticated,
    accountId,
    profile,
    ...data,
  };
};
