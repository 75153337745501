import airmalta from './airmalta';
import fonts from './fonts';
import images from './images';

// CUSTOM VARIABLES HERE FOR PORTAL
export const memberPortalStyles = {
  shipCove: '#607CB7',
  punch: '#DD4B39',
  buttonShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
  separatorColor: 'rgba(151, 151, 151, 0.4)',
  iconColor: '#BC1427',
  boxShadow: '16px 16px 30px 0 rgba(82, 82, 82, 0.05)',
};

const theme = {
  ...airmalta,
  textStyles: fonts,
  ...images,
  memberPortalStyles,
};

export default theme;
