interface TConfig {
  API_URL: string | undefined;
  API_USER: string | undefined;
  API_PASS: string | undefined;
  PIMCORE_URL: string | undefined;
  THEME_DEFAULT: string | undefined;
  KEYCLOAK_URL: string | undefined;
  KEYCLOAK_REALM: string | undefined;
  KEYCLOAK_CLIENT_ID: string | undefined;
}

const config: TConfig = {
  API_URL: process.env.API_URL,
  API_USER: process.env.API_USER,
  API_PASS: process.env.API_PASS,
  PIMCORE_URL: process.env.PIMCORE_URL,
  THEME_DEFAULT: process.env.THEME_DEFAULT,
  KEYCLOAK_URL: process.env.KEYCLOAK_URL,
  KEYCLOAK_REALM: process.env.KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID: process.env.KEYCLOAK_CLIENT_ID,
};

export default config;
