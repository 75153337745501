import { MouseEvent, MutableRefObject, ReactElement, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { getHeaderRequest } from 'src/store/config/configActions';
import { Theme } from 'types/themes';
import { MegaMenuOptions } from 'types/Layout';
import { useLocale } from 'src/hooks/useLocale';
import { getHeader } from 'src/store/config/configSelectors';
import { Dispatch } from 'redux';
import { International } from 'src/store/intl/intlConstants';
import config from 'src/config';
import { useAuthWrapper } from 'src/hooks/useAuthWrapper';
import { UserMenu, UserMenuMobile } from 'components/UserMenu';
import { LocaleDropdown } from 'components/LocaleDropdown';
import { disableFingerDragCallback } from 'utils/browserUtils';
import * as ui from './Header.templateA.ui';
import { DrawerDesktop, DrawerMobile } from '../components/Drawer';
import Icon from 'components/Icon';
import { useBaseUrl } from 'src/hooks/useBaseUrl';

const enum Menu {
  USER = 'userMenu',
  LANGUAGE = 'language',
  MOBILE = 'mobile',
  MOBILE_USER = 'MOBILE_USER',
  MOBILE_LANGUAGE = 'MOBILE_LANGUAGE',
}

export const HeaderTemplateA = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const locale = useLocale();
  const { isAuthenticated, login } = useAuthWrapper();
  const menuOptions: MegaMenuOptions = useSelector(getHeader);
  const theme: Theme = useTheme() as Theme;
  const getUrl = useBaseUrl();
  const [isMenuOpen, setMenuOpenState] = useState<boolean>(false);
  const [drawerContentId, setDrawerContent] = useState<string>('');
  const headerElementRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
  const overlayRef: RefObject<HTMLDivElement> = useRef(null);
  const headerRef: RefObject<HTMLDivElement> = useRef(null);

  /** Handling the scroll down hides MegaMenu */
  const scrollFoo = useCallback((): void => {
    const scrollTop: number = window.scrollY;
    const headerElement: HTMLDivElement | null = headerElementRef.current;
    if (!headerElement) return;

    if (scrollTop > 85 && !headerElement.classList.contains('disappear')) {
      headerElement.classList.add('disappear');
      headerElement.classList.remove('appear');
      if (isMenuOpen) {
        setMenuOpenState(false);
        setDrawerContent('');
      }
    } else if (scrollTop <= 85 && headerElement.classList.contains('disappear')) {
      headerElement.classList.add('appear');
      headerElement.classList.remove('disappear');
    }
  }, [isMenuOpen]);
  /** Swaps the drawer content or toggles it if clicked again on the same Menu option */
  const handleMenuOpenState = useCallback((event: MouseEvent, menu?: string): void => {
    let shouldClose = false;
    setDrawerContent((content: string): string => {
      if ((content && content === menu) || !menu) {
        shouldClose = true;
        return '';
      }
      return menu;
    });
    setMenuOpenState(shouldClose ? false : !!menu);
  }, []);
  /** Closes the Drawer and resets its content */
  const closeMenu = useCallback((): void => {
    setMenuOpenState(false);
    setDrawerContent('');
  }, []);
  /** */

  const overLayCloseCallback = useCallback(
    (e: MouseEvent): void => (overlayRef.current === e.nativeEvent.srcElement ? closeMenu() : undefined),
    [closeMenu],
  );

  // Effects
  useEffect(() => {
    dispatch(getHeaderRequest(locale.preferredLocale ?? locale.defaultLocale));
  }, [locale.preferredLocale]);

  useEffect(() => {
    window.addEventListener('scroll', scrollFoo);
    return () => window.removeEventListener('scroll', scrollFoo);
  }, [scrollFoo]);

  // Memos
  const mainMenu: Array<ReactElement> = useMemo(
    () =>
      Object.keys(menuOptions).map((key): ReactElement => {
        const option = menuOptions[key];
        return (
          <ui.HeaderButton onClick={(event: MouseEvent) => handleMenuOpenState(event, key)} key={key} $active={key === drawerContentId}>
            {option.title}
          </ui.HeaderButton>
        );
      }),
    [menuOptions, drawerContentId],
  );

  const disableDragCallback = useCallback(ev => disableFingerDragCallback(ev, headerRef.current), []);

  // Effects
  useEffect(() => {
    if (isMenuOpen) {
      document.body.addEventListener('touchmove', disableDragCallback, { passive: false });
      document.body.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
      document.body.removeEventListener('touchmove', disableDragCallback);
    }
  }, [isMenuOpen]);

  const [drawerContent, className] = useMemo(() => {
    const result: { content: ReactElement | null; isSmall: boolean } = {
      content: null,
      isSmall: false,
    };
    switch (drawerContentId) {
      case Menu.USER:
        result.content = <UserMenu onNavigationCallback={closeMenu} />;
        result.isSmall = true;
        break;
      case Menu.LANGUAGE:
        result.content = <LocaleDropdown onDone={closeMenu} />;
        result.isSmall = true;
        break;
      case Menu.MOBILE_USER:
        result.content = <UserMenuMobile onNavigationCallback={closeMenu} />;
        result.isSmall = true;
        break;
      case Menu.MOBILE_LANGUAGE:
        return [<LocaleDropdown onDone={closeMenu} />, {}];
      case Menu.MOBILE:
        return [<DrawerMobile closeMenu={closeMenu} content={menuOptions} />, {}];
      default:
        result.content = menuOptions[drawerContentId]?.items;
    }
    return [<DrawerDesktop closeMenu={closeMenu} content={result.content} />, result.isSmall ? { className: 'smallDrawer' } : {}];
  }, [drawerContentId, menuOptions, closeMenu]);

  const pimcoreOrigin = useMemo(() => (config.PIMCORE_URL || '').replace(/(^https?:\/\/[^/]+).*/, '$1'), []);
  const regionFlag = useMemo(
    () => locale.region && `${pimcoreOrigin}/bundles/pimcoreadmin/img/flags/countries/${locale.region?.toLowerCase()}.svg`,
    [locale.region],
  );

  const [userMenu, mobileUserMenu, mobileMenu, localeMenu] = useMemo(() => {
    return [
      <ui.DesktopIconWrapper
        onClick={(event: MouseEvent) => (isAuthenticated ? handleMenuOpenState(event, Menu.USER) : login())}
        $active={Menu.USER === drawerContentId}>
        <Icon name="user" outline={isAuthenticated} />
      </ui.DesktopIconWrapper>,
      <ui.MobileIconWrapper
        onClick={(event: MouseEvent) => (isAuthenticated ? handleMenuOpenState(event, Menu.MOBILE_USER) : login())}
        $active={false}>
        <Icon name="user" outline={isAuthenticated} />
      </ui.MobileIconWrapper>,
      <ui.MobileIconWrapper onClick={(event: MouseEvent) => handleMenuOpenState(event, Menu.MOBILE)} $active={false}>
        <Icon name={drawerContentId === Menu.MOBILE ? 'close' : 'menu'} />
      </ui.MobileIconWrapper>,
      <ui.HeaderIconButton onClick={(event: MouseEvent) => handleMenuOpenState(event, Menu.LANGUAGE)} $active={Menu.LANGUAGE === drawerContentId}>
        {locale.region === International || !regionFlag ? <Icon name="globe" /> : <img src={regionFlag} alt={locale.region} />}
        <span className="languageCode">{locale.preferredLocale?.toUpperCase()}</span>
      </ui.HeaderIconButton>,
    ];
  }, [drawerContentId, regionFlag, isAuthenticated]);

  const homePage = useMemo(() => getUrl(''), [getUrl]);
  // end of hooks

  return (
    <ui.HeaderWrapper ref={headerElementRef} {...className}>
      <ui.Overlay ref={overlayRef} onClick={overLayCloseCallback} show={isMenuOpen} />
      <ui.HeaderCenter>
        <ui.Header ref={headerRef}>
          <ui.LogoLink href={homePage}>
            <img alt="logo link" src={theme?.images?.logo} />
          </ui.LogoLink>
          <ui.HeaderMenuWrapper>{mainMenu}</ui.HeaderMenuWrapper>
          <ui.HeaderSecondaryMenuWrapper>
            {localeMenu}
            {userMenu}
            {mobileUserMenu}
            {mobileMenu}
          </ui.HeaderSecondaryMenuWrapper>
        </ui.Header>
        {isMenuOpen && drawerContentId ? drawerContent : null}
      </ui.HeaderCenter>
    </ui.HeaderWrapper>
  );
};
