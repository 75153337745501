import { ChangeEvent as ReactChangeEvent, useMemo, useCallback, useRef, useState, MouseEvent, useEffect } from 'react';
import * as ui from './Input.ui';
import { createChangeEvent } from './Input.utils';
import { debounce } from 'utils/commonUtils';
import { InputProps, InputField, ChangeEvent } from 'types/Form';
import { useIntl } from '@triplake/lib-intl';
import Icon from 'components/Icon';

export const Input: InputField = ({
  value,
  name,
  placeholder,
  tabindex,
  id,
  debounceOnChange,
  type,
  maxLength,
  minLength,
  inputClassName,
  autocomplete,
  width = 350,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClick = () => {},
  onKeyUp = () => {},
  disabled = false,
  optional = false,
  error = false,
  clearButton = false,
}: InputProps) => {
  const [fieldValue, setValue] = useState<string | number>(value || '');
  const intl = useIntl();

  // Refs
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  /** onChangeHandler is a Memo cause it is not a callback function in a normal sense, it is returning a new function
   * instead, that is created basing on the debounceOnChange value */
  const onChangeHandler = useMemo(
    (): ((...args: any[]) => boolean | string | void) => (debounceOnChange ? debounce(onChange, debounceOnChange) : onChange),
    [debounceOnChange, onChange],
  );

  // Callbacks
  const onChangeCallback = useCallback(
    (event: ReactChangeEvent): void => {
      const nativeEvent: InputEvent = event.nativeEvent as InputEvent;
      const value: string = (nativeEvent.target as HTMLInputElement).value;
      const shouldUpdate = onChangeHandler(
        {
          value,
          prevValue: fieldValue,
          targetName: name,
          targetElement: wrapperRef.current,
          lastTextInput: nativeEvent.data,
          inputType: nativeEvent.inputType,
        } as ChangeEvent<InputField>,
        value,
      );
      if (shouldUpdate === false) return;
      setValue(typeof shouldUpdate === 'string' ? shouldUpdate : value);
    },
    [onChangeHandler, name, fieldValue],
  );

  const clearFieldCallback = useCallback(
    (e: MouseEvent): void => {
      e.preventDefault();
      setValue('');
      onChangeCallback(createChangeEvent(''));
    },
    [onChangeCallback],
  );

  // Memos
  const className: string | undefined = useMemo(() => {
    const out: Array<string> = [];
    if (inputClassName) out.push(inputClassName);
    if (error) out.push('error');
    if (clearButton) out.push('clearButton');
    return out.length ? out.join(' ') : undefined;
  }, [inputClassName, error, clearButton]);

  // Effects
  useEffect(() => {
    setValue(value || '');
  }, [value]);

  return (
    <ui.InputWrapper $halfSize={width < 200} ref={wrapperRef}>
      <ui.Boundry>
        <ui.InputField
          ref={inputRef}
          value={fieldValue}
          name={name}
          id={id}
          tabIndex={tabindex}
          onChange={onChangeCallback}
          disabled={disabled}
          type={type}
          className={className}
          $width={width}
          minLength={minLength}
          maxLength={maxLength}
          required={!optional}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          onKeyUp={onKeyUp}
          autoComplete={autocomplete}
        />
        <ui.InputPlaceholder className={!!fieldValue ? 'small' : undefined}>
          {placeholder}
          {optional && <ui.InputOptionalLabel>{intl.formatMessage({ id: 'common.optional', defaultMessage: ' (Optional)' })}</ui.InputOptionalLabel>}
        </ui.InputPlaceholder>
        {clearButton && (
          <ui.ClearButton onMouseDown={clearFieldCallback} className={!!fieldValue ? 'show' : undefined}>
            <Icon name="clearButton" />
          </ui.ClearButton>
        )}
      </ui.Boundry>
      {typeof error === 'string' ? <ui.InputErrorMessage $width={width}>{error}</ui.InputErrorMessage> : null}
    </ui.InputWrapper>
  );
};
