import { SET_PREFERRED_LOCALE } from './intlConstants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { getMessages, getDefaultLocale, getPreferredLocale } from './intlSelectors';
import {
  importIntlPluralrulesPolyfill,
  importIntlPluralrules,
  importIntlRelativetimeformatPolyfill,
  importIntlRelativetimeformat,
  importMomentLocale,
} from './utils/localeImport';
import { intlGetMessagesRequest } from './intlActions';
import { GET_USER_PROFILE } from 'src/store/user/userConstants';
import { GET_LANGUAGES } from 'src/store/config/configConstants';

export function* loadMessages(locale: string) {
  const messages = yield select(getMessages, locale);

  if (!messages || !Object.keys(messages).length) {
    const { error } = yield put(intlGetMessagesRequest(locale));
    if (error) {
      throw error;
    }
  }
}

export function* setMomentLocale(locale: string) {
  yield importMomentLocale(locale);
  moment.locale(locale);
}

export function* addIntlLocaleData(locale: string) {
  yield importIntlPluralrulesPolyfill();
  yield importIntlPluralrules(locale);
  yield importIntlRelativetimeformatPolyfill();
  yield importIntlRelativetimeformat(locale);
}

function rollbackLocale(backupLocale: string) {
  // only moment.js setting needs to be rolled-back
  try {
    moment.locale(backupLocale);
  } catch (error) {
    // ignored
  }
}

let prevPL = '';
export function* loadLocale(action) {
  const defaultLocale: string = yield select(getDefaultLocale);
  const preferredLocale: string = yield select(getPreferredLocale);
  if (action.type === GET_LANGUAGES.SUCCESS && !preferredLocale) {
    prevPL = defaultLocale;
    yield call(loadMessages, defaultLocale);
    return;
  }
  if (prevPL === preferredLocale) return;

  let finallySelectedLocale;

  try {
    yield call(addIntlLocaleData, preferredLocale);
    yield call(setMomentLocale, preferredLocale);
    yield call(loadMessages, preferredLocale);
    prevPL = preferredLocale;

    if (defaultLocale !== preferredLocale) yield call(loadMessages, defaultLocale);

    finallySelectedLocale = preferredLocale || defaultLocale; // success
  } catch (error) {
    finallySelectedLocale = defaultLocale;
    rollbackLocale(finallySelectedLocale);
  }
}

function* intlSaga() {
  yield takeLatest(GET_LANGUAGES.SUCCESS, loadLocale);
  yield takeLatest(GET_USER_PROFILE.SUCCESS, loadLocale);
  yield takeLatest(SET_PREFERRED_LOCALE, loadLocale);
}

export default intlSaga;
