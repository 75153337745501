interface PartIndexes {
  yearPartIndex?: number;
  monthPartIndex?: number;
  dayPartIndex?: number;
  hoursPartIndex?: number;
  minutesPartIndex?: number;
  secondsPartIndex?: number;
}

interface ParsingDateTools {
  dateFormat: string;
  matcher: RegExp;
  matchedParts: Array<string>;
  separators: Array<string>;
  partsIndexes: PartIndexes;
}

export const getParsingDateTools = (dateFormat: string): ParsingDateTools => {
  const matchedParts: Array<string> = [].slice.apply(dateFormat.match(/(M{1,2})|(D{1,2})|((YY){1,2})|(H{1,2})|(m{1,2})|(s{1,2})/g));
  const partsIndexes: PartIndexes = matchedParts.reduce((indexes: PartIndexes, part, index) => {
    switch (part[0]) {
      case 'Y':
        indexes.yearPartIndex = index;
        break;
      case 'M':
        indexes.monthPartIndex = index;
        break;
      case 'D':
        indexes.dayPartIndex = index;
        break;
      case 'H':
        indexes.hoursPartIndex = index;
        break;
      case 'm':
        indexes.minutesPartIndex = index;
        break;
      case 's':
        indexes.secondsPartIndex = index;
    }
    return indexes;
  }, {});

  return {
    dateFormat,
    matcher: new RegExp(`^${dateFormat.replace(/[MDYHms]+/g, '?(\\d*)').replace(/^\?/, '')}$`),
    matchedParts,
    separators: [].slice.apply(dateFormat.match(/[^MDYHms]/g)),
    partsIndexes,
  };
};

export const getParseDate = (partsIndexes: PartIndexes, parts: Array<string>) => {
  const year = typeof partsIndexes.yearPartIndex === 'undefined' ? '1970' : parts[partsIndexes.yearPartIndex];
  const month = typeof partsIndexes.monthPartIndex === 'undefined' ? '01' : parts[partsIndexes.monthPartIndex];
  const day = typeof partsIndexes.dayPartIndex === 'undefined' ? '01' : parts[partsIndexes.dayPartIndex];
  const hour = typeof partsIndexes.hoursPartIndex === 'undefined' ? '00' : parts[partsIndexes.hoursPartIndex];
  const minutes = typeof partsIndexes.minutesPartIndex === 'undefined' ? '00' : parts[partsIndexes.minutesPartIndex];
  const seconds = typeof partsIndexes.secondsPartIndex === 'undefined' ? '00' : parts[partsIndexes.secondsPartIndex];

  const dateString = `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;

  return new Date(dateString);
};

export const weekdaysForTranslation = {
  Monday: new Date('1970-01-05'),
  Tuesday: new Date('1970-01-06'),
  Wednesday: new Date('1970-01-07'),
  Thursday: new Date('1970-01-01'),
  Friday: new Date('1970-01-02'),
  Saturday: new Date('1970-01-03'),
  Sunday: new Date('1970-01-04'),
};
