import { AirlineTheme, CssProperties, FontWeights } from 'types/themes';
import { css, CSSObject, FlattenSimpleInterpolation } from 'styled-components';
import { PageCustomThemes, PageElementCustomThemes } from 'src/themes/constants';
import { promisify } from 'utils/commonUtils';

export const createGradient = (angle: number, ...colours: Array<string | { color: string; position: number }>): string =>
  `linear-gradient(${angle}deg${colours.reduce((result: any, colour: string | { color: string; position: number }, i: number) => {
    const color: string = typeof colour === 'string' ? colour : colour.color;
    const position: number = typeof colour === 'string' ? (i === 0 ? 0 : Math.round((i / (colours.length - 1)) * 100)) : colour.position;
    return `${result}, ${color} ${position}%`;
  }, '')})`;

export const fontSizes: FontWeights = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
  extraBlack: '950',
};

export const getFont = (styles?: CssProperties, defaultStyles?: CssProperties): FlattenSimpleInterpolation => {
  const { color, family, lineHeight, size, style, weight, ...more }: CssProperties = {
    ...defaultStyles,
    ...styles,
  };
  return css`
    color: ${color};
    font: ${style} ${weight} ${size} / ${lineHeight} ${family};
    ${Object.keys(more).length
      ? Object.entries(more)
          .map(([key, value]) => `${key}: ${value};`)
          .join('\n')
      : ''}
  `;
};

export const createMediaBreakpoints = (breakpoint: number): ((args: CSSObject) => FlattenSimpleInterpolation) => {
  return (...args) => css`
    @media (max-width: ${breakpoint}px) {
      ${css(...args)};
    }
  `;
};

export const mergeCustomStyles = (
  theme: AirlineTheme,
  page: PageCustomThemes,
  element?: PageElementCustomThemes,
  customTheme?: string,
): string | undefined => {
  const addSemicolon = (style: string | undefined): string => {
    if (!style) return '';
    return /;$/.test(style) ? style : `${style};`;
  };
  if (!theme || !page) return customTheme;
  const customPageStyle: string | Object | undefined = theme[page].customStyles;
  const styles: Array<string> = [
    addSemicolon(customTheme),
    addSemicolon(element ? customPageStyle && customPageStyle[element] : customPageStyle),
  ].filter((style: string): boolean => !!style);
  if (!styles.length) return undefined;
  return styles.join(';');
};

export enum ImageSize {
  XL = '_xl',
  L = '_lg',
  M = '_md',
  S = '_sm',
  ALL = '',
}

const mapSize = (sizeName: string): string => {
  const [size, sizeFactor] = sizeName.split('_').map((part, index) => `${index ? '_' : ''}${part.toLowerCase()}`);
  switch (size) {
    case 'xl':
    case 'xlarge':
      return ImageSize.XL + (sizeFactor ?? '');
    case 'l':
    case 'lg':
    case 'large':
      return ImageSize.L + (sizeFactor ?? '');
    case 'm':
    case 'md':
    case 'medium':
      return ImageSize.M + (sizeFactor ?? '');
    case 's':
    case 'sm':
    case 'small':
      return ImageSize.S + (sizeFactor ?? '');
    case '':
      return ImageSize.ALL + (sizeFactor ?? '');
    default:
      return sizeName ? `_${sizeName}` : ImageSize.ALL;
  }
};

export const importImages = async (themeName: string, imageName: string = '', sizes: Array<string> = [], ext: string = 'jpg') => {
  const promises = sizes.map((size: string) => {
    const file = `${imageName}${size ? '_' : ''}${size}.${ext}`;
    const propertyName = `${imageName.substring(0, 1).toLowerCase()}${imageName.substring(1)}${mapSize(size)}`;
    return import(`src/assets/images/${themeName}/${imageName}/${file}`).then(image => ({ [propertyName]: image.default }));
  });

  return promisify(promises);
};
