import {
  ChangeEvent,
  CheckBoxField,
  CheckboxProps,
  FileField,
  FileProps,
  InputField,
  InputProps,
  RadioField,
  RadioProps,
  SelectField,
  SelectProps,
  SwitchField,
  SwitchProps,
  TriplakeDatePickerField,
  TriplakeDatePickerProps,
} from 'types/Form';
import { Checkbox, DatePicker, FileInput, Input, Radio, Select, Switch } from 'components/Form';
import { CommonFieldErrorsMessages, DataValues, FieldOptions, Validator, Value } from 'types/FormHooks';
import { FieldTypes } from 'src/hooks/useFormData';
import { AnyEnum } from 'types/types';

interface FormFieldProps<T extends Record<string, string>> {
  fieldName: string;
  options: FieldOptions<T>;
  error?: string | boolean;
  set: (event: ChangeEvent<TriplakeDatePickerField | InputField | SelectField | CheckBoxField | RadioField | SwitchField | FileField>) => void;
  onBlur?: (e?: any) => void;
  value?: Value;
  values: DataValues<T>;
  allValues?: Array<DataValues<T>>;
}

interface OnChangeInterface {
  (event: ChangeEvent<SelectField>, value: string | number, path?: Array<string | AnyEnum>): boolean | void;
  (event: ChangeEvent<InputField>, value: string): boolean | string | void;
  (event: ChangeEvent<TriplakeDatePickerField>, value: Date | null): boolean | string | Date | void;
  (event: ChangeEvent<CheckBoxField>, value: boolean): void;
  (event: ChangeEvent<RadioField>, value: string | number): void;
  (event: ChangeEvent<SwitchField>, isOn: boolean): void;
  (event: ChangeEvent<FileField>, file: File | FileList): boolean | void;
  (event: ChangeEvent<TriplakeDatePickerField | InputField | SelectField | CheckBoxField | RadioField | SwitchField | FileField>): void;
}

export const getFromField = <T extends Record<string, string>>({
  fieldName,
  options,
  error,
  set,
  onBlur,
  value,
  values,
  allValues,
}: FormFieldProps<T>) => {
  const fieldProps =
    typeof options.modifyPropsBeforeRender === 'function'
      ? options.modifyPropsBeforeRender({
          props: options.fieldProps || {},
          values,
          allRowsValues: allValues,
          setValue: (value: Value) => set({ value, targetName: fieldName, targetElement: null }),
        })
      : options.fieldProps;

  // @ts-ignore
  const onChange: OnChangeInterface =
    typeof fieldProps?.onChange === 'function'
      ? (event, value, path) => {
          // @ts-ignore
          const result = fieldProps?.onChange?.(event as any, value, path);
          if (typeof result === 'boolean' && !result) return false;
          if (typeof result === 'undefined') {
            set(event);
            return;
          }
          set({ ...event, value: result });
          return result;
        }
      : set;

  const props = {
    key: fieldName,
    onChange,
    onBlur,
    ...((options.width && { width: options.width }) || {}),
    ...((options.placeholder && { placeholder: options.placeholder }) || {}),
    name: fieldName,
    error,
  };

  switch (options.type) {
    case FieldTypes.SELECT:
      return (<Select {...(fieldProps as SelectProps)} {...props} value={value as string | number} />) as unknown as SelectField;
    case FieldTypes.CHECKBOX:
      return (<Checkbox checked={value as boolean} {...(fieldProps as CheckboxProps)} {...props} />) as unknown as CheckBoxField;
    case FieldTypes.RADIO:
      return (<Radio {...(fieldProps as RadioProps)} {...props} value={value as string | number} />) as unknown as RadioField;
    case FieldTypes.SWITCH:
      return (<Switch isOn={value as boolean} {...(fieldProps as SwitchProps)} {...props} />) as unknown as SwitchField;
    case FieldTypes.DATE:
      return (
        <DatePicker {...(fieldProps as TriplakeDatePickerProps)} {...props} value={value as string | Date | null} />
      ) as unknown as TriplakeDatePickerField;
    case FieldTypes.FILE:
      return (<FileInput {...(fieldProps as FileProps)} {...props} />) as unknown as FileField;
    case FieldTypes.INPUT:
    default:
      return (<Input {...(fieldProps as InputProps)} {...props} value={value as string} />) as unknown as InputField;
  }
};

export const getDefaultValueForField = <T extends Record<string, string>>(options?: FieldOptions<T>): Value => {
  switch (options?.type) {
    case FieldTypes.CHECKBOX:
      return (options?.fieldProps as CheckboxProps)?.checked === true;
    case FieldTypes.SWITCH:
      return (options?.fieldProps as SwitchProps)?.isOn === true;
    default:
      return options?.value || '';
  }
};

export const getNewValueForField = <T extends Record<string, string>>(options?: FieldOptions<T>): Value | undefined => {
  switch (options?.type) {
    case FieldTypes.CHECKBOX:
      return (options?.fieldProps as CheckboxProps)?.checked;
    case FieldTypes.SWITCH:
      return (options?.fieldProps as SwitchProps)?.isOn;
    default:
      return options?.value;
  }
};

export const isOptional = <T extends Record<string, string>>(options?: FieldOptions<T>): boolean =>
  options?.isRequired === false ||
  (options?.fieldProps && (options?.fieldProps as any).optional) ||
  ([FieldTypes.CHECKBOX, FieldTypes.SWITCH].includes(options?.type ?? FieldTypes.INPUT) && options?.isRequired !== true);

const isValidator = <T extends Record<string, string>>(validator: any): validator is Validator<T> =>
  validator instanceof RegExp || typeof validator === 'function';

export const validateField = <T extends Record<string, string>>(
  fieldOptions: FieldOptions<T> = {},
  value: Value | undefined,
  otherValues: DataValues<T>,
  errorMessages: CommonFieldErrorsMessages,
): boolean | string => {
  const errors: Array<string | boolean> = [];
  const optional = isOptional(fieldOptions);

  if (!optional && !value) errors.push(errorMessages?.emptyField ?? true);
  if (fieldOptions.type === FieldTypes.INPUT && typeof value === 'string') {
    const minLength: number | undefined = ((fieldOptions.fieldProps ?? {}) as InputProps).minLength;
    const maxLength: number | undefined = ((fieldOptions.fieldProps ?? {}) as InputProps).maxLength;
    if (minLength && value.length < minLength) errors.push(errorMessages?.tooShortEntry ?? true);
    if (maxLength && value.length > maxLength) errors.push(errorMessages?.tooLongEntry ?? true);
  }

  if (fieldOptions.validators) {
    fieldOptions.validators.forEach(validatorObj => {
      const validate = (validator: Validator<T>): boolean => {
        if (validator instanceof RegExp) return validator.test((value ?? '').toString());
        if (typeof validator === 'function') return validator(value, otherValues);
        return true;
      };

      const valid: boolean = isValidator(validatorObj) ? validate(validatorObj) : validate(validatorObj.validator);
      if (!valid) errors.push(isValidator(validatorObj) ? true : validatorObj.errorMessage);
    });
  }

  const filteredErrors = errors.filter(e => typeof e === 'string');
  return !!errors.length && (!filteredErrors.length || filteredErrors.join(',\n'));
};
