import PropTypes from 'prop-types';

export const locationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  state: PropTypes.shape()
});

export const historyShape = PropTypes.shape({
  action: PropTypes.string.isRequired,
  block: PropTypes.func.isRequired,
  createHref: PropTypes.func.isRequired,
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  listen: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  location: locationShape.isRequired
});

export const matchShape = PropTypes.shape({
  isExact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  params: PropTypes.shape()
});
