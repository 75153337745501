import { AnyAction } from 'redux';
import { createReducer } from '../utils/createReducer';
import {
  CLEAR_SECURED_API_ACCESS_TOKEN,
  SESSION_ABOUT_TO_EXPIRE,
  SESSION_CREATE,
  SESSION_EXPIRED,
  SESSION_EXTENDED,
  SESSION_TOKEN_KEY,
  SET_SECURED_API_ACCESS_TOKEN,
} from './sessionConstants';

export interface TSessionState {
  isSessionExpired: boolean;
  aboutToExpire?: boolean;
  extendTimestamp?: string;
  sessionTokenKey?: string;
  securedApiAccessToken?: string;
}

const initialState: TSessionState = {
  isSessionExpired: false,
  sessionTokenKey: '',
};

const sessionCreateSuccess = (state: TSessionState) => ({
  ...state,
  isSessionExpired: false,
  sessionTokenKey: sessionStorage.getItem(SESSION_TOKEN_KEY),
});

const sessionExpired = (state: TSessionState) => ({
  ...state,
  isSessionExpired: true,
  sessionTokenKey: '',
});

const sessionExtended = (state: TSessionState, action: AnyAction) => ({
  ...state,
  aboutToExpire: false,
  extendTimestamp: action.timestamp,
  sessionTokenKey: sessionStorage.getItem(SESSION_TOKEN_KEY),
});

const sessionAboutToExpire = (state: TSessionState) => ({
  ...state,
  aboutToExpire: true,
});

const setSecuredApiAccessToken = (state: TSessionState, action: AnyAction) => ({
  ...state,
  securedApiAccessToken: action.params.accessToken,
});

const clearSessionAfterLogout = (state: TSessionState) => ({
  ...state,
  securedApiAccessToken: undefined,
});

const sessionReducer = {
  [SESSION_CREATE.SUCCESS]: sessionCreateSuccess,
  [SESSION_EXPIRED]: sessionExpired,
  [SESSION_EXTENDED]: sessionExtended,
  [SESSION_ABOUT_TO_EXPIRE]: sessionAboutToExpire,
  [SET_SECURED_API_ACCESS_TOKEN]: setSecuredApiAccessToken,
  [CLEAR_SECURED_API_ACCESS_TOKEN]: clearSessionAfterLogout,
};

export default createReducer(sessionReducer, initialState);
