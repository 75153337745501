import { useRef, useState, useCallback } from 'react';
import * as ui from './Checkbox.ui';
import { CheckboxProps, CheckBoxField, ChangeEvent } from 'types/Form';
import { actOnEnterAndSpace } from 'utils/commonUtils';

export const Checkbox: CheckBoxField = ({
  checked = false,
  value,
  name,
  id,
  onChange,
  label,
  tabindex,
  disabled = false,
  error = false,
  alignCheckboxToTop = true,
}: CheckboxProps) => {
  const [checkedState, setCheckedState] = useState<boolean>(checked);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleChecked = useCallback(
    (e): void => {
      if (e.target?.nodeName === 'A') return;
      if (disabled) return;
      setCheckedState(!checkedState);
      if (typeof onChange === 'function')
        onChange(
          {
            value: !checkedState,
            prevValue: checkedState,
            targetName: name,
            targetElement: wrapperRef.current,
          } as ChangeEvent<CheckBoxField>,
          !checkedState,
        );
    },
    [disabled, name, onChange, checkedState],
  );

  const onKeyUpCallback = useCallback(actOnEnterAndSpace(toggleChecked, [{}]), [toggleChecked]);

  return (
    <ui.CheckBoxContainer>
      <ui.CheckBoxWrapper onClick={toggleChecked} disabled={disabled} ref={wrapperRef} $error={!!error}>
        <ui.CheckboxInput
          defaultValue={value}
          name={name}
          id={id}
          onChange={toggleChecked}
          checked={checkedState}
          disabled={disabled}
          tabIndex={tabindex}
          $alignToTop={alignCheckboxToTop}
          onKeyUp={onKeyUpCallback}
        />
        <ui.CheckboxLabel htmlFor={name}>{label}</ui.CheckboxLabel>
      </ui.CheckBoxWrapper>
      {typeof error === 'string' ? <ui.CheckboxErrorMessage>{error}</ui.CheckboxErrorMessage> : null}
    </ui.CheckBoxContainer>
  );
};
