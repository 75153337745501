import { createRequestTypes, TRequestTypes } from '../utils/actionHelpers';

export const SESSION_TOKEN_KEY: string = 'triplake-member-portal';
export const SESSION_EXPIRED: string = 'SESSION_EXPIRED';
export const CLEAR_SESSION: string = 'CLEAR_SESSION';
export const SESSION_EXTENDED: string = 'SESSION_EXTENDED';
export const SESSION_ABOUT_TO_EXPIRE: string = 'SESSION_ABOUT_TO_EXPIRE';
export const SET_SECURED_API_ACCESS_TOKEN: string = 'SET_SECURED_API_ACCESS_TOKEN';
export const CLEAR_SECURED_API_ACCESS_TOKEN: string = 'CLEAR_SECURED_API_ACCESS_TOKEN';

export const EXTEND_SESSION: TRequestTypes = createRequestTypes('EXTEND_SESSION');
export const SESSION_CREATE: TRequestTypes = createRequestTypes('SESSION_CREATE');
