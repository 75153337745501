import { useMemo } from 'react';
import * as ui from './UserMenu.ui';
import { messages } from './UserMenu.translations';
import { useUser } from 'src/hooks/useUser';
import { FormattedMessage } from '@triplake/lib-intl';
import { NavigationButton, Button } from 'components/Button';
import Icon from 'components/Icon';
import ExpandableMenu from 'components/ExpandableMenu';
import { UserMenuStructure } from './UserMenuStructure';
import { useAuthWrapper } from 'src/hooks/useAuthWrapper';

const UserMenu = ({ onNavigationCallback }) => {
  const { profile } = useUser();
  const { logout, isAuthenticated, login } = useAuthWrapper();

  const sections = useMemo(() => {
    return UserMenuStructure.map((section, sectionKey) => (
      <ui.MenuSection key={sectionKey}>
        {section.map((link, linkKey) => {
          if (typeof link.items !== 'undefined')
            return (
              <ExpandableMenu label={<FormattedMessage {...link.label} />} key={linkKey}>
                <ui.MenuSection>
                  {link.items.map((button, buttonKey) => (
                    <NavigationButton type="link-2-colors" key={buttonKey} path={button.path} onClick={onNavigationCallback}>
                      <FormattedMessage {...button.label} />
                    </NavigationButton>
                  ))}
                </ui.MenuSection>
              </ExpandableMenu>
            );
          return (
            <NavigationButton type="link-2-colors" path={link.path} onClick={onNavigationCallback} key={linkKey}>
              <FormattedMessage {...link.label} />
            </NavigationButton>
          );
        })}
      </ui.MenuSection>
    ));
  }, [onNavigationCallback]);

  return (
    <div>
      <ui.MenuSection>
        {isAuthenticated ? (
          <ui.NameHolder>
            <FormattedMessage
              {...messages.welcome}
              values={{ userName: `${profile?.personalDetails?.firstName ?? ''} ${profile?.personalDetails?.lastName ?? ''}` }}
            />
          </ui.NameHolder>
        ) : (
          <ui.MenuSection>
            <Button
              type="link-2-colors"
              onClick={() => {
                login();
              }}>
              <FormattedMessage {...messages.login} />
              <Icon name="login" />
            </Button>
          </ui.MenuSection>
        )}
      </ui.MenuSection>
      {sections}
      <ui.MenuSection>
        <Button type="link-2-colors" onClick={logout}>
          <FormattedMessage {...messages.logout} />
          <Icon name="logout" />
        </Button>
      </ui.MenuSection>
    </div>
  );
};

export default UserMenu;
