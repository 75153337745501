import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { STATE_KEYS } from './constants';
import selfServiceReducer from 'src/store/selfService/selfServiceReducer';
import configReducer from './config/configReducer';
import requestReducer from './request/requestReducer';
import userReducer from './user/userReducer';
import sessionReducer from './session/sessionReducer';
import intlReducer from './intl/intlReducer';

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    [STATE_KEYS.SelfService]: selfServiceReducer,
    [STATE_KEYS.Config]: configReducer,
    [STATE_KEYS.Request]: requestReducer,
    [STATE_KEYS.Session]: sessionReducer,
    [STATE_KEYS.User]: userReducer,
    [STATE_KEYS.Intl]: intlReducer,
  });
