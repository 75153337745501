import { colors, gradients } from './colors';
import { fontSizes } from 'src/themes/utils';
import { LevelTheme } from 'types/themes';

const cardLevels: Array<LevelTheme> = [
  {
    tierKey: 'tier-type.red',
    entryPoint: 0,
    color: colors.level1,
    heroGradient: colors.level1,
    dashboardGradient: colors.dodgerBlue,
  },
  {
    tierKey: 'tier-type.silver',
    entryPoint: 24000,
    color: colors.level2,
    heroGradient: colors.level2,
    dashboardGradient: colors.cerulean,
  },
  {
    tierKey: 'tier-type.gold',
    entryPoint: 100000,
    color: colors.level3,
    heroGradient: colors.level3,
    dashboardGradient: colors.malibu,
  },
];

const theme = {
  colorTextSecondary: colors.greySecondary,
  colorDateItem: colors.greySecondary,
  fontFamilyPrimary: `Poppins, sans-serif`,
  fontFamilySecondary: `Poppins, sans-serif`,
  fontSizeBody: '1.6rem',
  fonts: {
    primary: 'Poppins:300,400,500,600',
    secondary: 'Open Sans',
  },
  colorAccent: colors.green,
  colorLinkHover: colors.redDark,
  borderRadius: '1rem',
  boxShadow: '-16px -16px 30px 0 rgba(255,255,255,0.3), 16px 16px 30px 0 rgba(209,217,230,0.3)',
  accordionBarPadding: '3.3rem 4rem',
  fontSizeInput: '1.6rem',
  displayCreateAccount: 'none',
  colorLineSeparator: colors.alto,
  gradients,

  invertedTextColor: colors.whiteSand,
  defaultPadding: '4rem',
  defaultMobilePadding: '2.4rem',
  dashboardTheme: {
    order: {
      mainContainer: 2,
      actionButtons: 1,
    },
    dashboardButtonRows: 5,
    actionButtons: {
      milesCalculator: { hide: true },
    },
  },
  textColor: colors.darkGray,
  primaryColor: colors.red,
  primaryTextColor: colors.black,
  layoutTheme: {
    order: {
      extras: 1,
      breadCrumbs: 2,
      apiErrors: 3,
      contentWrapper: 4,
    },
  },
  secondaryTextColor: colors.white,
  borderColor: colors.alto,
  disabledColor: colors.blueMist,
  backgroundColor: colors.dustyGray,
  buttonColorPrimary: colors.primaryColor,
  colors,
  tableHeaderTextColor: colors.whiteSand,
  tableHeaderBackgroundColor: colors.mineShaft,
  tableRowBackgroundColor: colors.zanah,
  tableAltRowBackgroundColor: colors.rockBlue,
  shadow: 'none',
  inputFontSize: '1.2rem',
  fontWeights: fontSizes,
  cardLevels,
};

export default theme;
