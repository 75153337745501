import { createRequestTypes, TRequestTypes } from '../utils/actionHelpers';

export const GET_LANGUAGES: TRequestTypes = createRequestTypes('GET_LANGUAGES');
export const GET_AIRLINES: TRequestTypes = createRequestTypes('GET_AIRLINES');
export const GET_ROUTES: TRequestTypes = createRequestTypes('GET_ROUTES');
export const GET_DICTIONARIES: TRequestTypes = createRequestTypes('GET_DICTIONARIES');
export const GET_HEADER: TRequestTypes = createRequestTypes('GET_HEADER');
export const GET_FOOTER: TRequestTypes = createRequestTypes('GET_FOOTER');
export const SUBSCRIBE: TRequestTypes = createRequestTypes('SUBSCRIBE');

export enum Endpoints {
  HEADER = '/mobile/megamenu.json',
  FOOTER = '/mobile/footer.json',
}
