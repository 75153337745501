import styled from 'styled-components';
import { InputField, InputPlaceholder } from 'components/Form/Input/Input.ui';
import { Button } from 'components/Button/Button.ui';
import { themes } from '@triplake/ui-components/utils';

export const FooterWrapper = styled.div`
  font-size: 1.6rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  background: ${props => props.theme.gradients.footer || 'gray'};
`;

export const FooterGraphics = styled.div`
  background-image: url(${({ theme }) => theme.images.footer_bg});
  background-position: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100vw;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 5rem;
  max-width: 128rem;
  margin: 0 auto;
  &,
  a {
    color: ${({ theme }) => theme.invertedTextColor};
  }
  ${({ theme }) => theme.mediaQueries.MD`
    width: 100vw;
    padding: 3.2rem 1.6rem;
  `};
  ${({ theme }) => theme.mediaQueries.LG`
    padding: 3.2rem;
  `};
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.4rem;
  ${({ theme }) => theme.mediaQueries.MD`
    flex-direction: column;
    gap: 3.2rem;
  `};
`;

export const Newsletter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.6rem;
  gap: 2.4rem;
  ${InputPlaceholder} {
    color: ${({ theme }) => theme.invertedTextColor};
    font-weight: ${({ theme }) => theme.fontWeights.light};
  }
  ${InputField} {
    background: ${({ theme }) => theme.colors.sand20};
    color: ${({ theme }) => theme.invertedTextColor};
    border: none;
    padding: 2rem 1.6rem 0.4rem;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    & + ${InputPlaceholder}.small, &:focus + ${InputPlaceholder} {
      top: 0.4rem;
    }
  }
  ${InputField}:focus {
    background: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    & + ${InputPlaceholder} {
      color: ${({ theme }) => theme.textColor};
    }
  }
  &&& ${Button} {
    color: ${({ theme }) => theme.invertedTextColor};
    border-color: ${({ theme }) => theme.invertedTextColor};
    justify-content: center;
  }
  ${({ theme }) => theme.mediaQueries.MD`
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
    width: 100%;
    ${InputField}, ${Button} { width: 100%; }
  `};
`;

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.subtitle.s2SemiBold};
  color: ${({ theme }) => theme.invertedTextColor};
  margin-right: 2.4rem;
  ${({ theme }) => theme.mediaQueries.MD`
    ${theme.textStyles.subtitle.s3.join(' ')}
    color: ${theme.invertedTextColor};
  `}
`;

export const SocialMediaLinks = styled.div<{ $visible: 'desktop' | 'mobile' }>`
  margin: 0;
  display: flex;
  gap: 1.6rem;
  justify-content: flex-end;
  ${({ theme, $visible }) =>
    $visible === 'desktop' ? theme.mediaQueries.MD`display: none;` : `@media (min-width: ${themes.breakPoints.MD}px) { display: none; }`}
  ${({ $visible }) =>
    $visible === 'desktop'
      ? `
    align-self: center;
    margin-bottom: 1.6rem;
  `
      : ''}
  ${({ theme }) => theme.mediaQueries.MD`
    gap: 2.4rem;
    justify-content: flex-start;
  `}
`;

export const Regulations = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4rem;
  flex-wrap: wrap;
`;
export const CopyrightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 0.1rem solid hsla(0, 0%, 100%, 0.2);
  ${({ theme }) => theme.textStyles.caption.large};
  &,
  div {
    color: ${({ theme }) => theme.invertedTextColor};
  }
  ${({ theme }) => theme.mediaQueries.MD`
    ${theme.textStyles.caption.regular.join(' ')};
    &, div { color: ${({ theme }) => theme.invertedTextColor}; }
    flex-direction: column;
    justify-content: center;
    padding-top: 3.2rem;
    margin-top: 3.2rem;
    ${Regulations} {
      gap: 2.4rem;
      margin-bottom: 4rem;
      justify-content: center;
      padding: 0 3.2rem;
    }
  `};
`;

export const FooterDownloads = styled.div`
  padding-top: 4rem;
  gap: 1.6rem;
  &,
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div {
    gap: 3.2rem;
    div.row {
      display: flex;
      gap: 2.4rem;
      img {
        display: block;
      }
    }
  }
  header {
    ${({ theme }) => theme.textStyles.subtitle.s3};
    color: ${({ theme }) => theme.invertedTextColor};
  }
  ${({ theme }) => theme.mediaQueries.MD`
    padding-top: 0;
  `};
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 4.8rem;
  padding-top: 4rem;
  flex-grow: 1;
  ${({ theme }) => theme.mediaQueries.MD`
    padding-top: 0;
    row-gap: 3.2rem;
    column-gap: 1.6rem;
    flex-wrap: wrap;
    width: 100%;
  `};
`;

export const FooterLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex-grow: 1;
  header {
    ${({ theme }) => theme.textStyles.subtitle.s3};
  }
  a {
    ${({ theme }) => theme.textStyles.body.regular};
  }
  header,
  a {
    color: ${({ theme }) => theme.invertedTextColor};
  }
  ${({ theme }) => theme.mediaQueries.MD`
    a { ${theme.textStyles.caption.large.join(' ')}; }
    header, a { color: ${({ theme }) => theme.invertedTextColor}; }
  `}
`;

export const SubscribeModalForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  max-width: 72.4rem;
  margin-top: 4rem;
`;

export const SubscribeCheckboxLabel = styled.div`
  ${({ theme }) => theme.textStyles.body.small}
`;
