import { useTheme } from 'styled-components';
import * as ui from './Footer.ui';
import { FormattedMessage } from '@triplake/lib-intl';
import { messages } from './Footer.translations';
import Icon from 'components/Icon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFooterRequest } from 'src/store/config/configActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'src/hooks/useLocale';
import { FooterDefinition } from 'types/Layout';
import { getFooter } from 'src/store/config/configSelectors';
import { Input } from 'components/Form';
import Button from 'components/Button';
import Image from 'components/Image';
import { AirlineTheme } from 'types/themes';
import SubscribeModal from './SubscribeModal';
import { useBaseUrl } from 'src/hooks/useBaseUrl';

export const PageFooter = () => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const footer: FooterDefinition = useSelector(getFooter);
  const theme: AirlineTheme = useTheme() as AirlineTheme;
  const getUrl = useBaseUrl();
  const [showSubscription, setShowSubscription] = useState(false);
  const [email, setEmail] = useState('');

  // Callbscks
  const onSubscribeClose = useCallback(() => {
    setShowSubscription(false);
    setEmail('');
  }, []);

  // Memos
  const links = useMemo(
    () =>
      footer.links.map((column, columnKey) => (
        <ui.FooterLinksColumn key={columnKey}>
          {column.title && <header>{column.title}</header>}
          {column.items.map((link, linkKey) => (
            <a href={getUrl(link.path)} target={link.target} key={linkKey}>
              {link.text}
            </a>
          ))}
        </ui.FooterLinksColumn>
      )),
    [footer, getUrl],
  );

  const regulations = useMemo(
    () =>
      footer.regulations.map((link, key) => (
        <a href={getUrl(link.path)} target={link.target} key={key}>
          {link.text}
        </a>
      )),
    [footer, getUrl],
  );

  const socialMediaLinks = useMemo(
    () => (
      <>
        {theme.footerLinks?.instagramLink && (
          <a href={theme.footerLinks.instagramLink} rel="noreferrer" target="_blank">
            <Icon name="instagram" />
          </a>
        )}
        {theme.footerLinks?.facebookLink && (
          <a href={theme.footerLinks.facebookLink} rel="noreferrer" target="_blank">
            <Icon name="facebook" />
          </a>
        )}
        {theme.footerLinks?.tweeterLink && (
          <a href={theme.footerLinks.tweeterLink} rel="noreferrer" target="_blank">
            <Icon name="tweeter" />
          </a>
        )}
        {theme.footerLinks?.youtubeLink && (
          <a href={theme.footerLinks.youtubeLink} rel="noreferrer" target="_blank">
            <Icon name="youtube" />
          </a>
        )}
      </>
    ),
    [],
  );

  const [awards1Image, awards2Image] = useMemo(
    () => [
      <Image src={theme.images?.awards1 ?? ''} alt="Awards badge" imgProps={{ height: '64' }} generateDynamicImages="awards1" />,
      <Image src={theme.images?.awards2 ?? ''} alt="Awards badge" imgProps={{ height: '64' }} generateDynamicImages="awards2" />,
    ],
    [theme.images],
  );

  // Effects
  useEffect(() => {
    if (locale.preferredLocale) dispatch(getFooterRequest(locale.preferredLocale));
    else if (locale.defaultLocale) dispatch(getFooterRequest(locale.defaultLocale));
  }, [dispatch, locale.preferredLocale, locale.defaultLocale]);

  return (
    <ui.FooterWrapper>
      <ui.FooterGraphics>
        <ui.Footer>
          <ui.FooterRow>
            <ui.Newsletter>
              <ui.Title>
                <FormattedMessage {...messages.newsletterTitle} />
              </ui.Title>
              <Input
                name="subscribe"
                placeholder={<FormattedMessage {...messages.subscribePlaceholder} />}
                onChange={e => setEmail(e.value.toString())}
                value={email}
              />
              <Button type="secondary" className="narrow" onClick={() => setShowSubscription(true)}>
                Subscribe
              </Button>
            </ui.Newsletter>
            <ui.SocialMediaLinks $visible="desktop">{socialMediaLinks}</ui.SocialMediaLinks>
          </ui.FooterRow>
          <ui.FooterRow>
            <ui.FooterLinks>{links}</ui.FooterLinks>
            <ui.FooterDownloads>
              <header>
                <FormattedMessage {...messages.downloads} />
              </header>
              <div>
                {theme.footerLinks?.googlePlayStoreLink || theme.footerLinks?.appleStoreLink ? (
                  <div className="row">
                    {theme.footerLinks?.googlePlayStoreLink && (
                      <a href={theme.footerLinks.googlePlayStoreLink} rel="noreferrer" target="_blank">
                        <Image
                          src={theme.images?.googlePlayBadge ?? ''}
                          alt="Google Play Store"
                          imgProps={{ height: '40' }}
                          generateDynamicImages="googlePlayBadge"
                        />
                      </a>
                    )}
                    {theme.footerLinks?.appleStoreLink && (
                      <a href={theme.footerLinks.appleStoreLink} rel="noreferrer" target="_blank">
                        <Image src={theme.images?.downloadOnAppStore ?? ''} alt="Apple store" imgProps={{ height: '40' }} />
                      </a>
                    )}
                  </div>
                ) : null}
                <div className="row">
                  {theme.footerLinks?.award1Link ? (
                    <a href={theme.footerLinks.award1Link} rel="noreferrer" target="_blank">
                      {awards1Image}
                    </a>
                  ) : (
                    awards1Image
                  )}
                  {theme.footerLinks?.award2Link ? (
                    <a href={theme.footerLinks.award2Link} rel="noreferrer" target="_blank">
                      {awards2Image}
                    </a>
                  ) : (
                    awards2Image
                  )}
                </div>
              </div>
            </ui.FooterDownloads>
            <ui.SocialMediaLinks $visible="mobile">{socialMediaLinks}</ui.SocialMediaLinks>
          </ui.FooterRow>
          <ui.CopyrightSection>
            <ui.Regulations>{regulations}</ui.Regulations>
            <div>{footer.copyright}</div>
          </ui.CopyrightSection>
        </ui.Footer>
      </ui.FooterGraphics>
      <SubscribeModal isOpen={showSubscription} emailValue={email} onClose={onSubscribeClose} />
    </ui.FooterWrapper>
  );
};
