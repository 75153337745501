import { defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  viewCard: {
    id: 'Dashboard.viewCard',
    defaultMessage: 'View card',
  },
  myProfile: {
    id: 'MenuButton.myProfileLink',
    defaultMessage: 'My profile',
  },
  accountNo: {
    id: 'Dashboard.accountNumber',
    defaultMessage: 'Kestrelflyer Number',
  },
  memberStartDate: {
    id: 'Dashboard.memberStartDate',
    defaultMessage: 'Member since',
  },
  miles: {
    id: 'Dashboard.miles',
    defaultMessage: 'Available Miles',
  },
  cardName: {
    id: 'Dashboard.cardName',
    defaultMessage: 'Kestrelflyer',
  },
  level1: {
    id: 'Dashboard.level1Name',
    defaultMessage: 'Red',
  },
  level2: {
    id: 'Dashboard.level2Name',
    defaultMessage: 'Silver',
  },
  level3: {
    id: 'Dashboard.level3Name',
    defaultMessage: 'Gold',
  },
  expiringMiles: {
    id: 'Dashboard.expiringMiles',
    defaultMessage: 'Expiring Miles',
  },
  qualifyingMiles: {
    id: 'Dashboard.qualifyingMiles',
    defaultMessage: 'Qualifying Miles',
  },
  onDate: {
    id: 'Dashboard.onDate',
    defaultMessage: 'on',
  },
  useYourMilesTitle: {
    id: 'Dashboard.useYourMilesTitle',
    defaultMessage: 'Use your miles',
  },
  useYourMilesSubtitle: {
    id: 'Dashboard.useYourMilesSubtitle',
    defaultMessage: 'Choose how you prefer to use your award miles',
  },
  bookAwardTicket: {
    id: 'MenuButton.bookAwardTicket',
    defaultMessage: 'Book Award Ticket',
  },
  useMilesToUpgrade: {
    id: 'MenuButton.useMilesToUpgrade',
    defaultMessage: 'Use Miles for Upgrading',
  },
  useMilesForExtraBaggage: {
    id: 'MenuButton.useMilesForExtraBaggage',
    defaultMessage: 'Use Miles for Extra Baggage',
  },
  useYourMilesForLounge: {
    id: 'MenuButton.useYourMilesForLounge',
    defaultMessage: 'Use Miles for Premium Lounge',
  },
  myTransactions: {
    id: 'MenuButton.myTransactions',
    defaultMessage: 'My Transactions',
  },
  milesCalculator: {
    id: 'MenuButton.mulesCalculator',
    defaultMessage: 'Miles Calculator',
  },
  claimMiles: {
    id: 'MenuButton.claimMiles',
    defaultMessage: 'Claim Miles',
  },
  myBeneficiaries: {
    id: 'MenuButton.myBeneficiaries',
    defaultMessage: 'My Beneficiaries',
  },
  myNominees: {
    id: 'MenuButton.myNominees',
    defaultMessage: 'My Nominees',
  },
  yourCard: {
    id: 'Dashboard.yourCard',
    defaultMessage: 'Your card',
  },
  sendCardViaEmail: {
    id: 'Dashboard.sendCardViaEmail',
    defaultMessage: 'Save the Digital card',
  },
  expiryDateLabel: {
    id: 'Dashboard.expiryDateLabel',
    defaultMessage: 'Expiry',
  },
});
