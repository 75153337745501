import React from 'react';
import { render } from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import '@triplake/ui-tokens';

const root = document.getElementById('root');

root &&
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root,
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
