import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getRedirectUrls } from 'src/store/config/configSelectors';
import { useLocale } from './useLocale';
import { TRedirectUrl } from 'types/store/Config';

interface TRedirectUrls {
  createAccount: string;
  generateMmbDeeplink: (params: { chosenLanguage: string; pnr: string; paxLastName: string }) => string;
  generateCheckinDeeplink: (params: { chosenLanguage: string; pnr: string; paxLastName: string }) => string;
  forgotPassword: string;
  bookFlight: string;
  homePage: string;
}

export const useRedirectUrls = (): TRedirectUrls => {
  const redirectUrlsAsArray = useSelector(getRedirectUrls);
  const { defaultLocale, preferredLocale } = useLocale();

  const redirectUrlsAsKeyValuePairsWithPredicate = (redirectUrlsAsArray: TRedirectUrl[], predicate: (redirectUrl: TRedirectUrl) => boolean) => {
    return redirectUrlsAsArray.filter(predicate).reduce(
      (accumulated, current) => ({
        ...accumulated,
        [current.name]: current.url,
      }),
      {},
    );
  };

  const redirectUrlsAsKeyValuePairs: any = useMemo(() => {
    const redirectUrlsInDefaultLanguage = redirectUrlsAsKeyValuePairsWithPredicate(redirectUrlsAsArray, redirectUrl => redirectUrl.language === 'en');
    const redirectUrlsOverwritingDefaultLanguage = redirectUrlsAsKeyValuePairsWithPredicate(
      redirectUrlsAsArray,
      redirectUrl => redirectUrl.language === (defaultLocale || preferredLocale),
    );
    return {
      ...redirectUrlsInDefaultLanguage,
      ...redirectUrlsOverwritingDefaultLanguage,
    };
  }, [redirectUrlsAsArray, defaultLocale, preferredLocale]);

  const generateMmbDeeplink = (params: { chosenLanguage: string; pnr: string; paxLastName: string }) => {
    const mmbDeeplink = redirectUrlsAsKeyValuePairs.mmbDeeplink || '';
    return mmbDeeplink.replace('{chosenLanguage}', params.chosenLanguage).replace('{pnr}', params.pnr).replace('{paxLastName}', params.paxLastName);
  };

  const generateCheckinDeeplink = (params: { chosenLanguage: string; pnr: string; paxLastName: string }) => {
    const checkinDeeplink = redirectUrlsAsKeyValuePairs.checkinDeeplink || '';
    return checkinDeeplink
      .replace('{chosenLanguage}', params.chosenLanguage)
      .replace('{pnr}', params.pnr)
      .replace('{paxLastName}', params.paxLastName);
  };

  return {
    createAccount: redirectUrlsAsKeyValuePairs.createAccount,
    generateMmbDeeplink,
    generateCheckinDeeplink,
    forgotPassword: redirectUrlsAsKeyValuePairs.forgotPassword,
    bookFlight: redirectUrlsAsKeyValuePairs.bookFlight,
    homePage: redirectUrlsAsKeyValuePairs.homePage,
  };
};
