import { AnyAction } from 'redux';
import { all, call, CallEffect, delay, fork, put, take } from 'redux-saga/effects';
import { FAILURE } from '../utils/actionHelpers';
import { sessionCreate, sessionExpired } from './sessionActions';
import { requestCreateSession } from './sessionApi';
import { SESSION_CREATE } from './sessionConstants';

export const RETRY_TIMEOUT: number = 2000;

export const failurePattern = (action: AnyAction) => action.type.endsWith(FAILURE);

export const createSession = function* (tenant: string) {
  const { response, error } = yield call(requestCreateSession, tenant);
  if (response) {
    yield put(sessionCreate.success());

    return true;
  }
  yield put(sessionCreate.failure(error));
  return false;
};

export const watchRequestCreateSession = function* () {
  while (true) {
    const action: { tenant: string } = yield take(SESSION_CREATE.REQUEST);
    let sessionCreated: CallEffect = yield call(createSession, action.tenant);

    while (!sessionCreated) {
      yield delay(RETRY_TIMEOUT);
      sessionCreated = yield call(createSession, action.tenant);
    }
  }
};

export const watchRequestForbidden = function* () {
  while (true) {
    const action: { error: any } = yield take(failurePattern);
    if (action.error.httpStatusCode === 403 && /Alloy-Session-Id|Alloy-Session-Token/.test(action.error.message)) {
      yield put(sessionExpired());
    }
  }
};

const sessionSaga = function* () {
  yield all([fork(watchRequestCreateSession), fork(watchRequestForbidden)]);
};

export default sessionSaga;
