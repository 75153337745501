import styled from 'styled-components';
import { CommonErrorStyles } from '../Form.ui';
import { getTransitions, rem } from 'utils/commonUtils';

export const InputWrapper = styled.div<{ $halfSize?: boolean }>`
  position: relative;
  ${({ theme, $halfSize }) => theme.mediaQueries.MD`
    width: ${$halfSize ? 'calc(50% - 15px)' : '100%'};
  `}
`;

export const InputPlaceholder = styled.div`
  position: absolute;
  top: calc(50% - (1.6rem * 1.3) / 2);
  left: 1.6rem;
  ${({ theme }) => theme.textStyles.body.regular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 3.2rem);
  transition: ${({ theme }) => getTransitions(theme.animationSpeed, 'font-size', 'top')};
  pointer-events: none;
`;

export const ClearButton = styled.button`
  position: absolute;
  top: calc(50% - 1.2rem);
  right: 1.6rem;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.animationSpeed || '512ms'};
  & * {
    pointer-events: none;
  }
`;

export const InputField = styled.input.attrs(({ disabled }) => ({
  'aria-disabled': disabled ? true : undefined,
}))<{ $width: number }>`
  ${({ theme }) => theme.textStyles.button.large};
  ${({ disabled, theme }) => (disabled ? `color: ${theme.textColor}cb` : '')};
  padding: 2.862rem 1.6rem 1rem;
  border-radius: ${({ theme }) => theme.borderRadius || '1rem'};
  border: 0.1rem solid ${({ theme }) => theme.borderColor || '#000'};
  ${({ $width, theme }) => (theme.isMobile ? 'width: 100%;' : `width: ${rem($width)};`)}
  ${({ theme, $width }) => theme.mediaQueries.LG`
    width: calc(${rem($width)} * .7);
  `}
  box-sizing: border-box;
  transition: ${({ theme }) => getTransitions(theme.animationSpeed, 'background', 'border-color')};
  ${({ disabled, theme }) =>
    disabled &&
    `border-width: 0;
    background: ${theme.colors.disabledBackgroundColor};
    & + ${InputPlaceholder} {
		color: ${theme.textColor || '#073B50'}cb;
	}`}
  & + ${InputPlaceholder}.small, &:focus + ${InputPlaceholder}, &.forceFocus + ${InputPlaceholder} {
    top: 1.1rem;
    ${({ theme }) => theme.textStyles.caption.regular};
    ${({ theme }) => theme.mediaQueries.MD`top: 1rem`}
  }
  ${({ theme }) => theme.mediaQueries.MD`
    width: 100%;
    padding: 2.5rem 1.6rem 1rem;
    ${theme.textStyles.button.regular};
  `}
  &.error {
    border-color: ${({ theme }) => theme.colors.errorDark || '#A00'};
  }
  &.clearButton {
    padding-right: calc(1.6rem * 2 + 2rem);
  }
  &.clearButton:focus ~ ${ClearButton}.show, &.clearButton:hover ~ ${ClearButton}.show, &.clearButton ~ ${ClearButton}.show:hover {
    opacity: 1;
    pointer-events: auto;
  }
  &:focus,
  &.forceFocus {
    background: ${({ theme }) => theme.colorAccent || 'transparent'};
    border-color: ${({ theme }) => theme.colorAccent || theme.borderColor || '#000'};
    outline: none;
  }
`;

export const InputOptionalLabel = styled.span`
  color: ${({ theme }) => theme.disabledColor};
`;

export const InputErrorMessage = styled(CommonErrorStyles)<{ $width: number }>`
  ${({ $width }) => `width: ${rem($width)};`}
  ${({ theme, $width }) => theme.mediaQueries.LG`
    width: calc(${rem($width)} * .7);
  `}
`;

export const Boundry = styled.div`
  position: relative;
  width: 100%;
`;
