import { ReactNode, MouseEvent } from 'react';
import * as ui from './Button.ui';
import { ButtonTypes, ButtonSizes } from './Button';
import { useLocation } from 'react-router-dom';

interface NavigationButtonProps {
  children: ReactNode;
  disabled?: boolean;
  type?: ButtonTypes;
  size?: ButtonSizes;
  className?: string;
  path: string;
  onClick?: (e: MouseEvent) => void;
  takeWholeContainer?: boolean;
  state?: object;
  overwriteFocus?: string;
}

export const NavigationButton = ({
  children,
  className,
  path,
  type = 'link',
  size = 'regular',
  disabled = false,
  takeWholeContainer = false,
  onClick = () => null,
  state = {},
  overwriteFocus = '',
}: NavigationButtonProps) => {
  const location = useLocation();
  return (
    <ui.NavigationButton
      onClick={onClick}
      $type={type}
      $size={size}
      $takeWholeContainer={takeWholeContainer}
      className={className}
      to={path}
      $disabled={disabled}
      aria-disabled={disabled ? 'true' : undefined}
      state={{
        prevLocation: location,
        ...state,
      }}
      $overwriteFocus={overwriteFocus}>
      {children}
    </ui.NavigationButton>
  );
};
