import { action, TGenericRequestTypes } from '../utils/actionHelpers';
import { request, TRequestActionProps } from '../request/requestActions';
import {
  GET_USER_PROFILE,
  SET_ACCOUNT_ID,
  UPDATE_PROFILE_DETAILS,
  ADD_NOMINEE,
  ADD_BENEFICIARY,
  DELETE_BENEFICIARY,
  DELETE_NOMINEE,
  CREATE_ACCOUNT,
  FORGOT_PASSWORD,
} from './userConstants';
import {
  getProfileDetails,
  getUserData,
  updateProfileDetails,
  addNominee,
  addBeneficiary,
  deleteBeneficiary,
  deleteNominee,
  createProfile,
  forgotPassword,
} from './userApi';
import { requestStatusSelector } from '../request/requestSelectors';
import { getSecuredApiResourceAccesses } from '../selectors';
import { Beneficiary, CreateAccountData, Nominee } from 'types/store/User';
import { KeycloakProfile } from 'keycloak-js';

export const setAccountId = (params: { accountId: string }) => {
  return action(SET_ACCOUNT_ID, { params });
};
export const createAccountRequest = (accountData: CreateAccountData, actionProps?: TRequestActionProps) =>
  request({
    actionBase: CREATE_ACCOUNT,
    apiMethod: createProfile,
    apiMethodArg: { accountData },
    ...(actionProps || {}),
  });
export const forgotPasswordRequest = (data, actionProps?: TRequestActionProps) =>
  request({
    actionBase: FORGOT_PASSWORD,
    apiMethod: forgotPassword,
    apiMethodArg: { email: data.email, memberNumber: data.memberNumber },
    ...(actionProps || {}),
  });
export const getUserDataRequest = (dataType: TGenericRequestTypes, actionProps?: TRequestActionProps) =>
  request({
    actionBase: dataType,
    apiMethod: getUserData,
    apiMethodArgSelector: getSecuredApiResourceAccesses,
    apiMethodArg: { dataName: dataType.pathParam },
    ...(actionProps || {}),
  });

export const getUserDataRequestStatus = (dataType: TGenericRequestTypes) => requestStatusSelector(dataType);

export const getUserProfileRequest = () =>
  request({
    actionBase: GET_USER_PROFILE,
    apiMethod: getProfileDetails,
    apiMethodArgSelector: getSecuredApiResourceAccesses,
  });
export const setUserProfile = (data: KeycloakProfile) => action(GET_USER_PROFILE.SUCCESS, { data });

export const getContactDetailsRequest = requestStatusSelector(UPDATE_PROFILE_DETAILS);

export const updateProfileRequest = (profileDetails: any, actionProps: TRequestActionProps = {}) =>
  request({
    actionBase: UPDATE_PROFILE_DETAILS,
    apiMethod: updateProfileDetails,
    apiMethodArgSelector: getSecuredApiResourceAccesses,
    apiMethodArg: { data: profileDetails },
    ...actionProps,
  });

export const addNomineeRequest = (nominee, certificate: File | null, actionProps: TRequestActionProps = {}) =>
  request({
    actionBase: ADD_NOMINEE,
    apiMethod: addNominee,
    apiMethodArgSelector: getSecuredApiResourceAccesses,
    apiMethodArg: {
      data: {
        nominee,
        certificate,
      },
    },
    ...actionProps,
  });
export const addBeneficiaryRequest = (beneficiary, actionProps: TRequestActionProps = {}) =>
  request({
    actionBase: ADD_BENEFICIARY,
    apiMethod: addBeneficiary,
    apiMethodArgSelector: getSecuredApiResourceAccesses,
    apiMethodArg: {
      data: beneficiary,
    },
    ...actionProps,
  });
export const removeBeneficiaryRequest = (beneficiary: Beneficiary, actionProps: TRequestActionProps = {}) =>
  request({
    actionBase: DELETE_BENEFICIARY,
    apiMethod: deleteBeneficiary,
    apiMethodArgSelector: getSecuredApiResourceAccesses,
    apiMethodArg: { id: beneficiary.id },
    ...actionProps,
  });
export const removeNomineeRequest = (nominee: Nominee, actionProps: TRequestActionProps = {}) =>
  request({
    actionBase: DELETE_NOMINEE,
    apiMethod: deleteNominee,
    apiMethodArgSelector: getSecuredApiResourceAccesses,
    apiMethodArg: { id: nominee.id },
    ...actionProps,
  });
