import { createGenericRequestTypes, createRequestTypes, TGenericRequestTypes, TRequestTypes } from '../utils/actionHelpers';

export const SET_ACCOUNT_ID: string = 'SET_ACCOUNT_ID';
export const ID_CARD = 'ID Card';

export const GET_USER_PROFILE: TRequestTypes = createRequestTypes('GET_USER_PROFILE');
export const GET_USER_TRANSACTIONS: TGenericRequestTypes = createGenericRequestTypes('GET_USER_TRANSACTIONS', '/transactions');
export const GET_USER_BENEFICIARIES: TGenericRequestTypes = createGenericRequestTypes('GET_USER_BENEFICIARIES', '/beneficiaries');
export const GET_USER_NOMINEES: TGenericRequestTypes = createGenericRequestTypes('GET_USER_NOMINEES', '/nominees');
export const GET_USER_CARD: TGenericRequestTypes = createGenericRequestTypes('GET_USER_CARD', '/card');
export const UPDATE_PROFILE_DETAILS: TRequestTypes = createRequestTypes('UPDATE_PROFILE_DETAILS');

export const ADD_BENEFICIARY: TRequestTypes = createRequestTypes('ADD_BENEFICIARY');
export const DELETE_BENEFICIARY: TRequestTypes = createRequestTypes('DELETE_BENEFICIARY');
export const ADD_NOMINEE: TRequestTypes = createRequestTypes('ADD_NOMINEE');
export const DELETE_NOMINEE: TRequestTypes = createRequestTypes('DELETE_NOMINEE');

export const CREATE_ACCOUNT: TRequestTypes = createRequestTypes('CREATE_ACCOUNT');
export const FORGOT_PASSWORD: TRequestTypes = createRequestTypes('FORGOT_PASSWORD');
