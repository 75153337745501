import styled, { keyframes, Keyframes } from 'styled-components';

const slide: Keyframes = keyframes`
  0% { left: -50%; }
  100% { left: 150%; }
`;

export const SplashScreenBackground = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 6rem;
  background-color: ${({ theme }) => theme.colorAccent || 'inherit'};
  background-image: url(${({ theme }) => theme.images.splashScreen_xl});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.64;
    background: linear-gradient(240deg, #6b564600 35.17%, #49392db1 64.58%, #392c23 80.69%);
  }

  @media (max-aspect-ratio: 1/1) {
    background-position: center;
    padding: 0;
    &::after {
      background: linear-gradient(203deg, #6b564600 30.67%, #49392db1 55.46%, #392c23 73.1%);
    }
  }
  @media (min-resolution: 150dpi) {
    background-image: url(${({ theme }) => theme.images.splashScreen_xl_x2});
  }

  @media (max-width: 1440px) {
    padding: 4rem;
  }
  @media (max-width: 1440px) and (max-height: 960px) {
    background-image: url(${({ theme }) => theme.images.splashScreen_lg});
  }
  @media (max-width: 1440px) and (max-height: 960px) and (min-resolution: 150dpi) {
    background-image: url(${({ theme }) => theme.images.splashScreen_lg_x2});
  }
  @media (max-width: 960px) {
    padding: 2.4rem;
  }
  @media (max-width: 960px) and (max-height: 640px) {
    background-image: url(${({ theme }) => theme.images.splashScreen_md});
  }
  @media (max-width: 960px) and (max-height: 640px) and (min-resolution: 150dpi) {
    background-image: url(${({ theme }) => theme.images.splashScreen_md_x2});
  }
  @media (max-width: 512px) {
    padding: 1.6rem;
  }
  @media (max-width: 512px) and (max-height: 341px) {
    background-image: url(${({ theme }) => theme.images.splashScreen_sm});
  }
  @media (max-width: 512px) and (max-height: 341px) and (min-resolution: 150dpi) {
    background-image: url(${({ theme }) => theme.images.splashScreen_sm_x2});
  }
`;

export const SplashScreenContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8rem;
  width: 50%;
  height: 100%;
  position: relative;
  top: 0;
  z-index: 3;
  @media (max-aspect-ratio: 1/1) {
    align-items: center;
    padding: 3.2rem 0;
    width: 100%;
    justify-content: flex-end;
  }
  ${({ theme }) => theme.mediaQueries.LG`gap: 6rem;`}
  ${({ theme }) => theme.mediaQueries.MD`gap: 4.8rem;`}
`;

export const LogoWithMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3.2rem;
  ${({ theme }) => theme.textStyles.header.h2}
  color: ${({ theme }) => theme.invertedTextColor};
  ${({ theme }) => theme.mediaQueries.MD`gap: 2.4rem;`}
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.header.h3};
    color: ${theme.invertedTextColor};
    img { max-height: 3.2rem; }
  `}
  img {
    max-height: 4.1rem;
  }
  @media (max-aspect-ratio: 1/1) {
    align-items: center;
    text-align: center;
  }
`;

export const ProgressBarHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2.4rem;
  ${({ theme }) => theme.textStyles.body.regular}
  color: ${({ theme }) => theme.invertedTextColor};
  ${({ theme }) => theme.mediaQueries.MD`gap: 1.6rem;`}
  ${({ theme }) => theme.mediaQueries.SM`
    ${theme.textStyles.body.small};
    color: ${theme.invertedTextColor};
  `}
`;

export const Progress = styled.div`
  width: 16rem;
  height: 0.4rem;
  border-radius: 0.2rem;
  background: #ffffff35;
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    width: 50%;
    height: 100%;
    border-radius: 0.2rem;
    background: ${({ theme }) => theme.primaryColor};
    position: absolute;
    top: 0;
    animation: ${slide} 1s linear infinite;
  }
`;
