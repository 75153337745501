import styled from 'styled-components';

export const MenuSection = styled.div`
  padding: 2.5rem 0;
  &:first-child {
    padding-top: 0;
  }
  &:not(:first-child) {
    border-top: 0.1rem solid ${({ theme }) => theme.borderColor || '#073B50'};
  }
  &:last-child {
    padding-bottom: 0;
  }
  min-width: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const NameHolder = styled.div`
  padding-right: 4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold || '600'};
  color: ${({ theme }) => theme.textColor};
`;
