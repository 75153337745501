import { defineMessages } from '@triplake/lib-intl';

export const messages = defineMessages({
  homeTitle: {
    id: 'Layout.home.title',
    defaultMessage: 'Experience the beauty of Mauritius',
  },
  homeSubtitle: {
    id: 'Layout.home.subtitle',
    defaultMessage: 'Everyone’s dream destination',
  },
  login: {
    id: 'Layout.login',
    defaultMessage: 'Log in to your account',
  },
  registration: {
    id: 'Layout.registration',
    defaultMessage: 'Create your account',
  },
  forgotPassword: {
    id: 'Layout.forgotPassword',
    defaultMessage: 'Forgot password',
  },
  dashboard: {
    id: 'Layout.dashboard',
    defaultMessage: 'My Account',
  },
  profile: {
    id: 'Layout.profile',
    defaultMessage: 'My Profile',
  },
  transactions: {
    id: 'Layout.transactions',
    defaultMessage: 'My transactions',
  },
  beneficiaries: {
    id: 'Layout.beneficiaries',
    defaultMessage: 'My Beneficiaries',
  },
  nominees: {
    id: 'Layout.nominees',
    defaultMessage: 'My Nominees',
  },
  climeMiles: {
    id: 'Layout.climeMiles',
    defaultMessage: 'Claim Missing Miles',
  },
  milesCalculator: {
    id: 'Layout.milesCalculator',
    defaultMessage: 'Miles calculator',
  },
  awardTicket: {
    id: 'Layout.awardTicket',
    defaultMessage: 'Book Award Ticket',
  },
  extraBaggage: {
    id: 'Layout.extraBaggage',
    defaultMessage: 'Extra baggage',
  },
  upgrade: {
    id: 'Layout.upgrade',
    defaultMessage: 'Upgrading',
  },
  premiumLounge: {
    id: 'Layout.premiumLounge',
    defaultMessage: 'Use miles for Premium Lounge',
  },
});
