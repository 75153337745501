import { AnyAction } from 'redux';
import { all, call, fork, put, select, take, SelectEffect } from 'redux-saga/effects';

import { REQUEST } from '../utils/actionHelpers';
import { failure, success } from './requestActions';

export const requestPattern = (action: AnyAction) => action.type.endsWith(REQUEST);

export const performRequest = function* (action: AnyAction) {
  let apiMethodArg: SelectEffect = action.apiMethodArg;

  if (typeof action.apiMethodArgSelector === 'function') {
    let dataToAdd = {};
    const argSelector: SelectEffect = yield select(action.apiMethodArgSelector);

    if (typeof argSelector === 'string' || Array.isArray(argSelector)) {
      dataToAdd = { selectorValue: argSelector };
    } else if (typeof argSelector === 'object') {
      dataToAdd = argSelector;
    }

    apiMethodArg = apiMethodArg ? { ...apiMethodArg, ...dataToAdd } : argSelector;
  }

  let isArgValid = true;
  if (typeof action.argValidator === 'function') {
    isArgValid = yield select(action.argValidator, apiMethodArg);
  }

  if (!isArgValid) {
    const failureAction = failure(action.actionBase, 'invalid argument');
    yield put(failureAction);
    return;
  }

  const { response, error } = yield call(action.apiMethod, apiMethodArg);

  if (error) {
    const failureAction = failure(action.actionBase, error, action);
    yield put(failureAction);

    // if onError is a function, invoke it with proper args
    if (typeof action.onError === 'function') {
      const errorFoo = yield call(action.onError, error);
      if (typeof errorFoo === 'function') yield put(errorFoo);
    }
  } else {
    const successAction = success(action.actionBase, response?.responseBody, action);
    yield put(successAction);

    // if onSuccessAction is a function, invoke it
    if (typeof action.onSuccessAction === 'function') {
      const success = yield call(action.onSuccessAction, response);
      if (typeof success === 'function') put(success);
    }
  }
  if (typeof action.always === 'function') {
    const always = yield call(action.always, !!error);
    if (typeof always === 'function') yield put(action.always, !!error);
  }
};

/**
 * @description Common request handler for *REQUEST redux actions.
 * These actions should be created by {@link module:requestActions.request} function.
 */
export const watchRequest = function* () {
  while (true) {
    const action: AnyAction = yield take(requestPattern);
    if (action.apiMethod && action.actionBase) {
      yield fork(performRequest, action);
    }
  }
};

const requestSaga = function* () {
  yield all([fork(watchRequest)]);
};

export default requestSaga;
