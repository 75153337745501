import PropTypes from 'prop-types';
import { passengerShape } from '../main';

export const insuranceShape = PropTypes.shape({
  days: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired
});

export const passengerInsuranceShape = PropTypes.shape({
  passenger: passengerShape.isRequired,
  days: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired
});
