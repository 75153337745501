import {
  ChangeEvent as ReactChangeEvent,
  ReactElement,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
} from 'react';
import * as ui from './Select.ui';
import { debounce, escapeRegExp } from 'utils/commonUtils';
import { AlignItem, alignTheElementAroundTheWrapper, setThePositionToTheDesiredElement } from 'utils/DOMmanupulationUtils';
import { ChangeEvent, FilteredOption, OptionsGroup, SelectField, SelectOption, SelectProps } from 'types/Form';
import { joinParts, onKeyDown, flattenOptions, sortAllOptions, isOptionsGroup } from './Select.utils';
import { AnyEnum } from 'types/types';
import Icon from 'components/Icon';
import { useIntl } from '@triplake/lib-intl';

export const Select: SelectField = ({
  options = [],
  value,
  name,
  placeholder,
  tabindex,
  id,
  selectClassName,
  width = 350,
  onChange = () => {},
  disabled = false,
  optional = false,
  searchableSelect = false,
  debounceSearch = 0,
  minSearchLength = 1,
  openOptions = false,
  optionsPanelMaxHeight = 350,
  error = false,
  sortOptions = false,
  overflowItsAncestors = false,
  clearButton = false,
}: SelectProps) => {
  const { formatMessage } = useIntl();
  /** States **/
  const [fieldValue, setValue] = useState<string | number>(!value && value !== 0 ? '' : value);
  const [fieldLabel, setFieldLabel] = useState<ReactElement | null | string>(() => {
    if (!value && value !== 0) return null;
    const initialOption: SelectOption | undefined = flattenOptions(options).find((option: SelectOption): boolean => option.value === value);
    return initialOption ? initialOption.richLabel || initialOption.label : null;
  });
  const [displayOptionsPosition, setOptionsPosition] = useState<AlignItem>(AlignItem.BOTTOM_LEFT);
  const [searchFieldValue, setSearchValue] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');
  const [optionsVisible, setOptionsVisibility] = useState<boolean>(openOptions);

  /** Refs **/
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  // a Memo for all options available sorted by label
  const allOptions = useMemo(
    () => (sortOptions ? sortAllOptions(options) : options).filter(option => !(isOptionsGroup(option) && !option.options.length)),
    [options, sortOptions],
  );

  /** Callbacks **/
  // this is a reference to a Function with or without debounce (just like a callback)
  const setSearchStringHandler = useMemo(() => (debounceSearch ? debounce(setSearchString, debounceSearch) : setSearchString), [debounceSearch]);
  const onOptionClickCallback = useCallback(
    (option: SelectOption, path?: Array<string | AnyEnum>): void => {
      setSearchValue('');
      setSearchString('');
      const shouldUpdate = onChange(
        {
          value: option.value,
          prevValue: fieldValue,
          targetName: name,
          targetElement: wrapperRef.current,
        } as ChangeEvent<SelectField>,
        option.value,
        path,
      );
      if (shouldUpdate === false) return;
      setOptionsVisibility(false);
      setValue(option.value);
    },
    [onChange, name, fieldValue],
  );

  const onSearchChangeCallback = useCallback(
    (event: ReactChangeEvent): void => {
      const nativeEvent: InputEvent = event.nativeEvent as InputEvent;
      const value: string = (nativeEvent.target as HTMLInputElement).value;
      setSearchValue(value);
      setSearchStringHandler(value);
    },
    [setSearchStringHandler],
  );

  const toggleOptionsVisibilityCallback = useCallback(() => {
    if (disabled) return setOptionsVisibility(false);
    let whereToDisplayOptions = AlignItem.BOTTOM_LEFT;
    const wrapperDiv = wrapperRef.current;
    const optionsDiv = optionsRef.current;
    setOptionsVisibility((isVisible: boolean): boolean => {
      if (!isVisible && wrapperDiv && optionsDiv) {
        const optionsHeight: number = Math.min(optionsPanelMaxHeight, flattenOptions(options).length * 40) || optionsDiv.clientHeight || 0;
        const optionsWidth: number = optionsDiv.clientWidth || 0;
        whereToDisplayOptions = alignTheElementAroundTheWrapper(wrapperDiv, optionsWidth, optionsHeight + ui.OptionsBoxSpacing);
        if (overflowItsAncestors) setThePositionToTheDesiredElement(optionsDiv, wrapperDiv, whereToDisplayOptions, ui.OptionsBoxSpacing);
      }
      return !isVisible;
    });
    setOptionsPosition(whereToDisplayOptions);
  }, [disabled, optionsPanelMaxHeight, overflowItsAncestors]);

  const closeCallback = useCallback((e: MouseEvent): void => {
    const optionsDiv = optionsRef.current;
    if (optionsDiv && !optionsDiv.contains((e as any).target)) {
      setOptionsVisibility(false);
      setSearchValue('');
      setSearchString('');
    }
  }, []);

  const onKeyDownCallback = useCallback((ev: KeyboardEvent) => onKeyDown(ev, optionsRef.current, searchableSelect), [searchableSelect]);

  const generateOptionButton = useCallback(
    (option: SelectOption | FilteredOption, path?: Array<string | AnyEnum>) => (
      <ui.OptionElement
        key={`${option.label}#${option.value}`}
        onClick={function () {
          onOptionClickCallback(option, path);
        }}
        data-option-value={typeof option.value === 'string' ? option.value : `{${option.value}}`}
        disabled={!!option.disabled}
        tabIndex={-1}>
        {option.icon instanceof Function ? option.icon({}) : option.icon}
        <span>{(option as FilteredOption).selectBoxLabel || option.richLabel || option.label}</span>
      </ui.OptionElement>
    ),
    [onOptionClickCallback],
  );

  const generateButtons = useCallback(
    (options: Array<SelectOption | FilteredOption | OptionsGroup>, path: Array<string | AnyEnum> = []): Array<ReactElement> =>
      options.map((option, index) => {
        if (isOptionsGroup(option)) {
          return option.options.length ? (
            <Fragment key={`group#${option.id || option.label}`}>
              <ui.OptionGroupTitle>{option.richLabel || option.label}</ui.OptionGroupTitle>
              {generateButtons(option.options, option.groupType ? path.concat([option.groupType]) : path)}
              {options.length < index + 2 || isOptionsGroup(options[index + 1]) ? null : <hr />}
            </Fragment>
          ) : (
            <></>
          );
        }
        return generateOptionButton(option, path.length ? path : undefined);
      }),
    [generateOptionButton],
  );

  /** Effects **/
  useEffect(() => {
    if (typeof openOptions === 'boolean') setOptionsVisibility(openOptions);
  }, [openOptions]);

  useEffect(() => {
    setValue(!value && value !== 0 ? '' : value);
    if (!value) return setFieldLabel(null);
  }, [value]);

  useEffect(() => {
    const selectedOption: SelectOption | undefined = flattenOptions(options).find((option: SelectOption): boolean => option.value === fieldValue);
    setFieldLabel(selectedOption ? selectedOption.richLabel || selectedOption.label : null);
  }, [options, fieldValue]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (optionsVisible) {
        document.body.addEventListener('click', closeCallback, { passive: true });
        document.body.addEventListener('keydown', onKeyDownCallback);
      } else {
        document.body.removeEventListener('click', closeCallback);
        document.body.removeEventListener('keydown', onKeyDownCallback);
      }
    });
  }, [optionsVisible, onKeyDownCallback]);

  useEffect(() => {
    const optionsDiv = optionsRef.current;
    if (optionsDiv && optionsVisible && fieldValue) {
      const selectedOption: SelectOption | undefined = flattenOptions(options).find((option: SelectOption): boolean => option.value === fieldValue);
      const dataOptionValue = typeof selectedOption?.value === 'number' ? `{${selectedOption?.value}}` : selectedOption?.value;
      const optionElement: HTMLButtonElement | null = optionsDiv.querySelector(`[data-option-value="${dataOptionValue}"]`);
      if (optionElement) {
        const topPosition = optionElement.offsetTop > 20 ? optionElement.offsetTop - 16 : 0;
        if (!searchableSelect) optionElement.focus();
        optionsDiv.scrollTo(0, topPosition);
      }
    }
  }, [optionsVisible, searchableSelect]);

  useEffect(() => {
    const wrapper: HTMLDivElement | null = wrapperRef.current;
    const options: HTMLDivElement | null = optionsRef.current;
    if (!wrapper || !options) return;
    const wrapperWidth: number = wrapper.clientWidth;
    const availableWidth: number = Math.floor((window.innerWidth - wrapper.getBoundingClientRect().left - 80) / 10);
    options.style.minWidth = `${Math.ceil(wrapperWidth / 10)}rem`;
    // the Math.max is here just to be safe, although it should not be needed
    options.style.maxWidth = `${Math.max(wrapperWidth, availableWidth)}rem`;
  }, []);

  /** Memos / Elements to be rendered **/
  const filteredOptions = useMemo<Array<FilteredOption | OptionsGroup>>((): Array<FilteredOption | OptionsGroup> => {
    if (searchString.length < minSearchLength || !searchableSelect || !searchString) return allOptions as Array<FilteredOption>;
    const reg = new RegExp(escapeRegExp(searchString), 'gi');
    const recurse = (
      accumulator: Array<FilteredOption | OptionsGroup>,
      current: SelectOption | OptionsGroup,
    ): Array<FilteredOption | OptionsGroup> => {
      if (isOptionsGroup(current)) {
        const group = current.options.reduce(recurse, []);
        if (group.length)
          accumulator.push({
            label: current.label,
            richLabel: current.richLabel,
            options: group,
          });
        return accumulator;
      }
      const found: Array<string> = current.label.split(reg);
      if (found.length === 1) {
        if ((current.tags || []).filter(tag => reg.test(tag)).length) accumulator.push(current);
        return accumulator;
      }

      const createJoiner = (key: number, start: number, end: number) => <strong key={key}>{current.label.substring(start, end)}</strong>;
      accumulator.push({
        ...current,
        selectBoxLabel: <>{joinParts(found, searchString.length, createJoiner)}</>,
      });
      return accumulator;
    };
    return allOptions.reduce(recurse, []);
  }, [searchableSelect, searchString, allOptions, minSearchLength]);

  const optionsDropdown = useMemo(
    () => (
      <ui.OptionsBox ref={optionsRef} $maxHeight={optionsPanelMaxHeight}>
        {generateButtons(filteredOptions)}
      </ui.OptionsBox>
    ),
    [filteredOptions, optionsPanelMaxHeight],
  );

  const clearFieldCallback = useCallback(
    (e: ReactMouseEvent): void => {
      e.preventDefault();
      onOptionClickCallback({ value: '', label: '' });
    },
    [onOptionClickCallback],
  );

  const className: string | undefined = useMemo(() => {
    const classList: Array<string> = [];
    if (optionsVisible) classList.push('active');
    if (error) classList.push('error');
    if (clearButton) classList.push('clearButton');
    return classList.length ? classList.join(' ') : undefined;
  }, [optionsVisible, error, clearButton]);
  // end of hooks

  const selectInitiator =
    searchableSelect && optionsVisible ? (
      <ui.SelectInputField
        value={searchFieldValue}
        name={name}
        id={id}
        tabIndex={tabindex}
        disabled={disabled}
        className={selectClassName}
        onChange={onSearchChangeCallback}
        $width={width}
        autoFocus
      />
    ) : (
      <ui.SelectButton id={id} tabIndex={tabindex} disabled={disabled} className={selectClassName} $width={width}>
        {fieldLabel || '\u00A0'}
      </ui.SelectButton>
    );

  return (
    <ui.SelectWrapper className={className} $halfSize={width < 200} ref={wrapperRef} $disabled={disabled}>
      <ui.Boundry>
        <ui.FieldButton onClick={!disabled ? toggleOptionsVisibilityCallback : undefined}>
          {selectInitiator}
          <ui.SelectPlaceholder className={options.length && (!!searchFieldValue || !!fieldValue || fieldValue === 0) ? 'small' : undefined}>
            {placeholder}
            {optional && <ui.SelectOptionalLabel>{formatMessage({ id: 'common.optional', defaultMessage: ' (Optional)' })}</ui.SelectOptionalLabel>}
          </ui.SelectPlaceholder>
          {clearButton && (
            <ui.ClearButton onMouseDown={clearFieldCallback} className={!!searchFieldValue || !!fieldValue || fieldValue === 0 ? 'show' : undefined}>
              <Icon name="clearButton" />
            </ui.ClearButton>
          )}
          {searchableSelect && optionsVisible && <ui.OptionsCountLabel>{`${filteredOptions.length} / ${allOptions.length}`}</ui.OptionsCountLabel>}
        </ui.FieldButton>
        <ui.OptionsWrapper
          className={optionsVisible ? 'active' : undefined}
          $displayOptionsPosition={displayOptionsPosition}
          $overflowItsAncestors={overflowItsAncestors}>
          {optionsDropdown}
        </ui.OptionsWrapper>
      </ui.Boundry>
      {typeof error === 'string' ? <ui.SearchErrorMessage $width={width}>{error}</ui.SearchErrorMessage> : null}
    </ui.SelectWrapper>
  );
};
