import logo from '../../assets/images/oman/logo.svg';
import footer_bg from '../../assets/images/oman/footer_bg.png';
import heroBanner from '../../assets/images/airmauritius/hero-baner.jpeg';
import heroImage_home from '../../assets/images/airmauritius/hero-image-home.png';
import header_bg from '../../assets/images/oman/swirl.svg';
import card_level1 from '../../assets/images/oman/card_blue.png';
import card_level2 from '../../assets/images/oman/card_silver.png';
import card_level3 from '../../assets/images/oman/card_gold.png';
import selectOpenChevron from '../../assets/images/airmauritius/select-open-chevron.svg';

export default {
  footer_bg,
  header_level1_bg: `${header_bg}); background-position: 50% 0;(`,
  header_level2_bg: `${header_bg}); background-position: 50% 0;(`,
  header_level3_bg: `${header_bg}); background-position: 50% 0;(`,
  dashboard_bg: '',
  logo,
  heroBanner,
  heroImage_login: '',
  heroImage_home,
  card_level1,
  card_level2,
  card_level3,
  selectOpenChevron,
};
