import { ReactNode } from 'react';
import { OptionsGroup, SelectOption } from 'types/Form';

export const joinParts = (
  fragments: Array<string>,
  searchLength: number,
  createElementFoo: (key: number, startIndex: number, endIndex: number) => ReactNode,
): Array<ReactNode> => {
  const array: Array<ReactNode> = [...fragments];
  const prefixLengths: Array<number> = array.reduce(
    (prefixes: Array<number>, currentPart: ReactNode, count: number): Array<number> => {
      prefixes.push(prefixes[prefixes.length - 1] + (currentPart as string).length + (count ? searchLength : 0));
      return prefixes;
    },
    [0],
  );
  prefixLengths.shift();
  const length: number = array.length - 1;
  for (let i = 0; i < length; ++i) array.splice(i * 2 + 1, 0, createElementFoo(i * 2 + 1, prefixLengths[i], prefixLengths[i] + searchLength));
  return array;
};

export const onKeyDown = function (event: KeyboardEvent, optionsBox: HTMLDivElement | null, searchableSelect: boolean): any {
  if (!optionsBox) return;
  const focusedElement = document.activeElement;
  const children: Array<HTMLButtonElement> = [].slice.apply(optionsBox.children).filter((node: HTMLElement) => node.nodeName === 'BUTTON');
  if (!children.length) return;
  const findFocusedOption = (stopBubble: boolean = true): number => {
    if (stopBubble) event.preventDefault();
    return children.findIndex((element: HTMLButtonElement): boolean => element === focusedElement);
  };
  const getNextElement = (index: number): number => {
    let cursor: number = index;
    while (children[++cursor % children.length].disabled && cursor !== index);
    return cursor % children.length;
  };
  const getPrevElement = (index: number): number => {
    let cursor: number = index;
    const decrement = function () {
      if (cursor === 0) cursor = children.length;
      return --cursor;
    };
    while (children[decrement()].disabled && cursor !== index);
    return cursor;
  };
  switch (event.key) {
    case 'ArrowUp':
      const currentUp: number = findFocusedOption();
      if (!~currentUp) children[children.length - 1].focus();
      else children[getPrevElement(currentUp)].focus();
      break;
    case 'ArrowDown':
      const currentDn: number = findFocusedOption();
      if (!~currentDn) children[0].focus();
      else children[getNextElement(currentDn)].focus();
      break;
    case 'Enter':
    case ' ':
      const currentSelect: number = findFocusedOption(!searchableSelect);
      if (!~currentSelect) break;
      doAProperClickOnElement(children[currentSelect]);
      break;
  }
};

const doAProperClickOnElement = (element: HTMLElement) => {
  element.dispatchEvent(new MouseEvent('mousedown', { bubbles: true, cancelable: true }));
  element.dispatchEvent(new MouseEvent('mouseup', { bubbles: true, cancelable: true }));
  element.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
};

export const isOptionsGroup = (options: SelectOption | OptionsGroup): options is OptionsGroup => {
  return typeof (options as OptionsGroup).options !== 'undefined';
};

export const flattenOptions = (options: Array<SelectOption | OptionsGroup>) => {
  return options.reduce((accumulator: Array<SelectOption>, current: SelectOption | OptionsGroup): Array<SelectOption> => {
    if (isOptionsGroup(current)) return accumulator.concat(flattenOptions(current.options));
    accumulator.push(current as SelectOption);
    return accumulator;
  }, []);
};

const sortByLabel = (a: SelectOption | OptionsGroup, b: SelectOption | OptionsGroup): number => a.label.localeCompare(b.label);

export const sortAllOptions = (options: Array<SelectOption | OptionsGroup>): Array<SelectOption | OptionsGroup> => {
  const sortedGroups = options.map(element =>
    isOptionsGroup(element)
      ? {
          label: element.label,
          richLabel: element.richLabel,
          options: sortAllOptions(element.options),
        }
      : element,
  );
  return [...sortedGroups].sort(sortByLabel);
};
