import { REQUEST_STATUS } from '@triplake/lib-types';
import { TAppState } from '../constants';
import { REQUEST, TRequestTypes } from '../utils/actionHelpers';


export const requestStatusSelector = (actionBase: TRequestTypes) => (state: TAppState) =>
  state.request[`${actionBase[REQUEST]}-status`] || REQUEST_STATUS.NONE;

export const requestResponseSelector = (actionBase: TRequestTypes) => (state: TAppState) => state.request[`${actionBase[REQUEST]}-response`] || null;

export const requestErrorResponseSelector = (actionBase: TRequestTypes) => (state: TAppState) =>
  state.request[`${actionBase[REQUEST]}-error`] || null;

export const requestStateSelector = (state: TAppState) => state.request || null;
