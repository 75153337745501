import PropTypes from 'prop-types';
import { passengerShape, segmentShape } from '../main';

export const frequentFlyerDetails = PropTypes.shape({
  programName: PropTypes.string,
  number: PropTypes.string
});

export const frequentFlyerProgramShape = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string
});

export const checkinFlight = PropTypes.shape({
  checkedIn: PropTypes.bool.isRequired,
  key: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  seatNumber: PropTypes.string
});

export const checkinTicketShape = PropTypes.shape({
  number: PropTypes.string
});

export const checkinPassengerShape = PropTypes.shape({
  firstName: PropTypes.string,
  flights: PropTypes.arrayOf(checkinFlight),
  frequentFlyerDetails: PropTypes.arrayOf(frequentFlyerDetails),
  gender: PropTypes.string,
  lastName: PropTypes.string,
  missingMandatoryInformation: PropTypes.arrayOf(PropTypes.string),
  passengerId: PropTypes.string,
  passengerLocator: passengerShape,
  passengerStatus: PropTypes.string,
  tickets: PropTypes.arrayOf(checkinTicketShape),
  title: PropTypes.string
});

export const checkinPassengersShape = PropTypes.arrayOf(checkinPassengerShape);
export const checkinPassengersWithInfantsShape = PropTypes.objectOf(checkinPassengerShape);

export const paxCheckinDetailsShape = PropTypes.shape({
  fullName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  seat: PropTypes.string,
  segment: segmentShape,
  flightCode: PropTypes.string,
  date: PropTypes.string,
  cabinClass: PropTypes.string,
  boardingTime: PropTypes.string,
  terminal: PropTypes.string,
  zone: PropTypes.string,
  recordLocator: PropTypes.string,
  ticketNumber: PropTypes.string,
  qrCode: PropTypes.string,
  associatedInfant: PropTypes.shape({})
});

export const allPaxCheckinDetailsShape = PropTypes.arrayOf(paxCheckinDetailsShape);

export const boardingPassModalConfigShape = PropTypes.shape({
  boardingPassModalInitialIndex: PropTypes.number,
  handleBoardingPassModalOpen: PropTypes.func
});
