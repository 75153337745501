import * as ui from './TriplakeDatePicker.ui';
import { getParseDate, getParsingDateTools, weekdaysForTranslation } from './TriplakeDatePicker.utils';
import { messages } from './TriplakeDatePicker.translations';
import DatePicker from 'react-datepicker';
import * as React from 'react';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.module.css';
import { Input } from '../Input/Input';
import { InputOptionalLabel } from '../Input/Input.ui';
import { useIntl } from '@triplake/lib-intl';
import { AlignItem, alignTheElementAroundTheWrapper, setThePositionToTheDesiredElement } from 'utils/DOMmanupulationUtils';
import { normalizeDate } from 'utils/dateUtils';
import { createPortal } from 'react-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { actOnEnterAndSpace, getDateFormatRepresentation } from 'utils/commonUtils';
import { useDeviceType } from '@triplake/lib-hooks';
import { ChangeEvent, TriplakeDatePickerField, TriplakeDatePickerProps } from 'types/Form';

enum PickerDepth {
  YEAR,
  MONTH,
  DAY,
}

export const TriplakeDatePicker: TriplakeDatePickerField = ({
  value,
  name,
  tabindex,
  id,
  inputClassName,
  onChange,
  dateFormat = { year: 'numeric', month: '2-digit', day: '2-digit' },
  placeholder,
  minDate,
  maxDate,
  showPreviousMonths,
  monthsToShow = 2,
  forceTwoMonthsOnMobile = false,
  showDateFormat = true,
  width = 350,
  disabled = false,
  optional = false,
  error = false,
  clearButton = false,
  overflowItsAncestors = false,
  drillDownCalendar = false,
}: TriplakeDatePickerProps) => {
  const { formatDate, formatMessage } = useIntl();
  const { isMobile } = useDeviceType();

  // States
  const [selectedDate, setSelectedDate] = useState(() => {
    if (value instanceof Date) return value;
    if (!value) return null;
    const date = new Date(value);
    return isNaN(date.getTime()) ? new Date() : date;
  });
  const [isOpen, setOpenState] = useState(false);
  const [pickerPosition, setOptionsPosition] = useState(AlignItem.BOTTOM);
  const [dpError, setDpError] = useState<boolean | string>(error);

  const [depth, setDepth] = useState(drillDownCalendar ? PickerDepth.YEAR : PickerDepth.DAY);

  // Refs
  const wrapperRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const datePickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  // First global Memos
  const dateFormatString = useMemo(() => getDateFormatRepresentation(formatDate, dateFormat), [formatDate, dateFormat]);
  const parsingDateTools = useMemo(() => getParsingDateTools(dateFormatString), [dateFormatString]);

  // Callbacks
  const openPickerCallback = useCallback(() => !disabled && setOpenState(true), [disabled]);
  const closePickerCallback = useCallback(() => requestAnimationFrame(() => setOpenState(false)), []);

  const handleDateChangeCallback = useCallback((): boolean | void => {
    switch (depth) {
      case PickerDepth.YEAR:
        requestAnimationFrame(() => setDepth(PickerDepth.MONTH));
        return false;
      case PickerDepth.MONTH:
        requestAnimationFrame(() => setDepth(PickerDepth.DAY));
        return false;
    }
  }, [depth]);

  const goBackCallback = useCallback(() => {
    requestAnimationFrame(() =>
      setDepth((currentDepth: PickerDepth): PickerDepth => {
        switch (currentDepth) {
          case PickerDepth.DAY:
            return PickerDepth.MONTH;
          case PickerDepth.MONTH:
            return PickerDepth.YEAR;
        }
        return currentDepth;
      }),
    );
  }, []);

  const onChangeCallback = useCallback(
    (date: Date | null, event: React.SyntheticEvent<any> | undefined, forceDateCheckInDrillDown: boolean = false) => {
      if (drillDownCalendar && handleDateChangeCallback() === false && forceDateCheckInDrillDown !== true) return;
      if (date && isNaN(date.getTime())) return setDpError(formatMessage(messages.invalidDateError));
      if (date && minDate && +normalizeDate(date, true) < +normalizeDate(minDate, true)) return setDpError(formatMessage(messages.toEarlyError));
      if (date && maxDate && +normalizeDate(date, true) > +normalizeDate(maxDate, true)) return setDpError(formatMessage(messages.toLateError));
      let prevDate: null | Date = null;
      setSelectedDate(originalDate => {
        prevDate = originalDate;
        if (!date) return null;
        return !originalDate || +originalDate !== +date ? date : originalDate;
      });
      if (date) setDpError(false);
      else if (date === null && !optional) setDpError(formatMessage(messages.isRequiredError));
      closePickerCallback();
      if (typeof onChange === 'function')
        onChange.call(
          this,
          {
            targetName: name,
            targetElement: null,
            value: date,
            prevValue: prevDate,
          } as ChangeEvent<TriplakeDatePickerField>,
          date,
        );
    },
    [onChange, name, minDate, maxDate, optional, formatMessage, drillDownCalendar],
  );

  const onInputChangeCallback = useCallback(
    event => {
      const value = event.value.toString();
      if (!value) onChangeCallback(null, undefined, true);
      if (event.inputType === 'deleteContentBackward') {
        if (/.+\/$/.test(event.prevValue)) return value.substring(0, value.length - 1);
        return true;
      }
      if (!parsingDateTools.matcher.test(value)) return false;

      const parsed = value.match(parsingDateTools.matcher);
      const parts = parsed.slice(1, parsed.length);

      const test = parts
        .reduce((res: string, current: string, index: number): string => {
          res += current;
          if (
            (parsingDateTools.matchedParts[index].length < 2 && current.length === 2) ||
            current.length === parsingDateTools.matchedParts[index].length
          ) {
            return parsingDateTools.separators.length < index ? res : res + parsingDateTools.separators[index];
          }

          if (current.length === 1 && current !== '0' && event.lastTextInput === parsingDateTools.separators[index]) {
            if (parsingDateTools.matchedParts[index].length === 2) return res.substring(0, res.length - 1) + `0${current[0]}${event.lastTextInput}`;
            if (parsingDateTools.matchedParts[index].length === 1 && typeof parts[index + 1] !== 'undefined' && !parts[index + 1])
              return res + current[0] + event.lastTextInput;
          }

          return res;
        }, '')
        .substring(0, dateFormatString.length);

      if (test.length === dateFormatString.length) {
        const date = getParseDate(parsingDateTools.partsIndexes, parts);
        if (date instanceof Date && !isNaN(date.getTime())) setDepth(PickerDepth.DAY);
        onChangeCallback(date, undefined, true);
      }

      return test;
    },
    [parsingDateTools, onChangeCallback],
  );

  const closeCallback = useCallback((e: MouseEvent): void => {
    const wrapperDiv = wrapperRef.current;
    const datePickerDiv = datePickerRef.current;
    if (wrapperDiv && datePickerDiv && !datePickerDiv.contains((e as any).target) && !wrapperDiv.contains((e as any).target)) closePickerCallback();
  }, []);

  /** HEADER Rendering **/
  const monthsShown = useMemo(() => {
    if (
      drillDownCalendar ||
      (isMobile && !forceTwoMonthsOnMobile) ||
      (minDate && maxDate && minDate.getMonth() === maxDate.getMonth() && minDate.getFullYear() === maxDate.getFullYear())
    )
      return 1;
    return monthsToShow;
  }, [minDate, maxDate, monthsToShow, forceTwoMonthsOnMobile, drillDownCalendar]);
  const renderCustomHeader = useCallback(
    ({
      monthDate,
      customHeaderCount,
      decreaseMonth,
      decreaseYear,
      increaseMonth,
      increaseYear,
      prevMonthButtonDisabled,
      prevYearButtonDisabled,
      nextMonthButtonDisabled,
      nextYearButtonDisabled,
    }) => {
      const isYearHeader = depth === PickerDepth.YEAR;
      const isDayHeader = depth === PickerDepth.DAY;
      const prevMonth =
        monthsShown > 1 && customHeaderCount !== 0 ? null : (
          <Button
            ariaLabel={`Previous ${isDayHeader ? 'Month' : 'Year'}`}
            onClick={isDayHeader ? decreaseMonth : decreaseYear}
            disabled={isDayHeader ? prevMonthButtonDisabled : prevYearButtonDisabled}>
            <Icon name="prevMonth" />
          </Button>
        );
      const nextMonth =
        monthsShown > 1 && customHeaderCount < monthsShown - 1 ? null : (
          <Button
            ariaLabel={`Next ${isDayHeader ? 'Month' : 'Year'}`}
            onClick={isDayHeader ? increaseMonth : increaseYear}
            disabled={isDayHeader ? nextMonthButtonDisabled : nextYearButtonDisabled}>
            <Icon name="nextMonth" />
          </Button>
        );

      return (
        <ui.DatePickerHeader>
          {prevMonth}
          <ui.DatePickerHeaderMonth>{formatDate(monthDate, { month: isYearHeader ? undefined : 'short', year: 'numeric' })}</ui.DatePickerHeaderMonth>
          {nextMonth}
        </ui.DatePickerHeader>
      );
    },
    [formatDate, monthsShown, drillDownCalendar, depth],
  );

  const formatWeekDay = useCallback((date: string): string => formatDate(weekdaysForTranslation[date], { weekday: 'short' }), [formatDate]);
  /** *** **/
  // Other Memos
  const showPreviousMonthsVal = useMemo(() => {
    if (typeof showPreviousMonths === 'boolean') return showPreviousMonths;
    const currMonth = selectedDate?.getMonth() ?? new Date().getMonth();
    const currYear = selectedDate?.getFullYear() ?? new Date().getFullYear();
    return (
      maxDate?.getMonth() === currMonth &&
      maxDate?.getFullYear() === currYear &&
      ((minDate?.getMonth() ?? -1) < currMonth || (minDate?.getFullYear() ?? 0) < currYear)
    );
  }, [showPreviousMonths, maxDate, minDate, selectedDate]);

  const className = useMemo(() => {
    const names = [inputClassName];
    if (isOpen) names.push('forceFocus');
    return names.join(' ');
  }, [isOpen, inputClassName]);

  const placeholderValue = useMemo(() => {
    if (!showDateFormat) return placeholder;
    return (
      <>
        {placeholder} <InputOptionalLabel>({dateFormatString})</InputOptionalLabel>
      </>
    );
  }, [showDateFormat, placeholder, dateFormatString]);

  const fixedHeight = useMemo(() => !isMobile, []);

  // Effects
  useEffect(() => {
    const wrapperDiv = wrapperRef.current;
    const datePickerDiv = datePickerRef.current;
    if (isOpen && wrapperDiv && datePickerDiv) {
      const whereToDisplayOptions = alignTheElementAroundTheWrapper(wrapperDiv, datePickerDiv, 8);
      if (overflowItsAncestors) setThePositionToTheDesiredElement(datePickerDiv, wrapperDiv, whereToDisplayOptions, 8);
      setOptionsPosition(whereToDisplayOptions);
      requestAnimationFrame(() => (datePickerDiv.querySelector('.react-datepicker__day--keyboard-selected') as HTMLElement)?.focus());
      // ToDo: if the closing way accepted by QA, remove the commented out code
      // document.body.addEventListener('click', closeCallback, { passive: true });
    } //else {
    // document.body.removeEventListener('click', closeCallback);
    // }
  }, [isOpen, overflowItsAncestors, depth]);

  useEffect(() => {
    setDpError(error);
  }, [error]);

  useEffect(() => {
    setSelectedDate(() => {
      if (value instanceof Date) return value;
      if (!value) return null;
      const date = new Date(value);
      return isNaN(date.getTime()) ? new Date() : date;
    });
  }, [value]);
  // end of hooks

  return (
    <ui.DatePickerWrapper ref={wrapperRef}>
      <Input
        value={selectedDate ? formatDate(selectedDate, dateFormat) : ''}
        placeholder={placeholderValue}
        onClick={openPickerCallback}
        onKeyUp={actOnEnterAndSpace(openPickerCallback)}
        name={name}
        tabindex={tabindex}
        id={id}
        inputClassName={className}
        width={width}
        disabled={disabled}
        optional={optional}
        error={dpError}
        clearButton={clearButton}
        onChange={onInputChangeCallback}
        autocomplete="off"
      />
      <ui.DatePickerBox
        ref={datePickerRef}
        $alignment={pickerPosition}
        $width={width}
        $overflowItsAncestors={overflowItsAncestors}
        $column={drillDownCalendar}
      />
      {isOpen &&
        createPortal(
          <DatePicker
            onChange={onChangeCallback as (date: Date | null) => void}
            selected={selectedDate}
            monthsShown={monthsShown}
            minDate={minDate}
            maxDate={maxDate}
            inline
            renderCustomHeader={renderCustomHeader}
            showPreviousMonths={showPreviousMonthsVal}
            fixedHeight={fixedHeight}
            formatWeekDay={formatWeekDay}
            showYearPicker={drillDownCalendar && depth === PickerDepth.YEAR}
            showMonthYearPicker={drillDownCalendar && depth === PickerDepth.MONTH}
            showPopperArrow={false}
            onClickOutside={closePickerCallback}>
            {drillDownCalendar && depth > 0 ? (
              <ui.GoUp>
                <Button onClick={goBackCallback} type="link-2-colors">
                  <Icon name="prevMonth" />
                  {formatMessage(depth === PickerDepth.MONTH ? messages.backToYear : messages.backToMonth)}
                  {'\u00a0\u00a0'}
                </Button>
              </ui.GoUp>
            ) : null}
          </DatePicker>,
          datePickerRef.current!,
        )}
    </ui.DatePickerWrapper>
  );
};
