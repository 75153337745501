export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const RESET_REQUEST = 'RESET_REQUEST';
/**
 * @see {@link http://redux.js.org/docs/basics/Actions.html}
 */
export const action = <T = any>(type: string, payload?: T, callback?: () => void) => {
  return { type, ...payload, callback };
};

/**
 * Creates the object of action types (plain strings) keyed by REQUEST, SUCCESS and FAILURE.
 * @param {String} base - the prefix for action types.
 * @example
 * // createRequestTypes('GET_CONFIG')
 * {
 *  'REQUEST': 'GET_CONFIG_REQUEST',
 *  'SUCCESS': 'GET_CONFIG_SUCCESS',
 *  'FAILURE': 'GET_CONFIG_FAILURE'
 *  }
 *
 * @returns {TRequestTypes} object of action types
 */

export interface TRequestTypes {
  [REQUEST]: string;
  [SUCCESS]: string;
  [FAILURE]: string;
}
export interface TGenericRequestTypes extends TRequestTypes {
  pathParam: string;
}

export const createRequestTypes = (base: string): TRequestTypes => {
  return {
    [REQUEST]: `${base}_${REQUEST}`,
    [SUCCESS]: `${base}_${SUCCESS}`,
    [FAILURE]: `${base}_${FAILURE}`,
  };
};
export const createGenericRequestTypes = (base: string, pathParam: string): TGenericRequestTypes => {
  return { ...createRequestTypes(base), pathParam };
};
