import { ReactElement, useContext, useMemo } from 'react';
import * as ui from './Layout.ui';
import { ApiErrors } from '../ApiErrors';
import { HeroBanner, HeroBannerProps } from '../HeroBanner';
import { ROUTING_PATHS } from 'src/router/routingPaths';
import { FormattedMessage } from '@triplake/lib-intl';
import { messages } from './Layout.translations';
import { BreadCrumbs } from '../BreadCrumbs';
import { DashboardCard } from 'src/pages/DashboardPage/components/DashboardCard';
import { useTheme } from 'styled-components';
import { Theme, LayoutOrder } from 'types/themes';
import { useUser } from 'src/hooks/useUser';
import { getLevel } from 'src/pages/DashboardPage/utils';
import { DeviceTypeContext } from '@triplake/lib-hooks';
import { DeviceContextType } from 'types/types';

interface TLayout {
  path: string;
  children: JSX.Element;
}

interface HeroBannerData {
  [path: string]: HeroBannerProps;
}

const titles: HeroBannerData = {
  [ROUTING_PATHS.login]: {
    title: <FormattedMessage {...messages.login} />,
    image: 'heroImage_login',
    color: 'textColor',
  },
  [ROUTING_PATHS.registration]: {
    title: <FormattedMessage {...messages.registration} />,
    image: 'heroImage_login',
    backgroundPosition: 'center right',
    color: 'textColor',
    // customStyles: 'background-position: center right;'
  },
  [ROUTING_PATHS.forgotPassword]: {
    title: <FormattedMessage {...messages.forgotPassword} />,
    image: 'heroImage_login',
    backgroundPosition: 'center right',
    color: 'textColor',
  },
  [ROUTING_PATHS.user.dashboard]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
  },
  [ROUTING_PATHS.user.profile]: {
    title: <FormattedMessage {...messages.profile} />,
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
  },
  [ROUTING_PATHS.user.myTransactions]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.transactions} />,
  },
  [ROUTING_PATHS.user.milesCalculator]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.milesCalculator} />,
  },
  [ROUTING_PATHS.user.claimMiles]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.climeMiles} />,
  },
  [ROUTING_PATHS.user.myBeneficiaries]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.beneficiaries} />,
  },
  [ROUTING_PATHS.user.myNominees]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.nominees} />,
  },
  [ROUTING_PATHS.user.useMiles.bookAwardTicket]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.awardTicket} />,
  },
  [ROUTING_PATHS.user.useMiles.useMilesForUpgrading]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.upgrade} />,
  },
  [ROUTING_PATHS.user.useMiles.useMilesForExtraBaggage]: {
    image: 'header_levelX_bg',
    backgroundSize: 'contain',
    title: <FormattedMessage {...messages.extraBaggage} />,
  },
  '*': {
    backgroundSize: 'contain',
    image: 'header_levelX_bg',
  },
};

const Extras: { [key: string]: (props?: any) => ReactElement } = {
  [ROUTING_PATHS.user.dashboard]: (props?: any): ReactElement => <DashboardCard {...props} />,
};

export const Layout = ({ path, children }: TLayout) => {
  const { isMobile } = useContext(DeviceTypeContext) as DeviceContextType;
  const { profile } = useUser();
  const theme: Theme = useTheme() as Theme;
  const order: LayoutOrder = useMemo(
    () => ({
      extras: theme.layoutTheme?.order?.extras || 1,
      breadCrumbs: theme.layoutTheme?.order?.breadCrumbs || 2,
      apiErrors: theme.layoutTheme?.order?.apiErrors || 3,
      contentWrapper: theme.layoutTheme?.order?.contentWrapper || 4,
    }),
    [],
  );
  const componentsStyles = theme?.layoutTheme?.components;
  const heroProps = {
    ...titles[path],
    ...(componentsStyles?.HeroBannerStyles ? { customStyles: componentsStyles?.HeroBannerStyles } : {}),
  };
  if (heroProps.image === 'header_levelX_bg') {
    const level: number = getLevel(profile?.tier.typeKey ?? '', theme.cardLevels);
    heroProps.background = theme.cardLevels[level] && `background: ${theme.cardLevels[level].heroGradient};`;
    heroProps.image = heroProps.image.replace('X', (level + 1).toString());
  }
  if (path === '*') return <ui.LayoutErrorWrapper>{children}</ui.LayoutErrorWrapper>;

  return (
    <ui.LayoutWrapper>
      {order.apiErrors === 0 && <ApiErrors customStyles={componentsStyles?.ApiErrorsStyles} />}
      {order.breadCrumbs === 1 && <BreadCrumbs path={path} customStyles={componentsStyles?.BreadcrumbsStyles} />}
      {order.apiErrors === 1 && <ApiErrors customStyles={componentsStyles?.ApiErrorsStyles} />}
      <HeroBanner {...heroProps} />
      <ui.PageWrapper>
        {Extras.hasOwnProperty(path) && <ui.OrderedElement $order={order.extras}>{Extras[path]()}</ui.OrderedElement>}
        {order.breadCrumbs !== 1 && (
          <ui.OrderedElement $order={order.breadCrumbs}>
            <ui.FlexRowForPlaceholder>
              <BreadCrumbs path={path} customStyles={componentsStyles?.BreadcrumbsStyles} />
              {!isMobile && <div id="FilterPortalPlaceHolder" />}
            </ui.FlexRowForPlaceholder>
          </ui.OrderedElement>
        )}
        {order.apiErrors > 1 && (
          <ui.OrderedElement $order={order.apiErrors}>
            <ApiErrors customStyles={componentsStyles?.ApiErrorsStyles} />
          </ui.OrderedElement>
        )}
        <ui.OrderedElement $order={order.contentWrapper}>
          <ui.ContentWrapper $customStyles={componentsStyles?.PageWrapperStyles}>{children}</ui.ContentWrapper>
        </ui.OrderedElement>
      </ui.PageWrapper>
    </ui.LayoutWrapper>
  );
};
